import { FC, useRef } from 'react';
import { rgba } from 'polished';
import styled from 'styled-components/macro';

import { HEADER_DESKTOP_HEIGHT } from 'src/configs';
import { useWithScroll } from 'src/hooks/useWithScroll';
import { media } from 'src/modules/mediaQuery';
import TwoColumnLayout, {
  RightColumn,
  LeftColumn,
} from 'src/components/common/TwoColumnLayout';
import { CustomButton } from 'src/components/shared/CustomButton';
import { LargeDesktop } from 'src/components/shared/CResponsive';
import { PriceDisplay } from 'src/components/PriceDisplay';
import { I18n } from 'src/components/I18n';
import { SectionId } from 'src/components/pages/HotelDetails/useSectionsNavigator';
import type { DefaultAppMessagesTypeKey } from 'src/containers/ConnectedIntl/messages/defaultMessages';

const Wrapper = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: ${(p) => p.theme.custom.roomStickyHeaderTextColor};
  background: ${(p) => p.theme.custom.roomsStickyHeaderBgColor};
  box-shadow: 5px 5px 30px ${(p) => rgba(p.theme.custom.blackMainColor, 0.2)};
  z-index: 1000;

  ${TwoColumnLayout} {
    width: 95%;
    margin: 0 auto;

    ${media.large`
      width: 86%;
    `}
  }

  ${RightColumn}, ${LeftColumn} {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  ${RightColumn} {
    justify-content: flex-end;
  }
`;

const List = styled.ul`
  margin: 0;
  list-style: none;
`;

const Li = styled.li`
  display: inline-block;
  padding: 22px 0;
  margin-right: 30px;
`;

const ViewDetailsButton = styled(CustomButton)`
  margin: 0 20px;
  background: ${({ theme }) => theme.custom.mainColor };
  color: ${({ theme }) => theme.custom.roomViewDetailsButtonColor };
  border-radius: ${({ theme }) => theme.custom.mainBorderRadius };
  padding: 12px 24px;

  transition: opacity 0.3s ease;
  
  &:hover {
    background: ${({ theme }) => theme.custom.mainColor };
    opacity: 0.8;
  }

  &:active {
    background: ${({ theme }) => theme.custom.mainColor };
  }

  &:focus {
    background: ${({ theme }) => theme.custom.mainColor };
  }

  ${media.large`
    min-width: 187px;
  `}
`;

const ArrowIcon = styled.div.attrs({ className: 'icon-arrow-down' })`
  border-radius: 50%;
  border: 1px solid ${(p) => p.theme.custom.grayTextColor};
  width: 27px;
  height: 27px;
  line-height: 27px !important;
  font-size: 12px;
  color: ${(p) => p.theme.custom.grayTextColor};
  transform: rotate(180deg);

  ${media.large`
    margin-right: 12px;
  `}
`;

type LinkButtonProps = {
  isActive?: boolean;
};

const LinkButton = styled(CustomButton).attrs({
  btnType: 'link',
})<LinkButtonProps>`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${(p) =>
    p.isActive
      ? p.theme.custom.actionColor
      : p.theme.custom.roomStickyHeaderTextColor} !important;
  white-space: nowrap;
`;

const GoBackTopButton = styled(LinkButton)`
  display: flex;
  align-items: center;
  text-transform: uppercase;

  &:hover,
  &:focus,
  &:active {
    ${ArrowIcon} {
      border-color: inherit;
      color: inherit;
    }
  }
`;

const PriceWrapper = styled.div`
  text-align: right;
`;

const PriceNote = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: ${(p) => p.theme.custom.roomStickyHeaderTextColor};
`;

const CheapestPackagePrice = styled.div`
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  color: ${(p) => p.theme.custom.greenMain};
`;

interface Item {
  id: SectionId;
  i18nId: DefaultAppMessagesTypeKey;
}

const reviews: Item = {
  id: 'reviews',
  i18nId: 'HOTEL_DETAILS_PAGE.STICKY_HEADER.REVIEWS',
};

const data: Item[] = [
  { id: 'overview', i18nId: 'HOTEL_DETAILS_PAGE.STICKY_HEADER.OVERVIEW' },
  { id: 'rooms', i18nId: 'HOTEL_DETAILS_PAGE.STICKY_HEADER.ROOM_CHOICES' },
  {
    id: 'about-hotel',
    i18nId: 'HOTEL_DETAILS_PAGE.STICKY_HEADER.HOTEL_INFORMATION',
  },
  {
    id: 'facilities',
    i18nId: 'HOTEL_DETAILS_PAGE.STICKY_HEADER.FACILITIES',
  },
];

interface StickyHeaderProps {
  activeId: string;
  onActiveIdChange: (sectionId: SectionId) => void;
  price: number;
  withReviews: boolean;
}

export const StickyHeader: FC<StickyHeaderProps> = ({
  activeId,
  onActiveIdChange,
  price,
  withReviews,
}) => {
  const wrapRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (wrapRef.current) {
      wrapRef.current.style.display =
        window.pageYOffset > HEADER_DESKTOP_HEIGHT ? 'block' : 'none';
    }
  };

  useWithScroll(handleScroll);

  const handleButtonClick = (id: SectionId) => () => {
    onActiveIdChange(id);
  };

  const scrollTop = () => {
    window.scroll({ left: 0, top: 0, behavior: 'smooth' });
  };

  return (
    <Wrapper ref={wrapRef}>
      <TwoColumnLayout>
        <LeftColumn>
          <List>
            {(withReviews ? [...data, reviews] : data).map(({ id, i18nId }) => (
              <Li key={id}>
                <LinkButton
                  isActive={activeId === id}
                  onClick={handleButtonClick(id)}
                >
                  <I18n id={i18nId} />
                </LinkButton>
              </Li>
            ))}
          </List>
        </LeftColumn>
        <RightColumn>
          <PriceWrapper>
            <PriceNote>
              <I18n id="HOTEL_DETAILS_PAGE.STICKY_HEADER.PRICE_NOTE" />
            </PriceNote>
            <CheapestPackagePrice>
              <PriceDisplay valuesInEur={price} roundValue={true} />
            </CheapestPackagePrice>
          </PriceWrapper>
          <ViewDetailsButton
            btnType="primary"
            onClick={handleButtonClick('rooms')}
          >
            <I18n id="HOTEL_DETAILS_PAGE.STICKY_HEADER.VIEW_DETAILS_BTN.TEXT" />
          </ViewDetailsButton>
          <GoBackTopButton onClick={scrollTop}>
            <ArrowIcon />
            <LargeDesktop>
              <I18n id="HOTEL_DETAILS_PAGE.STICKY_HEADER.GO_BACK_TOP_BTN.TEXT" />
            </LargeDesktop>
          </GoBackTopButton>
        </RightColumn>
      </TwoColumnLayout>
    </Wrapper>
  );
};

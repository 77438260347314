const FooterDiscordIcon = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1_1823)">
  <path d="M20.2696 4.87712C18.7906 4.19999 17.2292 3.7196 15.6253 3.44824C15.4059 3.84059 15.2073 4.24426 15.0304 4.65757C13.322 4.40013 11.5846 4.40013 9.87617 4.65757C9.69921 4.2443 9.50063 3.84064 9.28124 3.44824C7.67634 3.72189 6.11391 4.20342 4.63339 4.88067C1.69418 9.22928 0.897404 13.4699 1.29579 17.6503C3.01707 18.9221 4.94367 19.8893 6.99184 20.5098C7.45303 19.8896 7.86113 19.2315 8.21179 18.5427C7.54575 18.2939 6.9029 17.987 6.29068 17.6255C6.45181 17.5087 6.60939 17.3883 6.76166 17.2714C8.54307 18.1091 10.4874 18.5435 12.4559 18.5435C14.4245 18.5435 16.3688 18.1091 18.1502 17.2714C18.3043 17.3971 18.4619 17.5175 18.6212 17.6255C18.0078 17.9876 17.3638 18.2951 16.6966 18.5445C17.0468 19.233 17.4549 19.8905 17.9165 20.5098C19.9664 19.8917 21.8945 18.925 23.6161 17.6521C24.0835 12.8041 22.8176 8.60249 20.2696 4.87712ZM8.71642 15.0794C7.60625 15.0794 6.68907 14.0719 6.68907 12.8325C6.68907 11.593 7.57437 10.5767 8.71288 10.5767C9.85138 10.5767 10.7615 11.593 10.742 12.8325C10.7225 14.0719 9.84784 15.0794 8.71642 15.0794ZM16.1955 15.0794C15.0835 15.0794 14.1699 14.0719 14.1699 12.8325C14.1699 11.593 15.0552 10.5767 16.1955 10.5767C17.3357 10.5767 18.2388 11.593 18.2193 12.8325C18.1998 14.0719 17.3269 15.0794 16.1955 15.0794Z" fill="currentColor"/>
  </g>
  <defs>
  <clipPath id="clip0_1_1823">
  <rect width="22.5115" height="17.0616" fill="white" transform="translate(1.2002 3.44824)"/>
  </clipPath>
  </defs>
  </svg>
)
          
export default FooterDiscordIcon;
          
interface LogoIconProps {
  isContrast?: boolean;
}

const CryptoClubLogo = ({ isContrast = false }: LogoIconProps) => (
  <svg width="303" height="30" viewBox="0 0 303 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3.64502 3.76123V19.9355H9.18701V22.6089H0.800781V3.76123H3.64502Z" fill="white"/>
  <path d="M13.5553 10.7192V17.543C13.5553 19.5124 14.3325 20.4971 15.8868 20.4971C17.4412 20.4971 18.2184 19.5124 18.2184 17.543V10.7192H20.9649V17.604C20.9649 18.5562 20.8469 19.3781 20.6109 20.0698C20.3831 20.6883 19.9884 21.2458 19.4269 21.7422C18.4991 22.5479 17.3191 22.9507 15.8868 22.9507C14.4627 22.9507 13.2867 22.5479 12.359 21.7422C11.7893 21.2458 11.3865 20.6883 11.1505 20.0698C10.9226 19.5164 10.8087 18.6945 10.8087 17.604V10.7192H13.5553Z" fill="white"/>
  <path d="M27.5183 16.1758L23.2458 10.7192H26.5173L29.1662 14.186L31.864 10.7192H35.2331L30.8386 16.1758L35.9289 22.6089H32.6574L29.1662 18.1655L25.5895 22.6089H22.2326L27.5183 16.1758Z" fill="white"/>
  <path d="M39.9432 10.7192V17.543C39.9432 19.5124 40.7203 20.4971 42.2747 20.4971C43.8291 20.4971 44.6063 19.5124 44.6063 17.543V10.7192H47.3528V17.604C47.3528 18.5562 47.2348 19.3781 46.9988 20.0698C46.771 20.6883 46.3763 21.2458 45.8148 21.7422C44.887 22.5479 43.707 22.9507 42.2747 22.9507C40.8506 22.9507 39.6746 22.5479 38.7469 21.7422C38.1772 21.2458 37.7744 20.6883 37.5384 20.0698C37.3105 19.5164 37.1966 18.6945 37.1966 17.604V10.7192H39.9432Z" fill="white"/>
  <path d="M50.2196 10.7192H52.9662V11.7812C53.4708 11.2523 53.9184 10.8901 54.309 10.6948C54.7077 10.4914 55.1798 10.3896 55.725 10.3896C56.4493 10.3896 57.2061 10.6257 57.9955 11.0977L56.7382 13.6123C56.2174 13.238 55.7087 13.0508 55.2123 13.0508C53.7149 13.0508 52.9662 14.182 52.9662 16.4443V22.6089H50.2196V10.7192Z" fill="white"/>
  <path d="M63.8164 20.3994L58.4697 10.7192H61.6558L65.2935 17.5552L68.687 10.7192H71.7876L62.3149 29.0786H59.1777L63.8164 20.3994Z" fill="white"/>
  <path d="M94.6355 4.77441V8.14355C92.9916 6.76823 91.2907 6.08057 89.5329 6.08057C87.5961 6.08057 85.9644 6.77637 84.6379 8.16797C83.3033 9.55143 82.6359 11.2441 82.6359 13.2461C82.6359 15.2236 83.3033 16.8919 84.6379 18.251C85.9725 19.61 87.6083 20.2896 89.5451 20.2896C90.5461 20.2896 91.3965 20.1268 92.0964 19.8013C92.487 19.6385 92.8898 19.4188 93.3049 19.1421C93.7281 18.8654 94.1716 18.5317 94.6355 18.1411V21.5713C93.0079 22.4909 91.2989 22.9507 89.5085 22.9507C86.8148 22.9507 84.5158 22.0107 82.6115 20.1309C80.7154 18.2347 79.7673 15.9479 79.7673 13.2705C79.7673 10.8698 80.5607 8.72949 82.1477 6.84961C84.1008 4.54655 86.6276 3.39502 89.7282 3.39502C91.4209 3.39502 93.0567 3.85482 94.6355 4.77441Z" fill="white"/>
  <path d="M97.1116 10.7192H99.8582V11.7812C100.363 11.2523 100.81 10.8901 101.201 10.6948C101.6 10.4914 102.072 10.3896 102.617 10.3896C103.341 10.3896 104.098 10.6257 104.887 11.0977L103.63 13.6123C103.109 13.238 102.601 13.0508 102.104 13.0508C100.607 13.0508 99.8582 14.182 99.8582 16.4443V22.6089H97.1116V10.7192Z" fill="white"/>
  <path d="M110.708 20.3994L105.362 10.7192H108.548L112.185 17.5552L115.579 10.7192H118.68L109.207 29.0786H106.07L110.708 20.3994Z" fill="white"/>
  <path d="M122.706 29.0786H119.959V10.7192H122.706V12.0132C123.788 10.9308 125.017 10.3896 126.393 10.3896C128.028 10.3896 129.375 10.9919 130.433 12.1963C131.507 13.3926 132.044 14.9022 132.044 16.7251C132.044 18.5073 131.511 19.9925 130.445 21.1807C129.387 22.3607 128.053 22.9507 126.441 22.9507C125.05 22.9507 123.805 22.3932 122.706 21.2783V29.0786ZM129.237 16.7373C129.237 15.598 128.928 14.6702 128.309 13.9541C127.682 13.2298 126.893 12.8677 125.941 12.8677C124.932 12.8677 124.114 13.2176 123.487 13.9175C122.861 14.6174 122.547 15.5369 122.547 16.6763C122.547 17.7912 122.861 18.7108 123.487 19.4351C124.106 20.1431 124.92 20.4971 125.929 20.4971C126.881 20.4971 127.666 20.139 128.285 19.4229C128.919 18.7067 129.237 17.8115 129.237 16.7373Z" fill="white"/>
  <path d="M137.401 13.2827V22.6089H134.655V13.2827H133.483V10.7192H134.655V6.36133H137.401V10.7192H139.538V13.2827H137.401Z" fill="white"/>
  <path d="M140.415 16.5786C140.415 14.8615 141.029 13.4007 142.258 12.1963C143.487 10.9919 144.984 10.3896 146.75 10.3896C148.524 10.3896 150.03 10.9959 151.267 12.2085C152.487 13.4211 153.098 14.9103 153.098 16.6763C153.098 18.4585 152.483 19.9518 151.255 21.1562C150.018 22.3525 148.5 22.9507 146.701 22.9507C144.919 22.9507 143.426 22.3403 142.221 21.1196C141.017 19.9152 140.415 18.4015 140.415 16.5786ZM143.222 16.6274C143.222 17.8156 143.54 18.7555 144.174 19.4473C144.826 20.1471 145.684 20.4971 146.75 20.4971C147.824 20.4971 148.683 20.1512 149.326 19.4595C149.969 18.7677 150.29 17.8441 150.29 16.6885C150.29 15.5329 149.969 14.6092 149.326 13.9175C148.675 13.2176 147.816 12.8677 146.75 12.8677C145.7 12.8677 144.85 13.2176 144.199 13.9175C143.548 14.6174 143.222 15.5207 143.222 16.6274Z" fill="white"/>
  <path d="M176.983 4.77441V8.14355C175.339 6.76823 173.639 6.08057 171.881 6.08057C169.944 6.08057 168.312 6.77637 166.986 8.16797C165.651 9.55143 164.984 11.2441 164.984 13.2461C164.984 15.2236 165.651 16.8919 166.986 18.251C168.32 19.61 169.956 20.2896 171.893 20.2896C172.894 20.2896 173.744 20.1268 174.444 19.8013C174.835 19.6385 175.238 19.4188 175.653 19.1421C176.076 18.8654 176.519 18.5317 176.983 18.1411V21.5713C175.356 22.4909 173.647 22.9507 171.856 22.9507C169.163 22.9507 166.864 22.0107 164.959 20.1309C163.063 18.2347 162.115 15.9479 162.115 13.2705C162.115 10.8698 162.909 8.72949 164.496 6.84961C166.449 4.54655 168.975 3.39502 172.076 3.39502C173.769 3.39502 175.405 3.85482 176.983 4.77441Z" fill="white"/>
  <path d="M182.206 1.99121V22.6089H179.459V1.99121H182.206Z" fill="white"/>
  <path d="M187.832 10.7192V17.543C187.832 19.5124 188.609 20.4971 190.163 20.4971C191.718 20.4971 192.495 19.5124 192.495 17.543V10.7192H195.241V17.604C195.241 18.5562 195.123 19.3781 194.887 20.0698C194.659 20.6883 194.265 21.2458 193.703 21.7422C192.775 22.5479 191.595 22.9507 190.163 22.9507C188.739 22.9507 187.563 22.5479 186.635 21.7422C186.066 21.2458 185.663 20.6883 185.427 20.0698C185.199 19.5164 185.085 18.6945 185.085 17.604V10.7192H187.832Z" fill="white"/>
  <path d="M200.855 1.99121V12.0986C201.953 10.9593 203.198 10.3896 204.59 10.3896C206.193 10.3896 207.528 10.9878 208.594 12.1841C209.66 13.3722 210.193 14.8534 210.193 16.6274C210.193 18.4585 209.656 19.9722 208.582 21.1685C207.516 22.3566 206.169 22.9507 204.541 22.9507C203.166 22.9507 201.937 22.4217 200.855 21.3638V22.6089H198.108V1.99121H200.855ZM207.385 16.7495C207.385 15.6102 207.076 14.6825 206.458 13.9663C205.831 13.2339 205.046 12.8677 204.102 12.8677C203.093 12.8677 202.271 13.2217 201.636 13.9297C201.009 14.6296 200.696 15.541 200.696 16.6641C200.696 17.8197 201.005 18.7433 201.624 19.4351C202.242 20.1431 203.056 20.4971 204.065 20.4971C205.017 20.4971 205.807 20.1431 206.433 19.4351C207.068 18.7189 207.385 17.8237 207.385 16.7495Z" fill="white"/>
  <path d="M214.344 2.72607V8.42139H213.094V2.72607H211.579V1.54639H215.86V2.72607H214.344ZM216.282 8.42139L217.758 0.921387L220.047 5.99951L222.337 0.921387L223.813 8.42139H222.532L221.891 4.6792L220.047 8.54639L218.204 4.6792L217.555 8.42139H216.282Z" fill="white"/>
  <line x1="232.312" y1="0.921387" x2="232.312" y2="29.0786" stroke="white" stroke-opacity="0.2"/>
  <path d="M302.054 12.7087L299.776 13.921C299.417 13.1863 298.972 12.8189 298.441 12.8189C298.188 12.8189 297.972 12.9046 297.792 13.076C297.613 13.2393 297.523 13.4516 297.523 13.7128C297.523 14.17 298.054 14.6231 299.115 15.0721C300.576 15.7008 301.56 16.2804 302.066 16.811C302.572 17.3417 302.825 18.056 302.825 18.9541C302.825 20.1052 302.401 21.0685 301.552 21.8441C300.727 22.5789 299.731 22.9462 298.564 22.9462C296.564 22.9462 295.147 21.9706 294.314 20.0195L296.666 18.9296C296.992 19.5011 297.241 19.8644 297.413 20.0195C297.747 20.3297 298.147 20.4848 298.613 20.4848C299.543 20.4848 300.009 20.0603 300.009 19.2112C300.009 18.7214 299.65 18.2642 298.931 17.8397C298.654 17.7009 298.376 17.5662 298.098 17.4356C297.821 17.305 297.539 17.1703 297.253 17.0315C296.453 16.6396 295.89 16.2477 295.564 15.8559C295.147 15.3579 294.939 14.717 294.939 13.9333C294.939 12.8964 295.294 12.0392 296.004 11.3616C296.731 10.684 297.613 10.3452 298.649 10.3452C300.176 10.3452 301.311 11.133 302.054 12.7087Z" fill="white"/>
  <path d="M292.634 1.91992V22.6032H289.879V1.91992H292.634Z" fill="white"/>
  <path d="M287.853 17.2886H279.306C279.379 18.2683 279.697 19.048 280.261 19.6276C280.824 20.1991 281.547 20.4848 282.428 20.4848C283.114 20.4848 283.681 20.3215 284.13 19.995C284.571 19.6684 285.073 19.0643 285.637 18.1826L287.963 19.4806C287.604 20.0929 287.225 20.6195 286.824 21.0604C286.424 21.4931 285.996 21.8523 285.539 22.138C285.081 22.4156 284.588 22.6197 284.057 22.7503C283.526 22.8809 282.951 22.9462 282.33 22.9462C280.551 22.9462 279.122 22.3748 278.044 21.2318C276.967 20.0807 276.428 18.554 276.428 16.6518C276.428 14.766 276.95 13.2393 277.995 12.0719C279.048 10.9208 280.444 10.3452 282.183 10.3452C283.939 10.3452 285.326 10.9044 286.347 12.0229C287.359 13.1332 287.865 14.6721 287.865 16.6396L287.853 17.2886ZM285.024 15.0354C284.641 13.5659 283.714 12.8311 282.245 12.8311C281.91 12.8311 281.595 12.8842 281.302 12.9903C281.008 13.0883 280.738 13.2352 280.493 13.4312C280.257 13.6189 280.053 13.8475 279.881 14.1169C279.71 14.3864 279.579 14.6925 279.489 15.0354H285.024Z" fill="white"/>
  <path d="M273.755 13.2471V22.603H271V13.2471H269.824V10.6755H271V6.30371H273.755V10.6755H275.898V13.2471H273.755Z" fill="white"/>
  <path d="M256.115 16.5539C256.115 14.8313 256.732 13.3659 257.964 12.1576C259.197 10.9493 260.699 10.3452 262.471 10.3452C264.251 10.3452 265.761 10.9534 267.002 12.1699C268.226 13.3863 268.839 14.8803 268.839 16.6518C268.839 18.4397 268.222 19.9378 266.99 21.1461C265.749 22.3462 264.226 22.9462 262.422 22.9462C260.634 22.9462 259.136 22.3339 257.928 21.1094C256.719 19.9011 256.115 18.3826 256.115 16.5539ZM258.932 16.6029C258.932 17.7948 259.25 18.7377 259.887 19.4317C260.54 20.1338 261.401 20.4848 262.471 20.4848C263.548 20.4848 264.41 20.1378 265.055 19.4439C265.7 18.75 266.022 17.8234 266.022 16.6641C266.022 15.5048 265.7 14.5782 265.055 13.8843C264.402 13.1822 263.54 12.8311 262.471 12.8311C261.418 12.8311 260.565 13.1822 259.911 13.8843C259.258 14.5864 258.932 15.4926 258.932 16.6029Z" fill="white"/>
  <path d="M242.666 11.0923H250.822V3.6958H253.675V22.6035H250.822V13.7742H242.666V22.6035H239.812V3.6958H242.666V11.0923Z" fill="white"/>
  </svg>
);

export default CryptoClubLogo;

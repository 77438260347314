import { useRef, VFC } from 'react';
import { useController } from 'react-hook-form';

import Filter, { FilterProps } from '.';

const FilterSet: VFC<FilterProps> = ({ label, disabled, name, value }) => {
  const {
    field: { value: currValue, onChange },
  } = useController({ name });

  const selected = currValue === value;

  const prevValueRef = useRef(selected ? undefined : currValue);

  return (
    <Filter
      label={label}
      disabled={!selected && disabled}
      checked={selected}
      onChange={(_, checked) => {
        if (checked) {
          prevValueRef.current = currValue;

          onChange(value);
        } else {
          onChange(prevValueRef.current);
        }
      }}
    />
  );
};

export default FilterSet;

import { VFC } from 'react';

import { I18n } from 'src/components/I18n';

import Filter from './Filter';
import useAddToSet from './Filter/useAddToSet';

type Props = {
  propertyTypes: number[];
  propertyTypesSet?: Set<number>;
  disabled: boolean;
};

const PropertyTypesSection: VFC<Props> = ({
  propertyTypes,
  propertyTypesSet,
  disabled,
}) => {
  const { set, handleChange } = useAddToSet('propertyTypes');

  return (
    <>
      {propertyTypes.map((id) => {
        const selected = set.has(id);
        const isInPropertyTypesSet = propertyTypesSet?.has(id);

        if (!isInPropertyTypesSet) {
          return null;
        }

        return (
          <Filter
            checked={selected}
            onChange={(_, checked) => handleChange(checked, id)}
            key={id}
            label={<I18n id={`PROPERTY_TYPE.${id}` as any} />}
            disabled={!selected && (!propertyTypesSet?.has(id) || disabled)}
          />
        );
      })}
    </>
  );
};

export default PropertyTypesSection;

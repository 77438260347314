
import { Controller, useForm } from 'react-hook-form';
import { FormEvent, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import * as yup from 'yup';

import { emailValidation } from 'src/components/shared/FormElements/utils';
import FooterXIcon from 'src/assets/home-page/footer-x-icon';
import FooterTelegramIcon from 'src/assets/home-page/footer-telegram-icon';
import FooterDiscordIcon from 'src/assets/home-page/footer-discord-icon';

import { joinNewsletter } from '../Home/white-label-page/sections/stay-informed-section/api';


import Bg from "./assets/icon.png"
import { 
  MaintenanceContainer, 
  Wrapper,
  Content,
  EntravelIcon,
  Title,
  BoxHeading,
  Subtitle,
  StayInformedForm,
  StyledTextField,
  InformedStyledButton,
  Desc,
  SocialText,
  FooterLinksNetworkItems,
  FooterLinksSectionItem,
} from "./styles"

const DUPLICATED_USER_ERROR_CODE = 17;

const validationSchema = yup.object().shape({
  email: emailValidation,
});

export const MaintenancePage = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [isInformed, setIsInformed] = useState(false);

   const { control, setError, getValues } = useForm<{
      email: string;
    }>({
      resolver: yupResolver(validationSchema),
      defaultValues: { email: '' },
    });

    const handleSendForm = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (isInformed || isLoading) {
        return;
      }

      setIsLoading(true);
      try {
        const result = await joinNewsletter({ email: getValues().email });

        if (result) {
          setIsInformed(true);
        }
      } catch (e: any) {
        console.error(e);
        if (e.data.errorCode === DUPLICATED_USER_ERROR_CODE) {
          return setError('email', { message: 'This user is already informed!' })
        }

        setError('email', { message: 'Something went wrong.' })
      } finally {
        setIsLoading(false);
      }
    }

    return (
      <MaintenanceContainer>
        <Wrapper>
          <Content>
            <Title>We are adding new features, the platform will be online soon</Title>
            <BoxHeading>
              <span>Maintenance</span>{" "}mode
            </BoxHeading>
            <Subtitle>Meanwhile, please sign up for our newsletter</Subtitle>
            <StayInformedForm  onSubmit={handleSendForm} >
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  {...field}
                  placeholder='Your email address'
                  color="focusSecondary"
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              )}
            />
            <InformedStyledButton disabled={isLoading || isInformed} type="submit">
              Submit
              {isLoading ? <CircularProgress size="20px" color="inherit" /> : null}
              {isInformed ? <CheckIcon /> : null}
            </InformedStyledButton>
          </StayInformedForm>
          <Desc>
            I consent to the processing of my data for the purpose of regularly receiving newsletters from  Entravel
          </Desc>
          <SocialText>
            or connect with us
          </SocialText>
          <FooterLinksNetworkItems>
            <FooterLinksSectionItem href="https://twitter.com/entravelhotels" target="_blank">
              <FooterXIcon />
            </FooterLinksSectionItem>
            <FooterLinksSectionItem href="https://t.me/entravel_official" target="_blank">
              <FooterTelegramIcon />
            </FooterLinksSectionItem>
            <FooterLinksSectionItem href="https://discord.com/invite/nHTjZ9sVAu" target="_blank">
              <FooterDiscordIcon />
            </FooterLinksSectionItem>
          </FooterLinksNetworkItems> 
        </Content>
        <EntravelIcon src={Bg} />
      </Wrapper>
    </MaintenanceContainer>
  )
}
import { CHorizontalSelect } from 'src/components/shared/CHorizontalSelect';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import useMatchMedia from 'src/hooks/useMatchMedia';
import { GridIcon } from 'src/icons/Grid';
import { ListIcon } from 'src/icons/List';
import { useTheme } from 'styled-components';
import {
  RoomsFiltersContainer,
  RoomsHeaderContainer,
  RoomsHeaderTitleContainer,
} from './styles';
import { RoomsDisplay, RoomsFilterBy } from './types';
import { HotelDetailsSectionTitle } from '../styles';

export interface RoomsContainerHeaderProps {
  showCount: number;
  allCount: number;
  display: RoomsDisplay;
  onDisplayChange: (value: RoomsDisplay) => void;
}

export const RoomsContainerHeader = ({
  showCount,
  allCount,
  display,
  onDisplayChange,
}: RoomsContainerHeaderProps) => {
  const [filterBy, setFilters] = useLocalStorage<RoomsFilterBy | null>(
    'roomsFilterBy',
    (v) => (v ? Number(v) : null),
    String(RoomsFilterBy.All)
  );

  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));
  return (
    <RoomsHeaderContainer>
      <RoomsHeaderTitleContainer>
        <HotelDetailsSectionTitle>View rooms</HotelDetailsSectionTitle>
        <CHorizontalSelect
          leftValue={RoomsDisplay.Grid}
          rightValue={RoomsDisplay.List}
          onSelect={onDisplayChange}
          selected={display}
          left={
            <>
              <GridIcon />
              Grid
            </>
          }
          right={
            <>
              <ListIcon />
              List
            </>
          }
        />
      </RoomsHeaderTitleContainer>
      <RoomsFiltersContainer>
        {/** @todo waiting for backend
        <div>
          <InlineBlock>
            <RoomsRoundedButton
              selected={filterBy === RoomsFilterBy.All}
              onClick={() => setFilters(RoomsFilterBy.All)}
            >
              All Rooms
            </RoomsRoundedButton>
            <RoomsRoundedButton
              selected={filterBy === RoomsFilterBy.Mapped}
              onClick={() => setFilters(RoomsFilterBy.Mapped)}
            >
              Mapped Rooms
            </RoomsRoundedButton>
            <RoomsRoundedButton
              selected={filterBy === RoomsFilterBy.Unmapped}
              onClick={() => setFilters(RoomsFilterBy.Unmapped)}
            >
              Unmapped Rooms
            </RoomsRoundedButton>
          </InlineBlock>
          <InlineBlock>
            <Tooltip
              msg={`Mapped Rooms: Categorized with full content, images, and details, showing the cheapest price per category.
                Unmapped Rooms: Direct 1-to-1 from the supplier's room name, without categorization or full content.
            `}
            />
          </InlineBlock>
        </div>
        **/}
      </RoomsFiltersContainer>
      Showing {showCount} of {allCount} rooms
    </RoomsHeaderContainer>
  );
};

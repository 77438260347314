import type {
  CountryNameType,
  DefaultAppMessagesTypeKey,
} from 'src/containers/ConnectedIntl/messages/defaultMessages';
import { appConfig } from 'src/modules/app-config';
import { CURRENT_DOMAIN, DOMAIN } from 'src/constants';

import stylesBreakPoints from './breakPoints.json';

export enum Languages {
  'AU' = 'au',
  'DA' = 'da',
  'DE' = 'de',
  'EN' = 'en',
  'EN_GB' = 'en-GB',
  'ES' = 'es',
  'FI' = 'fi',
  'FR' = 'fr',
  'IT' = 'it',
  'JA' = 'ja',
  'NL' = 'nl',
  'NZ' = 'nz',
  'PT' = 'pt',
  'NB' = 'nb',
  'SV' = 'sv',
  'RU' = 'ru',
  'ZH_CN' = 'zh-CN',
  'int' = 'int',
}

interface CountryNames {
  countryName: CountryNameType;
  countryCode: Languages;
  phone: string;
}

export const DEFAULT_LOCALE = 'en';

export const DEFAULT_CURRENCY = 'USD';

export const countryNames: CountryNames[] = [
  {
    countryName: 'DENMARK',
    countryCode: Languages.DA,
    phone: '+45 89 87 05 38',
  },
  {
    countryName: 'GERMANY',
    countryCode: Languages.DE,
    phone: '+49 32 221097712',
  },
  {
    countryName: 'NORWAY',
    countryCode: Languages.NB,
    phone: '+47 23 96 00 23',
  },
  {
    countryName: 'SWEDEN',
    countryCode: Languages.SV,
    phone: '+46 8 403 095 18',
  },
  {
    countryName: 'UNITED_KINGDOM',
    countryCode: Languages.EN_GB,
    phone: '+44 20 3936 3939',
  },
  {
    countryName: 'ITALY',
    countryCode: Languages.IT,
    phone: '+39 06 9480 2019',
  },
  {
    countryName: 'FRANCE',
    countryCode: Languages.FR,
    phone: '+33 1 76 41 03 38',
  },
  {
    countryName: 'SPAIN',
    countryCode: Languages.ES,
    phone: '+34 911 438 345',
  },
  {
    countryName: 'FINLAND',
    countryCode: Languages.FI,
    phone: '+358 9 42454775',
  },
  {
    countryName: 'AUSTRALIA',
    countryCode: Languages.AU,
    phone: '+61285203003',
  },
  {
    countryName: 'NEW_ZEALAND',
    countryCode: Languages.NZ,
    phone: '+6498016073',
  },
  {
    countryName: 'INTERNATIONAL',
    countryCode: Languages.int,
    phone: '+1 888 280 7093',
  },
];

type MenuListItem = { to: string; i18nId: DefaultAppMessagesTypeKey, type: string, status: string };

export const staticPageNavMenuList: MenuListItem[] = [
  {
    to: CURRENT_DOMAIN === DOMAIN.WIREX ? 'https://www.wirexapp.com' : '/about',
    i18nId: 'MAIN_NAV_MENU.ABOUT',
    type: CURRENT_DOMAIN === DOMAIN.WIREX ? 'external' : 'internal',
    status: 'visible',
  },
  {
    to: '/contact',
    i18nId: 'MAIN_NAV_MENU.CONTACT',
    type: 'internal',
    status: 'visible',
  },
  {
    to: '/faq',
    i18nId: 'MAIN_NAV_MENU.FAQ',
    type: 'internal',
    status: 'visible',
  },
  {
    to: '/impressum',
    i18nId: 'MAIN_NAV_MENU.IMPRESSUM',
    type: 'internal',
    status: CURRENT_DOMAIN === DOMAIN.WIREX ? 'hidden' : 'visible',
  },
  {
    to: '/privacy-policy',
    i18nId: 'MAIN_NAV_MENU.PRIVACY',
    type: 'internal',
    status: 'visible',
  },
  {
    to: '/terms-and-conditions',
    i18nId: 'MAIN_NAV_MENU.TERMS',
    type: 'internal',
    status: 'visible',
  },
];

export const HEADER_DESKTOP_HEIGHT = 72;

export const HEADER_MOBILE_HEIGHT = 46;

export const SIDE_FILTER_WIDTH = 295;

export const SEARCH_RESULTS_TOP_CONTAINER_HEIGHT = 82;

export const BOOKING_ITEM_SIDE_PANEL_WIDTH = 235;

export const GOOGLE_RECAPTCHA_URL =
  'https://www.google.com/recaptcha/api.js?render=explicit';
export const GOOGLE_EMBED_URL = `https://www.google.com/maps/embed/v1/place?key=${appConfig(
  'REACT_APP_GOOGLE_MAP_API_KEY'
)}`;
export const DEFAULT_MAP_ZOOM = 14;

export const breakPoints = stylesBreakPoints;

export const LOCALES_MAP = {
  [Languages.DA]: {
    moment: 'da',
    mozio: 'da-dk',
  },
  [Languages.DE]: {
    moment: 'de',
    mozio: 'de-de',
  },
  [Languages.EN]: {
    moment: 'en-gb',
    mozio: 'en-us',
  },
  [Languages.EN_GB]: {
    translation: 'en',
    moment: 'en-gb',
    mozio: 'en-gb',
  },
  [Languages.ES]: {
    moment: 'es',
    mozio: 'es-es',
  },
  [Languages.FI]: {
    moment: 'fi',
    mozio: 'fi-fi',
  },
  [Languages.FR]: {
    moment: 'fr',
    mozio: 'fr-fr',
  },
  [Languages.IT]: {
    moment: 'it',
    mozio: 'it-it',
  },
  [Languages.JA]: {
    moment: 'ja',
    mozio: 'ja-jp',
  },
  [Languages.NB]: {
    translation: 'no',
    moment: 'nb',
    mozio: 'no-no',
  },
  [Languages.NL]: {
    moment: 'nl',
    mozio: 'nl-nl',
  },
  [Languages.RU]: {
    moment: 'ru',
    mozio: 'ru-ru',
  },
  [Languages.ZH_CN]: {
    translation: 'zh_CN',
    moment: 'zh-cn',
    mozio: 'zh-cn',
  },
};

export enum SearchRequestDefaultParameters {
  START = 0,
  LIMIT = 60,
}

export const defaultDateStringFormat = 'YYYYMMDD';
export const cancellationPolicyDateStringFormat = 'DD MMMM YYYY HH:mm';

export const oneAllLibraryUrl = `//${appConfig(
  'ONEALL_HOST'
)}/socialize/library.js`;

export const allowedToConvertCurrenciesPayAtHotel = ['EUR'];

export const arraySerializeDelimiter = ',';

import styled from 'styled-components';

import { media } from 'src/modules/mediaQuery';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeaderText = styled.h1`
  padding: 0;
  margin: 0 0 15px 0;
  font-weight: 900;
  font-size: 64px;
  font-family: Roboto;
  text-align: center;
`;

export const StyledSpan = styled.span`
  font-weight: 400;
  font-size: 16px;
  font-family: Roboto;
  margin-bottom: 100px;
  text-align: center;

  ${media.phone`
    margin-bottom: 50px;
  `}
`;

export const NumsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  height: 400px;
  margin-bottom: 90px;

  ${media.desktop`
    @media (max-height: 800px) {
      height: 300px;
    }
  `}

  ${media.phone`
    height: 150px;
    gap: 25px;
  `}
`;

export const NumImg = styled.img`
  height: 100%;
  user-select: none;
`;

export const BackButton = styled.button`
  padding: 16px 55px;
  border-radius: 40px;
  color: ${(p) => p.theme.custom.whiteMainColor};
  background: ${(p) => p.theme.custom.blackMainColor};
  border: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
`;

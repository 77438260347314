import styled, { useTheme } from 'styled-components/macro';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { I18n } from 'src/components/I18n';
import { ROUTES } from 'src/router';
import { CURRENT_DOMAIN, DOMAIN } from 'src/constants';
import useMatchMedia from 'src/hooks/useMatchMedia';

import { StyledButton, StyledContainer } from './styles';

interface LogoIconProps {
  isContrast?: boolean;
}

export const SignIn = ({ isContrast = false }: LogoIconProps) => {
  const color = isContrast ? '#000000' : '#FFF';
  const history = useHistory();
  const theme = useTheme();
  const isDesktop = useMatchMedia(theme.breakpoints.up('md'));

  const openSignIn = () => {
    history.push(ROUTES.SIGN_IN)
  };
  const openSignUp = () => {
    history.push(ROUTES.SIGN_UP)
  };

  const Text = styled.div`
    color: ${({ theme }) => (CURRENT_DOMAIN === DOMAIN.ENTRAVEL || CURRENT_DOMAIN === DOMAIN.EBP) ? color : theme.custom.headerButtonColor};
    text-transform: uppercase;
  `;
  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={anchorRef}>
      <StyledContainer>
        <StyledButton
          color="buttonSecondary"
          onClick={openSignIn}
          className="styled-button"
        >
          <Text>
            <I18n id="HEADER.SIGN_IN_BUTTON" />
          </Text>
        </StyledButton>
        {isDesktop && CURRENT_DOMAIN !== DOMAIN.ENTRAVEL ? (
          <StyledButton
            color="buttonSecondary"
            onClick={openSignUp}
            className="styled-button"
            >
            <Text>
              <I18n id="HOME.REGISTER" />
            </Text>
          </StyledButton>
        ) : null}
      </StyledContainer>
    </div>
  );
};

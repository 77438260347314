import { FC, forwardRef } from 'react';

import { I18n } from 'src/components/I18n';
import { getIconByFacilityId } from 'src/modules/facilities';
import { toI18nKey } from 'src/modules/helpers';
import { HotelInfoSelectorResult } from 'src/store/hotel/selectors';

import { ActiveText, AlignIcon } from '../../../Rooms/GridRoom/styles';
import { FacilityDisplay } from './styles';
import { CURRENT_DOMAIN, DOMAIN } from 'src/constants';

interface FacilitiesListProps {
  items: NonNullable<HotelInfoSelectorResult>['facilityGroups'];
  scrollFn: () => void;
}

export const FacilitiesList: FC<FacilitiesListProps> = ({
  items,
  scrollFn,
}) => {
  const showItems = items.slice(0, 7);

  return (
    <>
      {showItems.map((facilitiesItem) => {
        const iconClass = getIconByFacilityId(facilitiesItem.id);

        return (
          <AlignIcon className={iconClass} gap={8}>
            <FacilityDisplay>
              <I18n id={toI18nKey(facilitiesItem.name, 'FACILITY_GROUPS')} />
            </FacilityDisplay>
          </AlignIcon>
        );
      })}
      <ActiveText
        onClick={scrollFn}
        useActiveText={CURRENT_DOMAIN !== DOMAIN.ENTRAVEL}
      >
        <I18n id={'FACILITIES.SHOW_MORE'} />
      </ActiveText>
    </>
  );
};

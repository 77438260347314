import ToggleButton from '@mui/material/ToggleButton';
import TextField from '@mui/material/TextField';
import styled from 'styled-components/macro';

import { POINTER_FINE } from 'src/configs/muiTheme';
import { CloseIcon } from 'src/icons/CloseIcon';
import { StarIcon } from 'src/icons/StarIcon';

export const StyledTextField = styled(TextField)`
  input {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
    text-align: right;
  }

  & .MuiInputBase-input {
    color: ${({ theme }) => theme.custom.secondaryActionColor};
    border-color: ${({ theme }) => theme.custom.secondaryActionColor};
    border-width: 1px;
    
  }

  & .MuiInputBase-root {
    border-radius: 6px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.custom.secondaryActionColor};
    border-width: 1px
  }
`;

export const FiltersTabButton = styled(ToggleButton)`
   {
    padding: 5px 10px;
    height: 28px;
    ${({ disabled, theme, selected }) =>
      disabled
        ? {
            backgroundColor: '#F9F9F9',
            color: '#757B7F !important',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '20px',
            border: 'none!important',
          }
        : selected
        ? {
            backgroundColor: `${theme.custom.filterTagBackgroundColor} !important`,
            borderRadius: '32px',
            border: '1px solid var(--button-button-primary-invert, #11171E)',
            color: '#000',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '20px',
            [POINTER_FINE]: {
              '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
              },
            },
          }
        : {
            backgroundColor: '#FFF',
            borderRadius: '32px',
            border: '1.5px solid var(--border-border-primary, #DEE1E2)',
            color: '#000',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '20px',
            [POINTER_FINE]: {
              '&:hover': {
                backgroundColor: theme.palette.buttonFilter.dark,
              },
            },
          }};
  }
  text-wrap: nowrap;
`;

export const StyledClose = styled(CloseIcon)`
  width: 20px;
  height: 20px;
  margin-left: 6px;
`;

export const StyledStarIcon = styled(StarIcon)`
  margin-right: 3px;
`;

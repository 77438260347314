import { FC } from 'react';
import ContentLoader from 'react-content-loader';

import { mainTheme } from 'src/configs/mainTheme';

export const RoomListSkeleton: FC = () => (
  <ContentLoader
    viewBox={'0 0 1076 299'}
    preserveAspectRatio="none"
    speed={2}
    backgroundOpacity={0.2}
    backgroundColor={mainTheme.borderColor}
    foregroundColor={mainTheme.greyUltraLightColor}
  >
    <rect x="10" y="10" width="220" height="225" />
    <rect x="856" y="10" width="220" height="225" />
    <rect x="240" y="10" width="400" height="30" />
    <rect x="240" y="50" width="600" height="30" />
    <rect x="240" y="90" width="600" height="30" />
    <rect x="240" y="130" width="500" height="30" />
    <rect x="240" y="170" width="350" height="30" />
    <rect x="240" y="210" width="550" height="25" />
  </ContentLoader>
);

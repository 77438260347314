export enum RoomsFilterBy {
  All,
  Mapped,
  Unmapped,
}

export enum RoomsDisplay {
  Grid,
  List,
}

import { FC } from 'react';
import styled from 'styled-components/macro';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';

import useMatchMedia from 'src/hooks/useMatchMedia';
import { breakPoints } from 'src/configs';
import {
  RoomGroup,
  CtDomainMarketingPricePresentation,
} from 'src/models/hotels';
import { RoomFacility } from 'src/models/config';
import { Image } from 'src/types';
import { media } from 'src/modules/mediaQuery';
import { I18n } from 'src/components/I18n';
import { TabletDesktop } from 'src/components/shared/CResponsive';
import { CheckoutStatuses } from 'src/models/checkout';

import { Row, Column, Label } from './CommonComponents';
import { RoomPackage } from './RoomPackage';

const StyledRoomPackage = styled(RoomPackage)``;

const Wrapper = styled.div`
  flex-grow: 1;
  position: relative;

  ${StyledRoomPackage}:not(:last-child) {
    ${media.tabletDesktop`
      margin-bottom: 15px;
   `}
  }
`;

const StyledWrapper = styled.div`
  color: ${(p) => p.theme.custom.roomAvailabilityTitle};

  @media (max-width: 767px) {
    padding: 15px;
  }
`;

interface PackagesProps {
  bookBtnsLoading: boolean;
  totalNights?: number;
  pricePresentation: CtDomainMarketingPricePresentation;
  images: Image[];
  packages: RoomGroup[];
  facilities: RoomFacility[];
  onChange?: (roomGroup: RoomGroup) => void;
  status?: CheckoutStatuses;
  priceChangePercent?: null | number;
}

export const RoomPackages: FC<PackagesProps> = ({
  packages,
  facilities,
  images,
  bookBtnsLoading,
  totalNights,
  pricePresentation,
  status,
  priceChangePercent,
}) => {
  const isTabletDesktop = useMatchMedia(`(min-width: ${breakPoints.md}px)`);

  return (
    <Wrapper>
      <TabletDesktop>
        <Row>
          <Column>
            <Label>
              <I18n id="HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.ROOM_TYPE" />
            </Label>
          </Column>
          <Column>
            <Label>
              <I18n id="HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.FACILITY_INFORMATION" />
            </Label>
          </Column>
          <Column>
            <Label>
              <I18n id="HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.PRICE_FOR_STAY" />
            </Label>
          </Column>
        </Row>
      </TabletDesktop>
      {packages.map((pack, index) => (
        <StyledRoomPackage
          key={pack.id}
          showFacilityInfo={isTabletDesktop || index === 0}
          {...{
            ...pack,
            totalNights,
            pricePresentation,
            facilities,
            bookBtnsLoading,
            images,
            status,
            priceChangePercent,
          }}
        />
      ))}
      <Fade in={status === CheckoutStatuses.Pending} mountOnEnter unmountOnExit>
        <StyledWrapper>
          <Typography variant="body2">
            <I18n id="HOTEL_DETAILS_PAGE.ROOMS.CHECKING_AVAILABILITY" />
          </Typography>
        </StyledWrapper>
      </Fade>
    </Wrapper>
  );
};

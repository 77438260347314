interface LogoIconProps {
  isContrast?: boolean;
}

const BitToMeLogo = ({ isContrast = false }: LogoIconProps) => (
  <svg width="167" height="31" viewBox="0 0 167 31" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_14137_17193" maskUnits="userSpaceOnUse" x="10" y="1" width="93" height="30">
  <path d="M102.238 1.36621H10.438V30.1662H102.238V1.36621Z" fill="white"/>
  </mask>
  <g mask="url(#mask0_14137_17193)">
  <path d="M16.538 12.6176C15.238 12.6176 14.238 13.215 13.538 14.1111V8.73438H10.438V23.4706H13.638V22.0766C14.338 23.0723 15.438 23.6697 16.638 23.6697C19.438 23.6697 21.238 21.4792 21.238 18.293C21.238 14.8081 19.338 12.6176 16.538 12.6176ZM15.838 20.8818C14.538 20.8818 13.638 19.7865 13.638 18.0939C13.638 16.5007 14.538 15.3059 15.838 15.3059C17.138 15.3059 18.038 16.5007 18.038 18.0939C18.038 19.687 17.138 20.8818 15.838 20.8818Z" fill="white"/>
  <path d="M24.5379 7.83789C23.5379 7.83789 22.8379 8.63444 22.8379 9.63013C22.8379 10.7254 23.5379 11.4224 24.5379 11.4224C25.5379 11.4224 26.2379 10.6258 26.2379 9.63013C26.2379 8.63444 25.5379 7.83789 24.5379 7.83789Z" fill="white"/>
  <path d="M22.938 23.4712H26.138V12.7178H22.938V23.4712Z" fill="white"/>
  <path d="M34.4382 20.4841C33.9382 20.6832 33.5382 20.8824 33.2382 20.8824C32.6382 20.8824 32.3382 20.5837 32.3382 19.6876V15.5057H34.8382V13.2156H32.2382V10.2285H29.0382V13.2156H27.6382V15.5057H29.0382V20.1854C29.0382 22.3759 30.4382 23.5707 32.3382 23.5707C33.3382 23.5707 34.2382 23.272 34.9382 22.8738L34.4382 20.4841Z" fill="white"/>
  <path d="M86.638 12.1191C84.938 12.1191 83.738 13.0153 83.038 14.6084C82.538 13.0153 81.338 12.1191 79.638 12.1191C78.138 12.1191 76.938 12.8161 76.238 14.1105V12.3183H72.938V23.47H76.238V17.9937C76.238 16.5002 77.038 15.4049 78.238 15.4049C79.338 15.4049 79.938 16.2015 79.938 17.4959V23.47H83.238V17.9937C83.238 16.5002 84.038 15.4049 85.238 15.4049C86.338 15.4049 87.038 16.2015 87.038 17.4959V23.47H90.338V16.5002C90.238 13.8118 88.838 12.1191 86.638 12.1191Z" fill="white"/>
  <path d="M97.0378 12.1191C93.8378 12.1191 91.7378 14.4092 91.7378 17.7946C91.7378 21.1799 93.8378 23.47 97.1378 23.47C99.0378 23.47 100.538 22.773 101.538 21.4786L99.8378 19.6864C99.1378 20.3834 98.2378 20.7816 97.4378 20.7816C96.2378 20.7816 95.3378 20.0847 95.0378 18.6907H102.238C102.438 14.7079 100.638 12.1191 97.0378 12.1191ZM99.0378 16.8984H94.9378C95.1378 15.6041 95.9378 14.6084 97.0378 14.6084C98.2378 14.6084 99.0378 15.5045 99.0378 16.8984Z" fill="white"/>
  <path d="M58.538 2.06314C56.438 1.36616 54.138 1.16702 51.938 1.56529C49.738 1.864 47.638 2.76012 45.838 3.95495C45.638 3.75581 45.438 3.65624 45.138 3.65624C44.838 3.55667 44.438 3.55667 44.138 3.55667C43.838 3.55667 43.438 3.65624 43.138 3.85538C42.838 4.05452 42.538 4.25365 42.338 4.45279C42.138 4.7515 41.938 5.0502 41.838 5.34891C41.738 5.74719 41.638 6.14546 41.738 6.54374C41.838 6.94201 41.938 7.34029 42.238 7.639C41.038 9.43124 40.238 11.5222 39.838 13.6131C39.538 15.8036 39.738 17.9942 40.338 20.0851C41.038 22.1761 42.138 24.0679 43.638 25.661C45.138 27.2541 47.038 28.4489 49.038 29.2454C49.238 29.345 49.338 29.345 49.538 29.4446C51.338 30.042 53.238 30.2411 55.138 30.1416C57.038 30.042 58.838 29.4446 60.538 28.648C61.138 28.3493 61.638 28.0506 62.138 27.6523L63.438 28.9467C63.538 29.0463 63.738 29.0463 63.738 28.8472L65.138 24.8644C66.238 23.4705 67.138 21.8773 67.738 20.1847C70.238 12.6174 66.138 4.55236 58.538 2.06314ZM66.638 19.7864C66.138 21.1804 65.438 22.4748 64.538 23.6696L60.238 25.1631C60.038 25.2627 60.038 25.3623 60.138 25.4618L61.338 26.6567C60.938 26.9554 60.538 27.1545 60.038 27.3536C58.538 28.1502 56.838 28.648 55.138 28.7476C53.438 28.8472 51.738 28.648 50.038 28.1502C43.038 25.8601 39.338 18.492 41.638 11.6218C42.038 10.5265 42.538 9.43124 43.238 8.43555C43.338 8.53512 43.438 8.53512 43.638 8.63469C43.938 8.73425 44.338 8.73425 44.638 8.73425C44.938 8.73425 45.338 8.63468 45.638 8.43555C45.838 8.23641 46.138 8.03727 46.338 7.73856C46.538 7.43986 46.738 7.14115 46.838 6.84244C46.938 6.54374 46.938 6.14546 46.938 5.84676C46.938 5.54805 46.838 5.14977 46.638 4.85107C48.338 3.75581 50.238 3.05883 52.238 2.76012C54.238 2.46141 56.238 2.66055 58.138 3.25796C58.338 3.35753 58.438 3.35753 58.638 3.4571C61.838 4.65193 64.438 6.94201 65.938 10.0286C67.438 13.0157 67.638 16.5006 66.638 19.7864Z" fill="white"/>
  <path d="M56.1381 17.3968C57.1381 16.4011 57.8381 15.505 58.1381 14.9076C58.5381 14.3102 58.7381 13.6132 58.7381 12.8167C58.7381 11.5223 58.2381 10.427 57.3381 9.73002C56.4381 8.93347 55.3381 8.63477 54.1381 8.63477C52.9381 8.63477 51.9381 8.93347 51.1381 9.43132C50.3381 9.92916 49.6381 10.7257 49.0381 11.7214L51.5381 13.2149C52.3381 11.9205 53.1381 11.3231 54.0381 11.3231C54.5381 11.3231 54.9381 11.5223 55.2381 11.821C55.4381 12.0201 55.5381 12.1197 55.5381 12.3188C55.6381 12.518 55.6381 12.7171 55.6381 12.9162C55.6381 13.4141 55.4381 13.8123 55.1381 14.3102C54.7381 14.808 54.2381 15.505 53.3381 16.3016L49.2381 20.4835V22.7735H59.0381V19.9856H53.5381L56.1381 17.3968Z" fill="white"/>
  </g>
  <line x1="111.338" y1="2.68652" x2="111.338" y2="28.8465" stroke="white" stroke-opacity="0.2"/>
  <path d="M120.718 23.2969V8.40284H123.532V20.6697H129.944V23.2969H120.718ZM131.91 10.5749V8.09255H134.723V10.5749H131.91ZM131.91 23.2969V12.1264H134.723V23.2969H131.91ZM138.755 23.2969V12.1677C138.755 11.885 138.762 11.5644 138.776 11.2058C138.789 10.8473 138.845 10.4852 138.941 10.1198C139.045 9.75434 139.231 9.42336 139.5 9.12686C139.844 8.74072 140.217 8.47869 140.617 8.34078C141.023 8.19598 141.417 8.12013 141.796 8.11324C142.175 8.09945 142.499 8.09255 142.768 8.09255H144.154V10.368H142.871C142.43 10.368 142.103 10.4818 141.889 10.7093C141.675 10.93 141.568 11.1955 141.568 11.5058V23.2969H138.755ZM136.997 14.2984V12.1264H144.154V14.2984H136.997ZM150.592 23.6072C149.447 23.6072 148.437 23.3624 147.561 22.8728C146.692 22.3763 146.01 21.6971 145.513 20.8352C145.024 19.9664 144.779 18.9735 144.779 17.8564C144.779 16.6359 145.02 15.574 145.503 14.6707C145.986 13.7675 146.651 13.0676 147.499 12.5711C148.347 12.0677 149.323 11.8161 150.426 11.8161C151.598 11.8161 152.595 12.0919 153.415 12.6435C154.236 13.1951 154.843 13.9709 155.236 14.9707C155.629 15.9705 155.767 17.1462 155.65 18.4977H152.867V17.4634C152.867 16.3256 152.685 15.5085 152.319 15.0121C151.96 14.5087 151.371 14.257 150.55 14.257C149.592 14.257 148.885 14.5501 148.43 15.1362C147.982 15.7154 147.758 16.5739 147.758 17.7116C147.758 18.7528 147.982 19.5596 148.43 20.1319C148.885 20.6973 149.551 20.98 150.426 20.98C150.978 20.98 151.45 20.8594 151.843 20.618C152.236 20.3767 152.536 20.0285 152.743 19.5734L155.556 20.3801C155.136 21.4006 154.47 22.1936 153.56 22.759C152.657 23.3245 151.667 23.6072 150.592 23.6072ZM146.889 18.4977V16.4084H154.295V18.4977H146.889Z" fill="white"/>
  </svg>
);

export default BitToMeLogo;

import { ofType, StateObservable } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { merge, Observable, of } from 'rxjs';
import _isNil from 'lodash/isNil';

import { ActionTypes, CURRENT_DOMAIN, DOMAIN } from 'src/constants';
import { observableApi$ } from 'src/modules/api';
import { changeLocale } from 'src/store/intl/actions';
import { initCurrency } from 'src/store/currency/actions';
import { DEFAULT_LOCALE, DEFAULT_CURRENCY } from 'src/configs';
import { ConfigResponse } from 'src/models/config';
import { mapErrorResponse } from 'src/store/epicHelpers';
import { InitAppAction } from 'src/store/app/actions';
import { ReduxState } from 'src/store/reducers';
import { logEndpointError } from 'src/modules/logError';

export function initApp(
  action$: Observable<InitAppAction>,
  state$: StateObservable<ReduxState>
) {
  return action$.pipe(
    ofType(ActionTypes.INIT_APP),
    mergeMap(() =>
      observableApi$<ConfigResponse>('/config').pipe(
        mergeMap((response) => {
          const { currentCurrency } = state$.value.currency;
          const { currentLocale } = state$.value.intl;
          const host = window.location.host;
          const hostSettings = response.defaultSettings[host];
          const hostLocale = hostSettings?.language ?? DEFAULT_LOCALE;
          const hostCurrency =
            hostSettings?.defaultCurrency ?? DEFAULT_CURRENCY;
          // TODO rewrite code below
          const locale = _isNil(response.locales[currentLocale])
            ? hostLocale
            : currentLocale;

          const isCurrentCurrencyAllowed = !_isNil(
            response.currencies[currentCurrency]
          );
          let currency = isCurrentCurrencyAllowed
            ? currentCurrency
            : hostCurrency;
          const rate = response.currencies[currency];

          if (CURRENT_DOMAIN === DOMAIN.EBP) {
            currency = 'CHF';
          }

          return merge(
            of({
              type: ActionTypes.FETCH_SETTINGS_SUCCESS,
              payload: response,
            }),
            of(initCurrency({ currency, rate })),
            of(changeLocale(locale))
          );
        }),

        catchError((response) => {
          void logEndpointError({ path: '/config', method: 'GET' }, response);

          return mapErrorResponse(ActionTypes.FETCH_SETTINGS_FAILURE, response);
        })
      )
    )
  );
}

import { ReactNode, useCallback, useRef, useState } from 'react';
import { Hide } from 'src/components/common/Hide';
import {
  TooltipContainer,
  TooltipMessage,
  TooltipMessageWrapper,
} from './styles';

interface ToolTipProps {
  msg?: string | ReactNode;
  showUnderCursor?: boolean;
  children: ReactNode;
}

export const Tooltip = ({
  children,
  msg,
  showUnderCursor = false,
}: ToolTipProps) => {
  const [showMsg, setShowMsg] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const updateOffset = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const target = e.target as HTMLDivElement;
      const rect = target.getBoundingClientRect();

      if (showUnderCursor)
        setOffset({
          x: Math.max(e.clientX - rect.left, 0),
          y: Math.max(e.clientY - rect.top, 0),
        });
    },
    [showUnderCursor, setOffset]
  );

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    updateOffset(e);
    setShowMsg(true);
  };

  const handleMouseLeave = () => {
    setShowMsg(false);
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    updateOffset(e);
    setShowMsg((s) => !s);
  };

  return (
    <TooltipContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      {children}
      <Hide hide={!msg || !showMsg}>
        <TooltipMessageWrapper offset={offset}>
          <TooltipMessage>{msg}</TooltipMessage>
        </TooltipMessageWrapper>
      </Hide>
    </TooltipContainer>
  );
};

import { VFC } from 'react';
import { useSelector } from 'react-redux';
import styled, { DefaultTheme, useTheme } from 'styled-components';

import { Button } from 'src/components/common/WrappedButtons';
import useToggler from 'src/hooks/useToggler';
import { currencySelector } from 'src/store/currency/selectors';
import { getCurrencySymbol } from 'src/modules/getCurrencySymbol';
import { CurrencyIcon } from 'src/icons/Currency';
import { I18n } from 'src/components/I18n';
import useMatchMedia from 'src/hooks/useMatchMedia';
import TogglerButton from 'src/components/common/TogglerButton';

import TabSection from '../Sections/TabSection';
import CurrencySection from '../Sections/CurrencySection';

import { CurrencyLocaleModal } from './CurrencyLocaleModal';

const CurrencySelect = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
`

const StyledTogglerButton = styled(TogglerButton)`
  font-weight: 400;
  :hover {
    background: inherit;
  }
`

interface StyledButtonProps {
  isContrast: boolean;
  theme: DefaultTheme;
}

const StyledButton = styled(Button)(({ isContrast, theme }: StyledButtonProps) => (
  `
    color: ${isContrast ? theme.custom.headerButtonColor : theme.custom.headerButtonContrastColor};
    font-weight: 400;
  `
));

interface CurrencyButtonProps {
  isContrast: boolean;
}

export const CurrencyButton = ({ isContrast }: CurrencyButtonProps) => {
  const { currentCurrency } = useSelector(currencySelector);
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));
  const { toggler, handleClose, handleOpen } = useToggler();

  const currencySymbol = getCurrencySymbol(currentCurrency);

  return (
    <>
      {isMobile ? (
        <CurrencySelect onClick={handleOpen}>
          <StyledTogglerButton
            size="small"
            variant="inline"
            startIcon={currentCurrency}
            value={currentCurrency}
          />
          Currency
        </CurrencySelect>
      ) : (
        <StyledButton
          isContrast={isContrast}
          startIcon={
            currencySymbol && currencySymbol.length === 1 ? (
              currencySymbol
            ) : (
              <CurrencyIcon />
            )
          }
          color="buttonSecondary"
          size="small"
          onClick={handleOpen}
        >
          {currentCurrency}
        </StyledButton>
      )}
      
      <CurrencyLocaleModal
        title={
          <I18n id="HEADER.NAV_MENU.SELECT_PANEL.CURRENCY_SELECT.ALL_CURRENCIES" />
        }
        open={toggler}
        onClose={handleClose}
      >
        <CurrencySection
          handleClose={handleClose}
          value={currentCurrency}
          Section={TabSection}
        />
      </CurrencyLocaleModal>
    </>
  );
};

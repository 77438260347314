export const cryptoClubTheme = {
  greyMainColor: 'rgba(149, 149, 149, 1)',
  bgGreyColor: 'rgba(213, 213, 213, 1)',
  greySecondaryColor: 'rgba(255, 255, 255, 0.2)',
  greyAlternativeColor: 'rgba(229, 231, 235, 1)',
  mainColor: 'rgba(234, 234, 234, 1)',
  secondaryButtonColor: 'rgba(149, 149, 149, 1)',
  greenSecondaryColor: 'rgba(0, 135, 78, 1)',
  headerBgColor: 'transparent',
  headerBgContrastColor: 'transparent',
  headerButtonColor: 'rgba(234, 234, 234, 1)',
  headerButtonContrastColor: 'rgba(234, 234, 234, 1)',
  headerBottomBorderColor: 'rgba(255, 255, 255, 0.2)',
  mainButtonColor: 'rgba(0, 0, 0, 1)',
  actionColor: 'rgba(242, 209, 125, 1)',
  showMoreTextColor: 'rgba(255, 255, 255, 1)',
  secondaryActionColor: 'rgba(234, 234, 234, 1)',
  textActionColor: 'rgba(0, 0, 0, 1)',
  muiSecondaryActionColor: 'rgba(245, 212, 128, 1)',
  activeBorderColor: 'rgba(0, 0, 0, 1)',
  linkActionColor: 'rgba(0, 0, 0, 1)',
  paymentCardBgColor: 'transparent',
  secondaryButtonTextColor: '#fff',
  footerBgColor: 'rgba(0, 0, 0, 1)',
  footerTextColor: 'rgba(234, 234, 234, 1)',
  footerTopBorderColor: 'transparent',
  footerBorderColor: 'rgba(255, 255, 255, 0.2)',
  footerIconBorder: 'rgba(255, 255, 255, 0.2)',
  nonRefundableColor: 'rgba(219, 31, 34, 1)',
  inputBackgroundColor: 'transparent',
  mainPageBgGradient: 'transparent',
  mainPageContrastColor: 'rgba(242, 209, 125, 1)',
  muiPrimaryColor: 'rgba(245, 212, 128, 1)',

  authTitleColor: 'rgba(255, 255, 255, 1)',

  contrastInputColor: 'rgb(0, 0, 0)',
  inputColor: '#fff',
  contrastInputLabelColor: 'rgb(118, 126, 131)',
  contrastButtonColor: 'rgb(0, 0, 0)',

  searchResultTitleColor: 'rgba(175, 175, 175, 1)',
  searchResultCounterColor: 'rgba(255, 255, 255, 1)',
  filterTagBackgroundColor: '#fff',
  buttonActionColor: 'rgb(0, 0, 0)',

  customFontFamily: '"Inter", sans-serif',
  mainPageTitleFontFamily: '"Poppins", sans-serif',
  mainPageTitleWeight: '500',

  mainBackground: 'rgba(0, 0, 0, 1)',
  mainPageSignInButtonColor: 'rgba(0, 0, 0, 1)',
  signInPageButtonTextColor: 'rgba(255, 255, 255, 1)',
  searchButtonColor: 'rgba(19, 10, 0, 1)',
  searchButtonTextColor: 'rgba(245, 246, 245, 1)',
  mainPageBorderColor: 'rgba(255, 255, 255, 0.2)',
  
  hotelsSectionTitleColor: 'rgba(234, 234, 234, 1)',
  hotelSectionBgColor: 'inherit',
  hotelSectionBorderColor: 'inherit',
  hotelSectionBorderBottomColor: 'rgba(255, 255, 255, 0.2)',
  hotelSectionBorderRadius: '0',
  hotelSectionMobileBorderRadius: '0',
  hotelSectionItemBorderColor: 'inherit',
  hotelSectionSubtitleColor: 'rgba(175, 175, 175, 1)',
  hotelSectionItemColor: 'transparent',

  experienceSectionBgColor: 'rgba(239, 232, 253, 0.25)',
  experienceSectionBorderColor: 'rgba(204, 185, 250, 1)',
  experienceDescriptionColor: 'rgba(175, 175, 175, 1)',

  savingsSectionDescriptionColor: 'rgba(33, 35, 35, 1)',
  savingsItemBgColor: 'rgba(255, 255, 255, 1)',
  savingsItemBorderColor: 'inherit',
  savingsItemDescriptionColor: 'rgba(130, 130, 127, 1)',

  checkoutWrapperBorderColor: 'transparent',
  checkoutSummaryBgColor: 'transparent',

  hotelCardBgColor: 'transparent',
  hotelCardBoxShadow: 'transparent',
  hotelCardRightRadius: '12px',
  hotelCardTitleColor: '#fff',
  hotelCardIconColor: '#fff',
  reviewTitleTextColor: 'rgba(242, 209, 125, 1)',
  reviewCountTextColor: 'rgba(175, 175, 175, 1)',

  priceGuaranteeBorderRadius: '4px',
  priceGuaranteeTextColor: 'rgba(255, 255, 255, 1)',
  priceGuaranteeBgColor: 'rgba(25, 25, 25, 1)',

  priceGuaranteeRoomBorderRadius: '4px',
  priceGuaranteeRoomTextColor: 'rgba(255, 255, 255, 1)',
  priceGuaranteeRoomBgColor: 'rgba(25, 25, 25, 1)',
  priceGuaranteeRoomBorder: 'none',

  ratingNumberTextColor: '#FFF',

  discountBorder: '1px solid rgba(255, 255, 255, 0.2)',
  discountBorderRadius: '4px',
  inputAdornmentColor: 'rgba(175, 175, 175, 1)',
  skeletonColor: 'rgba(217, 217, 217, 0.2)',

  roomContainerPadding: '16px 24px',
  roomContainerBackground: 'transparent',
  roomsSearchButtonTextColor: '#fff',
  roomsStickyHeaderBgColor: 'rgba(0, 0, 0, 1)',
  roomStickyHeaderTextColor: '#fff',
  roomViewDetailsButtonColor: 'rgba(0, 0, 0, 1)',
  roomAvailabilityTitle: 'rgba(255, 255, 255, 1)',
  roomLayoutSelectButtonActiveColor: 'rgba(234, 234, 234, 1)',
  roomLayoutSelectButtonColor: 'rgba(0, 0, 0, 1)',
  roomLayoutSelectBorder: '1px solid rgba(255, 255, 255, 0.2)',
  roomGridItemBgColor: 'transparent',
  roomCardCheckboxActiveColor: '#FFF',
  roomCardCheckboxColor: '#000000',
  roomListHeaderBgColor: 'rgba(255, 255, 255, 0.2)',
  roomListHeaderBgActionColor: 'linear-gradient(90deg, #AD7C33 13.9%, #F2D17D 86.91%)',
  roomListItemBgColor: 'transparent',
  roomInfoIconColor: 'rgba(234, 234, 234, 1)',

  aboutHotelBgColor: 'transparent',
  aboutHotelBorderRadius: '16px',
  aboutHotelBorder: 'rgba(255, 255, 255, 0.2)',
  aboutHotelPadding: '24px',

  footerTitleWeight: '500',

  mainBorderRadius: '150px',
  searchPanelRadius: '150px',
  searchPanelMobileRadius: '16px',
  secondaryBorderRadius: '20px',
  savingsBorderRadius: '32px',
  paymentInputBorder: '8px',  
  inputBorderRadius: '4px',
  paymentBorderRadius: '4px',

  timerColor: 'linear-gradient(90deg, #AD7C33 59.88%, #F2D17D 87.44%)',
  accountNavListActiveColor: 'rgba(255, 255, 255, 1)',

  checkoutSummaryBorderRadius: '16px',
  checkoutSummaryBorderColor: 'rgba(206, 206, 206, 1)',
  checkoutBackgroundColor: 'transparent',
  checkoutTimerLeftBackground: 'linear-gradient(90deg, #AD7C33 59.88%, #F2D17D 87.44%)',

  mainBoxShadow: 'box-shadow: 1px 2px 20px 0px rgba(0, 0, 0, 0.08)',

  greyLightColor: '#EBECF4',
  greyUltraLightColor: '#FAFAFA',
  whiteMainColor: '#FFF',
  blackMainColor: '#23232C',
  blueMainColor: '#2539EB',
  lightBlueColor: '#006ce4',
  blackSecondaryColor: '#3C3C48',
  orangeMainColor: '#FC5110',
  orangeSecondaryColor: '#DD3E02',
  orangeTr: '#FEDCCF',
  orangeAlternativeColor: '#F46311',
  redMainColor: '#FF1B1E',
  redLightColor: '#FFE8E9',
  darkMainColor: '#171723',

  new_orangeSecondaryColor: '#006ce4',
  new_orangeMainColor: '#006ce4',
  new_grayAdditionalColor: '#F8F8F8',
  new_greySecondaryColor: '#E4E4E7',
  new_greyMainColor: '#C4C4C4',
  new_blackAdditionalColor: '#797979',
  new_blackSecondaryColor: '#767E83',
  new_blackMainColor: '#000000',
  new_whiteMainColor: '#FFF',
  new_redMainColor: '#FF1B1E',
} as const;

import { useMemo, VFC } from 'react';

import useAddToSet from './Filter/useAddToSet';

import { StarsRating } from '.';
import { FilterTag } from './FilterTag';
import { Stack } from '@mui/material';

type Props = { disabled: boolean; starsMeta?: Record<number, boolean> };

const StarSection: VFC<Props> = ({ disabled, starsMeta = {} }) => {
  const { set, handleChange } = useAddToSet('stars');

  const renderStars = () => {
    const stars = Array.from({ length: 5 }, (_, index) => {
      const value = (index + 1) as StarsRating;

      const selected = set.has(value);

      return (
        <FilterTag
          key={value}
          value={value}
          selected={selected}
          disabled={!selected && (disabled || !starsMeta[value])}
          onChange={(selected) => handleChange(selected, value)}
          showStar={true}
          showX={false}
        />
      );
    });

    return stars;
  };

  return (
    <Stack spacing={2} direction="row">
      {renderStars()}
    </Stack>
  );
};

export default StarSection;

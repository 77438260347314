import { Button, TextField } from "@mui/material"
import styled, { useTheme } from "styled-components"

import useMatchMedia from "src/hooks/useMatchMedia"

export const MaintenanceContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: url('https://storage.googleapis.com/entravel-dev-hydra/images/maintenance-bg.png') no-repeat center center fixed;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
`

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  font-family: Roboto;
  color: #FFFFFF;
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.span(() => {
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));
  
  return `
    font-size: ${isMobile ? "16px" : "18px"};
    line-height: 130%;
    max-width: 306px;
    color: #FFF;
    text-align: center;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    margin-top: ${isMobile ? "35px" : "82px"};
  `
});

export const BoxHeading = styled.div(() => {
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));
  
  return `
    color: #FFF;
    flex-direction: ${isMobile ? 'column' : 'row'};
    font-size: ${isMobile ? '64px' : '96px'};
    text-align: center;
    padding: 0 5px;
    font-weight: ${isMobile ? '400px' : '600px'};
    line-height: 100%; 
  
    span {
      font-weight: 600;
      letter-spacing: ${isMobile ? '-3.2px' : '-4.8x'};
    }
  `
});

export const Subtitle = styled.p(() => {
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));

  return `
    font-size: ${isMobile ? '16px' : '18px'};
    font-weight: 400;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    color: #FFF;
    font-style: normal;
    text-align: center;
    line-height: 150%;
    margin-top: ${isMobile ? '24px' : '48px'};
  `
})

export const StayInformedForm = styled.form(() => {
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));

  return `
    max-width: ${isMobile ? "344px" : "470px" };
    margin-top: 32px;
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: row;
    margin-top: 16px;
 
    input {
       font-size: ${isMobile ? '14px' : '16px'};
      font-weight: 400;
      background-color: #FFFF;
      width: ${isMobile ? "350px" : "455px" };
    }
  
    button {
      font-family: Roboto !important;
      font-weight: 600;
      top: 0px;
      height: ${isMobile ? "42px" : "52px"};;
      width: ${isMobile ? "100px" : "148px"};
    }
  `
})

export const StyledTextField = styled(TextField)(() => { 
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));

  return `
    max-width: 455px;
    width: 100%;

    & .MuiInputBase-input {
      padding: 14px 24px;
      padding-top: ${isMobile ? "10px" : "16px" };;
    }

    & .MuiInputBase-root {
      border-radius: 150px;
    }

    & .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0,0,0,0.23);
      border-width: 1px
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
`
})

export const InformedStyledButton = styled.button(() => `
    position: absolute;
    right: 0;
    top: 6px;
    width: 148px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    border-top-right-radius: 150px;
    border-bottom-right-radius: 150px;
    background: rgba(18, 18, 18, 1);
    height: 47px;
    border: none;
    gap: 8px;
    cursor: pointer;
  `)

export const StyledButton = styled(Button)(() => `
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    padding: 12px 24px !important;
    font-size: 16px;
    font-weight: 400 !important;
    background: rgba(13, 13, 13, 1);
    border-radius: 150px !important;
    color: #fff;
    display: none;
    margin: 0 auto;
    width: 100%;
    gap: 8px;

    &:hover,
    &:focus {
      background: rgba(13, 13, 13, 1);
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    }
  `)

export const Desc = styled.div(() => {
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));

  return `
    max-width: ${isMobile ? "320px" : "465px" };;
    margin-top: 16px;
    padding: 0 5px;
    font-size: ${isMobile ? "10px" : "14px" };
    text-shadow: ${isMobile ? "0px 0px 8px rgba(0, 0, 0, 0.25)" : "0px"}; 
    line-height: 120%;
    font-weight: 400;
    text-align: center;
    color: #FFF;
  `
})

export const SocialText = styled.div(() => {
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));

  return `
    margin-top: 16px;
    font-size: ${isMobile ? "16px" : "18px" };
    font-weight: ${isMobile ? "400px" : "500px" };
    line-height: 150%;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  `
})

export const FooterLinksNetworkItems = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 8px;
`

export const FooterLinksSectionItem = styled.a`
  line-height: 24px;
  color: #F6F6F6;

  &:hover {
    color: inherit;
  }

  &:visited {
    color: inherit;
  }

  &:active {
    color: inherit;
  }

  &:focus {
    color: inherit;
  }
`

export const EntravelIcon = styled.img(() => {
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));

  return `
    width: 64px;
    height: 64px;
    margin-bottom: ${isMobile ? "44px" : "85px" };;
    color: #FFFF;
  `
})






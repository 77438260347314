export const RoundBackIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.00015 8.00008L3.29304 8.70719L2.58594 8.00008L3.29304 7.29297L4.00015 8.00008ZM9.00015 20.0001C8.44787 20.0001 8.00015 19.5524 8.00015 19.0001C8.00015 18.4478 8.44787 18.0001 9.00015 18.0001V20.0001ZM8.29304 13.7072L3.29304 8.70719L4.70726 7.29297L9.70726 12.293L8.29304 13.7072ZM3.29304 7.29297L8.29304 2.29297L9.70726 3.70719L4.70726 8.70719L3.29304 7.29297ZM4.00015 7.00008H14.5001V9.00008H4.00015V7.00008ZM14.5001 20.0001H9.00015V18.0001H14.5001V20.0001ZM21.0001 13.5001C21.0001 17.0899 18.0899 20.0001 14.5001 20.0001V18.0001C16.9854 18.0001 19.0001 15.9854 19.0001 13.5001H21.0001ZM14.5001 7.00008C18.09 7.00008 21.0001 9.91023 21.0001 13.5001H19.0001C19.0001 11.0148 16.9854 9.00008 14.5001 9.00008V7.00008Z"
      fill="white"
    />
  </svg>
);

import { CURRENT_DOMAIN, DOMAIN } from 'src/constants';
import { media } from 'src/modules/mediaQuery';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  margin: 10px 0;

  display: grid;
  gap: 10px 20px;
  justify-content: start;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  border-radius: 4px;

  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  `}

  ${media.phone`
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(auto-fill, 1fr);
  `}
`;

export const FacilityDisplay = styled.span`
  font-family: Roboto;
  font-size: 14px;
`;

export const FacilitiesUl = styled.ul`
  margin: 0;
  padding: 0;

  & li {
    margin: 2px 0;
  }
`;

import useSWR from 'swr';

import { FiltersForm } from 'src/components/pages/Search/Filters';
import {
  Currencies,
  CollectionItem,
  HotelFacility,
  HotFilterId,
} from 'src/models/config';
import { apiCall } from 'src/modules/api';
import { XOR } from 'src/types';
import { CurrencyType } from 'src/containers/ConnectedIntl/messages/defaultMessages.d';
import { CountryCode } from 'libphonenumber-js';

type Settings = {
  defaultCurrency: CurrencyType;
  language: string;
  supportPhone: string;
  usPricing: boolean;
};

type HotFilter = { id: keyof HotFilterId; name: string } & XOR<
  [
    {
      aliases: Record<keyof FiltersForm, number[]>;
      aliasType: 'include';
    },
    {
      aliases: Record<keyof FiltersForm, number | boolean>;
      aliasType: 'set';
    }
  ]
>;

export enum HotelReviewScoreCategory {
  Poor,
  Fair,
  Good,
  VeryGood,
  Excellent,
}

type ConfigResponse = {
  countryCode: CountryCode;
  countries: Record<string, string>;
  currencies: Currencies;
  locales: Record<string, string>;
  suitableTypes: CollectionItem[];
  hotelScoreCategoryOrder: HotelReviewScoreCategory[];
  facilities: HotelFacility[];
  hotelFacilityGroups: CollectionItem[];
  hotFilters: HotFilter[];
  defaultSettings: Readonly<Record<string, Settings>>;
  propertyTypes: { id: number; name: string }[];
  partner: string;
  featureToggles: {
    ShowAffiliateDashboard: boolean;
    ShowLoyaltyProgram: boolean;
    ShowByBit: boolean;
    [key: string]: boolean;
  };
};

export enum EFilterAction {
  ADD,
  SET,
}

export type HotFilterConfig = {
  value: unknown;
  action: EFilterAction;
  name: keyof FiltersForm;
};

const useConfig = () =>
  useSWR('/config', async (url) => {
    const res = await apiCall<ConfigResponse>(url);

    if (!Object.keys(res).length) {
      throw new Error();
    }

    const { facilities, propertyTypes, ...data } = res;

    const facilitiesGroupMap = new Map<number, number>();

    const filterableFacilities: number[] = [];

    for (let i = 0; i < facilities.length; i++) {
      const { id, groupId, filterable } = facilities[i];

      facilitiesGroupMap.set(id, groupId);

      if (filterable) {
        filterableFacilities.push(id);
      }
    }

    return {
      ...data,
      propertyTypes: propertyTypes?.map((item) => +item.id) || [],
      facilitiesGroupMap,
      filterableFacilities,
      hotFilters: data.hotFilters
        .filter((item) => item.id !== 'hideSoldOut')
        .map((item) => {
          const name = Object.keys(item.aliases)[0] as keyof FiltersForm;

          return {
            id: item.id,
            name,
            ...(item.aliasType === 'include'
              ? { action: EFilterAction.ADD, value: item.aliases[name][0] }
              : { action: EFilterAction.SET, value: item.aliases[name] }),
          };
        }),
    };
  });

export default useConfig;

import { Info, InfoBlock, Title } from 'src/components/shared/InfoBlock';
import { media } from 'src/modules/mediaQuery';
import styled from 'styled-components';

export const Wrapper = styled.ul`
  width: 100%;
  transform: translateZ(0);
  margin: 0;
  columns: 3;

  ${media.extraSmall`
    columns: 2;
  `}
`;

export const NoteText = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: ${({ theme }) => theme.custom.grayTextColor};

  a {
    text-decoration: underline;
  }
`;

export const InfoBlockWrapper = styled.li`
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
`;

export const StyledInfoBlock = styled(InfoBlock)`
  ${Title} {
    font-size: 16px;
  }

  ${Info} {
    padding-left: 25px;
  }
`;

import TextField from '@mui/material/TextField';
import { VFC } from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

import { useIntlFormatMessage } from 'src/hooks/useIntlFormatMessage';

type Props = { isLoading: boolean };

const StyledTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: ${({ theme }) => theme.custom.secondaryActionColor};
    border-color: ${({ theme }) => theme.custom.secondaryActionColor};
    border-width: 1px;
    
  }

  & .MuiInputBase-root {
    border-radius: 6px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.custom.secondaryActionColor};
    border-width: 1px
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

const NameSection: VFC<Props> = ({ isLoading }) => {
  const { field } = useController({ name: 'name' });

  const formatMessage = useIntlFormatMessage();

  return (
    <StyledTextField
      variant="outlined"
      placeholder={formatMessage(
        'SEARCH_PAGE.FILTERS_PANEL.HOTEL_NAME_PLACEHOLDER'
      )}
      {...field}
      value={field.value || ''}
      label={false}
      disabled={isLoading}
      inputProps={{ autoComplete: 'off' }}
    />
  );
};

export default NameSection;

import styled from 'styled-components/macro';

import { I18n } from 'src/components/I18n';
import { ConnectedIntl } from 'src/containers/ConnectedIntl';

import { ServerErrorPage } from '../common/ServerErrorPage';

const PageWrapper = styled.div`
  background: ${(p) => p.theme.custom.backgroundMain};
  height: 100vh;
`;

const ErrorBoundaryFallback = () => {
  const handleRefreshPage = () => {
    window.open('/', "_self")
  };

  return (
    <ConnectedIntl>
      <PageWrapper>
        <ServerErrorPage
          header={<I18n id="ERROR_FALLBACK.HEADER" />}
          text={<I18n id="ERROR_FALLBACK.TEXT" />}
          buttonText={<I18n id="ERROR_FALLBACK.BTN" />}
          onBtnClick={handleRefreshPage}
        />
      </PageWrapper>
    </ConnectedIntl>
  );
};

export default ErrorBoundaryFallback;

import { VFC, memo } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import format from 'date-fns/format';

import { fontSizeSmall } from 'src/modules/fontSizes';
import { STATIC_FORM_VALUES } from 'src/components/pages/HotelDetails';
import { searchParamsSelector } from 'src/store/search/selectors';
import { intlSelector } from 'src/store/intl/selectors';
import { datesLocales } from 'src/components/Calendar/CalendarProvider';

const DatesContainer = styled.div`
  flex-shrink: 0;
  text-align: center;
  color: ${(p) => p.theme.custom.mainColor};

  ${fontSizeSmall}

  span:nth-child(2) {
    margin: 0 5px;
  }
`;

export const DatesDisplay: VFC<{ isStatic?: boolean }> = memo((props) => {
  const { currentLocale } = useSelector(intlSelector);

  const getText = (v: Date | null, defaultValue: string) =>
    v
      ? format(v, 'dd MMM', {
          locale: datesLocales[currentLocale as keyof typeof datesLocales],
        })
      : defaultValue;

  const { dates: reduxDates } = useSelector(searchParamsSelector);

  const dates = props.isStatic ? STATIC_FORM_VALUES.dates : reduxDates;

  return (
    <DatesContainer>
      <span>{getText(dates[0], 'Start date')}</span>
      <span>-</span>
      <span>{getText(dates[1], 'End date')}</span>
    </DatesContainer>
  );
});

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ArrowSlider } from 'src/components/shared/ArrowSlider';
import { InfoIcon as InfoIconDefault } from 'src/icons/Info';
import { CURRENT_DOMAIN, DOMAIN } from 'src/constants';

const containerWidth = 292;

export const Container = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: ${containerWidth}px;
  border-radius: 7px;
  border: 1px solid
    ${(p) =>
      p.active
        ? p.theme.custom.actionColor
        : p.theme.custom.greyAlternativeColor};
  background: ${(p) => p.theme.custom.roomGridItemBgColor};
  margin-bottom: 20px;
  padding-bottom: 30px;
  transition: 0.3s;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 160px;
`;

export const Content = styled.div`
  height: 100%;
  padding: 16px 16px 24px 16px;
`;

export const DataContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DataSection = styled.div<{ mb?: number }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  margin-top: 10px;
  ${(p) => p.mb && `margin-bottom: ${p.mb}px`};
  padding-bottom: 10px;
  ${(p) =>
    !p.mb &&
    `
  background-image: linear-gradient(
    to right,
    ${p.theme.custom.greyMainColor} 15%,
    rgba(255, 255, 255, 0) 0%
  );
  `}
  background-position: bottom;
  background-size: 7px 1px;
  background-repeat: repeat-x;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    background-image: none;
  }
`;

export const ServiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
  gap: 10px;
  cursor: pointer;
  padding: 3px 0;
  color: ${({ theme }) => theme.custom.secondaryActionColor};
`;

export const ServicePrice = styled.span`
  margin: auto;
  margin-right: 0;
  text-wrap: nowrap;
`;

export const TotalsSection = styled(DataSection)`
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: 0;
`;

export const TotalsSectionContent = styled.div<{ align: 'start' | 'end' }>`
  display: flex;
  flex-direction: column;
  align-items: ${(p) => p.align};
  gap: 5px;
  color: ${({ theme }) => theme.custom.secondaryActionColor};
`;

export const NameWrapper = styled.div`
  height: 40px;
`;

export const SectionH2 = styled.h2`
  display: flex;
  gap: 5px;
  align-items: center;
  font-family: Roboto;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.custom.secondaryActionColor};
`;

export const SectionH3 = styled.h3`
  display: inline-block;
  font-family: Roboto;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.custom.secondaryActionColor};
`;

export const TextSecondary = styled.span`
  color: ${(p) => p.theme.custom.greyMainColor};
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
`;

export const TextPrimary = styled.span`
  color: ${(p) => p.theme.custom.blackMainColor};
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  margin-top: 10px;
  text-wrap: nowrap;
`;

export const SummaryPrice = styled.h1`
  display: inline-block
  font-family: Roboto;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  margin: 0;
  padding: 0;
`;

export const CardTag = styled.div`
  display: inline-block;
  padding: 3px 7px;
  color: inherit;
  background: ${(p) => p.theme.custom.whiteMainColor};
  border: 1px solid ${(p) => p.theme.custom.greyAlternativeColor};
  border-radius: 4px;
  font-size: 12px;
  margin-right: 5px;
  margin-bottom: 5px;
`;

export const CardTagsContainer = styled.div<{ expanded: boolean }>`
  max-height: ${(p) => (p.expanded ? 132 : 50)}px;
  overflow-y: ${(p) => (p.expanded ? 'scroll' : 'hidden')};
`;

export const CardTagAlternative = styled(CardTag)`
  font-size: 14px;
  padding: 5px 10px;
  display: flex;
  gap: 5px;
  margin: 0;
  background: ${({ theme }) => theme.custom.priceGuaranteeRoomBgColor};
  border-radius: ${({ theme }) => theme.custom.priceGuaranteeRoomBorderRadius};
  color: ${({ theme }) => theme.custom.priceGuaranteeRoomTextColor};
  border: ${({ theme }) => theme.custom.priceGuaranteeRoomBorder};
`;

export const CardTagFilled = styled(CardTag)<{ hide: boolean }>`
  font-size: 14px;
  padding: 5px 10px;
  display: flex;
  gap: 5px;
  margin: 0;
  color: ${(p) => p.theme.custom.roomViewDetailsButtonColor};
  background: ${(p) => p.theme.custom.actionColor};
  border: 1px solid ${(p) => p.theme.custom.greenMain};
  ${(p) => p.hide && 'opacity: 0'};
`;

export const CardImg = styled.img`
  width: ${containerWidth - 2}px;
  height: 160px;
`;

export const CardImageSlider = styled(ArrowSlider)`
  width: 100% !important;
  margin: 0;
`;

export const CardImageSliderCounter = styled.div`
  padding: 4px 12px;
  position: absolute;
  border-radius: 14px / 50%;
  font-weight: 700;
  font-size: 14px;
  background: color-mix(
    in srgb,
    ${(p) => p.theme.custom.blackMainColor},
    transparent 70%
  );
  color: ${(p) => p.theme.custom.whiteMainColor};
  backdrop-filter: blur(15px);
  left: 13px;
  bottom: 12px;
`;

export const BookButton = styled.button`
  width: calc(100% - 32px);
  align-self: center;
  justify-self: end;
  margin-top: auto;
  padding: 14px 0;
  background: ${({ theme }) => theme.custom.mainColor};
  color: ${({ theme }) => theme.custom.roomViewDetailsButtonColor};
  border-radius: ${({ theme }) => theme.custom.mainBorderRadius};
  font-weight: 500;
  font-size: 16px;
  border: none;
  cursor: pointer;
`;

export const ActiveText = styled.span<{
  useActiveText?: boolean;
  textDecoration?: string;
}>`
  ${(p) => p.textDecoration && 'text-decoration: ' + p.textDecoration};
  color: ${(p) =>
    p.useActiveText ? p.theme.custom.mainColor : p.theme.custom.blackMainColor};
  cursor: pointer;
  font-size: 14px;
`;

export const CardCheckbox = styled.div<{ checked: boolean }>`
  min-width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid;
  position: relative;

  ${(p) =>
    p.checked
      ? `
    border-color: ${p.theme.custom.roomCardCheckboxActiveColor};
    background: ${p.theme.custom.roomCardCheckboxActiveColor};
  `
      : `
    border-color: ${p.theme.custom.grayTextColor};
    background: ${p.theme.custom.roomCardCheckboxColor};
  `}

  &::before {
    position: absolute;
    content: '';
    width: 40%;
    height: 40%;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${(p) => p.theme.custom.whiteMainColor};
    display: ${(p) => (p.checked ? 'block' : 'none')};
  }
`;

export const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
`;

export const AlignIcon = styled.span<{ gap?: number; useActiveText?: boolean }>`
  position: static;
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;
  color: ${(p) => p.useActiveText && p.theme.custom.blackMainColor};
  gap: ${(p) => (p.gap ? p.gap : 3)}px;

  & span {
    line-height: normal !important;
  }
`;

export const NoImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 160px;
  border-bottom: 1px solid ${(p) => p.theme.custom.greyAlternativeColor};
  flex-shrink: 0;
`;

export const ScrollList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 54px;
  overflow-y: scroll;
`;

export const InfoIcon = styled(InfoIconDefault)`
  stroke: ${(p) => p.theme.custom.greyMainColor};
`;

interface LogoIconProps {
  isContrast?: boolean;
}

const BitToMeLogo = ({ isContrast = false }: LogoIconProps) => (
  <svg width="147" height="30" viewBox="0 0 147 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_14432_14754" maskUnits="userSpaceOnUse" x="0" y="0" width="93" height="30">
  <path d="M92.4377 0.599609H0.637695V29.3996H92.4377V0.599609Z" fill="white"/>
  </mask>
  <g mask="url(#mask0_14432_14754)">
  <path d="M6.7377 11.851C5.4377 11.851 4.4377 12.4484 3.7377 13.3445V7.96777H0.637695V22.704H3.8377V21.31C4.5377 22.3057 5.6377 22.9031 6.8377 22.9031C9.6377 22.9031 11.4377 20.7126 11.4377 17.5264C11.4377 14.0415 9.53769 11.851 6.7377 11.851ZM6.0377 20.1152C4.7377 20.1152 3.8377 19.0199 3.8377 17.3272C3.8377 15.7341 4.7377 14.5393 6.0377 14.5393C7.3377 14.5393 8.2377 15.7341 8.2377 17.3272C8.2377 18.9204 7.3377 20.1152 6.0377 20.1152Z" fill="#0046E1"/>
  <path d="M14.7376 7.07129C13.7376 7.07129 13.0376 7.86784 13.0376 8.86353C13.0376 9.95879 13.7376 10.6558 14.7376 10.6558C15.7376 10.6558 16.4376 9.85922 16.4376 8.86353C16.4376 7.86784 15.7376 7.07129 14.7376 7.07129Z" fill="#0046E1"/>
  <path d="M13.1377 22.7046H16.3377V11.9512H13.1377V22.7046Z" fill="#0046E1"/>
  <path d="M24.6379 19.7175C24.1379 19.9166 23.7379 20.1158 23.4379 20.1158C22.8379 20.1158 22.5379 19.8171 22.5379 18.921V14.7391H25.0379V12.449H22.4379V9.46191H19.2379V12.449H17.8379V14.7391H19.2379V19.4188C19.2379 21.6093 20.6379 22.8041 22.5379 22.8041C23.5379 22.8041 24.4379 22.5054 25.1379 22.1072L24.6379 19.7175Z" fill="#0046E1"/>
  <path d="M76.8377 11.3525C75.1377 11.3525 73.9377 12.2487 73.2377 13.8418C72.7377 12.2487 71.5377 11.3525 69.8377 11.3525C68.3377 11.3525 67.1377 12.0495 66.4377 13.3439V11.5517H63.1377V22.7034H66.4377V17.2271C66.4377 15.7336 67.2377 14.6383 68.4377 14.6383C69.5377 14.6383 70.1377 15.4349 70.1377 16.7293V22.7034H73.4377V17.2271C73.4377 15.7336 74.2377 14.6383 75.4377 14.6383C76.5377 14.6383 77.2377 15.4349 77.2377 16.7293V22.7034H80.5377V15.7336C80.4377 13.0452 79.0377 11.3525 76.8377 11.3525Z" fill="#0046E1"/>
  <path d="M87.2375 11.3525C84.0375 11.3525 81.9375 13.6426 81.9375 17.028C81.9375 20.4133 84.0375 22.7034 87.3375 22.7034C89.2375 22.7034 90.7375 22.0064 91.7375 20.712L90.0375 18.9198C89.3375 19.6168 88.4375 20.015 87.6375 20.015C86.4375 20.015 85.5375 19.3181 85.2375 17.9241H92.4375C92.6375 13.9413 90.8375 11.3525 87.2375 11.3525ZM89.2375 16.1318H85.1375C85.3375 14.8375 86.1375 13.8418 87.2375 13.8418C88.4375 13.8418 89.2375 14.7379 89.2375 16.1318Z" fill="#0046E1"/>
  <path d="M48.7377 1.29654C46.6377 0.599554 44.3377 0.400416 42.1377 0.798691C39.9377 1.0974 37.8377 1.99352 36.0377 3.18835C35.8377 2.98921 35.6377 2.88964 35.3377 2.88964C35.0377 2.79007 34.6377 2.79007 34.3377 2.79007C34.0377 2.79007 33.6377 2.88964 33.3377 3.08878C33.0377 3.28791 32.7377 3.48705 32.5377 3.68619C32.3377 3.9849 32.1377 4.2836 32.0377 4.58231C31.9377 4.98059 31.8377 5.37886 31.9377 5.77714C32.0377 6.17541 32.1377 6.57369 32.4377 6.87239C31.2377 8.66463 30.4377 10.7556 30.0377 12.8465C29.7377 15.037 29.9377 17.2276 30.5377 19.3185C31.2377 21.4095 32.3377 23.3013 33.8377 24.8944C35.3377 26.4875 37.2377 27.6823 39.2377 28.4788C39.4377 28.5784 39.5377 28.5784 39.7377 28.678C41.5377 29.2754 43.4377 29.4745 45.3377 29.375C47.2377 29.2754 49.0377 28.678 50.7377 27.8814C51.3377 27.5827 51.8377 27.284 52.3377 26.8857L53.6377 28.1801C53.7377 28.2797 53.9377 28.2797 53.9377 28.0806L55.3377 24.0978C56.4377 22.7038 57.3377 21.1107 57.9377 19.4181C60.4377 11.8508 56.3377 3.78576 48.7377 1.29654ZM56.8377 19.0198C56.3377 20.4138 55.6377 21.7082 54.7377 22.903L50.4377 24.3965C50.2377 24.4961 50.2377 24.5957 50.3377 24.6952L51.5377 25.8901C51.1377 26.1888 50.7377 26.3879 50.2377 26.587C48.7377 27.3836 47.0377 27.8814 45.3377 27.981C43.6377 28.0806 41.9377 27.8814 40.2377 27.3836C33.2377 25.0935 29.5377 17.7254 31.8377 10.8551C32.2377 9.75989 32.7377 8.66463 33.4377 7.66895C33.5377 7.76851 33.6377 7.76851 33.8377 7.86808C34.1377 7.96765 34.5377 7.96765 34.8377 7.96765C35.1377 7.96765 35.5377 7.86808 35.8377 7.66895C36.0377 7.46981 36.3377 7.27067 36.5377 6.97196C36.7377 6.67326 36.9377 6.37455 37.0377 6.07584C37.1377 5.77714 37.1377 5.37886 37.1377 5.08015C37.1377 4.78145 37.0377 4.38317 36.8377 4.08447C38.5377 2.98921 40.4377 2.29223 42.4377 1.99352C44.4377 1.69481 46.4377 1.89395 48.3377 2.49136C48.5377 2.59093 48.6377 2.59093 48.8377 2.6905C52.0377 3.88533 54.6377 6.17541 56.1377 9.26205C57.6377 12.2491 57.8377 15.734 56.8377 19.0198Z" fill="#0046E1"/>
  <path d="M46.3378 16.6302C47.3378 15.6345 48.0378 14.7384 48.3378 14.141C48.7378 13.5436 48.9378 12.8466 48.9378 12.0501C48.9378 10.7557 48.4378 9.6604 47.5378 8.96342C46.6378 8.16687 45.5378 7.86816 44.3378 7.86816C43.1378 7.86816 42.1378 8.16687 41.3378 8.66471C40.5378 9.16256 39.8378 9.95911 39.2378 10.9548L41.7378 12.4483C42.5378 11.1539 43.3378 10.5565 44.2378 10.5565C44.7378 10.5565 45.1378 10.7557 45.4378 11.0544C45.6378 11.2535 45.7378 11.3531 45.7378 11.5522C45.8378 11.7514 45.8378 11.9505 45.8378 12.1496C45.8378 12.6475 45.6378 13.0457 45.3378 13.5436C44.9378 14.0414 44.4378 14.7384 43.5378 15.535L39.4378 19.7169V22.0069H49.2378V19.219H43.7378L46.3378 16.6302Z" fill="#0046E1"/>
  </g>
  <line x1="101.538" y1="1.91992" x2="101.538" y2="28.0799" stroke="#323260" stroke-opacity="0.5"/>
  <path d="M110.918 22.5303V7.63624H113.731V19.9031H120.144V22.5303H110.918ZM122.11 9.80829V7.32595H124.923V9.80829H122.11ZM122.11 22.5303V11.3597H124.923V22.5303H122.11ZM128.955 22.5303V11.4011C128.955 11.1184 128.962 10.7978 128.975 10.4392C128.989 10.0807 129.044 9.71865 129.141 9.35319C129.244 8.98774 129.43 8.65676 129.699 8.36026C130.044 7.97411 130.416 7.71209 130.816 7.57418C131.223 7.42938 131.616 7.35353 131.995 7.34663C132.375 7.33284 132.699 7.32595 132.968 7.32595H134.354V9.60143H133.071C132.63 9.60143 132.302 9.7152 132.089 9.94275C131.875 10.1634 131.768 10.4289 131.768 10.7392V22.5303H128.955ZM127.196 13.5318V11.3597H134.354V13.5318H127.196ZM140.791 22.8406C139.647 22.8406 138.637 22.5958 137.761 22.1062C136.892 21.6097 136.209 20.9305 135.713 20.0686C135.223 19.1998 134.979 18.2069 134.979 17.0898C134.979 15.8693 135.22 14.8074 135.703 13.9041C136.185 13.0009 136.851 12.301 137.699 11.8045C138.547 11.3011 139.523 11.0495 140.626 11.0495C141.798 11.0495 142.795 11.3253 143.615 11.8769C144.436 12.4285 145.042 13.2043 145.436 14.2041C145.829 15.2039 145.966 16.3796 145.849 17.7311H143.067V16.6968C143.067 15.559 142.884 14.7419 142.519 14.2455C142.16 13.7421 141.571 13.4904 140.75 13.4904C139.792 13.4904 139.085 13.7835 138.63 14.3696C138.182 14.9488 137.957 15.8073 137.957 16.945C137.957 17.9862 138.182 18.793 138.63 19.3653C139.085 19.9307 139.75 20.2134 140.626 20.2134C141.178 20.2134 141.65 20.0928 142.043 19.8514C142.436 19.6101 142.736 19.2619 142.943 18.8068L145.756 19.6135C145.336 20.634 144.67 21.427 143.76 21.9924C142.857 22.5579 141.867 22.8406 140.791 22.8406ZM137.089 17.7311V15.6418H144.494V17.7311H137.089Z" fill="#0046E1"/>
  </svg>
);

export default BitToMeLogo;

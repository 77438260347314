import { inputClasses } from '@mui/material/Input';
import { inputLabelClasses } from '@mui/material/InputLabel';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { forwardRef } from 'react';
import styled from 'styled-components/macro';

import { CURRENT_DOMAIN, DOMAIN } from 'src/constants';

type Props = Omit<TextFieldProps, 'color' | 'variant'> & {
  focused: boolean;
  alignRight?: boolean;
  isContrast?: boolean;
};

type StyledTextFieldProps = TextFieldProps & {
  isContrast?: boolean;
};

const StyledTextField = styled(TextField)<StyledTextFieldProps>(
  ({ isContrast, theme }) => `
    .${inputClasses.input} {
      font-weight: 400 !important;
        ::selection {
          background-color: ${theme.custom.actionColor};
          color: white;
        }
    };
    & .MuiInputBase-input {
      color: ${
        isContrast ? theme.custom.contrastInputColor : theme.custom.inputColor
      };
    }
  `
);

const StyledButton = styled.div`
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SearchPanelInput = forwardRef<HTMLDivElement, Props>(
  ({ focused, alignRight, children, isContrast, ...props }, ref) => (
    <StyledTextField
      {...props}
      ref={ref}
      variant="standard"
      color="focusSecondary"
      isContrast={isContrast}
      InputLabelProps={{ ...props.InputLabelProps, focused, shrink: true }}
      //@ts-expect-error
      InputProps={{
        ...props.InputProps,
        ...(children
          ? {
              componentsProps: {
                input: {
                  children,
                },
              },
              inputComponent: StyledButton,
            }
          : {}),
        disableUnderline: true,
        onFocus: (e) => e.target.select(),
      }}
      inputProps={{ tabIndex: -1, ...props.inputProps }}
      sx={
        alignRight
          ? {
              [`.${inputLabelClasses.root}`]: { right: 0, left: 'auto' },
              [`.${inputClasses.input}`]: { textAlign: 'right' },
            }
          : undefined
      }
    />
  )
);

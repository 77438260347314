import { ArrowSlider } from 'src/components/shared/ArrowSlider';
import { media } from 'src/modules/mediaQuery';
import styled from 'styled-components';

export const ListRoomImageSlider = styled(ArrowSlider)`
  margin-right: 25px;
  margin-top: 2px;
  min-width: 286px;
  max-width: 286px;

  border-radius: 5px;
  overflow: hidden;

  ${media.phone`
    margin: auto;
    width: 100%;
    margin-bottom: 10px;
  `}

  ${media.tablet`
    min-width: 200px;
    max-width: 200px;
  `}
`;

export const CardImg = styled.img`
  width: 286px;
  height: 184px;
  object-fit: cover;

  ${media.tablet`
    width: 200px;
  `}
`;

export const NoImageWrapper = styled.div`
  position: relative;
  min-width: 286px;
  max-width: 286px;
  height: 190px;
  border: 1px solid ${(p) => p.theme.custom.greyLightColor};
  border-radius: 5px;
  margin-top: 20px;
  margin-right: 25px;

  ${media.phone`
    margin: auto;
    margin-bottom: 10px;
    padding: 15px;
    width: 100%;
  `}

  ${media.tablet`
    min-width: 200px;
    max-width: 200px;
    height: 133px;
  `}
`;

const FooterUsdtIcon = () => (
  <svg width="41" height="31" viewBox="0 0 41 31" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1_1751)">
  <circle cx="20.2002" cy="15.4482" r="9" fill="#50AF95"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.132 15.4394C19.1917 15.4439 19.4999 15.4624 20.1875 15.4624C20.7344 15.4624 21.1227 15.4459 21.2589 15.4394C23.3725 15.3456 24.95 14.9748 24.95 14.5307C24.95 14.0866 23.3725 13.7162 21.2589 13.621V15.07C21.1207 15.08 20.725 15.1036 20.1781 15.1036C19.5218 15.1036 19.1932 15.076 19.134 15.0705V13.622C17.0249 13.7167 15.4509 14.0876 15.4509 14.5307C15.4509 14.9738 17.0244 15.3446 19.134 15.4389L19.132 15.4394ZM19.132 13.4721V12.1755H16.1887V10.1982H24.2023V12.1755H21.2594V13.4716C23.6514 13.5824 25.4502 14.0601 25.4502 14.6324C25.4502 15.2048 23.6514 15.682 21.2594 15.7932V19.9482H19.1325V15.7917C16.746 15.681 14.9502 15.2038 14.9502 14.6319C14.9502 14.0601 16.744 13.5834 19.132 13.4721Z" fill="#F6F6F6"/>
  </g>
  <defs>
  <clipPath id="clip0_1_1751">
  <rect width="18" height="18" fill="white" transform="matrix(1 0 0 -1 11.2002 24.4482)"/>
  </clipPath>
  </defs>
  </svg>
)
          
export default FooterUsdtIcon;
          
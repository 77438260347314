import { AlignIcon } from '../../HotelDetails/Rooms/GridRoom/styles';
import { FiltersTabButton, StyledClose, StyledStarIcon } from './styled';

interface FiltersTagProps {
  value: any;
  showX?: boolean;
  showStar?: boolean;
  selected: boolean;
  disabled: boolean;
  onChange?: (value: boolean) => void;
}
export const FilterTag = ({
  value,
  selected,
  disabled,
  onChange,
  showX = true,
  showStar = false,
}: FiltersTagProps) => (
  <FiltersTabButton
    value={value}
    selected={selected}
    disabled={disabled}
    onChange={() => onChange?.(!selected)}
  >
    <AlignIcon>
      {showStar && <StyledStarIcon />}

      {value}

      {showX && selected && <StyledClose />}
    </AlignIcon>
  </FiltersTabButton>
);

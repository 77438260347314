import { useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  Divider,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

import { CPopover } from 'src/components/common/CPopover';
import useToggler from 'src/hooks/useToggler';
import useMatchMedia from 'src/hooks/useMatchMedia';
import { logOut } from 'src/store/user/actions';
import { userSelector } from 'src/store/user/selectors';
import useConfig from 'src/hooks/swr/useConfig';
import { CURRENT_DOMAIN, DOMAIN } from 'src/constants';

import { authPopoverProps } from '..';
import { CurrencyButton } from '../../CurrencyButton';

import { AccountButton } from './AccountButton';
import { Content } from './Content';
import { userNavMenu } from './constants';
import { ListItemHeader, StyledList } from './styles';

const CurrencySection = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const LoyaltyWrapper = styled.div`
  color: #0b0b0b;
  font-weight: 500;
  font-size: 17px;
  line-height: 140%;
  display: flex;
  gap: 6px;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 4px;
`;

const LoyaltyNew = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  line-height: 20px;
  padding: 2px 10px;
  background: #0b0b0b;
  border-radius: 30px;
`;

export const CloseIconWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  top: 12px;
  right: 12px;
  color: #0b0b0b;
  opacity: 0.8;
`;

interface AccountProps {
  isContrast?: boolean;
}

export const Account = ({ isContrast = false }: AccountProps) => {
  const { data: config } = useConfig();
  const { toggler, handleClose, handleOpen } = useToggler();
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const { info } = useSelector(userSelector);

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleOpenBurgerMenu = () => {
    setIsBurgerMenuOpen(true);
  };

  const handleCloseBurgerMenu = () => {
    setIsBurgerMenuOpen(false);
  };
  const handleLogout = () => dispatch(logOut());

  const handleRedirectToSupport = () => {
    window.open('/contact');
  };

  const generateName = () =>
    `${info?.name ? info?.name : ''} ${info?.surname ? info?.surname : ''}`;

  return (
    <div ref={anchorRef}>
      <AccountButton
        onClick={isMobile ? handleOpenBurgerMenu : handleOpen}
        isContrast={isContrast}
      />
      <CPopover
        {...authPopoverProps}
        open={toggler}
        anchorEl={anchorRef.current}
        onClickOutside={handleClose}
      >
        <Content onClose={handleClose} />
      </CPopover>

      <Drawer
        open={isBurgerMenuOpen}
        onClose={handleCloseBurgerMenu}
        anchor="right"
      >
        <CloseIconWrapper onClick={handleCloseBurgerMenu}>
          <CloseIcon />
        </CloseIconWrapper>
        <StyledList>
          <ListItem disablePadding onClick={handleCloseBurgerMenu}>
            <ListItemHeader>
              {info?.name || info?.surname ? (
                <Typography variant="h3">{generateName()}</Typography>
              ) : null}
              <ListItemText primary={info?.email} />
            </ListItemHeader>
          </ListItem>
          <Divider />
          <ListItem>
            <CurrencySection>
              <CurrencyButton isContrast={isContrast} />
            </CurrencySection>
          </ListItem>
          <Divider />
          {userNavMenu.map(({ label, url }) => (
            <ListItem
              key={label}
              disablePadding
              onClick={handleCloseBurgerMenu}
            >
              <Link to={url}>
                <ListItemButton>
                  <ListItemText primary={label} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
          <Divider />
          {config?.featureToggles?.ShowLoyaltyProgram ? (
            <ListItem disablePadding onClick={handleCloseBurgerMenu}>
              <Link to="/account/loyalty">
                <ListItemButton>
                  <LoyaltyWrapper>
                    <LoyaltyNew>NEW</LoyaltyNew>
                    Loyalty Program
                  </LoyaltyWrapper>
                </ListItemButton>
              </Link>
            </ListItem>
          ) : null}
          {config?.featureToggles?.ShowAffiliateDashboard &&
            (CURRENT_DOMAIN === DOMAIN.ENTRAVEL || CURRENT_DOMAIN === DOMAIN.EBP) && (
              <ListItem disablePadding onClick={handleCloseBurgerMenu}>
                <Link to="/affiliate-dashboard">
                  <ListItemButton>
                    <ListItemText primary="Affiliate dashboard" />
                  </ListItemButton>
                </Link>
              </ListItem>
            )}
          <ListItem disablePadding onClick={handleCloseBurgerMenu}>
            <ListItemButton onClick={handleRedirectToSupport}>
              <ListItemText primary="Support" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding onClick={handleCloseBurgerMenu}>
            <ListItemButton onClick={handleLogout}>
              <ListItemText primary="Sign out" />
            </ListItemButton>
          </ListItem>
        </StyledList>
      </Drawer>
    </div>
  );
};

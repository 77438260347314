import {
  FC,
  useState,
  useRef,
  useEffect,
  memo,
  useMemo,
  ReactNode,
} from 'react';
import styled from 'styled-components/macro';
import _isFunction from 'lodash/isFunction';

import { getComputedCssProperties } from 'src/modules/helpers';
import { fontSizeSmall } from 'src/modules/fontSizes';
import Collapsed from 'src/components/shared/Collapsed';
import { ExpandButton } from 'src/components/shared/CExpandButton';
import { I18n } from 'src/components/I18n';
import { useToggle } from 'src/hooks/useToggle';
import { CURRENT_DOMAIN, DOMAIN } from 'src/constants';

const Text = styled.div`
  position: relative;
  color: ${(p) => p.theme.custom.grayTextColor};
  font-weight: 300;
  text-align: justify;
  ${fontSizeSmall};

  & > :last-child {
    margin-bottom: 0;
  }

  * {
    line-height: inherit;
  }
`;

const ShowMoreWall = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 100px;
  max-height: 100%;
  background: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.3),
    rgb(255, 255, 255)
  );
  z-index: 1;
`;

const StyledExpandButton = styled(ExpandButton)`
  justify-content: flex-start;
  font-size: 14px;
  line-height: 18px;
`;

const DEFAULT_LINE_HEIGHT = 16;

function getElementProps(element: HTMLElement) {
  const [marginTop, marginBottom] = getComputedCssProperties(element, [
    'marginTop',
    'marginBottom',
  ]) as [number, number];

  return {
    outerHeight: Math.ceil(element.offsetHeight + marginTop + marginBottom),
    marginBottom,
  };
}

/**
 * Calculates max visible height which doesn't overflow a line.
 */
function calculateMaxVisiblePartHeight(
  children: HTMLElement[],
  maxHeight: number
) {
  let height = 0;
  let index = 0;

  while (height <= maxHeight && index < children.length) {
    const currentChildProps = getElementProps(children[index]);
    height += currentChildProps.outerHeight;
    index++;
  }

  const lastIncludedChildProps = getElementProps(children[index - 1]);
  if (lastIncludedChildProps.marginBottom) {
    height -= lastIncludedChildProps.marginBottom;
  }

  return height;
}

type RenderExpandButtonType = (
  collapsed: boolean,
  toggleFn: () => void
) => ReactNode;

interface MultilineCollapseProps {
  className?: string;
  text: string;
  numberOfLinesToShow?: number;
  withMaxVisiblePartHeight?: boolean;
  renderExpandButton?: RenderExpandButtonType;
  onExpandButtonClick?: (collapsed: boolean) => void;
}

export const MultilineCollapse: FC<MultilineCollapseProps> = memo(
  ({
    className,
    text,
    numberOfLinesToShow = 2,
    withMaxVisiblePartHeight = true,
    renderExpandButton,
    onExpandButtonClick,
  }) => {
    const [collapsed, toggleCollapse] = useToggle(true);
    const [shouldExpand, setShouldExpand] = useState(true);
    const [heightOfVisiblePart, setHeightOfVisiblePart] = useState(1);
    const node = useRef<HTMLDivElement>(null);
    // https://stackoverflow.com/questions/30242530/dangerouslysetinnerhtml-doesnt-update-during-render
    const textCompProps = useMemo(
      () => ({
        dangerouslySetInnerHTML: {
          __html: text,
        },
        key: new Date().toString(),
      }),
      [text]
    );
    useEffect(() => {
      const { current } = node;
      if (current) {
        const lineHeight = (getComputedCssProperties(current, [
          'lineHeight',
        ])[0] ?? DEFAULT_LINE_HEIGHT) as number;

        const visibleHeight = lineHeight * numberOfLinesToShow;
        const expandable = current.offsetHeight > visibleHeight;

        if (expandable) {
          const newHeightOfVisiblePart = withMaxVisiblePartHeight
            ? calculateMaxVisiblePartHeight(
                Array.from(current.children) as HTMLElement[],
                visibleHeight
              ) // based on the sum of children absolute height
            : visibleHeight; // based on the line height

          setHeightOfVisiblePart(newHeightOfVisiblePart);
        }

        setShouldExpand(expandable);
      }
    }, [numberOfLinesToShow, text, withMaxVisiblePartHeight]);

    const toggle = () => {
      toggleCollapse();

      if (_isFunction(onExpandButtonClick)) {
        onExpandButtonClick(collapsed);
      }
    };
    if (!text) {
      return null;
    }

    if (!shouldExpand) {
      return (
        <Text
          className={className}
          ref={node}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      );
    }

    const moreLessIntlId = collapsed
      ? 'GENERAL.SHOW_MORE'
      : 'GENERAL.SHOW_LESS';

    const expandButton = renderExpandButton ? (
      renderExpandButton(collapsed, toggle)
    ) : (
      <StyledExpandButton collapsed={collapsed} toggle={toggle}>
        <I18n id={moreLessIntlId} />
      </StyledExpandButton>
    );

    return (
      <>
        <Collapsed collapsed={collapsed} height={heightOfVisiblePart}>
          <Text {...textCompProps} className={className} ref={node} />
          {collapsed && CURRENT_DOMAIN !== DOMAIN.CRYPTO_CLUB && <ShowMoreWall />}
        </Collapsed>
        {expandButton}
      </>
    );
  }
);

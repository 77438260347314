import { VFC, memo } from 'react';
import styled from 'styled-components/macro';
import FavoriteSvgIcon from '@mui/icons-material/Favorite';
import { rgba } from 'polished';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { IconButton } from 'src/components/common/WrappedButtons';
import useFavorite from 'src/hooks/useFavorite';
import { userSelector } from 'src/store/user/selectors';
import { ROUTES } from 'src/router';

interface FavoriteButtonProps {
  hotelId: number;
  className?: string;
}

const StyledIconButton = styled(IconButton)`
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 10px;
  border: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
`;

const FavoriteIcon = styled(FavoriteSvgIcon)<{ $isFavorite?: boolean }>`
  stroke: ${({ theme }) => theme.palette.common.white};
  fill: ${({ theme, $isFavorite }) =>
    $isFavorite
      ? theme.custom.new_orangeMainColor
      : rgba(theme.custom.blackMainColor, 0.66)};
`;

const CButton: VFC<{
  selected?: boolean;
  onClick: () => void;
  className?: string;
}> = ({ className, onClick, selected }) => (
  <StyledIconButton className={className} onClick={onClick}>
    <FavoriteIcon $isFavorite={selected} />
  </StyledIconButton>
);

const CFavoriteButton: VFC<FavoriteButtonProps> = ({ className, hotelId }) => {
  const { handleFavorite, isFavorite } = useFavorite(hotelId);

  return (
    <CButton
      className={className}
      selected={isFavorite}
      onClick={handleFavorite}
    />
  );
};

const AuthButton: VFC<Pick<FavoriteButtonProps, 'className'>> = ({
  className,
}) => {
  const history = useHistory();

  return (
    <CButton className={className} onClick={() => history.push(ROUTES.SIGN_IN)} />
  );
};

const FavoriteButton: VFC<FavoriteButtonProps> = memo((props) => {
  const { isAuthenticated } = useSelector(userSelector);

  return isAuthenticated ? (
    <CFavoriteButton {...props} />
  ) : (
    <AuthButton className={props.className} />
  );
});

export default FavoriteButton;

import { FC, memo } from 'react';

import { I18n } from 'src/components/I18n';
import { HotelInfoSelectorResult } from 'src/store/hotel/selectors';

import { CURRENT_DOMAIN, DOMAIN } from 'src/constants';
import { HotelDetailsSectionTitle } from '../../styles';
import { Facilities } from './Facilities';
import { Wrapper } from './styles';

interface HotelFacilitiesCompactProps {
  hotelFacilities: NonNullable<HotelInfoSelectorResult>['facilityGroups'];
  sectionRef?: (el: HTMLDivElement | null) => void;
  className?: string;
  scrollFn: () => void;
}

export const HotelFacilitiesCompact: FC<HotelFacilitiesCompactProps> = memo(
  ({ hotelFacilities, sectionRef, scrollFn }) => {
    if (!hotelFacilities?.length) {
      return null;
    }

    let radius = 16;
    if (CURRENT_DOMAIN === DOMAIN.ENTRAVEL || CURRENT_DOMAIN === DOMAIN.EBP)
      radius = 5;

    return (
      <Wrapper ref={sectionRef} radius={radius} data-id="facilities">
        <HotelDetailsSectionTitle>
          <I18n id="HOTEL_DETAILS_PAGE.FACILITIES.TITLE" />
        </HotelDetailsSectionTitle>
        <Facilities data={hotelFacilities} scrollFn={scrollFn} />
      </Wrapper>
    );
  }
);

import Slider from '@mui/material/Slider';
import { VFC } from 'react';

import { PriceSectionProps } from '.';

export const DISABLED_SLIDER_MIN = 0;

export const DISABLED_SLIDER_MAX = 1;

type Props = PriceSectionProps & {
  fromValue: number | null;
  toValue: number | null;
  innerFromValue: number | undefined;
  innerToValue: number | undefined;
  onFromChange: (value: number) => void;
  onToChange: (value: number) => void;
  onInnerFromChange: (value: number) => void;
  onInnerToChange: (value: number) => void;
};

const PriceSlider: VFC<Props> = ({
  min,
  max,
  disabled,
  innerFromValue,
  innerToValue,
  fromValue,
  toValue,
  onFromChange,
  onToChange,
  onInnerFromChange,
  onInnerToChange,
}) => {
  min ??= innerFromValue ? innerFromValue : DISABLED_SLIDER_MIN;
  max ??= innerFromValue ? innerFromValue + 1 : DISABLED_SLIDER_MAX;

  return (
    <Slider
      disabled={disabled}
      disableSwap
      value={[innerFromValue ?? min, innerToValue ?? max]}
      onChange={(_, value) => {
        const [from, to] = value as [number, number];

        if (from !== innerFromValue) {
          onInnerFromChange(from);
        } else if (to !== innerToValue) {
          onInnerToChange(to);
        }
      }}
      onChangeCommitted={(_, value) => {
        const [from, to] = value as [number, number];

        if (from !== fromValue) {
          onFromChange(from);
        } else if (to !== toValue) {
          onToChange(to);
        }
      }}
      min={min}
      max={max}
    />
  );
};

export default PriceSlider;

const EbpFooterLogo = () => (
  <svg width="384" height="59" viewBox="0 0 384 59" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_3_106)">
  <path d="M242.213 38.9663L239.832 38.1923C238.047 37.6563 237.932 36.9123 237.932 36.5263C237.932 35.5443 238.765 34.8603 240.104 34.8603C240.376 34.831 240.652 34.859 240.913 34.9426C241.174 35.0262 241.414 35.1635 241.619 35.3457C241.823 35.5279 241.988 35.7509 242.101 36.0004C242.214 36.2499 242.274 36.5203 242.276 36.7943H247.276C247.285 35.8869 247.103 34.9878 246.74 34.1562C246.376 33.3245 245.841 32.5792 245.17 31.9693C244.498 31.3593 243.704 30.8985 242.842 30.6172C241.979 30.3359 241.066 30.2405 240.164 30.3373C239.267 30.2465 238.36 30.3412 237.501 30.6156C236.642 30.89 235.848 31.3382 235.17 31.9324C234.491 32.5266 233.942 33.254 233.557 34.0694C233.171 34.8848 232.957 35.7708 232.929 36.6723C232.929 38.5473 233.703 41.4933 238.345 42.7723L240.755 43.5723C242.838 44.2563 242.838 44.9413 242.838 45.2723C242.838 46.5813 241.588 47.1473 240.279 47.1473C239.681 47.1849 239.091 46.9985 238.624 46.6245C238.156 46.2504 237.845 45.7156 237.75 45.1243H232.602C232.602 49.0243 235.578 51.5513 240.22 51.5513C243.702 51.5513 247.867 49.8253 247.867 45.2433C247.927 43.7863 247.629 40.6323 242.213 38.9663Z" fill="white"/>
  <path d="M10.885 17.0084C11.7309 16.6191 12.4315 15.9714 12.886 15.1587C13.3404 14.3459 13.5253 13.4099 13.414 12.4854C13.4522 11.7785 13.3371 11.0718 13.0766 10.4136C12.8161 9.75543 12.4163 9.16135 11.9048 8.6721C11.3932 8.18285 10.7819 7.81 10.1127 7.5791C9.44359 7.3482 8.73243 7.26472 8.028 7.33438H0.5V28.1644H8.713C9.49582 28.2196 10.2815 28.1086 11.0184 27.8387C11.7552 27.5689 12.4267 27.1462 12.9887 26.5985C13.5507 26.0507 13.9905 25.3903 14.2792 24.6606C14.568 23.9309 14.6991 23.1484 14.664 22.3644C14.7261 21.1771 14.3872 20.0035 13.7018 19.0321C13.0164 18.0607 12.0243 17.3479 10.885 17.0084ZM2.523 9.29838H7.823C9.965 9.29838 11.423 10.3984 11.423 12.4824C11.4817 12.9345 11.4444 13.3939 11.3136 13.8307C11.1827 14.2674 10.9613 14.6716 10.6637 15.017C10.366 15.3624 9.99895 15.6411 9.58633 15.835C9.1737 16.0289 8.72483 16.1337 8.269 16.1424H2.523V9.29838ZM8.683 26.0514H2.523V17.9874H8.475C9.53951 18.0702 10.534 18.5502 11.2611 19.3321C11.9881 20.114 12.3947 21.1407 12.4 22.2084C12.412 22.7058 12.3248 23.2006 12.1436 23.664C11.9623 24.1274 11.6907 24.55 11.3444 24.9074C10.9982 25.2647 10.5843 25.5495 10.1268 25.7452C9.66938 25.941 9.17755 26.0437 8.68 26.0474L8.683 26.0514Z" fill="white"/>
  <path d="M32.548 13.6723C32.6516 12.866 32.5791 12.0468 32.3356 11.2712C32.092 10.4956 31.6833 9.782 31.1374 9.17958C30.5915 8.57716 29.9215 8.10024 29.1736 7.78168C28.4257 7.46313 27.6176 7.3105 26.805 7.33432H19.128V28.1643H21.151V19.9503H24.633L30.257 28.0143H32.697L27.043 19.9503C27.8325 19.9478 28.6125 19.7772 29.3311 19.45C30.0496 19.1228 30.6903 18.6464 31.2105 18.0525C31.7308 17.4586 32.1186 16.7607 32.3484 16.0053C32.5781 15.25 32.6445 14.4543 32.543 13.6713L32.548 13.6723ZM21.148 17.9573V9.32732H26.802C29.569 9.32732 30.551 11.4693 30.551 13.6713C30.6309 14.2196 30.5883 14.7788 30.4263 15.3086C30.2642 15.8385 29.9867 16.3258 29.6138 16.7356C29.2408 17.1454 28.7817 17.4674 28.2694 17.6785C27.7571 17.8895 27.2044 17.9844 26.651 17.9563L21.148 17.9573Z" fill="white"/>
  <path d="M42.903 7.33435L34.928 28.1644H37.07L39.183 22.6004H48.705L50.818 28.0164H52.958L45.135 7.33435H42.903ZM39.927 20.7243L43.944 10.1014L48.11 20.7243H39.927Z" fill="white"/>
  <path d="M69.089 24.5334L58.644 7.33435H56.502V28.0154H58.496V10.6974L69.03 28.0154H71.082V7.33435H69.089V24.5334Z" fill="white"/>
  <path d="M84.834 7.33435H77.064V28.1644H84.834C90.785 28.1644 93.999 23.7304 93.999 17.8644C93.999 11.9984 90.693 7.33435 84.834 7.33435ZM84.834 26.0514H79.058V9.32435H84.834C89.625 9.32435 92.005 12.9544 92.005 17.7154C92.005 22.4764 89.77 26.0514 84.834 26.0514Z" fill="white"/>
  <path d="M104.142 26.3493C102.947 26.4059 101.778 25.9868 100.892 25.1838C100.005 24.3807 99.4726 23.259 99.411 22.0643H97.447C97.509 23.7807 98.2492 25.4024 99.5052 26.5739C100.761 27.7453 102.43 28.3708 104.147 28.3133C105.719 28.4396 107.285 28.0075 108.569 27.0929C109.854 26.1783 110.775 24.84 111.17 23.3133C111.77 19.3553 109.028 17.3623 105.725 16.6483L102.749 16.0233C99.773 15.3393 99.449 13.8233 99.449 12.7503C99.5115 12.2016 99.6826 11.6708 99.9521 11.1888C100.222 10.7068 100.584 10.2832 101.019 9.94262C101.454 9.60205 101.952 9.35131 102.484 9.20499C103.017 9.05868 103.573 9.0197 104.121 9.09033C104.679 9.02833 105.243 9.08302 105.779 9.25095C106.314 9.41887 106.809 9.69635 107.232 10.0657C107.654 10.4351 107.995 10.8884 108.233 11.3967C108.471 11.905 108.601 12.4572 108.614 13.0183H110.608C110.611 12.1924 110.442 11.3748 110.112 10.6177C109.782 9.86065 109.297 9.18058 108.69 8.62082C108.083 8.06106 107.365 7.63385 106.584 7.3664C105.803 7.09895 104.974 6.9971 104.151 7.06733C102.544 6.96893 100.962 7.50682 99.7487 8.56478C98.5348 9.62273 97.786 11.1158 97.664 12.7213C97.664 14.4473 98.229 16.9463 102.514 17.8993L105.314 18.5243C107.665 19.0893 109.629 20.3693 109.242 22.9243C108.944 24.0093 108.267 24.9514 107.333 25.5793C106.399 26.2073 105.271 26.4794 104.154 26.3463L104.142 26.3493Z" fill="white"/>
  <path d="M167.554 30.4254C165.111 30.4255 162.744 31.2721 160.855 32.8212C158.966 34.3702 157.673 36.526 157.195 38.9215C156.717 41.317 157.083 43.8042 158.233 45.9597C159.382 48.1152 161.243 49.8058 163.498 50.7437C165.754 51.6816 168.265 51.8089 170.603 51.1038C172.942 50.3988 174.964 48.905 176.326 46.8768C177.687 44.8485 178.303 42.4112 178.07 39.9796C177.836 37.548 176.767 35.2725 175.045 33.5404C173.058 31.5519 170.365 30.4318 167.554 30.4254ZM167.554 46.6134C166.444 46.6251 165.355 46.3062 164.427 45.6974C163.499 45.0885 162.773 44.2172 162.341 43.1944C161.91 42.1715 161.792 41.0434 162.004 39.9536C162.215 38.8638 162.746 37.8616 163.529 37.0745C164.312 36.2875 165.312 35.7512 166.4 35.5339C167.489 35.3166 168.618 35.4282 169.643 35.8544C170.668 36.2807 171.543 37.0022 172.157 37.9274C172.77 38.8525 173.095 39.9393 173.089 41.0494C173.097 41.7785 172.959 42.5019 172.684 43.177C172.409 43.8522 172.001 44.4656 171.486 44.9812C170.97 45.4967 170.357 45.9041 169.682 46.1795C169.006 46.4548 168.283 46.5925 167.554 46.5844V46.6134Z" fill="#E75653"/>
  <path d="M157.02 17.4223C157.013 14.9789 156.159 12.6135 154.604 10.7289C153.049 8.84429 150.889 7.55705 148.491 7.0864C146.093 6.61574 143.607 6.99079 141.454 8.14765C139.302 9.30452 137.618 11.1717 136.687 13.4311C135.757 15.6906 135.639 18.2026 136.353 20.5394C137.067 22.8761 138.569 24.8932 140.603 26.2469C142.637 27.6007 145.078 28.2075 147.509 27.964C149.94 27.7204 152.212 26.6417 153.937 24.9113C154.918 23.9272 155.696 22.7591 156.225 21.474C156.754 20.1889 157.024 18.8121 157.02 17.4223ZM140.981 17.4223C141.002 16.1578 141.46 14.9393 142.277 13.9741C143.094 13.0089 144.22 12.3564 145.464 12.1275C146.708 11.8986 147.993 12.1074 149.1 12.7184C150.208 13.3294 151.069 14.305 151.539 15.4794C152.009 16.6537 152.057 17.9544 151.676 19.1605C151.295 20.3666 150.509 21.4036 149.45 22.0954C148.391 22.7872 147.126 23.0911 145.868 22.9555C144.611 22.8198 143.439 22.253 142.552 21.3513C142.044 20.8326 141.643 20.2183 141.374 19.544C141.104 18.8696 140.97 18.1485 140.981 17.4223Z" fill="#E75653"/>
  <path d="M82.033 43.3404H89.414V39.0254H82.034V35.0674H90.454V30.6934H77.034V51.3744H90.722V47.0004H82.033V43.3404Z" fill="white"/>
  <path d="M111.582 30.6934L105.868 43.2804L100.036 30.6934H95.87L93.311 51.3744H98.489L99.769 40.2454L103.969 48.9644H107.748L111.948 40.2454L113.198 51.3744H118.346L115.837 30.6934H111.582Z" fill="white"/>
  <path d="M130.001 30.6933H121.431V51.5233H126.46V44.4713H130.001C130.925 44.5387 131.854 44.4107 132.726 44.0957C133.597 43.7807 134.393 43.2858 135.061 42.6431C135.729 42.0005 136.254 41.2244 136.603 40.3654C136.951 39.5064 137.115 38.5837 137.083 37.6573C137.132 36.7199 136.981 35.7827 136.64 34.9079C136.3 34.033 135.778 33.2403 135.109 32.5821C134.439 31.9239 133.638 31.4152 132.758 31.0897C131.877 30.7641 130.938 30.629 130.001 30.6933ZM129.763 40.1563H126.463V35.0683H129.763C130.083 35.0661 130.399 35.1324 130.69 35.263C130.982 35.3935 131.242 35.5851 131.454 35.8248C131.665 36.0646 131.822 36.3469 131.915 36.6527C132.008 36.9585 132.034 37.2806 131.991 37.5973C132.033 37.9158 132.007 38.2396 131.914 38.5472C131.822 38.8548 131.664 39.1391 131.453 39.3812C131.242 39.6233 130.982 39.8177 130.69 39.9513C130.398 40.085 130.08 40.1549 129.759 40.1563H129.763Z" fill="white"/>
  <path d="M144.94 47.0004V30.6934H139.88V51.5234H148.093C148.569 50.0654 149.045 48.5474 149.551 47.1494L144.94 47.0004Z" fill="white"/>
  <path d="M204.125 43.3404H211.505V39.0254H204.125V35.0674H212.546V30.6934H199.126V51.3744H212.814V47.0004H204.125V43.3404Z" fill="white"/>
  <path d="M221.562 43.3404H228.942V39.0254H221.562V35.0674H229.953V30.6934H216.563V51.3744H230.221V47.0004H221.562V43.3404Z" fill="white"/>
  <path d="M126.519 20.0104H133.036V15.6964H126.519V11.7384H134.732V7.33435H121.431V28.0154H126.519V20.0104Z" fill="white"/>
  <path d="M160.918 28.0143H165.947V21.1143H166.393L170.827 28.0143H176.778L171.72 20.8743C173.19 20.5167 174.489 19.659 175.396 18.4483C176.302 17.2376 176.76 15.7492 176.689 14.2383C176.729 13.3058 176.572 12.3754 176.227 11.5079C175.883 10.6403 175.36 9.85516 174.692 9.2036C174.023 8.55205 173.225 8.04878 172.349 7.72668C171.473 7.40458 170.539 7.27088 169.608 7.33432H165.114C163.765 11.441 162.365 15.5177 160.914 19.5643L160.918 28.0143ZM165.947 11.7083H169.247C169.567 11.7055 169.884 11.7714 170.176 11.9017C170.468 12.0319 170.729 12.2235 170.94 12.4633C171.152 12.7031 171.31 12.9856 171.403 13.2917C171.496 13.5978 171.522 13.9203 171.479 14.2373C171.521 14.5558 171.495 14.8797 171.403 15.1874C171.31 15.495 171.153 15.7794 170.942 16.0215C170.73 16.2637 170.47 16.458 170.178 16.5916C169.886 16.7252 169.568 16.795 169.247 16.7963H165.947V11.7083Z" fill="white"/>
  <path d="M157.258 29.7704L160.918 19.5634C162.366 15.596 163.766 11.5194 165.118 7.33336C165.679 5.54836 166.274 3.76336 166.81 1.94836C162.823 10.8754 159.371 20.1004 155.86 29.2354C153.718 35.1864 151.575 41.1354 149.552 47.0894C149.046 48.5474 148.57 50.0654 148.094 51.4634C147.618 52.8614 146.844 55.2424 146.279 57.1474C150.295 48.1014 153.747 38.9064 157.258 29.7704Z" fill="#E75653"/>
  <path d="M186.688 38.1034L182.82 30.6034H177.106L184.069 43.4884V51.3744H189.306V43.3994L196.329 30.5994H190.556L186.688 38.1034Z" fill="white"/>
  </g>
  <path d="M264.373 29.5041L264.312 18.6623C264.297 14.4392 264.253 10.1308 264.182 5.7371C264.147 3.869 264.122 1.98889 264.075 0.101562C263.883 9.86142 263.892 19.7111 263.873 29.4974C263.906 35.8214 263.938 42.1439 264.008 48.4287C264.022 49.9714 264.052 51.5597 264.069 53.0357C264.085 54.5117 264.124 57.0126 264.172 58.9944C264.36 49.113 264.354 39.2914 264.373 29.5041Z" fill="#E75653"/>
  <path d="M380.982 24.5053C380.871 23.3891 380.379 22.5013 379.504 21.842C378.629 21.1827 377.529 20.853 376.204 20.853C375.271 20.853 374.448 21.0129 373.737 21.3328C373.032 21.6527 372.477 22.0965 372.072 22.6645C371.674 23.2259 371.475 23.8656 371.475 24.5836C371.475 25.1124 371.589 25.5759 371.817 25.9741C372.046 26.3722 372.353 26.715 372.738 27.0022C373.129 27.2829 373.56 27.5244 374.03 27.7268C374.507 27.9291 374.987 28.0988 375.47 28.2359L377.585 28.843C378.224 29.0193 378.864 29.2445 379.504 29.5186C380.144 29.7928 380.728 30.1388 381.257 30.5566C381.792 30.9678 382.219 31.4737 382.539 32.0743C382.866 32.6683 383.029 33.3831 383.029 34.2186C383.029 35.2892 382.751 36.252 382.197 37.1072C381.642 37.9623 380.845 38.6412 379.807 39.1438C378.769 39.64 377.526 39.888 376.077 39.888C374.712 39.888 373.528 39.6628 372.522 39.2124C371.517 38.7554 370.727 38.1255 370.153 37.3226C369.578 36.5197 369.255 35.5895 369.183 34.532H371.063C371.129 35.3218 371.39 35.9909 371.847 36.5393C372.304 37.0876 372.898 37.5054 373.629 37.7926C374.36 38.0733 375.176 38.2136 376.077 38.2136C377.076 38.2136 377.963 38.0472 378.74 37.7143C379.523 37.3748 380.137 36.9048 380.581 36.3043C381.031 35.6972 381.257 34.9922 381.257 34.1893C381.257 33.5104 381.08 32.9425 380.728 32.4855C380.375 32.022 379.882 31.6336 379.249 31.3203C378.623 31.007 377.895 30.7295 377.066 30.488L374.667 29.783C373.094 29.313 371.873 28.6635 371.005 27.8345C370.136 27.0054 369.702 25.9479 369.702 24.662C369.702 23.5849 369.99 22.6384 370.564 21.8224C371.145 20.9999 371.928 20.3602 372.914 19.9032C373.906 19.4397 375.019 19.208 376.253 19.208C377.5 19.208 378.603 19.4365 379.563 19.8934C380.522 20.3504 381.283 20.9803 381.844 21.7832C382.412 22.5796 382.716 23.487 382.755 24.5053H380.982Z" fill="white"/>
  <path d="M354.584 39.5453V19.4919H356.415V37.9003H365.972V39.5453H354.584Z" fill="white"/>
  <path d="M338.212 39.5453V19.4919H349.845V21.1369H340.043V28.6863H349.228V30.3313H340.043V37.9003H350.041V39.5453H338.212Z" fill="white"/>
  <path d="M319.549 21.1369V19.4919H334.09V21.1369H327.735V39.5453H325.904V21.1369H319.549Z" fill="white"/>
  <path d="M317.17 29.5186C317.17 31.6075 316.798 33.4255 316.054 34.9726C315.31 36.5131 314.285 37.7077 312.979 38.5563C311.68 39.3984 310.185 39.8194 308.495 39.8194C306.804 39.8194 305.306 39.3984 304 38.5563C302.701 37.7077 301.68 36.5131 300.935 34.9726C300.198 33.4255 299.829 31.6075 299.829 29.5186C299.829 27.4297 300.198 25.615 300.935 24.0744C301.68 22.5274 302.704 21.3328 304.01 20.4907C305.316 19.6421 306.81 19.2178 308.495 19.2178C310.185 19.2178 311.68 19.6421 312.979 20.4907C314.285 21.3328 315.31 22.5274 316.054 24.0744C316.798 25.615 317.17 27.4297 317.17 29.5186ZM315.388 29.5186C315.388 27.7235 315.088 26.1862 314.487 24.9067C313.887 23.6208 313.067 22.6383 312.029 21.9594C310.992 21.2806 309.813 20.9411 308.495 20.9411C307.183 20.9411 306.008 21.2806 304.97 21.9594C303.932 22.6383 303.109 23.6175 302.502 24.8969C301.902 26.1764 301.601 27.7169 301.601 29.5186C301.601 31.3137 301.902 32.851 302.502 34.1305C303.103 35.4099 303.922 36.3924 304.96 37.0778C305.998 37.7567 307.176 38.0961 308.495 38.0961C309.813 38.0961 310.992 37.7567 312.029 37.0778C313.074 36.3989 313.896 35.4197 314.497 34.1403C315.097 32.8543 315.394 31.3137 315.388 29.5186Z" fill="white"/>
  <path d="M280.373 39.5453V19.4919H282.204V28.6863H293.582V19.4919H295.413V39.5453H293.582V30.3313H282.204V39.5453H280.373Z" fill="white"/>
  <defs>
  <clipPath id="clip0_3_106">
  <rect width="247.373" height="55.199" fill="white" transform="translate(0.5 1.94836)"/>
  </clipPath>
  </defs>
  </svg>
);

export default EbpFooterLogo;

import { mergeMap, catchError, switchMap, delay } from 'rxjs/operators';
import { ofType, StateObservable } from 'redux-observable';
import { of, merge, iif, Observable, EMPTY } from 'rxjs';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { push } from 'connected-react-router';
import ThreeDSecureService from '@paybyrd/threedsecure-service';
import noop from 'lodash/noop';

import {
  CheckoutActionTypes,
  CURRENT_DOMAIN,
  TrackingActionTypes,
} from 'src/constants';
import { apiCall, observableApi$ } from 'src/modules/api';
import {
  getCheckoutInfo,
  getStripePaymentSecret,
  PayAtHotelBookAction,
  CheckoutBookFormFields,
  StripePaymentSecretAction,
  SetPackageAction,
  CheckCheckoutAction,
  CreateCheckoutAction,
  GetCheckoutInfoAction,
  CheckoutBookAction,
  CheckoutBookSuccessAction,
  UpdateCheckoutPackagePriceAction,
  HoldPayAtHotelAction,
  checkCheckout,
  Check3DSAction,
  stopCheckCheckout,
} from 'src/store/checkout/actions';
import { toggleCheckoutPopUp } from 'src/store/popUp/actions';
import { CheckoutPopUpEnum } from 'src/store/popUp/reducers';
import { ReduxState } from 'src/store/reducers';
import {
  CheckoutRequest,
  CheckoutCreateRequest,
  CheckoutBookRequest,
  CheckoutCreateResponse,
  CheckoutCheckResponse,
  CheckoutResponse,
  CheckoutBookResponse,
  CheckoutCheckRequest,
  CheckoutStatuses,
  CheckoutAgreeRequest,
  CheckoutAgreeResponse,
} from 'src/models/checkout';
import {
  StripePaymentSecretRequest,
  StripePaymentSecretResponse,
} from 'src/models/payments';
import {
  PayAtHotelBookRequest,
  PayAtHotelBookResponse,
} from 'src/models/payAtHotel';
import { mapErrorResponse, mapResponse } from 'src/store/epicHelpers';
import { PollingData, pollingOnResolved } from 'src/modules/pollingOnResolved';
import type { DefaultAppMessagesTypeKey } from 'src/containers/ConnectedIntl/messages/defaultMessages';
import { logEndpointError } from 'src/modules/logError';

import { changeCurrency } from '../currency/actions';
import { store } from '..';

import { triggerPayByrd3DSCheck } from './actions';

enum TrackPopupEvents {
  //book check
  BOOK_PRICE_CHANGED = 'bookPriceChanged',
  BOOK_SOLD_OUT_ROOM = 'bookSoldOutRoom',
  BOOK_SOLD_OUT_HOTEL = 'bookSoldOutHotel',
  BOOK_EXPIRED = 'bookExpired',
  BOOK_EXPIRED_NATIONALITY = 'bookExpiredNationality',
  BOOK_REFUNDABILITY_CHANGED = 'bookRefundabilityChanged',
  //check checkout
  CHECK_PRICE_CHANGED = 'checkPriceChanged',
  CHECK_SOLD_OUT_ROOM = 'checkSoldOutRoom',
  CHECK_SOLD_OUT_HOTEL = 'checkSoldOutHotel',
  CHECK_EXPIRED = 'checkExpired',
  CHECK_EXPIRED_NATIONALITY = 'checkExpiredNationality',
  CHECK_REFUNDABILITY_CHANGED = 'checkRefundabilityChanged',
}

const CheckoutAPI = {
  create$: (params: CheckoutCreateRequest) =>
    observableApi$<CheckoutCreateResponse>('/checkout/create', {
      method: 'post',
      data: params,
    }),
  info$: (params: CheckoutRequest) =>
    observableApi$<CheckoutResponse>('/checkout', {
      method: 'get',
      params,
    }),
  check$: (params: CheckoutCheckRequest) =>
    observableApi$<CheckoutCheckResponse>('/checkout/check', {
      method: 'get',
      params,
    }),
  agree$: (params: CheckoutAgreeRequest) =>
    observableApi$<CheckoutAgreeResponse>('/checkout/agree', {
      method: 'post',
      data: params,
    }),
  book$: (params: CheckoutBookRequest) =>
    observableApi$<CheckoutBookResponse>('/checkout/book', {
      method: 'post',
      data: params,
    }),
  stripePaymentSecret$: (params: StripePaymentSecretRequest) =>
    observableApi$<StripePaymentSecretResponse>(
      '/payments/pay/v2/getStripePaymentSecret',
      {
        method: 'post',
        data: params,
      }
    ),
  payAtHotelHold$: (params: StripePaymentSecretRequest) =>
    observableApi$<StripePaymentSecretResponse>('/payments/PayAtHotel/hold', {
      method: 'post',
      data: params,
    }),
  payAtHotelBook$: (params: PayAtHotelBookRequest) =>
    observableApi$<PayAtHotelBookResponse>('/payments/PayAtHotel/book', {
      method: 'post',
      data: params,
    }),
};

const mapBookResult = (
  resp: CheckoutBookResponse
): Observable<CheckoutBookSuccessAction> =>
  of({
    type: CheckoutActionTypes.BOOK_CHECKOUT_SUCCESS,
    payload: resp,
  });

const START_INDEX = 1;

export const getAdditionalGuestInfo = (params: CheckoutBookFormFields) =>
  params.names.slice(START_INDEX).map(
    (value, index) => ({
      ...value,
      bedTypes: Number(params.bedTypes[index + START_INDEX].roomBedType),
      specialRequests: params.specialRequests[index + START_INDEX],
    }),
    []
  );

export function handleCheckoutCreateRequest(
  action$: Observable<CreateCheckoutAction>,
  state$: StateObservable<ReduxState>
) {
  return action$.pipe(
    ofType(CheckoutActionTypes.CREATE_CHECKOUT_REQUEST),
    mergeMap(() => {
      const { currency, hotel, checkout, deprecated, searchParams } =
        state$.value;
      const requestBody: CheckoutCreateRequest = {
        hotelId: hotel.hotelId,
        searchRequestId: deprecated.searchId!,
        selectedPackageId: checkout.packageId!,
        currency: currency.currentCurrency,
        es: deprecated.es!,
      };

      return CheckoutAPI.create$(requestBody).pipe(
        mergeMap((resp) =>
          merge(
            hotel.info
              ? of({
                  // TODO: ????
                  type: TrackingActionTypes.TRACK_CHECKOUT,
                  payload: {
                    checkIn: searchParams.dates[0],
                    checkOut: searchParams.dates[1],
                    hotelId: hotel.hotelId,
                    country: hotel.info.country,
                    countryCode: hotel.info.countryCode,
                    city: hotel.info.city,
                    adultsCount: searchParams.rooms.reduce(
                      (count, room) => count + room.adults,
                      0
                    ),
                    childrenCount: searchParams.rooms.reduce(
                      (count, room) => count + room.children.length,
                      0
                    ),
                  },
                })
              : EMPTY,
            mapResponse(CheckoutActionTypes.CREATE_CHECKOUT_SUCCESS, resp),
            of(getCheckoutInfo())
          )
        ),
        catchError((response) => {
          void logEndpointError(
            {
              path: '/checkout/create',
              method: 'POST',
            },
            response
          );

          return mapErrorResponse(
            CheckoutActionTypes.CREATE_CHECKOUT_FAILURE,
            response
          );
        })
      );
    })
  );
}

export function handleCheckoutInfoRequest(
  action$: Observable<GetCheckoutInfoAction>,
  state$: StateObservable<ReduxState>
) {
  return action$.pipe(
    ofType(CheckoutActionTypes.FETCH_CHECKOUT_REQUEST),
    mergeMap(({ silent }) => {
      const { checkout, user } = state$.value;
      const checkoutId = checkout.checkoutId!;
      const requestParams: CheckoutRequest = {
        checkoutId,
      };
      const customerEmail = _get(user, 'info.email', '');
      const customerNationality = _get(user, 'info.country', '');
      return CheckoutAPI.info$(requestParams).pipe(
        mergeMap((checkoutResponse) =>
          merge(
            mapResponse(
              CheckoutActionTypes.FETCH_CHECKOUT_SUCCESS,
              checkoutResponse
            ),
            customerEmail && customerNationality && !silent
              ? of(checkCheckout({ customerEmail, customerNationality })).pipe(
                  delay(1000)
                )
              : EMPTY,
            _isEmpty(checkoutResponse.currencies) ? of(changeCurrency()) : EMPTY
          )
        ),
        catchError((err) => {
          void logEndpointError(
            {
              path: '/checkout',
              method: 'GET',
            },
            err
          );

          return mapErrorResponse(
            CheckoutActionTypes.FETCH_CHECKOUT_FAILURE,
            err
          );
        })
      );
    })
  );
}

export function handleCheckoutCheckRequest(
  action$: Observable<CheckCheckoutAction>,
  state$: StateObservable<ReduxState>
) {
  return action$.pipe(
    ofType(CheckoutActionTypes.CHECK_CHECKOUT_REQUEST),
    switchMap(({ params }) => {
      const { checkout } = state$.value;
      const { customerEmail, customerNationality, bookAfterModal } = params;

      const requestParams: CheckoutCheckRequest & CheckoutRequest = {
        checkoutId: checkout.checkoutId!,
        ...(customerEmail && customerNationality
          ? {
              customerEmail,
              customerNationality,
            }
          : {}),
      };

      const check$ = CheckoutAPI.check$(requestParams);

      const responseOperatorFunction = switchMap(
        (data: PollingData<CheckoutCheckResponse>) => {
          const { response } = data;
          const {
            checkoutStatus,
            validPrice,
            refundabilityChanged,
            isFinished,
          } = response;

          // show PriceChanged modal
          //#region
          const priceChanged$ = iif(
            () => Boolean(validPrice),
            merge(
              of(
                toggleCheckoutPopUp(
                  CheckoutPopUpEnum.PRICE_CHANGED,
                  bookAfterModal
                )
              ),
              of({
                type: TrackingActionTypes.TRACK_CHECKOUT_POPUP,
                event: TrackPopupEvents.CHECK_PRICE_CHANGED,
              })
            ),
            EMPTY
          );
          //#endregion

          // show Refundability Changed modal
          //#region
          const refundabilityChanged$ = iif(
            () => refundabilityChanged && validPrice === 0,
            merge(
              of(
                toggleCheckoutPopUp(
                  CheckoutPopUpEnum.REFUNDABILITY_CHANGED,
                  bookAfterModal
                )
              ),
              of({
                type: TrackingActionTypes.TRACK_CHECKOUT_POPUP,
                event: TrackPopupEvents.CHECK_REFUNDABILITY_CHANGED,
              })
            ),
            EMPTY
          );
          //#endregion

          // show expired modal
          //#region
          const expired$ = iif(
            () => checkoutStatus === CheckoutStatuses.Expired,
            merge(
              of(toggleCheckoutPopUp(CheckoutPopUpEnum.EXPIRED)),
              of({
                type: TrackingActionTypes.TRACK_CHECKOUT_POPUP,
                event: TrackPopupEvents.CHECK_EXPIRED,
              })
            ),
            EMPTY
          );
          //#endregion

          // show Expired Ni modal
          //#region
          const expiredNi$ = iif(
            () => checkoutStatus === CheckoutStatuses.ExpiredNi,
            merge(
              of(toggleCheckoutPopUp(CheckoutPopUpEnum.EXPIRED_NI)),
              of({
                type: TrackingActionTypes.TRACK_CHECKOUT_POPUP,
                event: TrackPopupEvents.CHECK_EXPIRED_NATIONALITY,
              })
            ),
            EMPTY
          );
          //#endregion

          // show SoldOutRoom modal
          //#region
          const soldoutRoom$ = iif(
            () => checkoutStatus === CheckoutStatuses.SoldOutRoom,
            merge(
              of(toggleCheckoutPopUp(CheckoutPopUpEnum.SOLD_OUT_ROOM)),
              of({
                type: TrackingActionTypes.TRACK_CHECKOUT_POPUP,
                event: TrackPopupEvents.CHECK_SOLD_OUT_ROOM,
              })
            ),
            EMPTY
          );
          //#endregion

          // show SoldOutHotel modal
          //#region
          const soldoutHotel$ = iif(
            () => checkoutStatus === CheckoutStatuses.SoldOutHotel,
            merge(
              of(toggleCheckoutPopUp(CheckoutPopUpEnum.SOLD_OUT_HOTEL)),
              of({
                type: TrackingActionTypes.TRACK_CHECKOUT_POPUP,
                event: TrackPopupEvents.CHECK_SOLD_OUT_HOTEL,
              })
            ),
            EMPTY
          );
          //#endregion

          return merge(
            mapResponse(CheckoutActionTypes.CHECK_CHECKOUT_SUCCESS, response),
            priceChanged$,
            soldoutRoom$,
            soldoutHotel$,
            expired$,
            expiredNi$,
            refundabilityChanged$,
            iif(
              () => isFinished && checkoutStatus !== CheckoutStatuses.Pending,
              of(stopCheckCheckout()),
              EMPTY
            )
          );
        }
      );

      const errorOperatorFunction = catchError((response) => {
        void logEndpointError(
          { path: '/checkout/check', method: 'GET' },
          response
        );

        return merge(
          mapErrorResponse(
            CheckoutActionTypes.CHECK_CHECKOUT_FAILURE,
            response
          ),
          of(stopCheckCheckout())
        );
      });

      const takeUntilOperator = action$.pipe(
        ofType(CheckoutActionTypes.STOP_CHECK_CHECKOUT) as any
      );

      return pollingOnResolved(
        check$,
        // @ts-expect-error
        responseOperatorFunction,
        errorOperatorFunction,
        takeUntilOperator
      );
    })
  );
}

export function handlePackagePriceUpdate(
  action$: Observable<UpdateCheckoutPackagePriceAction>,
  state$: StateObservable<ReduxState>
) {
  return action$.pipe(
    ofType(CheckoutActionTypes.UPDATE_CHECKOUT_PACKAGE_PRICE_REQUEST),
    mergeMap(() => {
      const { checkout } = state$.value;
      const requestBody = {
        checkoutId: checkout.checkoutId!,
      };
      return CheckoutAPI.agree$(requestBody).pipe(
        mergeMap((response) =>
          mapResponse(
            CheckoutActionTypes.UPDATE_CHECKOUT_PACKAGE_PRICE_SUCCESS,
            response
          )
        )
      );
    }),
    catchError((err) => {
      void logEndpointError({ path: '/checkout/agree', method: 'POST' }, err);

      return mapResponse(
        CheckoutActionTypes.UPDATE_CHECKOUT_PACKAGE_PRICE_FAILED,
        err
      );
    })
  );
}

export const handlePaybyrdCheck = (msg: string) => {
  void apiCall('/payments/pay/paybyrd/check', {
    method: 'POST',
    data: {
      data: msg,
      checkoutId: store.getState().checkout.checkoutId,
    },
  }).catch(noop);
};

export const handlePaybyrd = (
  action$: Observable<Check3DSAction>,
  state$: StateObservable<ReduxState>
) =>
  action$
    .pipe(
      ofType(CheckoutActionTypes.PAYBYRD_3DS_CHECK),
      mergeMap(
        ({ params }) =>
          new Observable<{
            setBackDropToggler: (value: boolean) => void;
            setError: (textId: DefaultAppMessagesTypeKey) => void;
            data?: {
              checkoutId: string | number;
              threeDSData: any;
              offerKey: string;
              tokenId: string;
              correlationId: string;
            };
          }>((subscribe) => {
            const p = params.cardCollect?.cardCollect_submit();

            if (p) {
              handlePaybyrdCheck('[card-collect] submitted');
            } else {
              handlePaybyrdCheck('[card-collect] submit failed, no instance');

              return;
            }

            p.then(
              ({ data }: any) => {
                handlePaybyrdCheck(
                  `[card-collect] submit successful: ${
                    data && JSON.stringify(data)
                  }`
                );

                void apiCall<string>(
                  '/payments/pay/paybyrd/token',
                  {
                    method: 'POST',
                    data: {
                      checkoutId: state$.value.checkout.checkoutId,
                      tokenId: data.tokenId,
                      offerKey: params.offerKey,
                    },
                  },
                  { path: '/payments/pay/paybyrd/token', method: 'POST' }
                ).then(
                  (id) => {
                    const threeDSService = new ThreeDSecureService({
                      maxAttempts: 100,
                      attemptDelay: 2000,
                      container: params.containerRef.current!,
                      onIFrameCreatedFn() {
                        params.setToggler(true);

                        handlePaybyrdCheck('[3ds] iframe shown');
                      },
                      onProgressFn(ev: any) {
                        if (!ev.type || !ev.type.startsWith('event:delay')) {
                          handlePaybyrdCheck(
                            `[3ds] onProgress: ${JSON.stringify(ev)}`
                          );
                        }
                      },
                    });

                    threeDSService
                      .execute({
                        id,
                      })
                      .then(
                        (resp) => {
                          subscribe.next({
                            data: {
                              checkoutId: state$.value.checkout.checkoutId!,
                              threeDSData: resp,
                              offerKey: params.offerKey,
                              tokenId: data.tokenId,
                              correlationId: data.correlationId,
                            },
                            setBackDropToggler: params.setBackDropToggler,
                            setError: params.setError,
                          });
                          subscribe.complete();

                          params.setToggler(false);
                        },
                        (err) => {
                          subscribe.next({
                            data: {
                              checkoutId: state$.value.checkout.checkoutId!,
                              threeDSData: err,
                              offerKey: params.offerKey,
                              tokenId: data.tokenId,
                              correlationId: data.correlationId,
                            },
                            setBackDropToggler: params.setBackDropToggler,
                            setError: params.setError,
                          });

                          subscribe.complete();

                          params.setToggler(false);
                        }
                      );
                  },
                  () => {
                    subscribe.next({
                      setBackDropToggler: params.setBackDropToggler,
                      setError: params.setError,
                    });

                    subscribe.complete();

                    params.setToggler(false);
                  }
                );
              },
              (err: any) => {
                handlePaybyrdCheck(
                  `[card-collect] submit failed: ${err && JSON.stringify(err)}`
                );

                params.setError('CHECKOUT_PAGE.PAYMENT_PANEL.PAYBYRD_TIMEOUT');
                params.setBackDropToggler(false);
              }
            );
          })
      )
    )
    .pipe(
      mergeMap(
        ({ setBackDropToggler, setError, data }) =>
          new Observable((subscribe) => {
            if (data) {
              void apiCall(
                '/payments/pay/paybyrd',
                {
                  method: 'POST',
                  data,
                },
                { path: '/payments/pay/paybyrd', method: 'POST' }
              )
                .then(
                  () => {
                    subscribe.next({ type: null, payload: true });
                  },
                  (err) => {
                    setError(
                      err?.status === 504
                        ? 'CHECKOUT_PAGE.PAYMENT_PANEL.PAYBYRD_TIMEOUT'
                        : 'CHECKOUT_PAGE.PAYMENT_PANEL.PAYBYRD_ERROR'
                    );
                    subscribe.next({ type: null, payload: false });
                  }
                )
                .finally(() => {
                  subscribe.complete();

                  setBackDropToggler(false);

                  handlePaybyrdCheck('[checkout] book loader closed');
                });
            } else {
              setError('CHECKOUT_PAGE.PAYMENT_PANEL.PAYBYRD_TIMEOUT');

              subscribe.next({ type: null, payload: false });

              subscribe.complete();

              setBackDropToggler(false);

              handlePaybyrdCheck('[checkout] book loader closed');
            }
          })
      )
    )
    .pipe(
      mergeMap((p: any) => (p.payload ? of(push('/confirmation')) : EMPTY))
    );

export function handleCheckoutBookRequest(
  action$: Observable<CheckoutBookAction>,
  state$: StateObservable<ReduxState>
) {
  return action$.pipe(
    ofType(CheckoutActionTypes.BOOK_CHECKOUT_REQUEST),
    mergeMap(({ params }) => {
      const { checkout, currency, intl } = state$.value;
      const { discount, formFields, agreed = false } = params;
      const formFieldsParams = formFields!;
      const { names, countries, bedTypes, email, phone, specialRequests } =
        formFieldsParams;
      const additionalGuestsInfo = getAdditionalGuestInfo(formFieldsParams);
      const requestBody: CheckoutBookRequest = {
        checkoutId: checkout.checkoutId!,
        agreed,
        customerInfo: {
          ...names[0],
          email,
          phoneCountryCode: phone.countryData.dialCode || '',
          phoneNumber: phone.fullNumber
            .replace(`+${phone.countryData.dialCode}`, '')
            .replace(/[^+\d]/g, ''),
          nationality: countries[0].countryCode,
          bedTypes: Number(bedTypes[0].roomBedType),
          specialRequests: specialRequests[0],
          additionalGuestsInfo,
        },
        currency: params.crypto ? currency.currentCurrency : 'USD',
        locale: intl.currentLocale,
      };

      return CheckoutAPI.book$(requestBody).pipe(
        mergeMap((response) => {
          const { offerKey } = response;

          const dummyAction = { type: 'DUMMY_ACTION' };

          const getAction = () => {
            if (!offerKey) {
              return dummyAction;
            }

            if (params.crypto) {
              return dummyAction;
            }

            if (params.paybyrd) {
              return triggerPayByrd3DSCheck({
                offerKey,
                ...params.paybyrd,
              });
            }

            if (params.stripe) {
              return getStripePaymentSecret({
                offerKey,
                discount,
              });
            }

            return dummyAction;
          };

          return merge(mapBookResult(response), of(getAction()));
        }),
        catchError((response) => {
          if (response?.response?.status === 422) {
            return of(
              checkCheckout({
                customerEmail: email,
                customerNationality: countries[0].countryCode,
                bookAfterModal: params,
              })
            );
          }

          void logEndpointError(
            { path: '/checkout/book', method: 'GET' },
            response
          );

          return mapErrorResponse(
            CheckoutActionTypes.BOOK_CHECKOUT_FAILURE,
            response
          );
        })
      );
    })
  );
}

export function handleStripePaymentSecretRequest(
  action$: Observable<StripePaymentSecretAction>
) {
  return action$.pipe(
    ofType(CheckoutActionTypes.STRIPE_PAYMENT_SECRET_REQUEST),
    mergeMap(({ params }) => {
      const { offerKey, discount } = params;

      return CheckoutAPI.stripePaymentSecret$({
        offerKey,
        discount,
      }).pipe(
        mergeMap((resp) =>
          mapResponse(CheckoutActionTypes.STRIPE_PAYMENT_SECRET_SUCCESS, resp)
        ),
        catchError((response) => {
          void logEndpointError(
            { path: '/payments/pay/v2/getStripePaymentSecret', method: 'GET' },
            response
          );

          return mapErrorResponse(
            CheckoutActionTypes.STRIPE_PAYMENT_SECRET_FAILURE,
            response
          );
        })
      );
    })
  );
}

export function handlePayAtHotelHoldRequest(
  action$: Observable<HoldPayAtHotelAction>
) {
  return action$.pipe(
    ofType(CheckoutActionTypes.HOLD_PAY_AT_HOTEL_REQUEST),
    mergeMap(({ params }) =>
      CheckoutAPI.payAtHotelHold$(params).pipe(
        mergeMap((resp) =>
          mapResponse(CheckoutActionTypes.STRIPE_PAYMENT_SECRET_SUCCESS, resp)
        ),
        catchError((response) =>
          mapErrorResponse(
            CheckoutActionTypes.STRIPE_PAYMENT_SECRET_FAILURE,
            response
          )
        )
      )
    )
  );
}

export function handleSetPackageAndProceedToCheckout(
  action$: Observable<SetPackageAction>,
  state$: StateObservable<ReduxState>
) {
  return action$.pipe(
    ofType(CheckoutActionTypes.SET_CHECKOUT_PACKAGE),
    mergeMap(({ payload }) => {
      const { packageId } = payload;
      const { rooms } = state$.value.hotel;

      const selectedPackages = rooms.filter(
        ({ groups }) =>
          groups.filter((group) => group.id === packageId).length !== 0
      );

      const price = _get(selectedPackages, [
        '0',
        'groups',
        '0',
        'price',
        'price',
      ]);

      return merge(
        of(push('/checkout')),
        of({ type: TrackingActionTypes.TRACK_ROOM_CLICK, payload: { price } })
      );
    })
  );
}

export function handlePayAtHotelBookRequest(
  action$: Observable<PayAtHotelBookAction>
) {
  return action$.pipe(
    ofType(CheckoutActionTypes.BOOK_PAY_AT_HOTEL_REQUEST),
    mergeMap(({ params }) => {
      const { offerKey } = params;

      return CheckoutAPI.payAtHotelBook$({ offerKey }).pipe(
        mergeMap((resp) =>
          merge(
            of(push('/confirmation')),
            mapResponse(CheckoutActionTypes.BOOK_PAY_AT_HOTEL_SUCCESS, resp)
          )
        ),
        catchError((response) =>
          mapErrorResponse(
            CheckoutActionTypes.BOOK_PAY_AT_HOTEL_FAILURE,
            response
          )
        )
      );
    })
  );
}

import { Suspense } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  CheckoutPage,
  ConfirmationPage,
  ContactPage,
  HomePage,
  NoMatchPage,
  NotAuthenticatedPage,
  SearchPage,
  HotelDetailsPage,
  StaticPage,
  FrequentlyAskedQuestionsPage,
  AboutUsPage,
  ImpressumPage,
  AccountPage,
  ResetPasswordPage,
  SignInPage,
  SignUpPage,
  ThankYouPage,
  UnsubscribePage,
  SubscriptionPage,
  AffiliateDashboardPage,
  RecoverPasswordPage
} from 'src/components/pages';
import { userSelector } from "src/store/user/selectors";

import MainLoader from "../components/MainLoader";

import { PrivateRoute } from "./private-route";
import { ROUTES } from "./constants";

 export const Router = () => {
  const history = useHistory();
  const { isAuthenticated } = useSelector(userSelector);

  const urlParams = new URLSearchParams(window.location.search);
  const referralCode = urlParams.get('invitationCode');

  if (referralCode && !isAuthenticated) {
    history.push({
      pathname: ROUTES.SIGN_UP,
      search: history.location.search
    })
  }

  return (
    <Suspense fallback={<MainLoader />}>
      <Switch>
        <Route exact path={ROUTES.HOME} component={HomePage} />
        <Route exact path={ROUTES.SEARCH} render={() => 
          <PrivateRoute>
            <SearchPage />
          </PrivateRoute>
        }/>
        <Route exact path={ROUTES.CHECKOUT} render={() => 
          <PrivateRoute>
            <CheckoutPage />
          </PrivateRoute>
        }/>
        <Route exact path={ROUTES.ACCOUNT} render={() => 
          <PrivateRoute>
            <AccountPage />
          </PrivateRoute>
        }/>
        <Route exact path={ROUTES.CONFIRMATION} render={() => 
          <PrivateRoute>
            <ConfirmationPage />
          </PrivateRoute>
        }/>
        <Route exact path={ROUTES.HOTEL} render={(props) => 
          <PrivateRoute>
            <HotelDetailsPage {...props} />
          </PrivateRoute>
        }/>
        <Route exact path={ROUTES.HOTEL_NAME} render={(props) => 
          <PrivateRoute>
            <HotelDetailsPage isStatic={true} {...props} />
          </PrivateRoute>
        }/>
        <Route exact path={ROUTES.AFFILIATE_DASHBOARD} render={() => 
          <PrivateRoute>
            <AffiliateDashboardPage />
          </PrivateRoute>
        }/>
        <Route exact path={ROUTES.UNSUBSCRIBE} render={(props) => 
          <PrivateRoute>
            <UnsubscribePage {...props} />
          </PrivateRoute>
        } />
        <Route exact path={ROUTES.SUBSCRIPTION} render={() => 
          <PrivateRoute>
            <SubscriptionPage />
          </PrivateRoute>
        }/>

        <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route exact path={ROUTES.RESET_PASSWORD} component={ResetPasswordPage} />
        <Route exact path={ROUTES.RECOVER_PASSWORD} component={RecoverPasswordPage} />

        <Route exact path={ROUTES.TERMS_AND_CONDITIONS} component={StaticPage} />
        <Route exact path={ROUTES.CONTACT} component={ContactPage} />
        <Route exact path={ROUTES.ABOUT} component={AboutUsPage} />
        <Route exact path={ROUTES.IMPRESSUM} component={ImpressumPage} />
        <Route exact path={ROUTES.FAQ} component={FrequentlyAskedQuestionsPage} />
        <Route exact path={ROUTES.NO_PAGE} component={NotAuthenticatedPage} />
        <Route exact path={ROUTES.NEWSLATTER} component={ThankYouPage} />
        <Route component={NoMatchPage} />
      </Switch>
    </Suspense>
  )
}

import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import { VFC } from 'react';
import styled from 'styled-components';

import { HotFilterConfig } from 'src/hooks/swr/useConfig';

const componentsProps: FormControlLabelProps['componentsProps'] = {
  typography: { variant: 'body4', display: 'block', width: '100%' },
};

type Props = Pick<
  FormControlLabelProps,
  'label' | 'checked' | 'onChange' | 'disabled'
>;

const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    color: ${({ theme }) => theme.custom.secondaryActionColor};
  }
`

export type FilterProps = Pick<Props, 'disabled' | 'label'> &
  Omit<HotFilterConfig, 'action'>;

const Filter: VFC<Props> = (props) => (
  <StyledFormControlLabel
    color="secondary"
    control={<Checkbox color="secondary" disableRipple />}
    componentsProps={componentsProps}
    {...props}
  />
);

export default Filter;

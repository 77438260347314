export const LowPriceIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_13192_16904)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.18544 2.41678C7.30463 2.27896 7.49132 2.2201 7.66801 2.26465C8.7838 2.54596 10.5022 3.27794 11.9509 4.58486C13.4099 5.90097 14.6087 7.81549 14.6087 10.4308C14.6087 11.2573 14.3616 12.5108 13.5481 13.601C12.7197 14.7109 11.3343 15.6099 9.15003 15.7404L9.14467 15.7407C6.27395 15.8803 4.25005 14.4379 3.43392 12.4336C2.6242 10.4451 3.03285 7.97334 4.85208 6.10209C4.94476 6.00676 5.07268 5.95394 5.20562 5.9561C5.33856 5.95826 5.4647 6.01522 5.55423 6.11351L6.84822 7.53416C6.85102 7.53723 6.85353 7.53877 6.85636 7.53984C6.85933 7.54098 6.86275 7.54157 6.86595 7.54154C7.34361 6.72259 7.57492 6.01165 7.60948 5.30139C7.64428 4.58615 7.48169 3.82929 7.10452 2.91624C7.03496 2.74783 7.06627 2.5546 7.18544 2.41678ZM10.7497 10.0438C10.985 9.80843 10.985 9.42683 10.7497 9.19147C10.5143 8.95611 10.1327 8.95611 9.89737 9.19147L6.73129 12.3575C6.49594 12.5929 6.49594 12.9745 6.73129 13.2099C6.96666 13.4453 7.34825 13.4453 7.58361 13.2099L10.7497 10.0438ZM7.29992 9.11335C7.69935 9.11335 8.02314 9.43715 8.02314 9.83657C8.02314 10.236 7.69935 10.5598 7.29992 10.5598C6.90051 10.5598 6.57667 10.236 6.57667 9.83657C6.57667 9.43715 6.90051 9.11335 7.29992 9.11335ZM10.346 11.8414C9.94662 11.8414 9.62279 12.1652 9.62279 12.5647C9.62279 12.9641 9.94658 13.2879 10.346 13.2879C10.7454 13.2879 11.0693 12.9641 11.0693 12.5647C11.0693 12.1652 10.7455 11.8414 10.346 11.8414Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_13192_16904">
        <rect
          width="13.5"
          height="13.5"
          fill="currentColor"
          transform="translate(2.07324 2.25)"
        />
      </clipPath>
    </defs>
  </svg>
);

import Stack from '@mui/material/Stack';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { FC, useState, VFC } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse, {
  CollapseProps,
  collapseClasses,
} from '@mui/material/Collapse';
import styled from 'styled-components/macro';

import { IconButton } from 'src/components/common/WrappedButtons';

type Props = { title: JSX.Element; spacing: number };

const OmittedKeyboardArrowDownIcon: VFC<SvgIconProps & { isOpen: boolean }> = ({
  isOpen,
  ...rest
}) => <KeyboardArrowDownIcon {...rest} />;

const OmittedCollapse = ({
  spacing,
  ...rest
}: CollapseProps & Pick<Props, 'spacing'>) => <Collapse {...rest} />;

const StyledCollapse = styled(OmittedCollapse)`
  .${collapseClasses.wrapperInner} {
    padding-top: ${({ theme, spacing }) => theme.spacing(spacing)};
  }
`;

const StyledArrow = styled(OmittedKeyboardArrowDownIcon)`
  transition: ${({ theme }) => theme.transitions.create('transform')};
  transform: scale(1, ${({ isOpen }) => (isOpen ? -1 : 1)});
`;

const Dropdown: FC<Props> = ({ title, children, spacing }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {title}
        <IconButton onClick={() => setIsOpen((prev) => !prev)}>
          <StyledArrow isOpen={isOpen} />
        </IconButton>
      </Stack>

      <StyledCollapse in={isOpen} mountOnEnter unmountOnExit spacing={spacing}>
        {children}
      </StyledCollapse>
    </div>
  );
};

export default Dropdown;

import { VFC } from 'react';

import { I18n } from 'src/components/I18n';
import { HotelReviewScoreCategory } from 'src/hooks/swr/useConfig';

import Filter from './Filter';
import useAddToSet from './Filter/useAddToSet';

type Props = {
  hotelScoreCategoryOrder: HotelReviewScoreCategory[];
  ratingMeta?: Record<number, boolean>;
};

const RatingSection: VFC<Props> = ({ hotelScoreCategoryOrder, ratingMeta }) => {
  const { set, handleChange } = useAddToSet('rating');

  return (
    <>
      {hotelScoreCategoryOrder.slice()
        .reverse()
        .map((id) => {
        const selected = set.has(id);
        const isRatingMetaTrue = ratingMeta?.[id as number] === true;

        if (!isRatingMetaTrue) {
          return null;
        }

        return (
          <Filter
            checked={selected}
            onChange={(_, checked) => handleChange(checked, id)}
            key={id}
            label={<I18n id={`HOTEL_RATING.${id}`} />}
            disabled={!selected && !ratingMeta?.[id as number]}
          />
        );
      })}
    </>
  );
};

export default RatingSection;

import { useTheme } from 'styled-components';
import AppleIcon from '@mui/icons-material/Apple';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import FooterBybitIcon from 'src/assets/home-page/footer-bybit-icon';
import FooterMastercardIcon from 'src/assets/home-page/footer-mastercard-icon';
import FooterUsdcIcon from 'src/assets/home-page/footer-usdc-icon';
import FooterUsdtIcon from 'src/assets/home-page/footer-usdt-icon';
import FooterVisaIcon from 'src/assets/home-page/footer-visa-icon';
import FooterAmexIcon from 'src/assets/home-page/footer-amex-icon';
import GooglePlayIcon from 'src/assets/icons/playstore.svg';
import FooterXIcon from 'src/assets/home-page/footer-x-icon';
import FooterTelegramIcon from 'src/assets/home-page/footer-telegram-icon';
import FooterDiscordIcon from 'src/assets/home-page/footer-discord-icon';
import useConfig from 'src/hooks/swr/useConfig';
import useMatchMedia from 'src/hooks/useMatchMedia';
import { userSelector } from 'src/store/user/selectors';
import { ROUTES } from 'src/router';
import { CURRENT_DOMAIN, DOMAIN } from 'src/constants';
import { FooterLogo } from 'src/components/common/FooterLogo';

import {
  AppButton,
  AppButtonInfo,
  AppButtonStoreTitle,
  AppButtonTitle,
  FooterAppActions,
  FooterAppLinksSection,
  FooterAppMobileContainer,
  FooterContainer,
  FooterIconWrapper,
  FooterLinks,
  FooterLinksNetworkItems,
  FooterLinksSection,
  FooterLinksSectionItem,
  FooterLinksSectionItems,
  FooterLinksSectionTitle,
  FooterMobileLinks,
  FooterMobileLinksContainer,
  FooterRights,
  FooterTopSection,
  FooterTopSectionPayments,
  FooterTopSectionPaymentsList,
  FooterTopSectionPaymentsTitle,
  FooterTopSectionTitle,
  FooterWrapper,
} from './styles';

const iOSLink = 'https://apps.apple.com/us/app/entravel/id6502831135';
const androidLink =
  'https://play.google.com/store/apps/details?id=com.entravel.android';

export const Footer = () => {
  const { data: config } = useConfig();
  const theme = useTheme();
  const isTablet = useMatchMedia(theme.breakpoints.down('md'));
  const { isAuthenticated } = useSelector(userSelector);
  const history = useHistory();

  const handleOpenAndroidApp = () => {
    window.open(androidLink);
  };

  const handleOpenIOSApp = () => {
    window.open(iOSLink);
  };

  const handleOpenBookingsPage = () => {
    if (!isAuthenticated) {
      history.push(ROUTES.SIGN_IN);

      return;
    }

    history.push('/account/bookings');
  };

  const renderCopyright = () => {
    if (CURRENT_DOMAIN === DOMAIN.CODED_ESTATE) {
      return 'Copyright © 2025 CodedEstate';
    }

    if (CURRENT_DOMAIN === DOMAIN.BIT_2_ME) {
      return 'Copyright © 2025 Bit2me';
    }

    if (CURRENT_DOMAIN === DOMAIN.WIREX) {
      return 'Copyright © 2025 Wirex';
    }

    if (CURRENT_DOMAIN === DOMAIN.EBP) {
      return 'Copyright © 2025 Brands for Employees';
    }

    if (CURRENT_DOMAIN === DOMAIN.CRYPTO_CLUB) {
      return '© 2025 LuxuryCryptoClub™. All Rights Reserved.'
    }

    if (CURRENT_DOMAIN === DOMAIN.X_PORTAL) {
      return '© MultiversX. All rights reserved.'
    }

    return '© 2025 Entravel ApS. All rights reserved'
    
  }


  const renderNetworks = () => {
    if (CURRENT_DOMAIN === DOMAIN.CODED_ESTATE) {
      return (
        <FooterLinksNetworkItems>
          <FooterLinksSectionItem
            href="https://x.com/codedestate?t=5OdxYhHLF31Z8VwzP3lI0g&mx=2"
            target="_blank"
          >
            <FooterXIcon />
          </FooterLinksSectionItem>
          <FooterLinksSectionItem
            href="https://discord.com/invite/fWfgQA8K6F"
            target="_blank"
          >
            <FooterDiscordIcon />
          </FooterLinksSectionItem>
        </FooterLinksNetworkItems>
      );
    }

    if (CURRENT_DOMAIN === DOMAIN.WIREX) {
      return (
        <FooterLinksNetworkItems>
          <FooterLinksSectionItem
            href="https://x.com/wirexapp"
            target="_blank"
          >
            <FooterXIcon />
          </FooterLinksSectionItem>
          <FooterLinksSectionItem
            href="https://t.me/wirexappofficial"
            target="_blank"
          >
            <FooterTelegramIcon />
          </FooterLinksSectionItem>
          <FooterLinksSectionItem
            href="https://discord.com/invite/hb5ANZxHkp"
            target="_blank"
          >
            <FooterDiscordIcon />
          </FooterLinksSectionItem>
        </FooterLinksNetworkItems>
      );
    }

    return (
      <FooterLinksNetworkItems>
        <FooterLinksSectionItem
          href="https://twitter.com/entravelhotels"
          target="_blank"
        >
          <FooterXIcon />
        </FooterLinksSectionItem>
        <FooterLinksSectionItem
          href="https://t.me/entravel_official"
          target="_blank"
        >
          <FooterTelegramIcon />
        </FooterLinksSectionItem>
        <FooterLinksSectionItem
          href="https://discord.com/invite/nHTjZ9sVAu"
          target="_blank"
        >
          <FooterDiscordIcon />
        </FooterLinksSectionItem>
      </FooterLinksNetworkItems>
    );
  };

  const renderLinks = () => {
    if (isTablet) {
      return (
        <FooterMobileLinks>
          <FooterTopSectionPayments>
            <FooterTopSectionPaymentsTitle>
              Pay with:
            </FooterTopSectionPaymentsTitle>
            <FooterTopSectionPaymentsList>
              {CURRENT_DOMAIN === DOMAIN.CODED_ESTATE ||
              CURRENT_DOMAIN === DOMAIN.BIT_2_ME ||
              CURRENT_DOMAIN === DOMAIN.ENTRAVEL ||
              CURRENT_DOMAIN === DOMAIN.CRYPTO_CLUB ? (
                <>
                  <FooterIconWrapper>
                    <FooterUsdtIcon />
                  </FooterIconWrapper>
                  <FooterIconWrapper>
                    <FooterUsdcIcon />
                  </FooterIconWrapper>
                </>
              ) : null}
              {CURRENT_DOMAIN === DOMAIN.X_PORTAL ? (
                <>
                  <FooterIconWrapper>
                    <FooterUsdtIcon />
                  </FooterIconWrapper>
                  <FooterIconWrapper>
                    <FooterUsdcIcon />
                  </FooterIconWrapper>
                  <FooterIconWrapper>
                    <FooterVisaIcon />
                  </FooterIconWrapper>
                  <FooterIconWrapper>
                    <FooterMastercardIcon />
                  </FooterIconWrapper>
                  <FooterIconWrapper>
                    <FooterAmexIcon />
                  </FooterIconWrapper>
                </>
              ) : null}
              <FooterIconWrapper>
                <FooterVisaIcon />
              </FooterIconWrapper>
              <FooterIconWrapper>
                <FooterMastercardIcon />
              </FooterIconWrapper>
              <FooterIconWrapper>
                <FooterAmexIcon />
              </FooterIconWrapper>
              {config?.featureToggles.ShowByBit ? (
                <FooterIconWrapper>
                  <FooterBybitIcon />
                </FooterIconWrapper>
              ) : null}
            </FooterTopSectionPaymentsList>
          </FooterTopSectionPayments>

          <FooterMobileLinksContainer>
            <FooterLinksSection>
              <FooterLinksSectionTitle>Company</FooterLinksSectionTitle>
              <FooterLinksSectionItems>
                {CURRENT_DOMAIN === DOMAIN.CODED_ESTATE ? (
                  <FooterLinksSectionItem
                    href="https://codedestate.com"
                    target="_blank"
                  >
                    About us
                  </FooterLinksSectionItem>
                ) : CURRENT_DOMAIN === DOMAIN.WIREX ? (
                  <FooterLinksSectionItem
                    href="https://www.wirexapp.com"
                    target="_blank"
                  >
                    About us
                  </FooterLinksSectionItem>
                ) : (
                  <FooterLinksSectionItem
                    href={`${window.location.origin}/about`}
                    target="_blank"
                  >
                    About us
                  </FooterLinksSectionItem>
                )}

                {CURRENT_DOMAIN === DOMAIN.CODED_ESTATE ? (
                  <FooterLinksSectionItem href="mailto:Media@codedestate.com">
                    Corporate Contact
                  </FooterLinksSectionItem>
                ) : null}

                {CURRENT_DOMAIN === DOMAIN.ENTRAVEL ? (
                  <FooterLinksSectionItem
                    href={`${window.location.origin}/impressum`}
                    target="_blank"
                  >
                    Corporate Contact
                  </FooterLinksSectionItem>
                ) : null}

                {CURRENT_DOMAIN !== DOMAIN.CODED_ESTATE &&
                CURRENT_DOMAIN !== DOMAIN.WIREX &&
                CURRENT_DOMAIN !== DOMAIN.BIT_2_ME ? (
                  <FooterLinksSectionItem
                    href={`${window.location.origin}/contact`}
                    target="_blank"
                  >
                    Contact us
                  </FooterLinksSectionItem>
                ) : null}
              </FooterLinksSectionItems>
            </FooterLinksSection>

            <FooterLinksSection>
              <FooterLinksSectionTitle>Support</FooterLinksSectionTitle>
              <FooterLinksSectionItems>
                <FooterLinksSectionItem onClick={handleOpenBookingsPage}>
                  Manage your trips
                </FooterLinksSectionItem>
                <FooterLinksSectionItem
                  href={`${window.location.origin}/contact`}
                  target="_blank"
                >
                  Contact customer care
                </FooterLinksSectionItem>
                <FooterLinksSectionItem
                  href={`${window.location.origin}/faq`}
                  target="_blank"
                >
                  Help Center
                </FooterLinksSectionItem>
              </FooterLinksSectionItems>
            </FooterLinksSection>

            <FooterLinksSection>
              <FooterLinksSectionTitle>Legal</FooterLinksSectionTitle>
              <FooterLinksSectionItems>
                <FooterLinksSectionItem
                  href={`${window.location.origin}/privacy-policy`}
                  target="_blank"
                >
                  Privacy & Cookies
                </FooterLinksSectionItem>
                <FooterLinksSectionItem
                  href={`${window.location.origin}/terms-and-conditions`}
                  target="_blank"
                >
                  Terms and Conditions
                </FooterLinksSectionItem>
                <FooterLinksSectionItem
                  href={`${window.location.origin}/terms-and-conditions`}
                  target="_blank"
                >
                  Dispute resolution
                </FooterLinksSectionItem>
              </FooterLinksSectionItems>
            </FooterLinksSection>
          </FooterMobileLinksContainer>

          <FooterAppLinksSection>
            {CURRENT_DOMAIN === DOMAIN.ENTRAVEL ? (
              <FooterAppMobileContainer>
                <FooterLinksSectionTitle>Get the app</FooterLinksSectionTitle>
                <FooterLinksSectionItems>
                  <FooterAppActions>
                    <AppButton onClick={handleOpenAndroidApp}>
                      <img src={GooglePlayIcon} alt="google-play-button" />
                      <AppButtonInfo>
                        <AppButtonTitle>Get in on</AppButtonTitle>
                        <AppButtonStoreTitle>Google Play</AppButtonStoreTitle>
                      </AppButtonInfo>
                    </AppButton>

                    <AppButton onClick={handleOpenIOSApp}>
                      <AppleIcon />
                      <AppButtonInfo>
                        <AppButtonTitle>Download on the</AppButtonTitle>
                        <AppButtonStoreTitle>App Store</AppButtonStoreTitle>
                      </AppButtonInfo>
                    </AppButton>
                  </FooterAppActions>
                </FooterLinksSectionItems>
              </FooterAppMobileContainer>
            ) : null}

            <FooterAppMobileContainer>
              <FooterLinksSectionTitle>Follow us</FooterLinksSectionTitle>
              {renderNetworks()}
            </FooterAppMobileContainer>
          </FooterAppLinksSection>
        </FooterMobileLinks>
      );
    }

    return (
      <FooterLinks>
        <FooterLinksSection>
          <FooterLinksSectionTitle>Company</FooterLinksSectionTitle>
          <FooterLinksSectionItems>
            {CURRENT_DOMAIN === DOMAIN.CODED_ESTATE ? (
              <FooterLinksSectionItem
                href="https://codedestate.com"
                target="_blank"
              >
                About us
              </FooterLinksSectionItem>
            ) : CURRENT_DOMAIN === DOMAIN.WIREX ? (
              <FooterLinksSectionItem
                href="https://www.wirexapp.com"
                target="_blank"
              >
                About us
              </FooterLinksSectionItem>
            ) : (
              <FooterLinksSectionItem
                href={`${window.location.origin}/about`}
                target="_blank"
              >
                About us
              </FooterLinksSectionItem>
            )}

            {CURRENT_DOMAIN === DOMAIN.CODED_ESTATE ? (
              <FooterLinksSectionItem href="mailto:Media@codedestate.com">
                Corporate Contact
              </FooterLinksSectionItem>
            ) : null}

            {CURRENT_DOMAIN === DOMAIN.ENTRAVEL ? (
              <FooterLinksSectionItem
                href={`${window.location.origin}/impressum`}
                target="_blank"
              >
                Corporate Contact
              </FooterLinksSectionItem>
            ) : null}

            {CURRENT_DOMAIN !== DOMAIN.CODED_ESTATE &&
            CURRENT_DOMAIN !== DOMAIN.WIREX &&
            CURRENT_DOMAIN !== DOMAIN.BIT_2_ME ? (
              <FooterLinksSectionItem
                href={`${window.location.origin}/contact`}
                target="_blank"
              >
                Contact us
              </FooterLinksSectionItem>
            ) : null}
          </FooterLinksSectionItems>
        </FooterLinksSection>

        <FooterLinksSection>
          <FooterLinksSectionTitle>Support</FooterLinksSectionTitle>
          <FooterLinksSectionItems>
            <FooterLinksSectionItem onClick={handleOpenBookingsPage}>
              Manage your trips
            </FooterLinksSectionItem>
            <FooterLinksSectionItem
              href={`${window.location.origin}/contact`}
              target="_blank"
            >
              Contact customer care
            </FooterLinksSectionItem>
            <FooterLinksSectionItem
              href={`${window.location.origin}/faq`}
              target="_blank"
            >
              Help Center
            </FooterLinksSectionItem>
          </FooterLinksSectionItems>
        </FooterLinksSection>

        <FooterLinksSection>
          <FooterLinksSectionTitle>Legal</FooterLinksSectionTitle>
          <FooterLinksSectionItems>
            <FooterLinksSectionItem
              href={`${window.location.origin}/privacy-policy`}
              target="_blank"
            >
              Privacy & Cookies
            </FooterLinksSectionItem>
            <FooterLinksSectionItem
              href={`${window.location.origin}/terms-and-conditions`}
              target="_blank"
            >
              Terms and Conditions
            </FooterLinksSectionItem>
            <FooterLinksSectionItem
              href={`${window.location.origin}/terms-and-conditions`}
              target="_blank"
            >
              Dispute resolution
            </FooterLinksSectionItem>
          </FooterLinksSectionItems>
        </FooterLinksSection>

        <FooterLinksSection>
          {CURRENT_DOMAIN === DOMAIN.ENTRAVEL ? (
            <>
              <FooterLinksSectionTitle>Get the app</FooterLinksSectionTitle>
              <FooterLinksSectionItems>
                <FooterAppActions>
                  <AppButton onClick={handleOpenAndroidApp}>
                    <img src={GooglePlayIcon} alt="google-play-button" />
                    <AppButtonInfo>
                      <AppButtonTitle>Get in on</AppButtonTitle>
                      <AppButtonStoreTitle>Google Play</AppButtonStoreTitle>
                    </AppButtonInfo>
                  </AppButton>

                  <AppButton onClick={handleOpenIOSApp}>
                    <AppleIcon />
                    <AppButtonInfo>
                      <AppButtonTitle>Download on the</AppButtonTitle>
                      <AppButtonStoreTitle>App Store</AppButtonStoreTitle>
                    </AppButtonInfo>
                  </AppButton>
                </FooterAppActions>
              </FooterLinksSectionItems>
            </>
          ) : null}

          <FooterLinksSectionTitle>Follow us</FooterLinksSectionTitle>
          {renderNetworks()}
        </FooterLinksSection>
      </FooterLinks>
    );
  };

  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterTopSection>
          <FooterTopSectionTitle>
            <FooterLogo />
          </FooterTopSectionTitle>

          {!isTablet ? (
            <FooterTopSectionPayments>
              <FooterTopSectionPaymentsTitle>
                Pay with:
              </FooterTopSectionPaymentsTitle>
              <FooterTopSectionPaymentsList>
                {CURRENT_DOMAIN === DOMAIN.CODED_ESTATE ||
                CURRENT_DOMAIN === DOMAIN.BIT_2_ME ||
                CURRENT_DOMAIN === DOMAIN.ENTRAVEL ||
                CURRENT_DOMAIN === DOMAIN.CRYPTO_CLUB ? (
                  <>
                    <FooterIconWrapper>
                      <FooterUsdtIcon />
                    </FooterIconWrapper>
                    <FooterIconWrapper>
                      <FooterUsdcIcon />
                    </FooterIconWrapper>
                  </>
                ) : null}
                {CURRENT_DOMAIN === DOMAIN.X_PORTAL ? (
                <>
                  <FooterIconWrapper>
                    <FooterUsdtIcon />
                  </FooterIconWrapper>
                  <FooterIconWrapper>
                    <FooterUsdcIcon />
                  </FooterIconWrapper>
                </>
              ) : null}
                <FooterIconWrapper>
                  <FooterVisaIcon />
                </FooterIconWrapper>
                <FooterIconWrapper>
                  <FooterMastercardIcon />
                </FooterIconWrapper>
                <FooterIconWrapper>
                  <FooterAmexIcon />
                </FooterIconWrapper>
                {config?.featureToggles.ShowByBit ? (
                  <FooterIconWrapper>
                    <FooterBybitIcon />
                  </FooterIconWrapper>
                ) : null}
              </FooterTopSectionPaymentsList>
            </FooterTopSectionPayments>
          ) : null}
        </FooterTopSection>

        {renderLinks()}

        <FooterRights>{renderCopyright()}</FooterRights>
      </FooterWrapper>
    </FooterContainer>
  );
};

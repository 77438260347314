interface LogoIconProps {
    isContrast?: boolean;
  }
  
  const XPortalLogo = ({ isContrast = false }: LogoIconProps) => (
    <svg width="271" height="28" viewBox="0 0 271 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_14319_4506)">
      <path d="M38.8899 3.92285C44.1562 3.92285 47.0202 6.3988 47.0202 10.8569C47.0202 15.3149 44.1562 17.8739 38.8899 17.8739H30.0836V27.1201H26.1646V3.92285H38.8899ZM30.0859 14.3643H38.8075C41.629 14.3643 42.9787 13.0028 42.9787 10.8961C42.9787 8.78935 41.6738 7.42794 38.8075 7.42794H30.0859V14.3643Z" fill="#FAFAFA"/>
      <path d="M70.9097 16.6623C70.9097 22.8147 66.1313 27.5003 59.9129 27.5003C53.6947 27.5003 48.9163 22.8147 48.9163 16.6623C48.9163 10.5099 53.6521 5.82422 59.9129 5.82422C66.1738 5.82422 70.9097 10.5099 70.9097 16.6623ZM66.9352 16.6623C66.9352 12.5193 64.1002 9.3402 59.9153 9.3402C55.7305 9.3402 52.8955 12.6036 52.8955 16.6623C52.8955 20.7209 55.7281 23.9843 59.9153 23.9843C64.1026 23.9843 66.9352 20.8052 66.9352 16.6623Z" fill="#FAFAFA"/>
      <path d="M118.662 14.068H125.726C125.726 10.8888 123.301 9.17178 119.917 9.17178C116.535 9.17178 115.111 11.097 114.778 11.766H110.932C111.476 9.29809 114.233 5.82422 119.917 5.82422C125.936 5.82422 129.362 9.21389 129.362 14.0258V21.6825C129.362 23.1469 129.865 23.6919 131.201 23.8159V27.2476C128.235 27.1635 126.438 26.0757 125.893 24.0264C124.891 25.5751 122.719 27.5003 119.039 27.5003C114.149 27.5003 110.346 24.7797 110.346 20.5128C110.346 16.2459 113.481 14.068 118.662 14.068ZM125.726 17.081H118.831C115.99 17.081 114.149 18.2109 114.149 20.5128C114.149 22.8147 116.406 24.3211 119.372 24.3211C122.717 24.3211 125.726 22.6883 125.726 19.2168V17.081Z" fill="#FAFAFA"/>
      <path d="M134.614 0.5H138.406V27.1198H134.614V0.5Z" fill="#FAFAFA"/>
      <path d="M107.691 27.1172H102.807C98.0073 27.1172 95.585 24.8206 95.585 20.2276V9.91654H89.49V6.49275H95.585V1.64062H99.384V6.49275H107.691V9.91654H99.384V19.3524C99.384 22.3584 100.343 23.6117 103.643 23.6117H107.691V27.1195V27.1172Z" fill="#FAFAFA"/>
      <path d="M74.3223 27.1196V6.36846H78.1404V10.1416C78.7272 8.64831 80.4901 6.2041 85.3561 6.2041H87.5941V9.97725H85.2729C79.9461 9.97725 78.1427 11.7406 78.1427 15.3869V27.1196H74.3223Z" fill="#FAFAFA"/>
      <path d="M11.34 9.62826L20.4766 4.88834L18.9405 2.02148L10.5743 5.30737C10.3589 5.3921 10.12 5.3921 9.90461 5.30737L1.53603 2.02148L0 4.88834L9.13659 9.62826L0 14.3659L1.53603 17.2327L9.90227 13.9468C10.1177 13.8621 10.3565 13.8621 10.5719 13.9468L18.9381 17.2327L20.4742 14.3659L11.3376 9.62598L11.34 9.62826Z" fill="#23F7DD"/>
      </g>
      <path d="M147.624 27.5V10.436H149.544V17.732C150.168 16.052 151.68 15.212 153.528 15.212C156.264 15.212 158.04 17.156 158.04 20.108V27.5H156.12V20.42C156.12 18.356 154.92 16.964 153.096 16.964C151.032 16.964 149.544 18.62 149.544 20.972V27.5H147.624ZM166.423 27.692C162.847 27.692 160.279 25.076 160.279 21.452C160.279 17.804 162.847 15.212 166.423 15.212C170.023 15.212 172.591 17.804 172.591 21.452C172.591 25.076 170.023 27.692 166.423 27.692ZM166.423 25.964C168.919 25.964 170.623 24.092 170.623 21.452C170.623 18.812 168.919 16.94 166.423 16.94C163.951 16.94 162.247 18.812 162.247 21.452C162.247 24.092 163.951 25.964 166.423 25.964ZM176.001 11.54H177.921V15.404H181.641V17.108H177.921V23.66C177.921 25.004 178.689 25.796 179.865 25.796H181.641V27.5H179.793C177.489 27.5 176.001 26.06 176.001 23.708V17.108H173.481V15.404H176.001V11.54ZM192.039 23.612H193.983C193.575 26.084 191.439 27.692 188.439 27.692C184.863 27.692 182.463 24.836 182.463 21.38C182.463 17.828 184.671 15.212 188.223 15.212C191.751 15.212 193.863 17.66 193.863 20.876C193.863 21.14 193.839 21.548 193.815 21.716H184.359C184.503 24.212 186.159 25.988 188.439 25.988C190.407 25.988 191.655 25.124 192.039 23.612ZM188.223 16.844C186.183 16.844 184.815 18.044 184.455 20.18H191.895C191.751 18.14 190.383 16.844 188.223 16.844ZM196.304 27.5V10.436H198.224V27.5H196.304ZM205.442 27.692C202.634 27.692 200.81 26.132 200.618 23.612H202.466C202.634 25.172 203.69 26.012 205.442 26.012C206.954 26.012 207.962 25.172 207.962 24.188C207.962 20.588 200.93 23.828 200.93 18.596C200.93 16.556 202.658 15.212 205.202 15.212C207.866 15.212 209.666 16.676 209.81 18.908H207.938C207.77 17.372 206.258 16.844 205.154 16.844C203.738 16.844 202.802 17.516 202.802 18.572C202.802 21.836 209.882 18.596 209.882 24.14C209.882 26.3 208.106 27.692 205.442 27.692Z" fill="#FAFAFA"/>
      <defs>
      <clipPath id="clip0_14319_4506">
      <rect width="138.406" height="27" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
      </defs>
    </svg>

  );
  
  export default XPortalLogo;
  
import { FC } from 'react';
import styled from 'styled-components/macro';

import { media } from 'src/modules/mediaQuery';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;

  button,
  &:hover,
  &:active,
  &:focus {
    color: ${(p) => p.theme.custom.actionColor} !important;
  }
`;

const Img = styled.img`
  width: 50px;
  height: 50px;
`;

const Text = styled.div`
  width: 50%;
  margin-top: 10px;
  text-align: center;
  font-size: 24px;
  color: ${(p) => p.theme.custom.roomAvailabilityTitle};

  & * {
    font-size: 24px;
    line-height: 1.2;
  }

  ${media.phone`
    width: 100%;
  `};
`;

interface NoResultsErrorProps {
  imgSrc?: string;
  children: any;
}

export const NoResultsError: FC<NoResultsErrorProps> = (props) => {
  const { imgSrc = '/assets/i/sad-smile.svg', children, ...rest } = props;

  return (
    <Wrap {...rest}>
      <Img src={imgSrc} alt="" />
      <Text>{children}</Text>
    </Wrap>
  );
};

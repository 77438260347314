interface CloseIconProps {
  className?: string;
}
export const CloseIcon = ({ className }: CloseIconProps) => (
  <svg
    className={className}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.8335"
      y="2.43848"
      width="12.3333"
      height="12.3333"
      rx="6.16667"
      fill="white"
    />
    <rect
      x="1.8335"
      y="2.43848"
      width="12.3333"
      height="12.3333"
      rx="6.16667"
      stroke="black"
    />
    <path
      d="M10.3983 6.74419C10.5466 6.59586 10.5466 6.35538 10.3983 6.20705V6.20705C10.2499 6.05872 10.0094 6.05872 9.86112 6.20705L8.00016 8.068L6.13921 6.20705C5.99088 6.05872 5.7504 6.05872 5.60207 6.20705V6.20705C5.45374 6.35538 5.45374 6.59586 5.60207 6.74419L7.46302 8.60514L5.60207 10.4661C5.45374 10.6144 5.45374 10.8549 5.60207 11.0032V11.0032C5.7504 11.1516 5.99088 11.1516 6.13921 11.0032L8.00016 9.14229L9.86112 11.0032C10.0094 11.1516 10.2499 11.1516 10.3983 11.0032V11.0032C10.5466 10.8549 10.5466 10.6144 10.3983 10.4661L8.53731 8.60514L10.3983 6.74419Z"
      fill="black"
    />
  </svg>
);

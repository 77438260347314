const XPortalSmallIcon = () => (
  <svg width="42" height="8" viewBox="0 0 42 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_14017_16290)">
  <g clip-path="url(#clip1_14017_16290)">
  <g clip-path="url(#clip2_14017_16290)">
  <path d="M12.0786 1.01465C13.639 1.01465 14.4876 1.74826 14.4876 3.06917C14.4876 4.39007 13.639 5.1483 12.0786 5.1483H9.4693V7.8879H8.30811V1.01465H12.0786ZM9.47 4.1084H12.0542C12.8902 4.1084 13.2901 3.705 13.2901 3.08079C13.2901 2.45658 12.9034 2.05319 12.0542 2.05319H9.47V4.1084Z" fill="black"/>
  <path d="M21.5659 4.78842C21.5659 6.61135 20.1501 7.99969 18.3076 7.99969C16.4651 7.99969 15.0493 6.61135 15.0493 4.78842C15.0493 2.96549 16.4525 1.57715 18.3076 1.57715C20.1627 1.57715 21.5659 2.96549 21.5659 4.78842ZM20.3883 4.78842C20.3883 3.56089 19.5483 2.61892 18.3083 2.61892C17.0683 2.61892 16.2283 3.58585 16.2283 4.78842C16.2283 5.99099 17.0676 6.95792 18.3083 6.95792C19.549 6.95792 20.3883 6.01595 20.3883 4.78842Z" fill="black"/>
  <path d="M35.7147 4.01975H37.8075C37.8075 3.07778 37.089 2.56902 36.0865 2.56902C35.0842 2.56902 34.6625 3.13946 34.5638 3.33769H33.4243C33.5855 2.60645 34.4024 1.57715 36.0865 1.57715C37.8699 1.57715 38.8851 2.5815 38.8851 4.00725V6.27589C38.8851 6.70979 39.0341 6.87129 39.4299 6.90802V7.92482C38.551 7.89989 38.0186 7.57759 37.8572 6.97039C37.5601 7.42925 36.9166 7.99969 35.8264 7.99969C34.3774 7.99969 33.2505 7.19359 33.2505 5.92932C33.2505 4.66505 34.1794 4.01975 35.7147 4.01975ZM37.8075 4.91249H35.7647C34.9229 4.91249 34.3774 5.24729 34.3774 5.92932C34.3774 6.61135 35.0462 7.05772 35.9251 7.05772C36.916 7.05772 37.8075 6.57392 37.8075 5.54532V4.91249Z" fill="black"/>
  <path d="M40.4414 0H41.565V7.88733H40.4414V0Z" fill="black"/>
  <path d="M32.4643 7.88748H31.0171C29.5949 7.88748 28.8772 7.20701 28.8772 5.84611V2.79099H27.0713V1.77653H28.8772V0.338867H30.0029V1.77653H32.4643V2.79099H30.0029V5.58681C30.0029 6.47748 30.287 6.84881 31.2647 6.84881H32.4643V7.88818V7.88748Z" fill="black"/>
  <path d="M22.5771 7.88762V1.73913H23.7084V2.8571C23.8823 2.41464 24.4047 1.69043 25.8464 1.69043H26.5095V2.8084H25.8218C24.2435 2.8084 23.7091 3.33086 23.7091 4.41126V7.88762H22.5771Z" fill="black"/>
  <path d="M3.91566 2.70406L6.6228 1.29964L6.16766 0.450195L3.68878 1.42379C3.62496 1.4489 3.55419 1.4489 3.49036 1.42379L1.01078 0.450195L0.555664 1.29964L3.2628 2.70406L0.555664 4.10779L1.01078 4.95723L3.48967 3.98363C3.5535 3.95853 3.62426 3.95853 3.68809 3.98363L6.16696 4.95723L6.6221 4.10779L3.91496 2.70338L3.91566 2.70406Z" fill="#23F7DD"/>
  </g>
  </g>
  </g>
  <defs>
  <clipPath id="clip0_14017_16290">
  <rect width="42" height="8" fill="white"/>
  </clipPath>
  <clipPath id="clip1_14017_16290">
  <rect width="40.8889" height="8" fill="white" transform="translate(0.555664)"/>
  </clipPath>
  <clipPath id="clip2_14017_16290">
  <rect width="41.0093" height="8" fill="white" transform="translate(0.555664)"/>
  </clipPath>
  </defs>
  </svg>
  
)

export default XPortalSmallIcon;

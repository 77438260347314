import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: auto;
  padding: 0;
  position: relative;
  overflow: hidden;
`;

export const Inner = styled.div<{ offset: number; gap: number }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: ${(p) => p.gap}px;
  margin: auto;
  margin-left: -${(p) => p.offset}px;
  transition: 0.3s;
`;

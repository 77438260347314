export const ListIcon = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_12705_20326)">
      <path
        d="M2.43565 3.53146C2.76525 3.53146 3.03244 3.26427 3.03244 2.93468C3.03244 2.60508 2.76525 2.33789 2.43565 2.33789C2.10606 2.33789 1.83887 2.60508 1.83887 2.93468C1.83887 3.26427 2.10606 3.53146 2.43565 3.53146Z"
        stroke="currentColor"
        stroke-width="1.29"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.61328 2.93555H16.1619"
        stroke="currentColor"
        stroke-width="1.29"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.43565 10.0959C2.76525 10.0959 3.03244 9.82872 3.03244 9.49913C3.03244 9.16954 2.76525 8.90234 2.43565 8.90234C2.10606 8.90234 1.83887 9.16954 1.83887 9.49913C1.83887 9.82872 2.10606 10.0959 2.43565 10.0959Z"
        stroke="currentColor"
        stroke-width="1.29"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.61328 9.5H16.1619"
        stroke="currentColor"
        stroke-width="1.29"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.43565 16.6604C2.76525 16.6604 3.03244 16.3931 3.03244 16.0636C3.03244 15.734 2.76525 15.4668 2.43565 15.4668C2.10606 15.4668 1.83887 15.734 1.83887 16.0636C1.83887 16.3931 2.10606 16.6604 2.43565 16.6604Z"
        stroke="currentColor"
        stroke-width="1.29"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.61328 16.0645H16.1619"
        stroke="currentColor"
        stroke-width="1.29"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_12705_20326">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@formatjs/intl-relativetimeformat/polyfill';

import smoothscroll from 'smoothscroll-polyfill';
import { useEffect, VFC } from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import 'intersection-observer';
import moment from 'moment';
import '@formatjs/intl-pluralrules/polyfill';
import CssBaseline from '@mui/material/CssBaseline';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';
import StyledEngineProvider from '@mui/styled-engine/StyledEngineProvider';
import ReactGA from 'react-ga4';
import { Loader } from 'google-maps-js-api-react';

import App from 'src/components/App/App';
import ErrorBoundaryFallback from 'src/components/ErrorBoundaryFallback';
import { store, persistor } from 'src/store';
import history from 'src/modules/history';
import { setSocialRefs } from 'src/modules/setSocialRefs';
import 'src/styles/index.css';

import muiTheme from './configs/muiTheme';
import { unregister } from './serviceWorker';
import { appConfig } from './modules/app-config';
import {
  CURRENT_DOMAIN,
  DOMAIN,
  ENV,
  GOOGLE_API_KEY,
  GOOGLE_TAG_ID,
} from './constants';
import { WebviewProvider } from './providers/webviewProvider/WebviewProvider';

import { Helmet } from 'react-helmet';

smoothscroll.polyfill();

declare module 'immutability-helper';

declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    google: typeof google;
    grecaptcha: ReCaptchaV2.ReCaptcha;
    // eslint-disable-next-line no-undef
    moment: typeof moment;
  }
}

setSocialRefs(window.location.search);

Sentry.init({
  dsn: 'https://181b8808fd98462da71a962c44d68272@o998054.ingest.sentry.io/5956491',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1,
  enabled: ENV === 'production',
  environment: ENV,
  release: appConfig('APP_BUILD_VERSION'),
});

const AppContainer: VFC = () => {
  useEffect(() => {
    const favicons = document.head.querySelectorAll(`link[rel~='icon']`);
    const faviconIco = favicons[0] as HTMLLinkElement;
    const faviconSvg = favicons[1] as HTMLLinkElement;

    if (!faviconIco || !faviconSvg) {
      return;
    }

    if (CURRENT_DOMAIN === DOMAIN.CODED_ESTATE) {
      faviconIco.href = '/coded-estate-favicon.ico';
      faviconSvg.href = '/coded-estate-favicon.svg';

      return;
    }

    if (CURRENT_DOMAIN === DOMAIN.WIREX) {
      faviconIco.href = '/wirex-favicon.ico';
      faviconSvg.href = '/wirex-favicon.svg';

      return;
    }

    if (CURRENT_DOMAIN === DOMAIN.BIT_2_ME) {
      faviconIco.href = '/bit-2-me-favicon.ico';
      faviconSvg.href = '/bit-2-me-favicon.svg';

      return;
    }

    if (CURRENT_DOMAIN === DOMAIN.EBP) {
      faviconIco.href = '/ebp-favicon.ico';
      faviconSvg.href = '/ebp-favicon.svg';

      return;
    }

    if (CURRENT_DOMAIN === DOMAIN.X_PORTAL) {
      faviconIco.href = '/xportal-favicon.ico';
      faviconSvg.href = '/xportal-favicon.svg';

      return;
    }

    if (CURRENT_DOMAIN === DOMAIN.CRYPTO_CLUB) {
      faviconIco.href = '/crypto-club-favicon.ico';
      faviconSvg.href = '/crypto-club-favicon.svg';

      return;
    }

    faviconIco.href = '/entravel-favicon.ico';
    faviconSvg.href = '/entravel-favicon.svg';
  }, [CURRENT_DOMAIN]);

  useEffect(() => {
    if (GOOGLE_TAG_ID) {
      ReactGA.initialize(GOOGLE_TAG_ID);

      // Google Tag Manager
      (function (w: any, d: any, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });
        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', GOOGLE_TAG_ID);
    }
  }, [GOOGLE_TAG_ID]);

  Loader.options = {
    version: '3',
    libraries: ['places', 'geometry'],
    apiKey: GOOGLE_API_KEY || '',
    defer: true,
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={muiTheme}>
            <CssBaseline />
            {/* @ts-expect-error */}
            <ConnectedRouter history={history}>
              {/* @ts-expect-error */}
              <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />}>
                <WebviewProvider>
                <Helmet>
                  {CURRENT_DOMAIN !== DOMAIN.ENTRAVEL ? (
                    <meta name="robots" content="noindex, nofollow" />
                  ) : null}
                </Helmet>
                  <App />
                </WebviewProvider>
              </Sentry.ErrorBoundary>
            </ConnectedRouter>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  );
};

ReactDOM.render(<AppContainer />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
unregister();

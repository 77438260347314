import { VFC } from 'react';

import { I18n } from 'src/components/I18n';

import Filter from './Filter';
import useAddToSet from './Filter/useAddToSet';

type Props = {
  filterableFacilities: number[];
  facilitiesSet?: Set<number>;
};

const FacilitiesSection: VFC<Props> = ({
  filterableFacilities,
  facilitiesSet,
}) => {
  const { set, handleChange } = useAddToSet('facilities');

  return (
    <>
      {filterableFacilities.map((facilityId) => {
        const selected = set.has(facilityId);
        const isInFacilitiesSet = facilitiesSet?.has(facilityId);

        if (!isInFacilitiesSet) {
          return null;
        }

        return (
          <Filter
            checked={selected}
            onChange={(_, checked) => handleChange(checked, facilityId)}
            key={facilityId}
            label={<I18n id={`FACILITIES.${facilityId}` as any} />}
            disabled={!selected && !facilitiesSet?.has(facilityId)}
          />
        );
      })}
    </>
  );
};

export default FacilitiesSection;

import ContentLoader from 'react-content-loader';
import { mainTheme } from 'src/configs/mainTheme';

export const RoomGridSkeleton = () => (
  <ContentLoader
    width={280}
    viewBox={'0 0 280 880'}
    preserveAspectRatio="none"
    speed={2}
    backgroundOpacity={0.2}
    backgroundColor={mainTheme.borderColor}
    foregroundColor={mainTheme.greyUltraLightColor}
  >
    <rect x="0" y="0" width="280" height="160" />
    <rect x="15" y="175" width="150" height="25" />
    <rect x="15" y="215" width="75" height="30" />
    <rect x="95" y="215" width="95" height="30" />
    <rect x="195" y="215" width="65" height="30" />
    <rect x="15" y="250" width="95" height="30" />
    <rect x="115" y="250" width="65" height="30" />
    <rect x="185" y="250" width="75" height="30" />
    <rect x="15" y="325" width="250" height="30" />
    <rect x="15" y="365" width="250" height="20" />
    <rect x="15" y="395" width="250" height="20" />
    <rect x="15" y="445" width="250" height="30" />
    <rect x="15" y="485" width="250" height="20" />
    <rect x="15" y="515" width="250" height="20" />
    <rect x="15" y="545" width="250" height="20" />
    <rect x="15" y="575" width="250" height="20" />
    <rect x="15" y="605" width="250" height="20" />
    <rect x="15" y="635" width="250" height="130" />
    <rect x="15" y="790" width="250" height="50" />
  </ContentLoader>
);

import { FC } from 'react';
import styled from 'styled-components/macro';

import {
  FoodType,
  getFoodTypeNameBy,
  getRefundabilityLabel,
} from 'src/modules/getRoomInfo';
import { I18n } from 'src/components/I18n';
import { PriceDisplay } from 'src/components/PriceDisplay';
import { CtDomainMarketingPricePresentation } from 'src/models/hotels';
import BybitIcon from 'src/assets/icons/bybit-icon-small';
import useConfig from 'src/hooks/swr/useConfig';
import XPortalSmallIcon from 'src/assets/icons/xportal-small';
import { CURRENT_DOMAIN, DOMAIN } from 'src/constants';


const FoodTypeName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(p) => p.theme.custom.roomAvailabilityTitle};
`;

type RefundabilityTypeProps = {
  isRefundable?: boolean;
};

const RefundabilityType = styled.div<RefundabilityTypeProps>`
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: ${(p) =>
    p.isRefundable
      ? p.theme.custom.greenSecondaryColor
      : p.theme.custom.nonRefundableColor};
`;

const TaxesChargesAndFees = styled(RefundabilityType)`
  color: ${(p) => p.theme.custom.roomAvailabilityTitle};
`;

const TaxesText = styled.div`
  font-size: 11px;
  line-height: 17px;
  color: ${(p) => p.theme.custom.grayTextColor};
`;

const BybitInfo = styled.div`
  font-size: 12px;
  display: flex;
  gap: 4px;
  align-items: center;
  line-height: 17px;
  color: ${(p) => p.theme.custom.blackMainColor};
  margin-top: 28px;
`;

const ByBitIconWrapper = styled.div`
`
interface PackageInfoProps {
  foodType: FoodType;
  isRefundable: boolean;
  tax: number;
  fee: number;
  pricePresentation: CtDomainMarketingPricePresentation;
  className?: string;
}

export const RoomInfo: FC<PackageInfoProps> = ({
  className,
  foodType,
  isRefundable,
  tax,
  fee,
  pricePresentation,
}) => {
  const foodTypeNameIntlId = getFoodTypeNameBy(foodType);
  const { data: config } = useConfig();

  const taxI18nId =
    pricePresentation === CtDomainMarketingPricePresentation.BaseRate
      ? 'HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.EXCLUDE_FEES'
      : 'HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.INCLUDE_FEES';
  const taxI18nValues = {
    amount: <PriceDisplay valuesInEur={tax} />,
  };

  const extraChargesI18nId =
    pricePresentation === CtDomainMarketingPricePresentation.AllInclusive
      ? 'HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.INCLUDE_EXTRA_CHARGES'
      : 'HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.EXCLUDE_EXTRA_CHARGES';
  const extraChargesI18nValues = {
    amount: <PriceDisplay valuesInEur={fee} />,
  };

  return (
    <div className={className}>
      <FoodTypeName>
        <I18n id={foodTypeNameIntlId} />
      </FoodTypeName>
      <RefundabilityType isRefundable={isRefundable}>
        <I18n id={getRefundabilityLabel(isRefundable)} />
      </RefundabilityType>
      {config?.featureToggles.ShowByBit ? (
        <BybitInfo >
          <I18n id="HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.BYBIT_INFO" /> 
          <ByBitIconWrapper>
            <BybitIcon/>
          </ByBitIconWrapper>
        </BybitInfo>
      ) : null}
      {CURRENT_DOMAIN === DOMAIN.X_PORTAL ? ( 
        <BybitInfo >
        <I18n id="HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.XPORTAL_INFO" />
        <ByBitIconWrapper>
          <XPortalSmallIcon/>
        </ByBitIconWrapper>
      </BybitInfo>
      ) : null}

      {Boolean(tax) && (
        <TaxesText data-test-id="HOTEL_DETAILS_PAGE.ROOMS.TAX_TEXT">
          <I18n id={taxI18nId} values={taxI18nValues} />
        </TaxesText>
      )}
      {Boolean(fee) && (
        <TaxesText data-test-id="HOTEL_DETAILS_PAGE.ROOMS.FEE_TEXT">
          <I18n id={extraChargesI18nId} values={extraChargesI18nValues} />
        </TaxesText>
      )}
    </div>
  );
};

import { FC } from 'react';
import styled from 'styled-components/macro';

import { ArrowIcon } from 'src/components/common/ArrowIcon';
import { fontSizeSmall } from 'src/modules/fontSizes';
import { DataElementsId } from 'src/constants';

const ShowMoreContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  ${fontSizeSmall};
  font-weight: bold;
`;

const ShowMore = styled.div`
  color: ${(p) => p.theme.custom.actionColor};
  align-self: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`;

const WrappedArrowIcon = styled(ArrowIcon)`
  line-height: 9px !important;
`;

interface ExpandButtonProps {
  collapsed: boolean;
  className?: string;
  toggle: () => void;
  withArrowIcon?: boolean;
}

const ExpandButton: FC<ExpandButtonProps> = ({
  collapsed,
  toggle,
  className = '',
  children,
  withArrowIcon,
}) => (
  <ShowMoreContainer
    className={className}
    onClick={toggle}
    data-test-id={DataElementsId.HOTEL_ROOM_LIST_SHOW_MORE_BUTTON}
  >
    <ShowMore>
      {children}
      {withArrowIcon && <WrappedArrowIcon rotate={collapsed ? 0 : 180} />}
    </ShowMore>
  </ShowMoreContainer>
);

export { ExpandButton };

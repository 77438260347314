export const ROUTES = {
  HOME: '/',
  SEARCH: '/search',
  CHECKOUT: '/checkout',
  ACCOUNT: '/account/:sectionId?',
  CONFIRMATION: '/confirmation',
  HOTEL: '/hotel/:hotelId',
  HOTEL_NAME: '/hotel-name/:hotelId',
  TERMS_AND_CONDITIONS: '/(terms-and-conditions|privacy-policy)',
  CONTACT: '/contact',
  AFFILIATE_DASHBOARD: '/affiliate-dashboard',
  ABOUT: '/about',
  IMPRESSUM: '/impressum',
  FAQ: '/faq',
  NO_PAGE: '/403',
  SUBSCRIPTION: '/subscriptions-management/:token',
  UNSUBSCRIBE: '/unsubscribe',
  NEWSLATTER: '/newsletter/thankyou',
  SIGN_UP: '/auth/sign-up',
  SIGN_IN: '/auth/sign-in',
  RESET_PASSWORD: '/auth/reset-password',
  RECOVER_PASSWORD: '/reset-password',
}
import Grid from '@mui/material/Grid';

import TogglerButton from 'src/components/common/TogglerButton';

import MagicGrid from './MagicGrid';
import { SectionProps } from './Section';

const TabSection = <T extends string | number>({
  onChange,
  value,
  values,
  getIcon,
  getLabel,
}: SectionProps<T>) => (
  <MagicGrid rowSpacing={7} columnSpacing={12}>
    {values.map((item) => (
      <Grid item key={item}>
        <TogglerButton
          startIcon={getIcon ? getIcon(item) : item}
          size="small"
          color="buttonSecondary"
          variant="inline"
          onClick={onChange}
          selected={item === value}
          value={item}
        >
          {getLabel ? getLabel(item) : item}
        </TogglerButton>
      </Grid>
    ))}
  </MagicGrid>
);

export default TabSection;

const areSetsEqual = <T>(a: Set<T>, b: Set<T>) => {
  if (a.size !== b.size) return false;

  const iterator = a.values();

  for (let i = a.size; i--; ) {
    if (!b.has(iterator.next().value)) return false;
  }

  return true;
};

export default areSetsEqual;

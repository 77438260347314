import { ReactNode, useRef, useState } from 'react';
import { Slider, SliderProps, SliderRef } from '../Slider';
import {
  ArrowLeft,
  ArrowLeftWrapper,
  ArrowRight,
  ArrowRightWrapper,
  Wrapper,
} from './styles';

type ArrowSliderProps = Pick<SliderProps, 'elements'> & {
  children?: ReactNode;
  className?: string;
  transparentArrows?: boolean;
  arrowPadding?: number;
  onChange?: (idx: number) => void;
};

export const ArrowSlider = ({
  elements,
  children,
  className,
  onChange,
  transparentArrows = false,
  arrowPadding = -16,
}: ArrowSliderProps) => {
  const ref = useRef<SliderRef>(null);

  const [currentIdx, setCurrentIdx] = useState(0);

  const handleChange = (idx: number) => {
    setCurrentIdx(idx);

    onChange?.(idx);
  };

  return (
    <Wrapper className={className}>
      <Slider elements={elements} ref={ref} onChange={handleChange} />
      {currentIdx > 0 && (
        <ArrowLeftWrapper
          padding={arrowPadding}
          transparent={transparentArrows}
          onClick={() => ref.current?.left()}
        >
          <ArrowLeft />
        </ArrowLeftWrapper>
      )}
      {currentIdx < elements.length - 1 && (
        <ArrowRightWrapper
          padding={arrowPadding}
          transparent={transparentArrows}
          onClick={() => ref.current?.right()}
        >
          <ArrowRight />
        </ArrowRightWrapper>
      )}
      {children}
    </Wrapper>
  );
};

import InputAdornment from '@mui/material/InputAdornment';
import Slider from '@mui/material/Slider';
import { VFC } from 'react';
import { useController } from 'react-hook-form';
import round from 'lodash/round';
import isNull from 'lodash/isNull';

import { I18n } from 'src/components/I18n';
import useDerivedState from 'src/hooks/useDerivedState';

import {
  DISABLED_SLIDER_MAX,
  DISABLED_SLIDER_MIN,
} from './PriceSection/PriceSlider';
import { StyledTextField } from './styled';
import { cursorToEnd } from './PriceSection/PriceInput';

import { FiltersForm } from '.';

type Props = { disabled: boolean; max?: number; min?: number };

const meterToKm = (value: number) => round(value / 1000, 2);

const kmToMeter = (value: number) => Math.round(value * 100) * 10;

const DistanceSection: VFC<Props> = ({ disabled, min, max }) => {
  const {
    field: { value, onChange, onBlur, ...rest },
  } = useController<FiltersForm, 'distanceToPoint'>({
    name: 'distanceToPoint',
  });

  const convertedMin = min && meterToKm(min);

  const convertedMax = max && meterToKm(max);

  const convertedValue = value ? meterToKm(value) : convertedMax!;

  const handleChange = (newValue: number) => {
    if (newValue === convertedValue) {
      return newValue;
    }

    let value;
    let innerValue;

    if (newValue >= convertedMax!) {
      value = null;

      innerValue = convertedMax!;
    } else if (newValue < convertedMin!) {
      value = min!;

      innerValue = convertedMin!;
    } else {
      value = kmToMeter(newValue);

      innerValue = newValue;
    }

    onChange(value);

    return innerValue;
  };

  const [innerValue, setInnerValue] = useDerivedState<number | ''>(
    convertedValue ?? ''
  );

  return (
    <div>
      <Slider
        disabled={disabled}
        value={isNull(innerValue) ? DISABLED_SLIDER_MAX : +innerValue}
        min={convertedMin ?? DISABLED_SLIDER_MIN}
        max={convertedMax ?? DISABLED_SLIDER_MAX}
        onChange={(_, value) => setInnerValue(value as number)}
        onChangeCommitted={(_, value) => {
          handleChange(value as number);
        }}
      />
      <StyledTextField
        variant="outlined"
        type="number"
        inputProps={{ pattern: '\\d*', inputMode: 'decimal' }}
        InputProps={{
          startAdornment: (
            <InputAdornment sx={{ color: (theme) => theme.custom.inputAdornmentColor }} position="start" disableTypography>
              <I18n id="SEARCH_PAGE.FILTERS_PANEL.DISTANCE_PREPEND_ADDON" />
            </InputAdornment>
          ),
        }}
        label={false}
        fullWidth
        disabled={disabled}
        value={innerValue}
        onChange={(e) => {
          const value = e.target.value;

          setInnerValue(value !== '' ? +value.replace(',', '.') : value);
        }}
        onBlur={() => {
          if (innerValue === '') {
            setInnerValue(convertedValue);
          } else {
            const changedValue = handleChange(innerValue);

            if (changedValue !== innerValue) {
              setInnerValue(changedValue);
            }
          }

          onBlur();
        }}
        onFocus={cursorToEnd}
        {...rest}
      />
    </div>
  );
};

export default DistanceSection;

import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { I18n } from 'src/components/I18n';
import { PriceDisplay } from 'src/components/PriceDisplay';
import { LowPriceIcon } from 'src/icons/LowPriceIcon';
import { ManWomanIcon } from 'src/icons/ManWomanIcon';
import { PriceGuaranteeIcon } from 'src/icons/PriceGuaranteeIcon';
import { RoomFacility } from 'src/models/config';
import { RoomGroup } from 'src/models/hotels';
import { FoodType, getFoodTypeNameBy } from 'src/modules/getRoomInfo';
import { ROUTES } from 'src/router';
import { setPackageAndProceedToCheckout } from 'src/store/checkout/actions';
import { currencySelector } from 'src/store/currency/selectors';
import { searchParamsSelector } from 'src/store/search/selectors';
import { userSelector } from 'src/store/user/selectors';
import { Image } from 'src/types';
import { Tooltip } from 'src/components/shared/Tooltip';

import { NoImagePlaceholder } from '../ListRoom/CommonComponents';

import {
  ActiveText,
  AlignIcon,
  BookButton,
  CardCheckbox,
  CardImageSlider,
  CardImageSliderCounter,
  CardImg,
  CardTag,
  CardTagAlternative,
  CardTagFilled,
  CardTagsContainer,
  Container,
  Content,
  DataContainer,
  DataSection,
  NameWrapper,
  NoImageWrapper,
  ScrollList,
  SectionH2,
  SectionH3,
  ServiceContainer,
  ServicePrice,
  StyledLink,
  SummaryPrice,
  TextSecondary,
  TotalsSection,
  TotalsSectionContent,
  InfoIcon,
  TextPrimary,
} from './styles';
import { hotelSelector } from 'src/store/hotel/selectors';

interface GridRoomProps {
  name: string;
  images: Image[];
  showImages: boolean;
  packages: RoomGroup[];
  facilities: RoomFacility[];
  active: boolean;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
  handleTouchStart: () => void;
  scrollFn: () => void;
}

export const GridRoom = memo(
  ({
    name,
    images,
    showImages,
    packages,
    facilities,
    active,
    handleMouseEnter,
    handleMouseLeave,
    handleTouchStart,
    scrollFn,
  }: GridRoomProps) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { isAuthenticated } = useSelector(userSelector);
    const { totalNights } = useSelector(hotelSelector);
    const { dates } = useSelector(searchParamsSelector);

    const [currentImage, setCurrentImage] = useState(0);
    const [tagsExpanded, setTagsExpaned] = useState(false);

    const [cancellation, setCancellation] = useState<boolean>(false);
    const [bookData, setBookData] = useState<{
      meal: FoodType;
      id: string;
      price: RoomGroup['price'];
      cheapest: boolean;
    } | null>(null);

    const { currentRate } = useSelector(currencySelector);

    const { rooms } = useSelector(searchParamsSelector);
    const guestsCount = useMemo(
      () => rooms.reduce((a, b) => b.adults + b.children.length + a, 0),
      [rooms]
    );

    const getPackagePrice = (price: RoomGroup['price']) => price.price;
    const formatPrice = (price: number, hideSign = false) => (
      <>
        {!hideSign && (price >= 0 ? `+ ` : `- `)}
        <PriceDisplay valuesInEur={Math.abs(price)} />
      </>
    );

    const findMinPricePackage = useCallback((packages: RoomGroup[]) => {
      let min = packages[0];
      for (let i = 1; i < packages.length; i++) {
        if (packages[i].price.price < min.price.price) min = packages[i];
      }

      return min;
    }, []);

    const handleSelectCancellation = (cancellation: boolean) => {
      let p = null;
      for (const _package of packages) {
        if (_package.isRefundable === cancellation) {
          p = _package;

          if (!bookData || _package.boarding === bookData?.meal) {
            break;
          }
        }
      }

      if (!p) return new Error("Can't find package");

      setCancellation(cancellation);
      setBookData({
        meal: p.boarding,
        id: p.id,
        price: p.price,
        cheapest: p.isCheapest,
      });
    };

    const hanldeSelectMeal = (meal: FoodType) => {
      const p = packages.find(
        (p) => p.isRefundable === cancellation && p.boarding === meal
      );

      if (!p) return new Error("Can't find package");

      setBookData({
        meal: p.boarding,
        id: p.id,
        price: p.price,
        cheapest: p.isCheapest,
      });
    };

    const allowedCancellations = useMemo(() => {
      const nonRefundable = findMinPricePackage(
        packages.filter((p) => !p.isRefundable)
      );

      const refundable = findMinPricePackage(
        packages.filter((p) => p.isRefundable)
      );

      return (
        <>
          {nonRefundable && (
            <ServiceContainer
              onClick={() => {
                handleSelectCancellation(false);
              }}
            >
              <CardCheckbox checked={!cancellation} />
              <I18n id="BOOKING_TYPE.NON_REFUNDABLE" />
              <ServicePrice>{formatPrice(0)}</ServicePrice>
            </ServiceContainer>
          )}
          {refundable && (
            <ServiceContainer
              onClick={() => {
                handleSelectCancellation(true);
              }}
            >
              <CardCheckbox checked={cancellation} />
              <I18n id="BOOKING_TYPE.REFUNDABLE" />
              <ServicePrice>
                {nonRefundable
                  ? formatPrice(
                      getPackagePrice(refundable.price) -
                        getPackagePrice(nonRefundable.price)
                    )
                  : formatPrice(0)}
              </ServicePrice>
            </ServiceContainer>
          )}
        </>
      );
    }, [currentRate, cancellation, packages]);

    const { mealsLength, allowedMeals } = useMemo(() => {
      const min = findMinPricePackage(
        packages.filter((p) => p.isRefundable === cancellation)
      );

      const data = Array.from(
        new Set(
          packages
            .filter((p) => p.isRefundable === cancellation)
            .map((p) => ({
              meal: p.boarding,
              price: p.price,
            }))
        )
      );

      return {
        mealsLength: data.length,
        allowedMeals: (
          <>
            {data.map((data) => (
              <ServiceContainer
                key={data.meal}
                onClick={() => {
                  hanldeSelectMeal(data.meal);
                }}
              >
                <CardCheckbox checked={bookData?.meal === data.meal} />
                <I18n id={getFoodTypeNameBy(data.meal)} />
                <ServicePrice>
                  {formatPrice(
                    getPackagePrice(data.price) - getPackagePrice(min.price)
                  )}
                </ServicePrice>
              </ServiceContainer>
            ))}
          </>
        ),
      };
    }, [currentRate, cancellation, bookData, packages]);

    useEffect(() => {
      if (!packages.length) throw new Error('No rooms packages provided');

      const min = findMinPricePackage(packages);

      setCancellation(min.isRefundable);
      setBookData({
        meal: min.boarding,
        id: min.id,
        price: min.price,
        cheapest: min.isCheapest,
      });
    }, [packages]);

    const handleBook = () => {
      if (!bookData) return;

      if (isAuthenticated) {
        dispatch(setPackageAndProceedToCheckout(bookData?.id));
      } else {
        history.push(ROUTES.SIGN_IN);
      }
    };

    const formatDates = () => {
      const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
      };

      const s1 = dates[0]?.toLocaleDateString(undefined, options);
      const s2 = dates[1]?.toLocaleDateString(undefined, options);

      return `(${s1} - ${s2})`;
    };

    return (
      <Container
        active={active}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
      >
        {showImages && images.length ? (
          <CardImageSlider
            onChange={setCurrentImage}
            arrowPadding={12}
            transparentArrows={true}
            elements={images.map((i) => ({
              key: i.id,
              node: <CardImg src={i.url} />,
            }))}
          >
            <CardImageSliderCounter>
              {currentImage + 1}/{images.length}
            </CardImageSliderCounter>
          </CardImageSlider>
        ) : (
          <NoImageWrapper>
            <NoImagePlaceholder />
          </NoImageWrapper>
        )}

        <Content>
          <DataContainer>
            <DataSection>
              <SectionH2>{name}</SectionH2>
              <ActiveText onClick={() => scrollFn()} textDecoration="underline">
                <I18n id="HOTEL_DETAILS_PAGE.ROOMS.MORE" />
              </ActiveText>
            </DataSection>
            <DataSection>
              <SectionH2>
                Cancellation policy{' '}
                <Tooltip
                  msg={
                    <I18n id="HOTEL_DETAILS_PAGE.ROOMS.TOOLTIP.CANCELLATION" />
                  }
                >
                  <AlignIcon>
                    <InfoIcon />
                  </AlignIcon>
                </Tooltip>
              </SectionH2>

              {allowedCancellations}
            </DataSection>
            {mealsLength > 0 && (
              <DataSection mb={30}>
                <SectionH2>
                  Meals{' '}
                  <Tooltip
                    msg={<I18n id="HOTEL_DETAILS_PAGE.ROOMS.TOOLTIP.MEALS" />}
                  >
                    <AlignIcon>
                      <InfoIcon />
                    </AlignIcon>
                  </Tooltip>
                </SectionH2>
                {allowedMeals}
              </DataSection>
            )}
            <TotalsSection>
              <TotalsSectionContent align={'start'}>
                <SectionH3>Total guests</SectionH3>
                <AlignIcon>
                  <ManWomanIcon /> x{guestsCount}
                </AlignIcon>
              </TotalsSectionContent>
              <TotalsSectionContent align={'end'}>
                <SectionH3>
                  Total for ({rooms.length}) Room{rooms.length > 1 && 's'}
                </SectionH3>
                <CardTagFilled hide={!bookData?.cheapest}>
                  <AlignIcon>
                    <LowPriceIcon /> Lowest price
                  </AlignIcon>
                </CardTagFilled>
                <CardTagAlternative>
                  <AlignIcon>
                    <PriceGuaranteeIcon /> Price guarantee
                  </AlignIcon>
                </CardTagAlternative>
                <TextPrimary>
                  {totalNights} night(s) · {formatDates()}
                </TextPrimary>
                {bookData && (
                  <SummaryPrice>
                    {formatPrice(getPackagePrice(bookData.price), true)}
                  </SummaryPrice>
                )}
                <TextSecondary>Including taxes & fees</TextSecondary>
              </TotalsSectionContent>
            </TotalsSection>
          </DataContainer>
        </Content>
        <BookButton onClick={() => handleBook()}>Book</BookButton>
      </Container>
    );
  }
);

import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import LogoIcon from 'src/icons/Logo';
import CodedEstateLogo from 'src/icons/CodedEstateLogo';
import { CURRENT_DOMAIN, DOMAIN } from 'src/constants';
import BitToMeFooterLogo from 'src/icons/BitToMeFooterLogo';
import WirexFooterLogo from 'src/icons/WirexFooterLogo';
import EbpFooterLogo from 'src/icons/EbpFooterLogo';
import CryptoClubLogo from 'src/icons/CryptoClub';
import xPortalLogo from 'src/icons/XPortal';


// TODO: remove important after GlobalStyle refactoring
const StyledLogoLink = styled(Link)`
  color: inherit !important;
`;

interface ILogo {
  onClick?: () => void;
  isContrast?: boolean;
}

const logoMap = {
  [DOMAIN.BIT_2_ME]: BitToMeFooterLogo,
  [DOMAIN.CODED_ESTATE]: CodedEstateLogo,
  [DOMAIN.ENTRAVEL]: LogoIcon,
  [DOMAIN.WIREX]: WirexFooterLogo,
  [DOMAIN.EBP]: EbpFooterLogo,
  [DOMAIN.CRYPTO_CLUB]: CryptoClubLogo,
  [DOMAIN.X_PORTAL]: xPortalLogo,
}

export const FooterLogo = ({ onClick, isContrast }: ILogo) => {
  const CurrentLogo = logoMap[CURRENT_DOMAIN];

  return (
    <StyledLogoLink to="/" onClick={onClick}>
      <CurrentLogo />
    </StyledLogoLink>
  )
};

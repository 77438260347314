import _merge from 'lodash/merge';
import _get from 'lodash/get';

declare const window: Window & {
  CLICKTRIP: {
    config: any;
  };
};

let effectiveConfig: any = null;

/**
 * Resolves configuration variables
 */
export const appConfig = (name: string): string => {
  if (!effectiveConfig) {
    effectiveConfig = _merge(
      {},
      (window.CLICKTRIP || {}).config
    );
  }
  return _get(effectiveConfig, name);
};

import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components/macro';

import LogoIcon from 'src/icons/Logo';
import CodedEstateLogo from 'src/icons/CodedEstateLogo';
import { CURRENT_DOMAIN, DOMAIN } from 'src/constants';
import BitToMeLogo from 'src/icons/BitToMeLogo';
import WirexLogo from 'src/icons/WirexLogo';
import EbpLogo from 'src/icons/EbpLogo';
import CryptoClubLogo from 'src/icons/CryptoClub';
import CryptoClubMobileLogo from 'src/icons/CryptoClubMobileLogo';
import useMatchMedia from 'src/hooks/useMatchMedia';
import xPortalLogo from 'src/icons/XPortal';

// TODO: remove important after GlobalStyle refactoring
const StyledLogoLink = styled(Link)`
  color: inherit !important;
`;

interface ILogo {
  onClick?: () => void;
  isContrast?: boolean;
}

export const Logo = ({ onClick, isContrast }: ILogo) => {
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('sm'));

  const logoMap = {
    [DOMAIN.BIT_2_ME]: BitToMeLogo,
    [DOMAIN.CODED_ESTATE]: CodedEstateLogo,
    [DOMAIN.ENTRAVEL]: LogoIcon,
    [DOMAIN.WIREX]: WirexLogo,
    [DOMAIN.EBP]: EbpLogo,
    [DOMAIN.CRYPTO_CLUB]: isMobile ? CryptoClubMobileLogo : CryptoClubLogo,
    [DOMAIN.X_PORTAL]: xPortalLogo,
  }
  
  const CurrentLogo = logoMap[CURRENT_DOMAIN]

  return (
    <StyledLogoLink to="/" onClick={onClick}>
      <CurrentLogo isContrast={isContrast} />
    </StyledLogoLink>
  )
};

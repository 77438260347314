import { FC, memo, useCallback } from 'react';
import styled from 'styled-components/macro';

import { HotelInfoSelectorResult } from 'src/store/hotel/selectors';
import { I18n } from 'src/components/I18n';
import { MultilineCollapse } from 'src/components/shared/MultilineCollapse';
import { MailToLink } from 'src/components/common/MailToLink';
import { SectionId } from 'src/components/pages/HotelDetails/useSectionsNavigator';

import { StyledPanel } from '../common';

const Wrapper = styled.div`
  background-color: ${(p) => p.theme.custom.aboutHotelBgColor};
  border-radius: ${(p) => p.theme.custom.aboutHotelBorderRadius};
  border: 1px solid ${(p) => p.theme.custom.aboutHotelBorder};
  padding: 36px 40px;
`;

const StyledMultilineCollapse = styled(MultilineCollapse)`
  color: ${(p) => p.theme.custom.roomAvailabilityTitle};
  line-height: 20px;

  h4 {
    font-size: 16px;
    margin: 0;
  }

  p {
    font-size: 14px;
  }

  ul {
    padding-left: 15px;
    font-size: 14px;
  }
`;

const LINES_TO_SHOW = 30;

interface AboutHotelProps {
  info: HotelInfoSelectorResult;
  scrollToSection: (sectionId: SectionId) => void;
  sectionRef?: (el: HTMLDivElement | null) => void;
}

export const AboutHotel: FC<AboutHotelProps> = memo((props) => {
  const { info, scrollToSection, sectionRef } = props;
  const { description } = info!;
  const handleExpandButtonClick = useCallback(
    (collapsed: boolean) => {
      if (!collapsed) {
        scrollToSection('about-hotel');
      }
    },
    [scrollToSection]
  );

  return (
    <Wrapper ref={sectionRef} data-id="about-hotel">
      <StyledPanel title={<I18n id="HOTEL_DETAILS_PAGE.ABOUT_HOTEL.TITLE" />}>
        {description ? (
          <StyledMultilineCollapse
            numberOfLinesToShow={LINES_TO_SHOW}
            text={description}
            onExpandButtonClick={handleExpandButtonClick}
          />
        ) : (
          <I18n
            id="HOTEL_DETAILS_PAGE.CONTENT.ABOUT_HOTEL.EMPTY_HOTEL_INFO"
            values={{ link: <MailToLink email={'support@entravel.com'} /> }}
          />
        )}
      </StyledPanel>
    </Wrapper>
  );
});

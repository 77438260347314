import { parse } from 'querystring';
import { PropsWithChildren, useEffect, useState } from 'react';
import { WebviewContext } from './WebviewContext';

export const WebviewProvider = ({ children }: PropsWithChildren) => {
  const [webview, setWebview] = useState(false);

  useEffect(() => {
    if (webview) return;

    const params = parse(document.location.search);
    if (params['?webview'] || params['webview']) setWebview(true);
  }, [document.location.search]);

  return (
    <WebviewContext.Provider value={{ webview }}>
      {children}
    </WebviewContext.Provider>
  );
};

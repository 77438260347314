import { ReactElement } from 'react';
import styled from 'styled-components';

interface HideProps {
  noFlow?: boolean;
  hide?: boolean;
  transition?: boolean;
  children: ReactElement;
}

export const Hide = ({
  noFlow = false,
  hide = false,
  transition = false,
  children,
}: HideProps) => (
  <Container hide={hide} noFlow={noFlow} transition={transition}>
    {children}
  </Container>
);

const Container = styled.div<{
  hide: boolean;
  noFlow: boolean;
  transition: boolean;
}>`
  display: ${(props) => (props.noFlow ? 'none' : 'block')};
  visibility: ${(props) => (props.hide ? 'hidden' : 'visible')};
  opacity: ${(props) => (props.hide ? '0' : '1')};
  ${(props) => (props.transition ? 'transition: .3s opacity' : '')};
`;

import { push } from 'connected-react-router';
import debounce from 'lodash/debounce';
import isNull from 'lodash/isNull';
import { parse, stringifyUrl } from 'query-string';
import { FC, useCallback, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useConst from 'src/hooks/useConst';
import {
  parseClientOptions,
  stringifyClientOptions,
} from 'src/modules/queryStringOptions';
import {
  FiltersForm,
  FiltersQueryParams,
  getUrlWithoutFilters,
} from './Filters';
import { ResetFiltersContext } from './SearchPageProvider';

const DEFAULT_VALUES: FiltersForm = {
  name: '',
  distanceToPoint: null,
  priceFrom: null,
  priceTo: null,
  stars: new Set(),
  suitableTypes: new Set(),
  facilities: new Set(),
  rating: new Set(),
  propertyTypes: new Set(),
  hideSoldOut: true,
};

const SET_NAMES = new Set<keyof FiltersQueryParams>([
  'facilities',
  'propertyTypes',
  'rating',
  'stars',
]);

const getFilterParams = (params: Record<string, any>) =>
  (Object.keys(DEFAULT_VALUES) as (keyof FiltersForm)[]).reduce((acc, key) => {
    if (key in params) {
      const value = (params as FiltersQueryParams)[key];

      return {
        ...acc,
        [key]: SET_NAMES.has(key)
          ? new Set(Array.isArray(value) ? value : [value])
          : value,
      };
    }

    return acc;
  }, {} as Partial<FiltersForm>);

const getDefaultParams = (): FiltersForm => ({
  ...DEFAULT_VALUES,
  ...getFilterParams(parse(window.location.search, parseClientOptions)),
});

const filtersToQuery = (filters: FiltersForm) =>
  (Object.keys(filters) as (keyof typeof filters)[]).reduce((acc, key) => {
    const value = filters[key];

    if (isNull(value)) {
      return acc;
    }

    if (value instanceof Set) {
      if (value.size) {
        return { ...acc, [key]: Array.from(value) };
      }

      return acc;
    }

    return { ...acc, [key]: value };
  }, {} as FiltersQueryParams);

const LOCAL_STORAGE_FILTERS_ITEM = 'search_filters';

export const SearchPageFormProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const { setResetFiltersHandler } = useContext(ResetFiltersContext);
  const { listen } = useHistory();
  const defaultValues = useConst(getDefaultParams);
  const methods = useForm({
    defaultValues,
  });

  const { reset, handleSubmit, watch } = methods;

  const updateFilters = useCallback((data: FiltersForm) => {
    const url = stringifyUrl(
      {
        url: getUrlWithoutFilters(),
        query: filtersToQuery(data),
      },
      stringifyClientOptions
    );

    dispatch(push(url));
  }, []);

  useEffect(() => {
    const data = localStorage.getItem(LOCAL_STORAGE_FILTERS_ITEM);
    if (data) {
      const parsed = JSON.parse(data);
      updateFilters(parsed);
      reset({ ...DEFAULT_VALUES, ...getFilterParams(parsed) });
    }
  }, []);

  useEffect(
    () =>
      listen((_, action) => {
        if (action === 'POP') {
          reset(getDefaultParams());
        }
      }),
    []
  );

  useEffect(() => {
    setResetFiltersHandler((withPush) => {
      reset(DEFAULT_VALUES);

      if (withPush) {
        const url = getUrlWithoutFilters();

        if (url !== `/search${window.location.search}`) {
          localStorage.removeItem(LOCAL_STORAGE_FILTERS_ITEM);
          dispatch(push(url));
        }
      }
    });
  }, [reset]);

  useEffect(() => {
    const fn = handleSubmit((data) => {
      const dataCopy = filtersToQuery(data);
      localStorage.setItem(
        LOCAL_STORAGE_FILTERS_ITEM,
        JSON.stringify(dataCopy)
      );
      updateFilters(data);
    });

    const debouncedFn = debounce(fn, 400);

    const t = watch((_, { name }) => {
      if (name === 'name') {
        void debouncedFn();
      } else {
        void fn();
      }
    });

    return () => t.unsubscribe();
  }, [watch]);

  return <FormProvider {...methods}>{children}</FormProvider>;
};

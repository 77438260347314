import _get from 'lodash/get';
import { createMiddleware } from 'redux-beacon';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import format from 'date-fns/format';
import { parse } from 'querystring';
import md5 from 'md5';

import { TrackingActionTypes } from 'src/constants';
import { ReduxState } from 'src/store/reducers';
import {
  getTransactionTotalPrice,
  getIncludedTaxes,
} from 'src/modules/helpers';

const gtm = GoogleTagManager();

const getReferrer = () => {
  try {
    if (!document.referrer) {
      localStorage.setItem('GA_referrer', '');
      return '';
    }
    if (document.referrer.includes('https://entravel.com')) {
      return localStorage.getItem('GA_referrer');
    }
    localStorage.setItem('GA_referrer', document.referrer);
    return document.referrer;
  } catch {
    return document.referrer || '';
  }
};

const TRACKING_ORIGINAL_LOCATION =
  window.decodeURIComponent(
    (parse(window.location.href).originalLocation || '') as string
  ) || window.location.href;

const pageview = (
  action: any,
  prevState: ReduxState,
  nextState: ReduxState
) => {
  const prevLocation = _get(prevState, 'router.location', {});
  const nextLocation = _get(nextState, 'router.location', {});
  const isPathnameChanged =
    nextLocation.pathname !== prevLocation.pathname ||
    // first page load
    nextLocation.key === prevLocation.key;

  const userEmail = _get(nextState, 'user.info.email');

  return isPathnameChanged
    ? {
        event: 'entravel_event',
        eventName: 'pageview',
        userId: userEmail ? md5(userEmail) : '',
        page: `${action.payload.location.pathname}${action.payload.location.search}`,
        title: document.title,
        referrer: getReferrer(),
        originalLocation: TRACKING_ORIGINAL_LOCATION,
      }
    : {};
};

const searchHotelsEvent = (action: any, prevState: ReduxState) => {
  const payload = action.payload;
  const userEmail = _get(prevState, 'user.info.email');

  const {
    checkIn,
    checkOut,
    city,
    country,
    countryCode,
    adultsCount,
    childrenCount
  } = payload;
  
  return {
    event: 'entravel_event',
    eventName: 'search',
    userId: userEmail ? md5(userEmail) : '',
    checkin_date: format(checkIn, 'yyyy-MM-dd'),
    checkout_date: format(checkOut, 'yyyy-MM-dd'),
    city,
    country,
    country_code: countryCode,
    num_adults: adultsCount,
    num_children: childrenCount,
  }
};

const hotelDetailsEvent = ({
  payload: {
    checkIn,
    checkOut,
    city,
    country,
    countryCode,
    adultsCount,
    childrenCount,
    hotelId,
  },
}: any, prevState: ReduxState) => {
  const userEmail = _get(prevState, 'user.info.email');

  return {
    event: 'entravel_event',
    eventName: 'detail',
    userId: userEmail ? md5(userEmail) : '',
    checkin_date: format(checkIn, 'yyyy-MM-dd'),
    checkout_date: format(checkOut, 'yyyy-MM-dd'),
    city,
    country,
    country_code: countryCode,
    num_adults: adultsCount,
    num_children: childrenCount,
    hotelId,
  }
};

const checkoutEvent = ({
  payload: {
    checkIn,
    checkOut,
    city,
    country,
    countryCode,
    adultsCount,
    childrenCount,
    hotelId,
  },
}: any, prevState: ReduxState) => {
  const userEmail = _get(prevState, 'user.info.email');

  return {
    event: 'entravel_event',
    eventName: 'checkout',
    userId: userEmail ? md5(userEmail) : '',
    checkin_date: format(checkIn, 'yyyy-MM-dd'),
    checkout_date: format(checkOut, 'yyyy-MM-dd'),
    city,
    country,
    country_code: countryCode,
    num_adults: adultsCount,
    num_children: childrenCount,
    hotelId,
  }
};

const bingConversionEvent = ({
  payload: {
    checkIn,
    checkOut,
    currency,
    transactionId,
    hotelId,
    taxAndFees,
    creditCardFee,
    salePrice,
  },
}: any, prevState: ReduxState) => {
  const includedTaxes = getIncludedTaxes(taxAndFees);
  const basePrice = Math.round((salePrice - includedTaxes) * 100) / 100;
  const userEmail = _get(prevState, 'user.info.email');

  return {
    event: 'entravel_event',
    eventName: 'bingConversion',
    userId: userEmail ? md5(userEmail) : '',
    hct_total_price: getTransactionTotalPrice(salePrice, creditCardFee),
    hct_base_price: basePrice,
    hct_checkin_date: format(checkIn, 'yyyy-MM-dd'),
    hct_checkout_date: format(checkOut, 'yyyy-MM-dd'),
    hct_partner_hotel_id: hotelId,
    hct_booking_xref: transactionId,
    currency,
  };
};

const bookEvent = ({
  payload: {
    checkIn,
    checkOut,
    city,
    country,
    countryCode,
    adultsCount,
    childrenCount,
    price,
    currency,
    transactionId,
    hotelId,
  },
}: any, prevState: ReduxState) => {
  const userEmail = _get(prevState, 'user.info.email');

  return {
    event: 'entravel_event',
    eventName: 'transaction',
    userId: userEmail ? md5(userEmail) : '',
    checkin_date: format(checkIn, 'yyyy-MM-dd'),
    checkout_date: format(checkOut, 'yyyy-MM-dd'),
    city,
    country,
    country_code: countryCode,
    currency,
    num_adults: adultsCount,
    num_children: childrenCount,
    value: price,
    transactionId,
    hotelId,
  }
};

const loginEvent = (action: any, prevState: ReduxState) => {
  const userEmail = _get(prevState, 'user.info.email');

  return {
    event: 'entravel_event',
    eventName: 'login',
    userId: userEmail ? md5(userEmail) : '',
  };
}

const registrationEvent = (action: any, prevState: ReduxState) => {
  const userEmail = _get(prevState, 'user.info.email');

  return {
    event: 'entravel_event',
    eventName: 'registration',
    userId: userEmail ? md5(userEmail) : '',
  };
}

const clickHotelEvent = ({ payload: { price } }: any, prevState: ReduxState) => {
  const userEmail = _get(prevState, 'user.info.email');

  return {
    event: 'entravel_event',
    eventName: 'chooseHotel',
    userId: userEmail ? md5(userEmail) : '',
    value: price,
  }
}

const clickRoomEvent = ({ payload: { price } }: any, prevState: ReduxState) => {
  const userEmail = _get(prevState, 'user.info.email');

  return {
    event: 'entravel_event',
    eventName: 'chooseRoom',
    userId: userEmail ? md5(userEmail) : '',
    value: price,
  }
}

const confirmBookingEvent = (action: any, prevState: ReduxState) => {
  const price = _get(prevState, [
    'checkout',
    'info',
    'internalPackage',
    'salePrice',
  ]) as number;
  const creditCardFee = _get(prevState, [
    'checkout',
    'info',
    'internalPackage',
    'creditCardFee',
  ]) as number;
  const hotelId = _get(prevState, 'checkout.info.hotelId', '');
  const userEmail = _get(prevState, 'user.info.email');

  console.log('action', action)

  return {
    event: 'entravel_event',
    eventName: 'confirmBooking',
    userId: userEmail ? md5(userEmail) : '',
    value: price + creditCardFee,
    hotelId,
  };
};

const checkoutPopupEvent = ({ eventName }: any, prevState: ReduxState) => {
  const userEmail = _get(prevState, 'user.info.email');

  return {
    event: 'entravel_event',
    userId: userEmail ? md5(userEmail) : '',
    eventName,
  }
}

const eventsMap = {
  '@@router/LOCATION_CHANGE': pageview,
  [TrackingActionTypes.TRACK_SEARCH_HOTELS]: searchHotelsEvent,
  [TrackingActionTypes.TRACK_HOTEL_DETAILS]: hotelDetailsEvent,
  [TrackingActionTypes.TRACK_CHECKOUT]: checkoutEvent,
  [TrackingActionTypes.TRACK_BOOK]: bookEvent,
  [TrackingActionTypes.TRACK_BING_CONVERSION]: bingConversionEvent,
  [TrackingActionTypes.TRACK_LOGIN]: loginEvent,
  [TrackingActionTypes.TRACK_REGISTRATION]: registrationEvent,
  [TrackingActionTypes.TRACK_HOTEL_CLICK]: clickHotelEvent,
  [TrackingActionTypes.TRACK_ROOM_CLICK]: clickRoomEvent,
  [TrackingActionTypes.TRACK_CONFIRM_BOOKING]: confirmBookingEvent,
  [TrackingActionTypes.TRACK_CHECKOUT_POPUP]: checkoutPopupEvent,
};

export const gtmMiddleware = createMiddleware(eventsMap, gtm);

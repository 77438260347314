import { TextFieldProps } from '@mui/material/TextField';
import { FocusEventHandler, VFC } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import { FiltersForm } from '..';
import { StyledTextField } from '../styled';

type Props = {
  field:
    | ControllerRenderProps<FiltersForm, 'priceFrom'>
    | ControllerRenderProps<FiltersForm, 'priceTo'>;
  value: number;
  innerValue: number | '';
  onChange: (value: number) => boolean;
  onInnerValueChange: (value: number | '') => void;
} & Omit<TextFieldProps, 'onChange' | 'value'>;

export const cursorToEnd: FocusEventHandler<HTMLInputElement> = (e) => {
  const el = e.target;

  const l = el.value.length;

  el.type = 'text';

  el.setSelectionRange(l, l);

  el.type = 'number';
};

const PriceInput: VFC<Props> = ({
  disabled,
  field,
  value,
  innerValue,
  onChange,
  onInnerValueChange,
  ...props
}) => (
  <StyledTextField
    variant="outlined"
    label={false}
    {...field}
    {...props}
    value={innerValue ?? ''}
    color="secondary"
    disabled={disabled}
    fullWidth
    type="number"
    inputProps={{ pattern: '\\d*', inputMode: 'numeric' }}
    onChange={(e) => {
      const value = e.target.value;

      onInnerValueChange(value !== '' ? +value : value);
    }}
    onBlur={() => {
      if (innerValue === '') {
        onInnerValueChange(value);
      } else {
        if (innerValue !== value && !onChange(innerValue)) {
          onInnerValueChange(value);
        }
      }

      field.onBlur();
    }}
    onFocus={cursorToEnd}
  />
);

export default PriceInput;

import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from 'axios';
import { Observable, Subscriber, Observer } from 'rxjs';
import Cookies from 'js-cookie';
import _defaultsDeep from 'lodash/defaultsDeep';
import { stringify } from 'query-string';

import { getGlobalLocale } from 'src/modules/setGlobalLocale';

import {
  ACCESS_TOKEN_COOKIE_NAME,
  ACCESS_TOKEN_HEADER_NAME,
  BASE_URL,
} from '../constants';

import { stringifyServerOptions } from './queryStringOptions';
import { EndpointErrorInfo, logEndpointError } from './logError';

const API = axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
  paramsSerializer: (params) => stringify(params, stringifyServerOptions),
});

export const apiCall = async <T = unknown>(
  url: string,
  config: AxiosRequestConfig = {},
  errorInfo?: EndpointErrorInfo
) => {
  const defaultHeaders: DefaultHeaders = {
    'Accept-Language': getGlobalLocale(),
  };

  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

  if (accessToken) {
    defaultHeaders[ACCESS_TOKEN_HEADER_NAME] = accessToken;
  }

  try {
    const res = await API.request<T>({
      url,
      headers: defaultHeaders,
      ...config,
    });

    return res.data;
  } catch (err: any) {
    if (errorInfo) {
      void logEndpointError(errorInfo, err);
    }

    throw (err as AxiosError).response;
  }
};

class AxiosSubscriber<T> extends Subscriber<any> {
  private source: CancelTokenSource;
  private aborted: boolean;

  constructor(observer: Observer<T>, url: string, config: AxiosRequestConfig) {
    super(observer);

    this.source = axios.CancelToken.source();

    // XHR abort pointer
    this.aborted = false;

    // make axios request on subscription
    API.request({
      url,
      cancelToken: this.source.token,
      ...config,
    })
      .then((response: AxiosResponse<T>) => {
        observer.next(response.data);
        observer.complete();
      })
      .catch((error: any) => {
        observer.error(error);
      });
  }

  unsubscribe() {
    super.unsubscribe();

    // cancel XHR
    if (this.aborted === false) {
      this.source.cancel();
      this.aborted = true;
    }
  }
}

type DefaultHeaders = {
  [ACCESS_TOKEN_HEADER_NAME]?: string;
  'Accept-Language': string;
};

export const observableApi$ = <T>(
  url: string,
  config: AxiosRequestConfig = {}
) =>
  new Observable<T>((observer) => {
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const defaultHeaders: DefaultHeaders = {
      'Accept-Language': getGlobalLocale(),
    };

    if (accessToken) {
      defaultHeaders[ACCESS_TOKEN_HEADER_NAME] = accessToken;
    }

    const mergedConfig = _defaultsDeep({ headers: defaultHeaders }, config);
    return new AxiosSubscriber<T>(observer, url, mergedConfig);
  });

import Skeleton from '@mui/material/Skeleton';
import { VFC } from 'react';

import Filter from '.';

type Props = { count: number };

const SkeletonFilters: VFC<Props> = ({ count }) => (
  <>
    {Array.from({ length: count }, (_, index) => (
      <Filter key={index} label={<Skeleton sx={{ bgcolor: (theme) => theme.custom.skeletonColor }} />} disabled />
    ))}
  </>
);

export default SkeletonFilters;

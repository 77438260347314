import { useFormContext } from 'react-hook-form';

import areSetsEqual from 'src/modules/areSetsEqual';
import handleSetValue from 'src/modules/handleSetValue';

const useAddToSet = (name: string) => {
  const methods = useFormContext();

  const set: Set<unknown> = methods.watch(name);

  return {
    handleChange: (willBeChecked: boolean, value: unknown) => {
      const newSet = new Set(set);

      if (willBeChecked) {
        newSet.add(value);
      } else {
        newSet.delete(value);
      }

      handleSetValue(methods, name, newSet, areSetsEqual);
    },
    set,
  };
};

export default useAddToSet;

import { useRef, useState, forwardRef, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import styled from 'styled-components/macro';
import format from 'date-fns/format';
import Divider from '@mui/material/Divider';

import { I18n } from 'src/components/I18n';
import useToggler from 'src/hooks/useToggler';
import { useIntlFormatMessage } from 'src/hooks/useIntlFormatMessage';
import {
  Calendar,
  DateProps,
  ECalendarType,
  EDateSelection,
} from 'src/components/Calendar';
import { useCalendarLocale } from 'src/components/Calendar/CalendarProvider';
import CalendarIcon from 'src/assets/icons/calendar';

import { SearchPanelPopover } from '../../SearchPanelPopover';
import { FieldsName, SearchPanelInputProps } from '../..';
import { SearchPanelInput } from '../../SearchPanelInput';
import { AppliedDrawer } from '../../AppliedDrawer';

import { MobileDatesToggler } from './MobileDatesToggler';
import { NightsCount } from './NightsCount';

type Props = { onBlur: () => void; inputFormat: string; isContrast?: boolean } & DateProps &
  SearchPanelInputProps;

const DateDivider = styled.div`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgb(228, 228, 231);
`;

const StyledPopper = styled(SearchPanelPopover)`
    --padding: ${({ theme }) => theme.spacing(10)};
    padding-bottom: 0;
    z-index: 1000000;
`;

const StyledLabel = styled.div`
  color: ${({ theme }) => theme.custom.contrastInputLabelColor};
`

const StyledContrastLabel = styled.div`
  color: #767E83;
`

const DateRangeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const DateRangeInput = forwardRef<HTMLDivElement, Props>(
  (
    {
      value,
      onChange,
      isMobile,
      onBlur,
      inputFormat,
      handleIsOpen,
      openDefault,
      isContrast,
    },
    outerRef
  ) => {
    const locale = useCalendarLocale();
    const [start, end] = value;

    const [dateSelection, setDateSelection] = useState(EDateSelection.START);

    const { toggler, handleClose, handleOpen } = useToggler(
      handleIsOpen && {
        onOpen: () => handleIsOpen(true),
        onClose: () => handleIsOpen(false),
      }
    );

    const formatMessage = useIntlFormatMessage();

    const anchorRef = useRef<HTMLDivElement>(null);

    const startRef = useRef<HTMLDivElement>(null);

    const endRef = useRef<HTMLDivElement>(null);

    const startInputLabelRef = useRef<HTMLLabelElement>(null);

    const endInputLabelRef = useRef<HTMLLabelElement>(null);

    const popoverRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (openDefault && !isMobile && !toggler) handleOpen();
    }, []);

    const drawerRef = useRef<HTMLDivElement>(null);

    const calendar = (
      <Calendar
        type={isMobile ? ECalendarType.VERTICAL : ECalendarType.HORIZONTAL}
        value={value}
        onChange={onChange}
        dateSelection={dateSelection}
        parentRef={drawerRef}
        setDateSelection={setDateSelection}
      />
    );

    const handleClick = (selection: EDateSelection) => () => {
      if (toggler && dateSelection === selection) {
        handleClose();
      } else {
        setDateSelection(selection);

        if (!toggler) handleOpen();
      }
    };

    return (
      <DateRangeContainer ref={anchorRef}>
        {/* {isContrast ? <CalendarIcon /> : null} */}
        <Stack
          ref={outerRef}
          onBlur={onBlur}
          direction="row"
          spacing={{ xs: 3, sm: 8, lg: 12 }}
          justifyContent={'space-between'}
          alignItems="center"
          width={'100%'}
        >
          <SearchPanelInput
            inputProps={{
              size: inputFormat.length+2,
            }}
            ref={startRef}
            onClick={handleClick(EDateSelection.START)}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ ref: startInputLabelRef }}
            value={
              start && locale ? format(start, inputFormat, { locale }) : ''
            }
            placeholder={formatMessage('CALENDAR.START_DATE')}
            focused={toggler && dateSelection === EDateSelection.START}
            isContrast={isContrast}
            label={
              isContrast ?
              <StyledLabel>
                <I18n id="CHECKOUT_PAGE.HOTEL_INFO_BLOCK.CHECKIN" />
              </StyledLabel>
              : <I18n id="CHECKOUT_PAGE.HOTEL_INFO_BLOCK.CHECKIN" />
            }
          />
          <DateDivider />
          <SearchPanelInput
            alignRight
            inputProps={{
              size: inputFormat.length+2,
            }}
            ref={endRef}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ ref: endInputLabelRef }}
            onClick={handleClick(EDateSelection.END)}
            value={end && locale ? format(end, inputFormat, { locale }) : ''}
            placeholder={formatMessage('CALENDAR.END_DATE')}
            focused={toggler && dateSelection === EDateSelection.END}
            isContrast={isContrast}
            label={
              isContrast ?
                <StyledLabel>
                  <I18n id="CHECKOUT_PAGE.HOTEL_INFO_BLOCK.CHECKOUT" />
                </StyledLabel>
                : <I18n id="CHECKOUT_PAGE.HOTEL_INFO_BLOCK.CHECKOUT" />
            }
          />
        </Stack>
        {isMobile ? (
          <AppliedDrawer
            fieldName={FieldsName.DATES}
            open={toggler}
            onClose={handleClose}
            header={<I18n id="CAIRBNB_CALENDAR.SELECT_DATES" />}
            buttonText={<I18n id="GENERAL_APPLY" />}
            ref={drawerRef}
          >
            <MobileDatesToggler
              dateSelection={dateSelection}
              setDateSelection={setDateSelection}
              dates={value}
            />
            {calendar}
          </AppliedDrawer>
        ) : (
          <StyledPopper
            open={toggler}
            anchorEl={anchorRef.current}
            arrowTargetRef={
              dateSelection === EDateSelection.START
                ? startInputLabelRef
                : endInputLabelRef
            }
            onClickOutside={(e) => {
              if (
                toggler &&
                ![startRef, endRef].some(({ current }) =>
                  current?.contains(e.target as Node)
                )
              ) {
                handleClose();
              }
            }}
            ref={popoverRef}
          >
            <Stack spacing={2} marginBottom={2}>
              {calendar}
              <Divider />
              <NightsCount dates={value} />
            </Stack>
          </StyledPopper>
        )}
      </DateRangeContainer>
    );
  }
);

export const GridIcon = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_12705_20317)">
      <path
        d="M6.61315 2.33789H3.03244C2.37325 2.33789 1.83887 2.87228 1.83887 3.53146V7.11218C1.83887 7.77136 2.37325 8.30575 3.03244 8.30575H6.61315C7.27234 8.30575 7.80672 7.77136 7.80672 7.11218V3.53146C7.80672 2.87228 7.27234 2.33789 6.61315 2.33789Z"
        stroke="currentColor"
        stroke-width="1.29"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.9686 2.33789H11.3879C10.7287 2.33789 10.1943 2.87228 10.1943 3.53146V7.11218C10.1943 7.77136 10.7287 8.30575 11.3879 8.30575H14.9686C15.6278 8.30575 16.1622 7.77136 16.1622 7.11218V3.53146C16.1622 2.87228 15.6278 2.33789 14.9686 2.33789Z"
        stroke="currentColor"
        stroke-width="1.29"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.61315 10.6934H3.03244C2.37325 10.6934 1.83887 11.2277 1.83887 11.8869V15.4676C1.83887 16.1269 2.37325 16.6612 3.03244 16.6612H6.61315C7.27234 16.6612 7.80672 16.1269 7.80672 15.4676V11.8869C7.80672 11.2277 7.27234 10.6934 6.61315 10.6934Z"
        stroke="currentColor"
        stroke-width="1.29"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.9686 10.6934H11.3879C10.7287 10.6934 10.1943 11.2277 10.1943 11.8869V15.4676C10.1943 16.1269 10.7287 16.6612 11.3879 16.6612H14.9686C15.6278 16.6612 16.1622 16.1269 16.1622 15.4676V11.8869C16.1622 11.2277 15.6278 10.6934 14.9686 10.6934Z"
        stroke="currentColor"
        stroke-width="1.29"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_12705_20317">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

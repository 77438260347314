import { memo, ReactNode, SyntheticEvent } from 'react';

import { AlignIcon } from 'src/components/pages/HotelDetails/Rooms/GridRoom/styles';
import { RoundBackIcon } from 'src/assets/icons/back-rounded';

import {
  BackButton,
  Container,
  HeaderText,
  Inner,
  NumImg,
  NumsContainer,
  StyledSpan,
} from './styles';

interface ServerErrorPageProps {
  header: ReactNode;
  text: ReactNode;
  buttonText: ReactNode;
  onBtnClick: (e: SyntheticEvent) => void;
}

export const ServerErrorPage = memo(
  ({ header, text, buttonText, onBtnClick }: ServerErrorPageProps) => (
    <Container>
      <Inner>
        <HeaderText>{header}</HeaderText>
        <StyledSpan>{text}</StyledSpan>
        <NumsContainer>
          <NumImg src={'num5.png'} />
          <NumImg src={'num0-filled.png'} />
          <NumImg src={'num0.png'} />
        </NumsContainer>
        <BackButton onClick={onBtnClick}>
          <AlignIcon gap={16}>
            <RoundBackIcon />
            {buttonText}
          </AlignIcon>
        </BackButton>
      </Inner>
    </Container>
  )
);

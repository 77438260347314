import { ReactNode } from 'react';

import {
  HorizontalSelectContainer,
  HorizontalSelectElementWrapper,
  HorizontalSelectInner,
  HorizontalSelectToggler,
} from './styles';

type CHorizontalSelectProps = {
  left: ReactNode;
  leftValue: any;
  right: ReactNode;
  rightValue: any;
  selected: any;
  onSelect: (value: any) => void;
};

export const CHorizontalSelect = ({
  left,
  leftValue,
  right,
  rightValue,
  onSelect,
  selected,
}: CHorizontalSelectProps) => (
  <HorizontalSelectContainer>
    <HorizontalSelectInner>
      <HorizontalSelectToggler selectedRight={selected === rightValue} />
      <HorizontalSelectElementWrapper onClick={() => onSelect(leftValue)} selected={selected === rightValue}>
        {left}
      </HorizontalSelectElementWrapper>
      <HorizontalSelectElementWrapper onClick={() => onSelect(rightValue)} selected={selected === leftValue}>
        {right}
      </HorizontalSelectElementWrapper>
    </HorizontalSelectInner>
  </HorizontalSelectContainer>
);

import {
  forwardRef,
  MutableRefObject,
  ReactNode,
  RefObject,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Container, Inner } from './styles';

export interface SliderProps {
  onChange?: (idx: number) => void;
  elements: { key: any; node: ReactNode }[];
}

export interface SliderRef {
  left: Function;
  right: Function;
}

type CalcOffsetFunc = (elRect: DOMRect, innerRect: DOMRect) => number;

export const Slider = forwardRef<SliderRef, SliderProps>(
  ({ elements, onChange }, ref) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const elRefs = useRef<(HTMLDivElement | null)[]>([]);

    const [offset, setOffset] = useState(0);

    useImperativeHandle(ref, () => ({
      left: () => {
        const res = changeOffset(
          elRefs.current.reverse(),
          (elRect: DOMRect, innerRect: DOMRect) => {
            const offset = elRect.x - innerRect.x;
            return offset < 0 ? offset : 0;
          }
        );

        if (res && res.idx !== null) {
          setOffset((prevState) => prevState + res.offset);
          onChange?.(elements.length - res.idx - 1);
        }
      },
      right: () => {
        const res = changeOffset(
          elRefs.current,
          (elRect: DOMRect, innerRect: DOMRect) => {
            const offset =
              elRect.x + elRect.width - innerRect.x - innerRect.width;
            return offset > 0 ? offset : 0;
          }
        );

        if (res && res.idx !== null) {
          setOffset((prevState) => prevState + res.offset);
          onChange?.(res.idx);
        }
      },
    }));

    const changeOffset = (
      elements: (HTMLDivElement | null)[],
      calcFunc: CalcOffsetFunc
    ) => {
      if (!containerRef.current) return;
      const innerRect = containerRef.current.getBoundingClientRect();

      let addOffset = 0;
      let elIdx = null;
      for (let i = 0; i < elements.length; i++) {
        const el = elements[i];
        if (!el) return;

        addOffset = calcFunc(el.getBoundingClientRect(), innerRect);

        if (addOffset !== 0) {
          elIdx = i;
          break;
        }
      }

      return {
        offset: addOffset,
        idx: elIdx,
      };
    };

    return (
      <Container ref={containerRef}>
        <Inner offset={offset} gap={10}>
          {elements.map((el, idx) => (
            <div ref={(el) => (elRefs.current[idx] = el)} key={el.key}>
              {el.node}
            </div>
          ))}
        </Inner>
      </Container>
    );
  }
);

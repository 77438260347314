import { apiCall } from "src/modules/api";

export interface JoinNewsletterResponse {
  isSuccessful: boolean;
  errorCode: number;
  error: string;
}

export interface JoinNewsletterBody {
  email: string;
}

export const joinNewsletter = async ({ email }: JoinNewsletterBody) => {
  const response = await apiCall<JoinNewsletterResponse>(
    '/customers/join-newsletter', { 
      method: 'POST',
      data: { email },
    }
  );

  return response;
};

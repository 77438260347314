import Skeleton from '@mui/material/Skeleton';
import { ReactNode, useState } from 'react';
import styled from 'styled-components/macro';

import { ImagesCountSmall } from 'src/components/HotelPreview/ImagesCountSmall';
import { HotelPreviewSize } from 'src/components/HotelPreview/type';
import { I18n } from 'src/components/I18n';
import CPanel, {
  Container,
  HeaderTitle,
  HeaderWrapper,
} from 'src/components/shared/CPanel';
import { CheckoutStatuses } from 'src/models/checkout';
import { RoomFacility } from 'src/models/config';
import {
  CtDomainMarketingPricePresentation,
  RoomGroup,
} from 'src/models/hotels';
import { media } from 'src/modules/mediaQuery';
import { Image } from 'src/types';

import { CardImageSlider, CardImageSliderCounter } from '../GridRoom/styles';

import { NoImagePlaceholder, StyledHotelPreview } from './CommonComponents';
import { RoomPackages as Packages } from './Packages';
import { CardImg, ListRoomImageSlider, NoImageWrapper } from './styles';

const RoomPanel = styled(CPanel)<{ highlighted?: boolean }>`
  margin-bottom: 25px;
  border: 1px solid ${(p) => p.theme.custom.greyAlternativeColor};
  border-radius: 12px;
  overflow: hidden;
  color: ${(p) => p.theme.custom.roomAvailabilityTitle};

  ${(p) => media.phone`
    background: ${p.theme.custom.roomListHeaderBgColor};
  `}

  ${HeaderWrapper} {
    align-items: center;
    padding: 15px 25px;
    border-bottom: 1px solid ${(p) => p.theme.custom.greyAlternativeColor};
    background-color: ${(p) => p.theme.custom.roomListHeaderBgColor};

    ${media.phone`
      padding: 15px;
    `}
  }

  ${HeaderTitle} {
    padding: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${(p) => p.theme.custom.roomAvailabilityTitle};

    ${media.phone`
      font-size: 14px;
      line-height: 16px;
    `}
  }

  ${Container} {
    display: flex;
    padding: 20px;
    background: ${(p) => p.theme.custom.roomListItemBgColor};

    ${media.phone`
      flex-wrap: wrap;
      padding: 0;
    `}
  }

  ${({ highlighted, theme }) =>
    highlighted &&
    `
      border: 1px solid ${theme.custom.roomListHeaderBgActionColor};

      ${HeaderWrapper} {
        background: ${theme.custom.roomListHeaderBgActionColor};
      }

      ${HeaderTitle} {
        color: ${theme.custom.whiteMainColor};
      }
    `};
`;

const HotelPreviewWrapper = styled.div`
  margin-right: 25px;
  width: 20%;

  height: fit-content;
  border-radius: 5px;
  overflow: hidden;

  ${media.phone`
    margin-right: 0;
    padding: 15px;
    width: 100%;
  `}
`;

const CheapestRoom = styled.span`
  margin-right: 5px;
`;

export const StyledSkeleton = styled(Skeleton)`
  @media (min-width: 768px) {
    padding-bottom: 100%;
  }
  @media (max-width: 767px) {
    padding-bottom: 60%;
  }
`;

interface ListRoomProps {
  cheapestRoom?: boolean;
  name: string;
  images: Image[];
  showImages: boolean;
  bookBtnsLoading: boolean;
  pricePresentation: CtDomainMarketingPricePresentation;
  packages?: RoomGroup[];
  facilities: RoomFacility[];
  totalNights?: number;
  marketingRoom?: boolean;
  extras?: ReactNode;
  status?: CheckoutStatuses;
  priceChangePercent?: null | number;
}

export const ListRoom = ({
  packages = [],
  name,
  cheapestRoom,
  images,
  showImages,
  facilities,
  bookBtnsLoading,
  pricePresentation,
  totalNights,
  marketingRoom,
  extras,
  status,
  priceChangePercent,
}: ListRoomProps) => {
  const [currentImage, setCurrentImage] = useState(0);

  const title = cheapestRoom ? (
    <span>
      <CheapestRoom>
        <I18n id="HOTEL_DETAILS_PAGE.ROOMS.CHEAPEST_ROOM" />
      </CheapestRoom>
      {name}
    </span>
  ) : (
    name
  );

  const id = cheapestRoom ? 'cheapest-room' : '';

  return (
    <RoomPanel
      withBorder
      highlighted={cheapestRoom || marketingRoom}
      title={title}
      id={id}
      extras={extras}
    >
      {showImages && !!images.length ? (
        <ListRoomImageSlider
          onChange={setCurrentImage}
          arrowPadding={12}
          transparentArrows={true}
          elements={images.map((i) => ({
            key: i.id,
            node: <CardImg src={i.url} />,
          }))}
        >
          <CardImageSliderCounter>
            {currentImage + 1}/{images.length}
          </CardImageSliderCounter>
        </ListRoomImageSlider>
      ) : (
        <NoImageWrapper>
          <NoImagePlaceholder />
        </NoImageWrapper>
      )}

      <Packages
        bookBtnsLoading={bookBtnsLoading}
        pricePresentation={pricePresentation}
        packages={packages}
        facilities={facilities}
        images={images}
        totalNights={totalNights}
        status={status}
        priceChangePercent={priceChangePercent}
      />
    </RoomPanel>
  );
};

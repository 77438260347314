export const defaultTheme = {
  mainBorderRadius: '64px',
  savingsBorderRadius: '64px',
  searchPanelRadius: '86px',
  searchPanelMobileRadius: '24px',
  secondaryBorderRadius: '20px',
  paymentInputBorder: '3px',
  inputBorderRadius: '3px',
  paymentBorderRadius: '3px',
  checkoutSummaryBorderRadius: '3px',
  checkoutSummaryBorderColor: '#D0D0DB',
  checkoutBackgroundColor: '#fff',

  mainBoxShadow: 'box-shadow: 1px 2px 20px 0px rgba(0, 0, 0, 0.08)',

  bgGreyColor: 'rgba(213, 213, 213, 1)',
  greyMainColor: '#757588',
  greySecondaryColor: '#EEEFF2',
  greyAlternativeColor: '#D0D0DB',
  greyLightColor: '#EBECF4',
  greyUltraLightColor: '#FAFAFA',
  whiteMainColor: '#FFF',
  mainColor: 'rgb(17, 23, 30)',
  greenSecondaryColor: '#12A84E',
  blackMainColor: '#23232C',
  blueMainColor: '#2539EB',
  blackSecondaryColor: '#3C3C48',
  orangeMainColor: '#FC5110',
  orangeSecondaryColor: '#DD3E02',
  orangeTr: '#FEDCCF',
  orangeAlternativeColor: '#F46311',
  redMainColor: '#FF1B1E',
  redLightColor: '#FFE8E9',
  darkMainColor: '#171723',
  headerBgColor: 'rgba(255, 255, 255, 0.05)',
  headerBgContrastColor: '#121212',
  headerButtonColor: 'rgb(0, 0, 0)',
  headerButtonContrastColor: '#fff',
  headerBottomBorderColor: 'rgba(255, 255, 255, 0.2)',
  mainButtonColor: '#19191A',
  actionColor: '#00874E',
  secondaryActionColor: 'rgba(32, 32, 32, 1)',
  showMoreTextColor: 'rgba(32, 32, 32, 1)',
  textActionColor: '#00874E',
  muiSecondaryActionColor: 'rgb(0, 108, 228)',
  activeBorderColor: '#000000',
  paymentCardBgColor: '#F1FAF6',
  secondaryButtonColor: '#F2F2F2',
  secondaryButtonTextColor: 'rgb(0, 0, 0)',
  footerBgColor: 'rgba(13, 13, 13, 1)',
  footerTextColor: 'rgba(246, 246, 246, 1)',
  footerTopBorderColor: 'rgba(128, 128, 128, 1)',
  footerBorderColor: 'rgba(246, 246, 246, 0.2)',
  footerIconBorder: 'transparent',
  nonRefundableColor: '#006ce4',
  lightBlueColor: '#006ce4',
  inputBackgroundColor: 'transparent',
  mainPageBgGradient: 'transparent',
  mainPageContrastColor: 'rgba(32, 32, 32, 1)',
  muiPrimaryColor: '#11171E',

  authTitleColor: 'rgb(0, 0, 0)',

  contrastInputColor: 'rgb(0, 0, 0)',
  inputColor: 'rgb(0, 0, 0)',
  contrastInputLabelColor: 'rgb(118, 126, 131)',
  contrastButtonColor: 'rgba(255, 255, 255, 1)',
  reviewCountTextColor: '#323232',
  reviewTitleTextColor: '#00874E',
  priceGuaranteeBorderRadius: '30px',
  priceGuaranteeTextColor: '#00874E',
  priceGuaranteeBgColor: '#DEE1E2',

  priceGuaranteeRoomBorderRadius: '4px',
  priceGuaranteeRoomTextColor: 'rgb(0, 135, 78)',
  priceGuaranteeRoomBgColor: 'transparent',
  priceGuaranteeRoomBorder: '1px solid rgb(208, 208, 219)',

  ratingNumberTextColor: '#FFF',

  discountBorder: 'none',
  discountBorderRadius: '16px',
  inputAdornmentColor: 'rgba(0, 0, 0, 0.54)',
  skeletonColor: 'rgba(0, 0, 0, 0.11)',

  customFontFamily: '"Roboto", sans-serif',
  mainPageTitleFontFamily: '"Roboto", sans-serif',
  mainPageTitleWeight: '700',
  mainPageSignInButtonColor: 'rgba(255, 255, 255, 1)',
  signInPageButtonTextColor: 'rgba(255, 255, 255, 1)',
  searchButtonColor: 'rgb(17, 23, 30)',
  searchButtonTextColor: 'rgba(245, 246, 245, 1)',
  mainPageBorderColor: 'transparent',

  searchResultTitleColor: '#525252',
  searchResultCounterColor: '#000',
  filterTagBackgroundColor: 'rgba(0, 0, 0, 0.08)',
  hotelCardTitleColor: 'rgb(0, 0, 0)',
  hotelCardIconColor: 'rgb(0, 0, 0)',

  hotelsSectionTitleColor: '#000',
  hotelSectionBgColor: 'transparent',
  hotelSectionBorderColor: 'transparent',
  hotelSectionBorderBottomColor: 'inherit',
  hotelSectionBorderRadius: '0',
  hotelSectionMobileBorderRadius: '0',
  hotelSectionItemBorderColor: 'transparent',
  hotelSectionSubtitleColor: 'rgb(0, 0, 0)',
  hotelSectionItemColor: '#fff',

  hotelCardBgColor: 'rgba(255, 255, 255, 1)',
  hotelCardBoxShadow: 'transparent',
  hotelCardRightRadius: '12px',

  roomContainerPadding: '24px',
  roomContainerBackground: '#fff',
  roomsSearchButtonTextColor: 'rgba(255,255,255,0.87)',
  roomStickyHeaderTextColor: '#23232C',
  roomViewDetailsButtonColor: 'rgba(255, 255, 255, 1)',
  roomsStickyHeaderBgColor: '#FFF',
  roomAvailabilityTitle: '#23232C',
  roomLayoutSelectButtonActiveColor: 'rgba(32, 32, 32, 1)',
  roomLayoutSelectButtonColor: 'rgba(32, 32, 32, 1)',
  roomLayoutSelectBorder: 'none',
  roomGridItemBgColor: '#FFF',
  roomCardCheckboxActiveColor: '#000000',
  roomCardCheckboxColor: '#FFF',
  roomListHeaderBgColor: '#fff',
  roomListItemBgColor: '#fff',
  roomListHeaderBgActionColor: '#00874E',
  roomInfoIconColor: 'rgb(17, 23, 30)',

  aboutHotelBgColor: 'transparent',
  aboutHotelBorderRadius: '0',
  aboutHotelBorder: 'transparent',
  aboutHotelPadding: '0',

  accountNavListActiveColor: 'rgb(0, 108, 228)',

  experienceSectionBgColor: 'rgba(239, 232, 253, 0.25)',
  experienceSectionBorderColor: 'rgba(204, 185, 250, 1)',
  experienceDescriptionColor: 'rgb(0, 0, 0)',

  savingsSectionDescriptionColor: 'rgba(90, 90, 90, 1)',
  savingsItemBgColor: 'inherit',
  savingsItemBorderColor: 'rgba(213, 213, 213, 1)',
  savingsItemDescriptionColor: 'rgba(90, 90, 90, 1)',

  checkoutWrapperBorderColor: 'rgba(227, 227, 227, 1)',
  checkoutTimerLeftBackground: '#fff',

  timerColor: 'rgb(0, 0, 0)',

  checkoutSummaryBgColor: '#fff',
  buttonActionColor: '#fff',

  mainBackground: '#F7F7F8',

  footerTitleWeight: '300',

  new_greenMainColor: '#05A8A8',
  new_orangeSecondaryColor: '#006ce4',
  new_orangeMainColor: '#006ce4',
  new_grayAdditionalColor: '#F8F8F8',
  new_greySecondaryColor: '#E4E4E7',
  new_greyMainColor: '#C4C4C4',
  new_blackAdditionalColor: '#797979',
  new_blackSecondaryColor: '#767E83',
  new_blackMainColor: '#000000',
  new_whiteMainColor: '#FFF',
  new_redMainColor: '#FF1B1E',
} as const;

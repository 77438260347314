interface LogoIconProps {
  isContrast?: boolean;
}

const CryptoClubMobileLogo = ({ isContrast = false }: LogoIconProps) => (
  <svg width="248" height="26" viewBox="0 0 248 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3.15542 4.37938V17.5584H7.67112V19.7367H0.837891V4.37938H3.15542Z" fill="white"/>
  <path d="M11.2304 10.0489V15.6089C11.2304 17.2136 11.8637 18.016 13.1302 18.016C14.3967 18.016 15.03 17.2136 15.03 15.6089V10.0489H17.268V15.6587C17.268 16.4345 17.1718 17.1042 16.9795 17.6679C16.7938 18.1718 16.4722 18.626 16.0147 19.0305C15.2588 19.687 14.2973 20.0152 13.1302 20.0152C11.9698 20.0152 11.0116 19.687 10.2557 19.0305C9.79152 18.626 9.46329 18.1718 9.27099 17.6679C9.08532 17.217 8.99249 16.5472 8.99249 15.6587V10.0489H11.2304Z" fill="white"/>
  <path d="M22.6077 14.4949L19.1264 10.0489H21.7921L23.9505 12.8737L26.1486 10.0489H28.8939L25.3131 14.4949L29.4608 19.7367H26.7952L23.9505 16.1162L21.0361 19.7367H18.3009L22.6077 14.4949Z" fill="white"/>
  <path d="M32.7317 10.0489V15.6089C32.7317 17.2136 33.3649 18.016 34.6315 18.016C35.898 18.016 36.5312 17.2136 36.5312 15.6089V10.0489H38.7692V15.6587C38.7692 16.4345 38.673 17.1042 38.4808 17.6679C38.2951 18.1718 37.9735 18.626 37.5159 19.0305C36.76 19.687 35.7985 20.0152 34.6315 20.0152C33.471 20.0152 32.5129 19.687 31.7569 19.0305C31.2928 18.626 30.9645 18.1718 30.7722 17.6679C30.5866 17.217 30.4937 16.5472 30.4937 15.6587V10.0489H32.7317Z" fill="white"/>
  <path d="M41.1051 10.0489H43.3431V10.9142C43.7542 10.4832 44.1189 10.1881 44.4372 10.029C44.7621 9.8632 45.1467 9.78031 45.591 9.78031C46.1811 9.78031 46.7978 9.97261 47.441 10.3572L46.4165 12.4062C45.9921 12.1012 45.5777 11.9486 45.1732 11.9486C43.9531 11.9486 43.3431 12.8703 43.3431 14.7138V19.7367H41.1051V10.0489Z" fill="white"/>
  <path d="M52.184 17.9364L47.8274 10.0489H50.4234L53.3875 15.6189L56.1526 10.0489H58.679L50.9605 25.0084H48.4043L52.184 17.9364Z" fill="white"/>
  <path d="M77.2958 5.20493V7.95016C75.9563 6.82952 74.5704 6.26921 73.1382 6.26921C71.56 6.26921 70.2305 6.83615 69.1496 7.97005C68.0621 9.09732 67.5184 10.4766 67.5184 12.1078C67.5184 13.7191 68.0621 15.0785 69.1496 16.1858C70.2371 17.2932 71.5699 17.8469 73.1481 17.8469C73.9637 17.8469 74.6566 17.7143 75.2269 17.449C75.5452 17.3164 75.8734 17.1374 76.2116 16.9119C76.5564 16.6865 76.9178 16.4146 77.2958 16.0963V18.8913C75.9696 19.6406 74.5771 20.0152 73.1183 20.0152C70.9234 20.0152 69.0501 19.2493 67.4985 17.7176C65.9535 16.1726 65.181 14.3093 65.181 12.1277C65.181 10.1715 65.8275 8.42759 67.1205 6.89583C68.712 5.01927 70.7709 4.08098 73.2973 4.08098C74.6765 4.08098 76.0094 4.45563 77.2958 5.20493Z" fill="white"/>
  <path d="M79.3134 10.0489H81.5513V10.9142C81.9625 10.4832 82.3272 10.1881 82.6455 10.029C82.9704 9.8632 83.355 9.78031 83.7992 9.78031C84.3894 9.78031 85.0061 9.97261 85.6493 10.3572L84.6248 12.4062C84.2004 12.1012 83.786 11.9486 83.3815 11.9486C82.1614 11.9486 81.5513 12.8703 81.5513 14.7138V19.7367H79.3134V10.0489Z" fill="white"/>
  <path d="M90.3923 17.9364L86.0357 10.0489H88.6317L91.5958 15.6189L94.3609 10.0489H96.8873L89.1688 25.0084H86.6126L90.3923 17.9364Z" fill="white"/>
  <path d="M100.168 25.0084H97.9302V10.0489H100.168V11.1032C101.05 10.2213 102.051 9.78031 103.172 9.78031C104.505 9.78031 105.602 10.271 106.464 11.2524C107.34 12.2271 107.777 13.4572 107.777 14.9425C107.777 16.3947 107.343 17.6049 106.474 18.573C105.612 19.5345 104.525 20.0152 103.212 20.0152C102.078 20.0152 101.063 19.561 100.168 18.6526V25.0084ZM105.489 14.9525C105.489 14.0241 105.238 13.2682 104.734 12.6847C104.223 12.0945 103.58 11.7994 102.804 11.7994C101.982 11.7994 101.315 12.0846 100.805 12.6548C100.294 13.2251 100.039 13.9744 100.039 14.9027C100.039 15.8112 100.294 16.5605 100.805 17.1506C101.309 17.7275 101.972 18.016 102.794 18.016C103.57 18.016 104.21 17.7242 104.714 17.1407C105.231 16.5572 105.489 15.8278 105.489 14.9525Z" fill="white"/>
  <path d="M112.142 12.1376V19.7367H109.904V12.1376H108.949V10.0489H109.904V6.49797H112.142V10.0489H113.883V12.1376H112.142Z" fill="white"/>
  <path d="M114.597 14.8232C114.597 13.424 115.098 12.2338 116.099 11.2524C117.101 10.271 118.321 9.78031 119.76 9.78031C121.205 9.78031 122.432 10.2743 123.44 11.2623C124.434 12.2503 124.932 13.4638 124.932 14.9027C124.932 16.3549 124.431 17.5717 123.43 18.5531C122.422 19.5278 121.185 20.0152 119.72 20.0152C118.268 20.0152 117.051 19.5179 116.069 18.5233C115.088 17.5419 114.597 16.3085 114.597 14.8232ZM116.885 14.863C116.885 15.8311 117.144 16.597 117.661 17.1606C118.191 17.7309 118.891 18.016 119.76 18.016C120.635 18.016 121.334 17.7342 121.858 17.1705C122.382 16.6069 122.644 15.8543 122.644 14.9127C122.644 13.9711 122.382 13.2185 121.858 12.6548C121.328 12.0846 120.628 11.7994 119.76 11.7994C118.904 11.7994 118.211 12.0846 117.681 12.6548C117.15 13.2251 116.885 13.9611 116.885 14.863Z" fill="white"/>
  <path d="M144.394 5.20493V7.95016C143.055 6.82952 141.669 6.26921 140.236 6.26921C138.658 6.26921 137.329 6.83615 136.248 7.97005C135.16 9.09732 134.617 10.4766 134.617 12.1078C134.617 13.7191 135.16 15.0785 136.248 16.1858C137.335 17.2932 138.668 17.8469 140.246 17.8469C141.062 17.8469 141.755 17.7143 142.325 17.449C142.643 17.3164 142.972 17.1374 143.31 16.9119C143.655 16.6865 144.016 16.4146 144.394 16.0963V18.8913C143.068 19.6406 141.675 20.0152 140.217 20.0152C138.022 20.0152 136.148 19.2493 134.597 17.7176C133.052 16.1726 132.279 14.3093 132.279 12.1277C132.279 10.1715 132.926 8.42759 134.219 6.89583C135.81 5.01927 137.869 4.08098 140.396 4.08098C141.775 4.08098 143.108 4.45563 144.394 5.20493Z" fill="white"/>
  <path d="M148.65 2.93714V19.7367H146.412V2.93714H148.65Z" fill="white"/>
  <path d="M153.233 10.0489V15.6089C153.233 17.2136 153.867 18.016 155.133 18.016C156.4 18.016 157.033 17.2136 157.033 15.6089V10.0489H159.271V15.6587C159.271 16.4345 159.175 17.1042 158.982 17.6679C158.797 18.1718 158.475 18.626 158.018 19.0305C157.262 19.687 156.3 20.0152 155.133 20.0152C153.973 20.0152 153.015 19.687 152.259 19.0305C151.794 18.626 151.466 18.1718 151.274 17.6679C151.088 17.217 150.995 16.5472 150.995 15.6587V10.0489H153.233Z" fill="white"/>
  <path d="M163.845 2.93714V11.1728C164.74 10.2445 165.755 9.78031 166.888 9.78031C168.195 9.78031 169.282 10.2677 170.151 11.2424C171.02 12.2106 171.454 13.4174 171.454 14.863C171.454 16.3549 171.016 17.5883 170.141 18.563C169.272 19.5312 168.175 20.0152 166.849 20.0152C165.728 20.0152 164.727 19.5842 163.845 18.7222V19.7367H161.607V2.93714H163.845ZM169.166 14.9624C169.166 14.0341 168.914 13.2782 168.41 12.6946C167.9 12.0978 167.26 11.7994 166.491 11.7994C165.668 11.7994 164.999 12.0879 164.481 12.6648C163.971 13.2351 163.715 13.9777 163.715 14.8928C163.715 15.8344 163.967 16.587 164.471 17.1506C164.975 17.7275 165.638 18.016 166.461 18.016C167.237 18.016 167.88 17.7275 168.39 17.1506C168.908 16.5671 169.166 15.8377 169.166 14.9624Z" fill="white"/>
  <path d="M174.836 3.53592V8.17654H173.818V3.53592H172.583V2.57469H176.071V3.53592H174.836ZM176.415 8.17654L177.618 2.06543L179.483 6.20316L181.348 2.06543L182.552 8.17654H181.508L180.986 5.12735L179.483 8.27839L177.981 5.12735L177.453 8.17654H176.415Z" fill="white"/>
  <line x1="189.478" y1="2.06543" x2="189.478" y2="25.0084" stroke="white" stroke-opacity="0.2" stroke-width="0.814815"/>
  <path d="M246.304 11.6694L244.448 12.6573C244.156 12.0586 243.793 11.7592 243.361 11.7592C243.154 11.7592 242.978 11.8291 242.832 11.9688C242.685 12.1018 242.612 12.2748 242.612 12.4876C242.612 12.8602 243.045 13.2293 243.909 13.5952C245.1 14.1074 245.902 14.5797 246.314 15.0121C246.727 15.4445 246.933 16.0265 246.933 16.7583C246.933 17.6962 246.587 18.4812 245.895 19.1131C245.223 19.7118 244.412 20.0111 243.46 20.0111C241.831 20.0111 240.677 19.2162 239.998 17.6264L241.914 16.7383C242.18 17.204 242.383 17.5 242.523 17.6264C242.795 17.8792 243.121 18.0055 243.5 18.0055C244.259 18.0055 244.638 17.6596 244.638 16.9678C244.638 16.5687 244.345 16.1962 243.76 15.8503C243.534 15.7372 243.307 15.6274 243.081 15.521C242.855 15.4146 242.626 15.3048 242.393 15.1917C241.741 14.8724 241.282 14.5531 241.016 14.2338C240.677 13.828 240.507 13.3058 240.507 12.6672C240.507 11.8224 240.796 11.124 241.375 10.5718C241.967 10.0197 242.685 9.74365 243.53 9.74365C244.774 9.74365 245.699 10.3856 246.304 11.6694Z" fill="white"/>
  <path d="M238.628 2.87891V19.732H236.383V2.87891H238.628Z" fill="white"/>
  <path d="M234.731 15.4013H227.767C227.827 16.1995 228.086 16.8348 228.545 17.3071C229.004 17.7727 229.593 18.0055 230.311 18.0055C230.87 18.0055 231.332 17.8725 231.698 17.6064C232.057 17.3403 232.466 16.8481 232.925 16.1297L234.821 17.1873C234.529 17.6862 234.219 18.1153 233.893 18.4745C233.567 18.8271 233.218 19.1198 232.846 19.3526C232.473 19.5788 232.071 19.7451 231.638 19.8515C231.206 19.9579 230.737 20.0111 230.231 20.0111C228.781 20.0111 227.617 19.5455 226.739 18.6142C225.861 17.6763 225.422 16.4323 225.422 14.8824C225.422 13.3458 225.848 12.1018 226.699 11.1506C227.557 10.2126 228.695 9.74365 230.112 9.74365C231.542 9.74365 232.673 10.1993 233.504 11.1107C234.329 12.0153 234.741 13.2693 234.741 14.8724L234.731 15.4013ZM232.427 13.5653C232.114 12.3679 231.359 11.7692 230.161 11.7692C229.889 11.7692 229.633 11.8125 229.393 11.8989C229.154 11.9788 228.934 12.0985 228.735 12.2581C228.542 12.4111 228.375 12.5974 228.236 12.8169C228.096 13.0364 227.99 13.2859 227.916 13.5653H232.427Z" fill="white"/>
  <path d="M223.244 12.1088V19.7321H220.999V12.1088H220.041V10.0134H220.999V6.45117H223.244V10.0134H224.99V12.1088H223.244Z" fill="white"/>
  <path d="M208.873 14.8026C208.873 13.399 209.375 12.2049 210.38 11.2204C211.384 10.2359 212.608 9.74365 214.052 9.74365C215.502 9.74365 216.732 10.2392 217.744 11.2304C218.741 12.2216 219.24 13.4389 219.24 14.8824C219.24 16.3392 218.738 17.5599 217.734 18.5444C216.723 19.5222 215.482 20.0111 214.012 20.0111C212.555 20.0111 211.334 19.5122 210.35 18.5144C209.365 17.5299 208.873 16.2926 208.873 14.8026ZM211.168 14.8425C211.168 15.8137 211.427 16.582 211.946 17.1474C212.478 17.7195 213.18 18.0055 214.052 18.0055C214.93 18.0055 215.632 17.7228 216.157 17.1574C216.683 16.592 216.945 15.837 216.945 14.8924C216.945 13.9478 216.683 13.1928 216.157 12.6273C215.625 12.0553 214.923 11.7692 214.052 11.7692C213.194 11.7692 212.498 12.0553 211.966 12.6273C211.434 13.1994 211.168 13.9378 211.168 14.8425Z" fill="white"/>
  <path d="M197.913 10.3525H204.558V4.32568H206.883V19.7319H204.558V12.5377H197.913V19.7319H195.588V4.32568H197.913V10.3525Z" fill="white"/>
  </svg>
);

export default CryptoClubMobileLogo;

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import Dropdown from './Dropdown';

type Props = { title: ReactNode; dropdown?: boolean };

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.custom.secondaryActionColor};
`;

const FilterGroup: FC<Props> = ({ title, dropdown, children }) => {
  const _title = <StyledTypography variant="h5">{title}</StyledTypography>;

  const spacing = 5;

  if (dropdown)
    return (
      <Dropdown title={_title} spacing={spacing}>
        {children}
      </Dropdown>
    );

  return (
    <Stack spacing={spacing}>
      {_title}
      {children}
    </Stack>
  );
};

export default FilterGroup;

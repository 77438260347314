export const wirexTheme = {
  greyMainColor: 'rgba(149, 149, 149, 1)',
  bgGreyColor: 'rgba(213, 213, 213, 1)',
  greySecondaryColor: 'rgba(227, 227, 227, 1)',
  greyAlternativeColor: 'rgba(229, 231, 235, 1)',
  mainColor: 'rgba(0, 177, 90, 1)',
  secondaryButtonColor: 'rgba(149, 149, 149, 1)',
  greenSecondaryColor: 'rgba(0, 135, 78, 1)',
  headerBgColor: 'transparent',
  headerBgContrastColor: 'transparent',
  headerButtonColor: 'rgba(32, 32, 32, 1)',
  headerButtonContrastColor: 'rgba(32, 32, 32, 1)',
  headerBottomBorderColor: 'rgba(229, 231, 235, 1)',
  mainButtonColor: 'rgba(0, 177, 90, 1)',
  actionColor: 'rgba(0, 177, 90, 1)',
  showMoreTextColor: 'rgba(0, 177, 90, 1)',
  secondaryActionColor: 'rgba(32, 32, 32, 1)',
  textActionColor: 'rgba(0, 177, 90, 1)',
  muiSecondaryActionColor: 'rgba(0, 177, 90, 1)',
  activeBorderColor: 'rgba(0, 177, 90, 1)',
  linkActionColor: 'rgba(0, 177, 90, 1)',
  paymentCardBgColor: '#F1FAF6',
  secondaryButtonTextColor: '#fff',
  footerBgColor: 'rgba(255, 255, 255, 1)',
  footerTextColor: 'rgba(32, 32, 32, 1)',
  footerTopBorderColor: 'transparent',
  footerBorderColor: 'rgba(213, 213, 213, 1)',
  footerIconBorder: 'rgba(213, 213, 213, 1)',
  nonRefundableColor: 'rgba(219, 31, 34, 1)',
  inputBackgroundColor: 'rgba(255, 255, 255, 1)',
  mainPageBgGradient: 'transparent',
  mainPageContrastColor: 'rgba(0, 177, 90, 1)',
  muiPrimaryColor: 'rgba(0, 177, 90, 1)',

  authTitleColor: 'rgb(0, 0, 0)',

  contrastInputColor: 'rgb(0, 0, 0)',
  inputColor: 'rgb(0, 0, 0)',
  contrastInputLabelColor: 'rgb(118, 126, 131)',
  contrastButtonColor: 'rgba(255, 255, 255, 1)',
  
  searchResultTitleColor: '#525252',
  searchResultCounterColor: '#000',
  filterTagBackgroundColor: 'rgba(0, 0, 0, 0.08)',
  hotelCardTitleColor: 'rgb(0, 0, 0)',
  hotelCardIconColor: 'rgb(0, 0, 0)',
  reviewTitleTextColor: 'rgba(0, 177, 90, 1)',
  reviewCountTextColor: '#323232',

  priceGuaranteeBorderRadius: '30px',
  priceGuaranteeTextColor: 'rgba(0, 177, 90, 1)',
  priceGuaranteeBgColor: '#DEE1E2',
  priceGuaranteeRoomBorderRadius: '30px',
  priceGuaranteeRoomTextColor: 'rgba(0, 177, 90, 1)',
  priceGuaranteeRoomBgColor: '#DEE1E2',
  priceGuaranteeRoomBorder: 'none',

  ratingNumberTextColor: '#FFF',

  discountBorder: 'none',
  discountBorderRadius: '16px',
  inputAdornmentColor: 'rgba(0, 0, 0, 0.54)',
  skeletonColor: 'rgba(0, 0, 0, 0.11)',

  customFontFamily: '"Inter", sans-serif',
  mainPageTitleFontFamily: '"Inter", sans-serif',
  mainPageTitleWeight: '700',

  mainBackground: 'rgba(245, 246, 245, 1)',
  mainPageSignInButtonColor: 'rgba(255, 255, 255, 1)',
  signInPageButtonTextColor: 'rgba(255, 255, 255, 1)',
  searchButtonColor: 'rgba(0, 177, 90, 1)',
  searchButtonTextColor: 'rgba(245, 246, 245, 1)',
  mainPageBorderColor: 'transparent',

  hotelsSectionTitleColor: '#000',
  hotelSectionBgColor: 'inherit',
  hotelSectionBorderColor: 'inherit',
  hotelSectionBorderBottomColor: 'inherit',
  hotelSectionBorderRadius: '0',
  hotelSectionMobileBorderRadius: '0',
  hotelSectionItemBorderColor: 'inherit',
  hotelSectionSubtitleColor: 'rgba(0, 0, 0, 1)',
  hotelSectionItemColor: 'rgba(247, 247, 247, 1)',

  experienceSectionBgColor: 'rgba(239, 232, 253, 0.25)',
  experienceSectionBorderColor: 'rgba(204, 185, 250, 1)',
  experienceDescriptionColor: 'rgba(32, 32, 32, 1)',

  savingsSectionDescriptionColor: 'rgba(33, 35, 35, 1)',
  savingsItemBgColor: 'rgba(255, 255, 255, 1)',
  savingsItemBorderColor: 'inherit',
  savingsItemDescriptionColor: 'rgba(130, 130, 127, 1)',

  checkoutWrapperBorderColor: 'transparent',
  checkoutSummaryBgColor: '#fff',

  hotelCardBgColor: 'rgba(255, 255, 255, 1)',
  hotelCardBoxShadow: 'transparent',
  hotelCardRightRadius: '0',
  buttonActionColor: '#fff',

  roomContainerPadding: '24px',
  roomContainerBackground: '#fff',
  roomsSearchButtonTextColor: '#fff',
  roomsStickyHeaderBgColor: '#FFF',
  roomStickyHeaderTextColor: '#23232C',
  roomViewDetailsButtonColor: 'rgba(255, 255, 255, 1)',
  roomAvailabilityTitle: '#23232C',
  roomLayoutSelectButtonActiveColor: 'rgba(32, 32, 32, 1)',
  roomLayoutSelectButtonColor: 'rgba(32, 32, 32, 1)',
  roomLayoutSelectBorder: 'none',
  roomGridItemBgColor: '#FFF',
  roomCardCheckboxActiveColor: '#000000',
  roomCardCheckboxColor: '#FFF',
  roomListHeaderBgColor: '#fff',
  roomListItemBgColor: '#fff',
  roomListHeaderBgActionColor: 'rgba(0, 177, 90, 1)',
  roomInfoIconColor: 'rgba(0, 177, 90, 1)',

  aboutHotelBgColor: 'rgba(255, 255, 255, 1)',
  aboutHotelBorderRadius: '16px',
  aboutHotelBorder: 'rgba(206, 206, 206, 1)',
  aboutHotelPadding: '36px 40px',

  footerTitleWeight: '500',

  mainBorderRadius: '150px',
  searchPanelRadius: '150px',
  searchPanelMobileRadius: '16px',
  secondaryBorderRadius: '20px',
  savingsBorderRadius: '32px',
  paymentInputBorder: '8px',  
  inputBorderRadius: '4px',
  paymentBorderRadius: '4px',

  checkoutSummaryBorderRadius: '16px',
  checkoutSummaryBorderColor: 'rgba(206, 206, 206, 1)',
  checkoutBackgroundColor: 'transparent',
  checkoutTimerLeftBackground: '#FFF',

  timerColor: 'rgb(0, 0, 0)',
  accountNavListActiveColor: 'rgb(0, 108, 228)',

  mainBoxShadow: 'box-shadow: 1px 2px 20px 0px rgba(0, 0, 0, 0.08)',

  greyLightColor: '#EBECF4',
  greyUltraLightColor: '#FAFAFA',
  whiteMainColor: '#FFF',
  blackMainColor: '#23232C',
  blueMainColor: '#2539EB',
  lightBlueColor: '#006ce4',
  blackSecondaryColor: '#3C3C48',
  orangeMainColor: '#FC5110',
  orangeSecondaryColor: '#DD3E02',
  orangeTr: '#FEDCCF',
  orangeAlternativeColor: '#F46311',
  redMainColor: '#FF1B1E',
  redLightColor: '#FFE8E9',
  darkMainColor: '#171723',

  new_orangeSecondaryColor: '#006ce4',
  new_orangeMainColor: '#006ce4',
  new_grayAdditionalColor: '#F8F8F8',
  new_greySecondaryColor: '#E4E4E7',
  new_greyMainColor: '#C4C4C4',
  new_blackAdditionalColor: '#797979',
  new_blackSecondaryColor: '#767E83',
  new_blackMainColor: '#000000',
  new_whiteMainColor: '#FFF',
  new_redMainColor: '#FF1B1E',
} as const;

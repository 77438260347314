import { useTheme } from '@mui/system';
import { useState } from 'react';
import useMatchMedia from 'src/hooks/useMatchMedia';
import {
  CtDomainMarketingPricePresentation,
  HotelRoomsResponse,
  Room,
} from 'src/models/hotels';
import { GridRoom } from './GridRoom';
import { RoomGridSkeleton } from './GridRoom/RoomGridSkeleton';
import { ListRoom } from './ListRoom';
import { Row } from './ListRoom/CommonComponents';
import { RoomListSkeleton } from './ListRoom/RoomListSkeleton';
import { HorizontalScroll, HorizontalScrollInner } from './styles';
import { RoomsDisplay } from './types';

interface RoomContainerProps {
  display: RoomsDisplay;
  rooms: Room[];
  loading: boolean;
  showImg: boolean;
  guaranteedOfferRoom: HotelRoomsResponse['hotelDetails']['guaranteedOfferRoom'];
  bookBtnsLoading: boolean;
  pricePresentation: CtDomainMarketingPricePresentation;
  totalNights?: number;
  scrollFn: () => void;
}

export const RoomContainer = ({
  display,
  loading,
  rooms,
  guaranteedOfferRoom,
  bookBtnsLoading,
  pricePresentation,
  totalNights,
  showImg,
  scrollFn,
}: RoomContainerProps) => {
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));
  const [hoveredRoomId, setHoveredRoomId] = useState<string | null>(null);

  const renderSkeleton = () => {
    if (display === RoomsDisplay.Grid)
      return (
        <>
          <RoomGridSkeleton />
          <RoomGridSkeleton />
          <RoomGridSkeleton />
          <RoomGridSkeleton />
        </>
      );

    return (
      <>
        <RoomListSkeleton />
        <RoomListSkeleton />
      </>
    );
  };

  const renderRooms = () =>
    rooms.map((room) => {
      const { name, facilities = [], groups = [], images, id } = room;

      const isRoomCheapest = guaranteedOfferRoom
        ? false
        : groups.some((groupItem) => groupItem.isCheapest);

      if (display === RoomsDisplay.Grid)
        return (
          <GridRoom
            key={id}
            name={name}
            showImages={showImg}
            images={images}
            packages={groups}
            facilities={facilities}
            handleMouseEnter={() => setHoveredRoomId(id)}
            handleMouseLeave={() => setHoveredRoomId(null)}
            handleTouchStart={() => setHoveredRoomId(id)}
            active={hoveredRoomId === id}
            scrollFn={scrollFn}
          />
        );

      return (
        <ListRoom
          key={id}
          name={name}
          showImages={showImg}
          images={images}
          packages={groups}
          facilities={facilities}
          cheapestRoom={isRoomCheapest}
          bookBtnsLoading={isRoomCheapest && bookBtnsLoading}
          pricePresentation={pricePresentation}
          totalNights={totalNights}
        />
      );
    });

  const renderContent = () => {
    let content;
    if (loading) content = renderSkeleton();
    else content = renderRooms();

    if (display === RoomsDisplay.List) {
      return <div>{content}</div>;
    }

    if (isMobile && display === RoomsDisplay.Grid) {
      return (
        <HorizontalScroll>
          <HorizontalScrollInner>{content}</HorizontalScrollInner>
        </HorizontalScroll>
      );
    }

    return <Row gap={10}>{content}</Row>;
  };

  return <div>{renderContent()}</div>;
};

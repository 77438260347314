import styled from 'styled-components';

export const RoomsFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const RoomsRoundedButton = styled.button<{ selected: boolean }>`
  border: 1px solid ${(props) => props.theme.custom.blackSecondaryColor};
  border-radius: 20px / 50%;
  padding: 8px 10px;
  font: Roboto;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  margin-right: 7px;
  cursor: pointer;

  ${(props) =>
    props.selected
      ? `
  background: color-mix(
    in srgb,
    ${props.theme.custom.blackSecondaryColor},
    transparent 90%
  );
  `
      : `
  background: ${props.theme.custom.whiteMainColor};
  `}
`;

export const RoomsHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
  color: ${(props) => props.theme.custom.secondaryActionColor};
`;

export const RoomsHeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
`;

export const HorizontalScroll = styled.div`
  overflow-x: scroll;
  width: 100%;
`;

export const HorizontalScrollInner = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: fit-content;
`;

export const Container = styled.div`
  margin-bottom: 20px;
`;

export const InlineBlock = styled.div`
  display: inline-block;
`;

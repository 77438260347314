import noop from 'lodash/noop';
import React, {
  createContext,
  Dispatch,
  FC,
  Reducer,
  useContext,
  useReducer,
  useState,
} from 'react';

import { SortingType } from 'src/constants';
import useConst from 'src/hooks/useConst';
import { SearchPageFormProvider } from './SearchPageFormProvider';

type TResetFiltersContext = {
  resetFilters?: (withPush: boolean) => void;
  setResetFiltersHandler: (fn: TResetFiltersContext['resetFilters']) => void;
};

export const ResetFiltersContext = createContext<TResetFiltersContext>({
  setResetFiltersHandler: noop,
});

export const FiltersTogglerContext = createContext(false);

export enum ESearchType {
  COUNTRY,
  CITY,
  POI,
}

export const SearchNightsToStayContext = createContext(0);

export const SearchUrlContext = createContext<string | undefined>(undefined);

export const SearchTypeContext = createContext<ESearchType>(ESearchType.CITY);

export const SearchSortingContext = createContext<SortingType>(
  undefined as any
);

type MapData = {
  isOpen: boolean;
};

export const SearchMapDataContext = createContext<MapData>(null as any);

export const SearchMapSetIsOpenContext = createContext<
  ((isOpen: boolean) => void) | undefined
>(undefined);

export const IsInProgressContext = createContext(true);

type AvailableFilters = {
  stars: number[];
  propertyTypes: number[];
};

interface AvailableFiltersState {
  availableFilters: AvailableFilters;
  setAvailableFilters: React.Dispatch<React.SetStateAction<AvailableFilters>>;
}
export const FiltersContext = createContext<AvailableFiltersState | undefined>(
  undefined
);

export enum SearchPageActions {
  FILTER,
  IN_PROGRESS,
  MAP_ENABLE,
}

type Action<T, P> = { type: T; payload: P };

type Actions =
  | Action<SearchPageActions.FILTER, boolean>
  | Action<SearchPageActions.IN_PROGRESS, boolean>
  | Action<SearchPageActions.MAP_ENABLE, boolean>;

const reducer: Reducer<
  { isFiltersOpen: boolean; isInProgress: boolean; isMapEnable: boolean },
  Actions
> = (prevState, { type, payload }) => {
  switch (type) {
    case SearchPageActions.FILTER:
      return { ...prevState, isFiltersOpen: payload };
    case SearchPageActions.IN_PROGRESS:
      return prevState.isInProgress !== payload
        ? { ...prevState, isInProgress: payload }
        : prevState;
    case SearchPageActions.MAP_ENABLE:
      return prevState.isMapEnable !== payload
        ? { ...prevState, isMapEnable: payload }
        : prevState;
  }
};

const DispatchContext = createContext<Dispatch<Actions>>(noop);

export const useSearchPageDispatch = () => useContext(DispatchContext);

type Props = {
  nightsToStay: number;
  searchType: ESearchType;
  url?: string;
  sorting?: SortingType;
};

const SearchPageProvider: FC<Props> = ({
  children,
  searchType,
  url,
  nightsToStay,
  sorting = SortingType.POPULARITY_SCORE,
}) => {
  const { resetFiltersContext, mapData, setMapIsOpen } = useConst(() => {
    const resetFiltersContext: TResetFiltersContext = {
      setResetFiltersHandler(fn) {
        if (fn) {
          resetFiltersContext.resetFilters = fn;
        } else {
          delete resetFiltersContext.resetFilters;
        }
      },
    };

    const mapData: MapData = {
      isOpen: false,
    };

    return {
      resetFiltersContext,
      mapData,
      setMapIsOpen: (value: boolean) => {
        mapData.isOpen = value;
      },
    };
  });

  const [state, dispatch] = useReducer(reducer, {
    isFiltersOpen: false,
    isInProgress: true,
    isMapEnable: false,
  });

  const initialAvailableFilters: AvailableFilters = {
    stars: [],
    propertyTypes: [],
  };
  const [availableFilters, setAvailableFilters] = useState<AvailableFilters>(
    initialAvailableFilters
  );

  return (
    <DispatchContext.Provider value={dispatch}>
      <ResetFiltersContext.Provider value={resetFiltersContext}>
        <FiltersTogglerContext.Provider value={state.isFiltersOpen}>
          <SearchNightsToStayContext.Provider value={nightsToStay}>
            <SearchTypeContext.Provider value={searchType}>
              <SearchUrlContext.Provider value={url}>
                <SearchSortingContext.Provider value={sorting}>
                  <SearchMapDataContext.Provider value={mapData}>
                    <SearchMapSetIsOpenContext.Provider
                      value={state.isMapEnable ? setMapIsOpen : undefined}
                    >
                      <IsInProgressContext.Provider value={state.isInProgress}>
                        <FiltersContext.Provider
                          value={{ availableFilters, setAvailableFilters }}
                        >
                          <SearchPageFormProvider>
                            {children}
                          </SearchPageFormProvider>
                        </FiltersContext.Provider>
                      </IsInProgressContext.Provider>
                    </SearchMapSetIsOpenContext.Provider>
                  </SearchMapDataContext.Provider>
                </SearchSortingContext.Provider>
              </SearchUrlContext.Provider>
            </SearchTypeContext.Provider>
          </SearchNightsToStayContext.Provider>
        </FiltersTogglerContext.Provider>
      </ResetFiltersContext.Provider>
    </DispatchContext.Provider>
  );
};

export default SearchPageProvider;

import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { userSelector } from 'src/store/user/selectors';

export const PrivateRoute = ({ children }: { children: ReactElement }) => {
  const history = useHistory();
  const { isAuthenticated } = useSelector(userSelector);

  if (!isAuthenticated) {
    history.push('/', { replace: true });
    return null;
  }

  return children;
};

import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { push } from 'connected-react-router';
import { stringifyUrl } from 'query-string';
import { useHistory } from 'react-router-dom';

import { Image } from 'src/types';
import { DataElementsId } from 'src/constants';
import {
  RoomGroup,
  CtDomainMarketingPricePresentation,
} from 'src/models/hotels';
import { RoomFacility } from 'src/models/config';
import { media } from 'src/modules/mediaQuery';
import { setPackageAndProceedToCheckout } from 'src/store/checkout/actions';
import Loader from 'src/components/common/Loader';
import { I18n } from 'src/components/I18n';
import { CheckoutStatuses } from 'src/models/checkout';
import { store } from 'src/store';
import { userSelector } from 'src/store/user/selectors';
import { ROUTES } from 'src/router';

import { Row, Column, BookRoomButton } from './CommonComponents';
import { PriceForStay } from './PriceForStay';
import { FacilityInformation } from './FacilityInformation';
import { RoomInfo } from './RoomInfo';

const Wrapper = styled.div<{ showFacilityInfo: boolean }>`
  ${Row} {
    border: 1px solid ${(p) => p.theme.custom.greyAlternativeColor};
    border-radius: 5px;
    min-height: 166px;
    background-color: ${(p) => p.theme.custom.roomsStickyHeaderBgColor};
    color: ${(p) => p.theme.custom.roomAvailabilityTitle};

    ${media.phone`
      border: 0;
    `}
  }

  ${Column} {
    padding: 15px;
    border-right: 1px solid ${(p) => p.theme.custom.greyAlternativeColor};
    color: ${(p) => p.theme.custom.roomAvailabilityTitle};

    &:last-child {
      display: flex;
      flex-direction: column;
      border-right: 0;
    }

    ${(p) => media.phone`
      border: 0;
      width: 100%;

      &:first-child {
        border-top: 1px solid ${p.theme.custom.greyAlternativeColor};
      }

      &:last-child {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 10px;
      }
    `}

    ${(p) =>
      p.showFacilityInfo &&
      media.phone`
      &:first-child {
        order: 2;
      }

      &:nth-child(2) {
        order: 1;
        padding-top: 0;
      }

      &:last-child {
        order: 3;
      }
    `}
  }
`;

interface RoomPackageProps extends RoomGroup {
  totalNights?: number;
  pricePresentation: CtDomainMarketingPricePresentation;
  bookBtnsLoading: boolean;
  showFacilityInfo: boolean;
  images: Image[];
  facilities: RoomFacility[];
  className?: string;
  status?: CheckoutStatuses;
  priceChangePercent?: null | number;
}

const getButtonText = (
  status: CheckoutStatuses,
  priceChangePercent?: null | number
) => {
  switch (status) {
    case CheckoutStatuses.Pending:
      return <Loader whiteRightDot={true} />;

    case CheckoutStatuses.SoldOutHotel:
    case CheckoutStatuses.SoldOutRoom:
      return <I18n id="HOTEL_DETAILS_PAGE.ROOMS.CHECK_ANOTHER_HOTELS" />;

    case CheckoutStatuses.Available:
      return <I18n id="HOTEL_DETAILS_PAGE.ROOMS.BOOK_NOW_BUTTON" />;
  }
};

export const RoomPackage: FC<RoomPackageProps> = (props) => {
  const {
    className,
    id,
    isRefundable,
    price,
    boarding,
    pricePresentation,
    bookBtnsLoading,
    showFacilityInfo,
    totalNights,
    status,
    priceChangePercent,
  } = props;

  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(userSelector);
  const history = useHistory();

  const handleSignIn = () => {
    history.push(ROUTES.SIGN_IN);
  };

  const isSoldOut =
    status === CheckoutStatuses.SoldOutHotel ||
    status === CheckoutStatuses.SoldOutRoom;

  const handleClick = isSoldOut
    ? () => {
        const info = store.getState().hotel.info!;

        dispatch(
          push(
            stringifyUrl({
              url: '/search',
              query: { q: `${info.city}, ${info.country}` },
            })
          )
        );
      }
    : () => {
        if (isAuthenticated) {
          dispatch(setPackageAndProceedToCheckout(id));
        } else {
          handleSignIn();
        }
      };

  return (
    <Wrapper className={className} showFacilityInfo={showFacilityInfo}>
      <Row>
        <Column>
          <RoomInfo
            foodType={boarding}
            isRefundable={isRefundable}
            tax={price.tax}
            fee={price.fee}
            pricePresentation={pricePresentation}
          />
        </Column>
        {showFacilityInfo && (
          <Column>
            <FacilityInformation {...props} />
          </Column>
        )}
        <Column>
          <PriceForStay
            pricePerNight={price.pricePerNight}
            totalPrice={price.price}
            totalNights={totalNights}
            isSoldOut={isSoldOut}
            priceChangePercent={priceChangePercent}
          />
          <BookRoomButton
            btnType={isSoldOut ? 'secondary' : 'primary'}
            block={true}
            onClick={handleClick}
            data-test-id={DataElementsId.HOTEL_BOOK_BUTTON}
            disabled={
              status === undefined
                ? bookBtnsLoading
                : status === CheckoutStatuses.Pending
            }
          >
            {status === undefined ? (
              bookBtnsLoading ? (
                <Loader whiteRightDot={true} />
              ) : (
                <I18n id="HOTEL_DETAILS_PAGE.ROOMS.BOOK_NOW_BUTTON" />
              )
            ) : (
              getButtonText(status, priceChangePercent)
            )}
          </BookRoomButton>
        </Column>
      </Row>
    </Wrapper>
  );
};

import { FC } from 'react';
import styled from 'styled-components/macro';
import Grid from '@mui/material/Grid';

import { fontSizeSmall } from 'src/modules/fontSizes';
import { RoomFacility } from 'src/models/config';

const FacilityName = styled.span`
  ${fontSizeSmall};
  line-height: 20px;
  color: ${(p) => p.theme.custom.roomAvailabilityTitle};
`;

interface RoomFacilitiesProps {
  facilities: RoomFacility[];
}

export const RoomFacilities: FC<RoomFacilitiesProps> = ({ facilities }) => (
  <Grid container spacing={{ xs: 1, md: 2 }}>
    {facilities.map(({ name }) => (
      <Grid item xs={16} key={name}>
        <FacilityName>{name}</FacilityName>
      </Grid>
    ))}
  </Grid>
);

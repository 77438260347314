import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import round from 'lodash/round';
import { FC, useRef, VFC } from 'react';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { I18n } from 'src/components/I18n';
import { useAdaptiveEffect } from 'src/hooks/useAdaptiveEffect';
import useDerivedState from 'src/hooks/useDerivedState';
import { getCurrencySymbol } from 'src/modules/getCurrencySymbol';
import { currencySelector } from 'src/store/currency/selectors';

import { FiltersForm } from '..';

import PriceInput from './PriceInput';
import PriceSlider from './PriceSlider';

export type PriceSectionProps = {
  min?: number;
  max?: number;
  disabled: boolean;
};

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  > div {
    position: absolute;
  }
`;

const Container: FC = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);

  useAdaptiveEffect(() => {
    const el = ref.current;

    if (el) {
      el.style.height = `${el.children[0].clientHeight}px`;
    }
  }, []);

  return (
    <StyledContainer ref={ref}>
      <div>{children}</div>
    </StyledContainer>
  );
};

const PriceSection: VFC<PriceSectionProps> = ({ min, max, disabled }) => {
  const { currentRate, currentCurrency } = useSelector(currencySelector);

  const { field: fromField } = useController<FiltersForm, 'priceFrom'>({
    name: 'priceFrom',
  });

  const { field: toField } = useController<FiltersForm, 'priceTo'>({
    name: 'priceTo',
  });

  const convertedMin = min && Math.floor(min * currentRate);

  const convertedMax = max && Math.ceil(max * currentRate);

  const convertedFrom = fromField.value
    ? Math.floor(fromField.value * currentRate)
    : convertedMin!;

  const convertedTo = toField.value
    ? Math.ceil(toField.value * currentRate)
    : convertedMax!;

  const handleChangeFn =
    (
      onChange: (value: number | null) => void,
      minOrMax: number,
      check: (value: number) => boolean
    ) =>
    (value: number) => {
      if (value >= convertedMin! && value <= convertedMax! && check(value)) {
        onChange(value !== minOrMax ? round(value / currentRate, 2) : null);

        return true;
      }

      return false;
    };

  const handleFromChange = handleChangeFn(
    fromField.onChange,
    convertedMin!,
    (value) => value <= convertedTo
  );

  const handleToChange = handleChangeFn(
    toField.onChange,
    convertedMax!,
    (value) => value >= convertedFrom
  );

  const InputProps = {
    startAdornment: (
      <InputAdornment sx={{ color: (theme) => theme.custom.inputAdornmentColor }} position="start" disableTypography>
        {getCurrencySymbol(currentCurrency) || currentCurrency}
      </InputAdornment>
    ),
  };

  const [innerFromValue, setInnerFromValue] = useDerivedState<number | ''>(
    convertedFrom
  );

  const [innerToValue, setInnerToValue] = useDerivedState<number | ''>(
    convertedTo
  );

  return (
    <Stack spacing={1} alignItems="center">
      <PriceSlider
        min={convertedMin}
        max={convertedMax}
        disabled={disabled}
        innerFromValue={innerFromValue || convertedMin}
        innerToValue={innerToValue || convertedMax}
        fromValue={convertedFrom}
        toValue={convertedTo}
        onFromChange={handleFromChange}
        onToChange={handleToChange}
        onInnerFromChange={setInnerFromValue}
        onInnerToChange={setInnerToValue}
      />
      <Stack direction="row" spacing={4} width="100%">
        <Container>
          <PriceInput
            label={<I18n id="SEARCH_PAGE.FILTERS_PANEL.PRICE.MIN" />}
            disabled={disabled}
            value={convertedFrom}
            innerValue={innerFromValue}
            onChange={handleFromChange}
            onInnerValueChange={setInnerFromValue}
            field={fromField}
            InputProps={InputProps}
          />
        </Container>
        <Container>
          <PriceInput
            label={<I18n id="SEARCH_PAGE.FILTERS_PANEL.PRICE.MAX" />}
            disabled={disabled}
            value={convertedTo}
            innerValue={innerToValue}
            onChange={handleToChange}
            onInnerValueChange={setInnerToValue}
            field={toField}
            InputProps={InputProps}
          />
        </Container>
      </Stack>
    </Stack>
  );
};

export default PriceSection;

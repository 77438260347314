import { FC } from 'react';
import styled from 'styled-components/macro';
import Typography from '@mui/material/Typography';
import DoDisturbIcon from '@mui/icons-material/DoDisturbAlt';

import { PriceDisplay } from 'src/components/PriceDisplay';
import { I18n } from 'src/components/I18n';

const Wrapper = styled.div<Pick<PriceInfoProps, 'isSoldOut'>>`
  ${({ isSoldOut }) => isSoldOut && 'opacity: 0.2;'}
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  &&& + * {
    margin-top: 16px;
  }
`;

const PricePerNight = styled.div`
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 21px;
  line-height: 20px;
  color: ${(p) => p.theme.custom.roomAvailabilityTitle};
`;

const PricePerNightText = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: ${(p) => p.theme.custom.grayTextColor};
`;

const TotalPriceText = styled(PricePerNightText)`
  color: ${(p) => p.theme.custom.roomAvailabilityTitle};
  font-weight: 500;
`;

const StyledSoldOut = styled(Typography)`
  font-size: 20px;
  white-space: nowrap;
  @media (min-width: 768px) and (max-width: 900px) {
    font-size: 14px;
  }
`;

interface PriceInfoProps {
  pricePerNight: number;
  totalPrice: number;
  className?: string;
  totalNights?: number;
  isSoldOut?: boolean;
  priceChangePercent?: number | null;
}

export const PriceForStay: FC<PriceInfoProps> = ({
  className,
  pricePerNight,
  totalPrice,
  totalNights,
  isSoldOut,
  priceChangePercent,
}) => (
  <Container>
    <Wrapper className={className} isSoldOut={isSoldOut}>
      <PricePerNight data-test-id="HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.TITLE_PRICE">
        <PriceDisplay valuesInEur={pricePerNight} roundValue={true} />
      </PricePerNight>
      <PricePerNightText data-test-id="HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.PRICE_PER_NIGHT">
        <I18n id="HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.PRICE_PER_NIGHT" />
      </PricePerNightText>
      <TotalPriceText data-test-id="HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.TOTAL_PRICE">
        <I18n
          id="HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.TOTAL_PRICE"
          values={{
            price: <PriceDisplay valuesInEur={totalPrice} roundValue={true} />,
            nights: totalNights,
          }}
        />
      </TotalPriceText>
    </Wrapper>
    {(isSoldOut || Boolean(priceChangePercent)) && (
      <div>
        {isSoldOut ? (
          <StyledSoldOut
            variant="body1"
            color="primary"
            display="flex"
            alignItems="center"
            fontWeight={500}
            lineHeight={1}
          >
            <DoDisturbIcon sx={{ marginRight: 2, fontSize: '0.75em' }} />{' '}
            <I18n id="GENERAL_SOLD_OUT_INFO" />
          </StyledSoldOut>
        ) : (
          <Typography
            color="primary"
            fontSize="9px !important"
            textTransform="uppercase"
            fontWeight={500}
            lineHeight={1}
          >
{/*            <I18n
              id="HOTEL_DETAILS_PAGE.ROOMS.PRICE_INCREASED_BY"
              values={{
                percent:
                  priceChangePercent! < 1
                    ? '<1'
                    : Math.round(priceChangePercent!),
              }}
            />*/}
          </Typography>
        )}
      </div>
    )}
  </Container>
);

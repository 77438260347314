interface LogoIconProps {
  isContrast?: boolean;
}

const CodedEstateLogo = ({ isContrast = false }: LogoIconProps) => (
  <svg width="213" height="33" viewBox="0 0 213 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_369_20491)">
    <path d="M53.6667 13.3704C51.9346 13.3704 50.6233 11.9763 50.6233 10.1408C50.6233 8.33698 51.9346 6.95867 53.6667 6.95867C54.633 6.95867 55.6939 7.50641 56.4127 8.38454L58.0189 6.47212C56.9262 5.34275 55.2411 4.62305 53.6192 4.62305C50.297 4.62305 47.7852 7.00622 47.7852 10.1725C47.7852 13.3862 50.2338 15.8147 53.5271 15.8147C55.1353 15.8147 56.8654 15.0158 58.037 13.8072L56.4151 12.0826C55.6493 12.868 54.5879 13.3704 53.6667 13.3704Z" fill="#323260"/>
    <path d="M63.1646 15.7833C65.9105 15.7833 67.7534 14.0587 67.7534 11.456C67.7534 8.85328 65.913 7.14453 63.1646 7.14453C60.4029 7.14453 58.5625 8.85328 58.5625 11.456C58.5625 14.061 60.4029 15.7833 63.1646 15.7833ZM63.1646 9.29459C64.273 9.29459 65.0369 10.1886 65.0369 11.4899C65.0369 12.8071 64.273 13.6852 63.1646 13.6852C62.0405 13.6852 61.2771 12.8071 61.2771 11.4899C61.2771 10.1886 62.043 9.29459 63.1646 9.29459Z" fill="#323260"/>
    <path d="M72.5929 15.798C73.6699 15.798 74.5279 15.3589 75.1208 14.5758V15.6893H77.8192V4.05859H75.1208V8.35422C74.5279 7.57111 73.6386 7.13207 72.5302 7.13207C70.2051 7.13207 68.6602 8.84077 68.6602 11.4276C68.6621 14.0439 70.2213 15.798 72.5929 15.798ZM73.2485 9.29344C74.3726 9.29344 75.1208 10.1874 75.1208 11.4887C75.1208 12.7901 74.3726 13.6999 73.2485 13.6999C72.1245 13.6999 71.3767 12.7901 71.361 11.4887C71.3767 10.1874 72.1245 9.29344 73.2485 9.29344Z" fill="#323260"/>
    <path d="M83.7959 15.7829C85.3414 15.7829 86.6051 15.233 87.4631 14.2938L86.044 12.8996C85.4835 13.4631 84.7646 13.7619 84.0326 13.7619C83.0345 13.7619 82.3161 13.1984 82.0501 12.1935H87.9634C88.1505 9.10646 86.684 7.14648 83.7195 7.14648C81.1132 7.14648 79.3359 8.87109 79.3359 11.4896C79.3335 14.0607 81.05 15.7829 83.7959 15.7829ZM83.7489 9.02725C84.7152 9.02725 85.3708 9.70166 85.3708 10.7359H82.0006C82.1721 9.71751 82.8115 9.02725 83.7489 9.02725Z" fill="#323260"/>
    <path d="M95.3044 4.05859V8.35422C94.712 7.57111 93.8222 7.13207 92.7138 7.13207C90.3892 7.13207 88.8438 8.84077 88.8438 11.4276C88.8438 14.0462 90.4049 15.8002 92.775 15.8002C93.8516 15.8002 94.71 15.3611 95.3024 14.5781V15.6916H98.0009V4.06086H95.3044V4.05859ZM93.4326 13.6977C92.3085 13.6977 91.5608 12.7878 91.5451 11.4865C91.5608 10.1852 92.3085 9.29118 93.4326 9.29118C94.5567 9.29118 95.3044 10.1852 95.3044 11.4865C95.3044 12.7878 94.5567 13.6977 93.4326 13.6977Z" fill="#323260"/>
    <path d="M50.6035 23.8373H56.1111V22.631H50.6035V19.0256H56.7667V17.8193H49.3555V28.7914H56.9696V27.5987H50.6035V23.8373Z" fill="#323260"/>
    <path d="M59.9269 22.6309C59.9269 21.8637 60.6296 21.5332 61.5488 21.5332C62.3122 21.5332 63.1389 21.8003 63.7788 22.2077L64.2473 21.2368C63.5603 20.766 62.6097 20.4854 61.6253 20.4854C60.1273 20.4854 58.771 21.144 58.771 22.7259C58.7553 25.6568 63.3574 24.6384 63.3104 26.63C63.2942 27.4448 62.5151 27.8069 61.5464 27.8069C60.5801 27.8069 59.5188 27.4154 58.8161 26.7568L58.3477 27.6824C59.1272 28.4338 60.3297 28.8412 61.4988 28.8412C63.0737 28.8412 64.5109 28.1215 64.5109 26.4897C64.5128 23.5249 59.9269 24.4641 59.9269 22.6309Z" fill="#323260"/>
    <path d="M69.1562 27.6623C68.4531 27.6623 68.0636 27.2866 68.0636 26.2817V21.7824H70.6699V20.7799L68.0636 20.7957V18.6162H66.863V20.7957H65.6758V21.7847H66.863V26.5035C66.863 28.1331 67.7529 28.855 68.9534 28.855C69.609 28.8392 70.2328 28.6197 70.8095 28.2281L70.467 27.2391C70.0142 27.522 69.5772 27.6623 69.1562 27.6623Z" fill="#323260"/>
    <path d="M75.7345 20.4834C74.3307 20.4834 73.4413 20.8908 72.38 21.5969L72.896 22.4909C73.7544 21.8956 74.5968 21.5494 75.4998 21.5494C76.9507 21.5494 77.7009 22.2691 77.7009 23.4777V23.9643H75.002C73.067 23.9801 71.9297 24.8876 71.9297 26.3316C71.9297 27.7574 73.0224 28.8551 74.7227 28.8551C76.0339 28.8551 77.0791 28.416 77.7347 27.522V28.7917H78.922L78.9058 23.3373C78.8882 21.5652 77.7641 20.4834 75.7345 20.4834ZM77.7166 25.9378C77.4035 27.083 76.3426 27.7868 74.9549 27.7868C73.7995 27.7868 73.067 27.1916 73.067 26.2818C73.067 25.372 73.7858 24.9487 75.1578 24.9487H77.7166V25.9378Z" fill="#323260"/>
    <path d="M83.9336 27.6623C83.2304 27.6623 82.8409 27.2866 82.8409 26.2817V21.7824H85.4472V20.7799L82.8409 20.7957V18.6162H81.6404V20.7957H80.4531V21.7847H81.6404V26.5035C81.6404 28.1331 82.5302 28.855 83.7307 28.855C84.3864 28.8392 85.0101 28.6197 85.5868 28.2281L85.2443 27.2391C84.7916 27.522 84.3545 27.6623 83.9336 27.6623Z" fill="#323260"/>
    <path d="M90.42 20.4991C88.0323 20.4991 86.3477 22.2079 86.3477 24.6702C86.3477 27.1304 88.0166 28.8391 90.4362 28.8391C91.8102 28.8391 92.9636 28.3389 93.7452 27.4608L93.0739 26.7411C92.4339 27.4156 91.5441 27.7754 90.5308 27.7754C88.9402 27.7754 87.7691 26.7569 87.5506 25.141H94.2612C94.3846 22.3323 93.0739 20.4833 90.42 20.4991ZM87.5506 24.1361C87.7373 22.5677 88.8614 21.5493 90.4225 21.5493C92.0307 21.5493 93.0896 22.5519 93.1685 24.1361H87.5506Z" fill="#323260"/>
    <path d="M15.2291 30.6453L20.8246 1.78466C20.9868 0.951791 21.6693 0.385986 22.3496 0.519516C22.933 0.632677 23.325 1.22338 23.325 1.91366C23.325 2.02909 23.3137 2.14903 23.2912 2.26898L17.6957 31.1296C17.5335 31.9625 16.851 32.5283 16.1707 32.3947C15.5873 32.2816 15.1953 31.6909 15.1953 31.0006C15.1953 30.8852 15.2065 30.7653 15.2291 30.6453Z" fill="url(#paint0_linear_369_20491)"/>
    <path d="M17.7979 6.38147C17.674 7.02422 17.1131 7.48817 16.4621 7.48817H10.5715L10.5468 7.49044L2.78423 16.4551L10.5603 25.4355L10.5715 25.4242H12.4547C13.3107 25.4242 13.955 26.2096 13.7905 27.0538C13.6666 27.6965 13.1057 28.1605 12.4547 28.1605H10.5715C9.84165 28.1605 9.13208 27.8708 8.62527 27.3638L0.727596 18.2476C0.259053 17.7587 0 17.125 0 16.4551C0 15.7852 0.256801 15.1515 0.727596 14.6649L8.5577 5.62105L8.62753 5.54637C9.13659 5.04165 9.84616 4.75195 10.5738 4.75195H16.4643C17.3181 4.75195 17.9624 5.53731 17.7979 6.38147Z" fill="url(#paint1_linear_369_20491)"/>
    <path d="M29.8937 5.54863L32.4369 8.48402H36.1942C36.6988 8.48402 37.1088 8.89593 37.1088 9.40291V10.4847C37.1088 10.9917 36.6988 11.4036 36.1942 11.4036H34.9643L37.7913 14.6672C38.2599 15.156 38.5189 15.7897 38.5189 16.4573C38.5189 17.1273 38.2621 17.761 37.7913 18.2476L29.9612 27.2891L29.8914 27.3638C29.3824 27.8708 28.6728 28.1605 27.9429 28.1605H22.0546C21.1986 28.1605 20.5543 27.3751 20.7165 26.531C20.8404 25.8882 21.4013 25.4242 22.0546 25.4242L27.9699 25.422L35.7325 16.4573L27.9587 7.4769L27.9452 7.48817H26.062C25.206 7.48817 24.5617 6.70285 24.7262 5.85869C24.8501 5.21592 25.411 4.75195 26.062 4.75195H27.9452C28.675 4.75195 29.3846 5.04392 29.8937 5.54863Z" fill="url(#paint2_linear_369_20491)"/>
    <path d="M27.603 13.8001L29.5267 16.0203C29.6416 16.1403 29.7046 16.2942 29.7046 16.4571C29.7046 16.6201 29.6416 16.774 29.5267 16.8939L27.6187 19.096L27.603 19.1141C27.479 19.2364 27.3056 19.3088 27.1277 19.3088H25.8121C25.4967 19.3088 25.2422 19.053 25.2422 18.7362V14.1781C25.2422 13.8612 25.4967 13.6055 25.8121 13.6055H27.1277C27.3056 13.6055 27.479 13.6756 27.603 13.8001Z" fill="url(#paint3_linear_369_20491)"/>
    <path d="M11.9139 19.1001L9.57791 18.0545C9.48103 18.0115 9.41797 17.9142 9.41797 17.8078V15.1032C9.41797 14.9969 9.48103 14.8996 9.57791 14.8566L11.9139 13.8109C12.0625 13.7453 12.2292 13.7453 12.3779 13.8109L14.7139 14.8566C14.8107 14.8996 14.8738 14.9969 14.8738 15.1032V17.8078C14.8738 17.9142 14.8107 18.0115 14.7139 18.0545L12.3779 19.1001C12.2292 19.168 12.0603 19.168 11.9139 19.1001Z" fill="url(#paint4_linear_369_20491)"/>
    </g>
    <line x1="106.5" y1="3.41992" x2="106.5" y2="29.5799" stroke="#323260" stroke-opacity="0.5"/>
    <path d="M195.485 29.5808C190.487 29.5808 188.419 26.2718 188.281 23.8934H190.453C190.694 25.5824 192.762 27.6505 195.485 27.6505C198.036 27.6505 200.035 26.0994 200.035 23.9279C200.035 21.5495 197.829 20.7223 194.934 20.2397C191.969 19.7571 188.971 18.723 188.971 15.3106C188.971 11.9326 192.142 10.0713 195.382 10.0713C199.449 10.0713 201.724 11.8982 202.103 15.2072H200.035C199.587 12.6565 196.899 12.105 195.796 12.0705C193.658 12.0016 191.452 12.8978 191.452 15.0004C191.452 17.3098 193.693 17.8958 196.175 18.2749C199.036 18.723 202.517 19.9295 202.517 23.7555C202.517 26.9956 199.484 29.5808 195.485 29.5808Z" fill="#323260"/>
    <path d="M182.428 29.2708L182.462 3.41895H184.772V29.2708H182.428Z" fill="#323260"/>
    <path d="M178.718 19.6885C178.718 19.9642 178.718 20.3089 178.683 20.6536H164C164.069 25.1346 167.412 27.3751 170.135 27.4096C173.479 27.444 175.271 25.7895 175.822 23.7903H177.994C177.718 25.7551 175.754 29.4777 170.376 29.4432C164.999 29.4088 161.725 25.1001 161.725 19.8264C161.725 14.4147 164.93 10.2095 170.307 10.2095C175.512 10.2095 178.718 14.139 178.718 19.6885ZM170.342 12.3121C166.619 12.3121 164.448 14.9662 164.034 18.6889H176.512C176.167 14.9317 173.996 12.3121 170.342 12.3121Z" fill="#323260"/>
    <path d="M158.045 29.3053C155.184 29.3053 153.082 28.1678 153.082 24.583L153.116 12.1741H150.221V10.2438H153.116V3.41895H155.529V10.2438H159.769V12.2086L155.426 12.1741V24.2383C155.426 25.7549 155.529 27.1337 158.045 27.1337H160.044V29.3053H158.045Z" fill="#323260"/>
    <path d="M140.584 29.512C135.206 29.512 131.553 25.1689 131.553 19.7572C131.553 14.3456 135.206 10.0024 140.584 10.0024C145.995 10.0024 149.649 14.3456 149.649 19.7572C149.649 25.1689 145.995 29.512 140.584 29.512ZM140.584 27.3059C144.651 27.3059 147.202 23.928 147.202 19.7572C147.202 15.552 144.651 12.2085 140.584 12.2085C136.551 12.2085 133.966 15.552 133.966 19.7572C133.966 23.928 136.551 27.3059 140.584 27.3059Z" fill="#323260"/>
    <path d="M123.031 10.1062C127.133 10.1062 129.925 12.8292 129.925 17V29.271H127.443V17.2413C127.443 14.2769 125.444 12.3122 122.41 12.3122C119.343 12.3122 116.482 14.3459 116.482 17.3447V29.271H114V3.45361H116.482V13.2084C117.826 11.3126 120.411 10.1062 123.031 10.1062Z" fill="#323260"/>
    <defs>
    <linearGradient id="paint0_linear_369_20491" x1="-8.64348" y1="33.0788" x2="41.158" y2="3.68978" gradientUnits="userSpaceOnUse">
    <stop offset="2.29095e-07" stop-color="#6317F7"/>
    <stop offset="1" stop-color="#18FFFF"/>
    </linearGradient>
    <linearGradient id="paint1_linear_369_20491" x1="-10.7109" y1="29.5781" x2="39.0907" y2="0.18904" gradientUnits="userSpaceOnUse">
    <stop offset="2.29095e-07" stop-color="#6317F7"/>
    <stop offset="1" stop-color="#18FFFF"/>
    </linearGradient>
    <linearGradient id="paint2_linear_369_20491" x1="-6.57967" y1="36.58" x2="43.2219" y2="7.19098" gradientUnits="userSpaceOnUse">
    <stop offset="2.29095e-07" stop-color="#6317F7"/>
    <stop offset="1" stop-color="#18FFFF"/>
    </linearGradient>
    <linearGradient id="paint3_linear_369_20491" x1="-6.76411" y1="36.2631" x2="43.0374" y2="6.87411" gradientUnits="userSpaceOnUse">
    <stop offset="2.29095e-07" stop-color="#6317F7"/>
    <stop offset="1" stop-color="#18FFFF"/>
    </linearGradient>
    <linearGradient id="paint4_linear_369_20491" x1="-10.4953" y1="29.943" x2="39.3062" y2="0.553996" gradientUnits="userSpaceOnUse">
    <stop offset="2.29095e-07" stop-color="#6317F7"/>
    <stop offset="1" stop-color="#18FFFF"/>
    </linearGradient>
    <clipPath id="clip0_369_20491">
    <rect width="98" height="32" fill="white" transform="translate(0 0.5)"/>
    </clipPath>
    </defs>
    </svg>
  );

export default CodedEstateLogo;

import styled from 'styled-components';

export const TooltipContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

export const TooltipMessageWrapper = styled.div<{
  offset: { x: number; y: number };
}>`
  z-index: 10;
  position: absolute;
  padding-top: 9px;
  ${(p) => `
    transform: translate(
      calc(-50% + 6px + ${p.offset.x}px),
      ${p.offset.y}px
    );
  `}

  &::before {
    content: '';
    width: 0;
    border: 10px solid;
    border-color: transparent transparent
      ${(p) => p.theme.custom.blackMainColor} transparent;
    position: absolute;
    left: 0;
    right: 0;
    top: -10px;
    margin: auto;
  }
`;

export const TooltipMessage = styled.div`
  position: relative;
  padding: 8px;
  width: 160px;
  text-align: center;
  text-wrap: auto;
  white-space: pre-line;
  background: ${(p) => p.theme.custom.blackMainColor};
  color: ${(p) => p.theme.custom.whiteMainColor};
  font-size: 11px;
`;

export const PriceGuaranteeIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_13192_12429)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.55566 4.17885C2.55566 3.38001 3.20325 2.73242 4.00209 2.73242H13.6449C14.4438 2.73242 15.0914 3.38001 15.0914 4.17885V6.94977C15.0914 10.6853 12.701 14.0017 9.15721 15.183L8.97598 15.2434C8.87702 15.2764 8.77002 15.2764 8.67106 15.2434L8.48983 15.183C4.946 14.0017 2.55566 10.6853 2.55566 6.94977V4.17885ZM9.42617 4.92219C9.42617 4.58935 9.15634 4.31951 8.82349 4.31951C8.49064 4.31951 8.22081 4.58935 8.22081 4.92219V5.55928C7.30351 5.58922 6.56907 6.34227 6.56907 7.26686C6.56907 8.06975 7.12812 8.76432 7.91246 8.93589L9.37241 9.25526C9.66425 9.3191 9.87263 9.57791 9.87263 9.87708C9.87263 10.2287 9.58751 10.5137 9.23647 10.5137H8.41058C8.13454 10.5137 7.89809 10.3375 7.81047 10.0896C7.69955 9.77582 7.35522 9.61133 7.0414 9.72225C6.72757 9.83317 6.56309 10.1775 6.67401 10.4913C6.90637 11.1487 7.50179 11.6358 8.22081 11.7094V12.3552C8.22081 12.6881 8.49064 12.9579 8.82349 12.9579C9.15634 12.9579 9.42617 12.6881 9.42617 12.3552V11.7094C10.3543 11.6144 11.078 10.8298 11.078 9.87708C11.078 9.0119 10.4756 8.26273 9.62999 8.07775L8.17004 7.75839C7.93906 7.70786 7.77443 7.50331 7.77443 7.26686C7.77443 6.98898 7.99969 6.76371 8.27757 6.76371H9.23647C9.42482 6.76371 9.59344 6.84481 9.71078 6.97592C9.76591 7.03751 9.80888 7.10939 9.83658 7.18777C9.9475 7.5016 10.2918 7.66607 10.6056 7.55515C10.9195 7.44424 11.084 7.09991 10.973 6.78609C10.892 6.55672 10.7671 6.34875 10.6089 6.17205C10.3121 5.84039 9.89502 5.61613 9.42617 5.56804V4.92219Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_13192_12429">
        <rect
          width="13.5"
          height="13.5"
          fill="white"
          transform="translate(2.07324 2.25)"
        />
      </clipPath>
    </defs>
  </svg>
);

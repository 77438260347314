export const codedEstateTheme = {
  greyMainColor: 'rgba(149, 149, 149, 1)',
  bgGreyColor: 'rgba(213, 213, 213, 1)',
  greySecondaryColor: 'rgba(227, 227, 227, 1)',
  greyAlternativeColor: 'rgba(229, 231, 235, 1)',
  mainColor: 'rgba(91, 29, 238, 1)',
  secondaryButtonColor: 'rgba(149, 149, 149, 1)',
  greenSecondaryColor: 'rgba(0, 135, 78, 1)',
  headerBgColor: 'rgba(255, 255, 255, 1)',
  headerBgContrastColor: 'rgba(255, 255, 255, 1)',
  headerButtonColor: 'rgba(32, 32, 32, 1)',
  headerButtonContrastColor: 'rgba(32, 32, 32, 1)',
  headerBottomBorderColor: 'rgba(229, 231, 235, 1)',
  mainButtonColor: 'rgba(91, 29, 238, 1)',
  actionColor: 'rgba(91, 29, 238, 1)',
  showMoreTextColor: 'rgba(91, 29, 238, 1)',
  secondaryActionColor: 'rgba(32, 32, 32, 1)',
  textActionColor: 'rgba(91, 29, 238, 1)',
  muiSecondaryActionColor: 'rgba(91, 29, 238, 1)',
  activeBorderColor: 'rgba(91, 29, 238, 1)',
  linkActionColor: 'rgba(91, 29, 238, 1)',
  paymentCardBgColor: 'rgba(240, 233, 255, 1)',
  secondaryButtonTextColor: '#fff',
  footerBgColor: 'rgba(239, 232, 253, 0.25)',
  footerTextColor: 'rgba(32, 32, 32, 1)',
  footerTopBorderColor: 'transparent',
  footerBorderColor: 'rgba(213, 213, 213, 1)',
  footerIconBorder: 'rgba(213, 213, 213, 1)',
  nonRefundableColor: 'rgba(219, 31, 34, 1)',
  inputBackgroundColor: 'rgba(255, 255, 255, 1)',
  mainPageBgGradient: 'transparent',
  mainPageContrastColor: 'rgba(32, 32, 32, 1)',
  muiPrimaryColor: '#11171E',

  authTitleColor: 'rgb(0, 0, 0)',

  contrastInputColor: 'rgb(0, 0, 0)',
  inputColor: 'rgb(0, 0, 0)',
  contrastInputLabelColor: 'rgb(118, 126, 131)',
  contrastButtonColor: 'rgba(255, 255, 255, 1)',

  searchResultTitleColor: '#525252',
  searchResultCounterColor: '#000',
  filterTagBackgroundColor: '#fff',
  hotelCardTitleColor: 'rgb(0, 0, 0)',
  hotelCardIconColor: 'rgb(0, 0, 0)',
  reviewTitleTextColor: 'rgba(242, 209, 125, 1)',
  reviewCountTextColor: '#323232',

  priceGuaranteeBorderRadius: '30px',
  priceGuaranteeTextColor: 'rgb(0, 0, 0)',
  priceGuaranteeBgColor: '#DEE1E2',

  priceGuaranteeRoomBorderRadius: '30px',
  priceGuaranteeRoomTextColor: 'rgb(0, 0, 0)',
  priceGuaranteeRoomBgColor: '#DEE1E2',
  priceGuaranteeRoomBorder: 'none',

  ratingNumberTextColor: '#FFF',

  discountBorder: 'none',
  discountBorderRadius: '16px',
  inputAdornmentColor: 'rgba(0, 0, 0, 0.54)',
  skeletonColor: 'rgba(0, 0, 0, 0.11)',

  customFontFamily: '"Inter Tight", sans-serif',
  mainPageTitleFontFamily: '"Inter Tight", sans-serif',
  mainPageTitleWeight: '700',

  mainBackground: 'rgba(244, 246, 245, 1)',
  mainPageSignInButtonColor: 'rgba(255, 255, 255, 1)',
  signInPageButtonTextColor: 'rgba(255, 255, 255, 1)',
  searchButtonColor: 'rgba(91, 29, 238, 1)',
  searchButtonTextColor: 'rgba(245, 246, 245, 1)',
  mainPageBorderColor: 'transparent',

  hotelsSectionTitleColor: '#000',
  hotelSectionBgColor: 'rgba(250, 250, 250, 1)',
  hotelSectionBorderColor: 'rgba(213, 213, 213, 1)',
  hotelSectionBorderBottomColor: 'inherit',
  hotelSectionBorderRadius: '40px',
  hotelSectionMobileBorderRadius: '20px',
  hotelSectionItemBorderColor: 'rgba(229, 231, 235, 1)',
  hotelSectionSubtitleColor: 'rgba(91, 29, 238, 1)',
  hotelSectionItemColor: '#fff',

  experienceSectionBgColor: 'rgba(239, 232, 253, 0.25)',
  experienceSectionBorderColor: 'rgba(204, 185, 250, 1)',
  experienceDescriptionColor: 'rgba(32, 32, 32, 1)',

  savingsSectionDescriptionColor: 'rgba(90, 90, 90, 1)',
  savingsItemBgColor: 'inherit',
  savingsItemBorderColor: 'rgba(213, 213, 213, 1)',
  savingsItemDescriptionColor: 'rgba(90, 90, 90, 1)',

  checkoutWrapperBorderColor: 'rgba(227, 227, 227, 1)',
  checkoutSummaryBorderColor: 'rgba(229, 231, 235, 1)',
  checkoutBackgroundColor: '#fff',
  checkoutTimerLeftBackground: '#fff',
  checkoutSummaryBgColor: '#fff',
  buttonActionColor: '#fff',

  timerColor: '#23232C',
  accountNavListActiveColor: 'rgb(0, 108, 228)',

  hotelCardBgColor: 'rgba(255, 255, 255, 1)',
  hotelCardBoxShadow: '0px 4px 28px 0px rgba(0, 0, 0, 0.08)',
  hotelCardRightRadius: '0',

  roomContainerPadding: '24px',
  roomContainerBackground: '#fff',
  roomsSearchButtonTextColor: 'rgba(255,255,255,0.87)',
  roomsStickyHeaderBgColor: '#FFF',
  roomStickyHeaderTextColor: '#23232C',
  roomViewDetailsButtonColor: 'rgba(255, 255, 255, 1)',
  roomAvailabilityTitle: '#23232C',
  roomLayoutSelectButtonActiveColor: 'rgba(32, 32, 32, 1)',
  roomLayoutSelectButtonColor: 'rgba(32, 32, 32, 1)',
  roomLayoutSelectBorder: 'none',
  roomGridItemBgColor: '#FFF',
  roomCardCheckboxActiveColor: '#000000',
  roomCardCheckboxColor: '#FFF',
  roomListHeaderBgColor: '#fff',
  roomListItemBgColor: '#fff',
  roomListHeaderBgActionColor: 'rgba(91, 29, 238, 1)',
  roomInfoIconColor: 'rgba(91, 29, 238, 1)',

  aboutHotelBgColor: 'rgba(255, 255, 255, 1)',
  aboutHotelBorderRadius: '16px',
  aboutHotelBorder: 'rgba(206, 206, 206, 1)',
  aboutHotelPadding: '36px 40px',

  footerTitleWeight: '500',

  mainBorderRadius: '12px',
  searchPanelRadius: '12px',
  searchPanelMobileRadius: '12px',
  savingsBorderRadius: '12px',
  secondaryBorderRadius: '20px',
  paymentInputBorder: '8px',
  inputBorderRadius: '12px',
  paymentBorderRadius: '12px',
  checkoutSummaryBorderRadius: '3px',

  mainBoxShadow: 'box-shadow: 1px 2px 20px 0px rgba(0, 0, 0, 0.08)',

  greyLightColor: '#EBECF4',
  greyUltraLightColor: '#FAFAFA',
  whiteMainColor: '#FFF',
  blackMainColor: '#23232C',
  blueMainColor: '#2539EB',
  lightBlueColor: '#006ce4',
  blackSecondaryColor: '#3C3C48',
  orangeMainColor: '#FC5110',
  orangeSecondaryColor: '#DD3E02',
  orangeTr: '#FEDCCF',
  orangeAlternativeColor: '#F46311',
  redMainColor: '#FF1B1E',
  redLightColor: '#FFE8E9',
  darkMainColor: '#171723',

  new_orangeSecondaryColor: '#006ce4',
  new_orangeMainColor: '#006ce4',
  new_grayAdditionalColor: '#F8F8F8',
  new_greySecondaryColor: '#E4E4E7',
  new_greyMainColor: '#C4C4C4',
  new_blackAdditionalColor: '#797979',
  new_blackSecondaryColor: '#767E83',
  new_blackMainColor: '#000000',
  new_whiteMainColor: '#FFF',
  new_redMainColor: '#FF1B1E',
} as const;

interface PaymentSecretRequest {
  offerKey: string;
  discount?: string;
}

export type StripePaymentSecretRequest = PaymentSecretRequest;

export interface StripePaymentSecretResponse {
  clientSecret: string;
}

export enum StripePaymentMethodType {
  StripeCard = 'StripeCard',
}

export enum ReepayPaymentMethodType {
  FlatPayCard = 'FlatPayCard',
}

export type TPaymentMethodTypes =
  | StripePaymentMethodType
  | ReepayPaymentMethodType;

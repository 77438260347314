import styled from 'styled-components/macro';

import CPanel, {
  HeaderWrapper,
  HeaderTitle,
  Container,
} from 'src/components/shared/CPanel';
import { media } from 'src/modules/mediaQuery';

export const StyledPanel = styled(CPanel)`
  display: flex;
  margin: 0 !important;

  ${HeaderWrapper} {
    width: 25%;
  }

  ${HeaderTitle} {
    font-size: 18px;
    font-weight: 500;
    color: ${(p) => p.theme.custom.roomAvailabilityTitle};
  }

  ${Container} {
    width: 75%;
  }

  ${media.phone`
    flex-direction: column;

    ${HeaderWrapper} {
      width: 100%;
    }

    ${Container} {
      width: 100%
    }
  `};
`;

import Typography from '@mui/material/Typography';
import { push } from 'connected-react-router';
import { VFC, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import useSWR from 'swr/immutable';

import { L10nLink } from 'src/components/common/Link';
import { NoResultsError as _NoResultsError } from 'src/components/common/NoResultsError';
import { I18n } from 'src/components/I18n';
import { SectionId } from 'src/components/pages/HotelDetails/useSectionsNavigator';
import { CustomButton } from 'src/components/shared/CustomButton';
import { useToggle } from 'src/hooks/useToggle';
import GoogleIcon from 'src/icons/providers/Google';
import TrivagoIcon from 'src/icons/providers/Trivago';
import WegoIcon from 'src/icons/providers/Wego';
import { CheckoutStatuses } from 'src/models/checkout';
import { AffiliateApiSystem } from 'src/models/hotels';
import { apiCall } from 'src/modules/api';
import { media } from 'src/modules/mediaQuery';
import { hotelSelector } from 'src/store/hotel/selectors';
import { useLocalStorage } from 'src/hooks/useLocalStorage';

import { RoomsContainerHeader } from './RoomsContainerHeader';
import { RoomsDisplay } from './types';
import { ListRoom } from './ListRoom';
import { RoomContainer } from './RoomContainer';

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.custom.roomContainerPadding};
  border-radius: ${(p) => p.theme.custom.aboutHotelBorderRadius};
  border: 1px solid ${(p) => p.theme.custom.aboutHotelBorder};
  background: ${(p) => p.theme.custom.roomContainerBackground};
  display: flex;
  flex-direction: column;
`;

const StartNewSearchBtn = styled(CustomButton)`
  display: block;
  margin: 20px auto 0;
  background: ${({ theme }) => theme.custom.mainColor};
  border-radius: ${({ theme }) => theme.custom.mainBorderRadius};
  padding: 12px 24px;

  transition: opacity 0.3s ease;

  &:hover {
    background: ${({ theme }) => theme.custom.mainColor};
    opacity: 0.8;
  }

  &:active {
    background: ${({ theme }) => theme.custom.mainColor};
  }

  &:focus {
    background: ${({ theme }) => theme.custom.mainColor};
  }
`;

const ShowMoreRoomsBtn = styled(CustomButton)`
  color: ${({ theme }) => theme.custom.secondaryButtonTextColor};
  background: ${({ theme }) => theme.custom.secondaryButtonColor};
  width: 100%;
  border-radius: ${({ theme }) => theme.custom.mainBorderRadius};
  padding: 12px 24px;
  border: none;

  &:hover {
    background: ${({ theme }) => theme.custom.secondaryButtonColor};
    color: ${({ theme }) => theme.custom.secondaryButtonTextColor};
  }

  &:active {
    background: ${({ theme }) => theme.custom.secondaryButtonColor};
    color: ${({ theme }) => theme.custom.secondaryButtonTextColor};
  }
  ${media.tabletDesktop`
    align-self: flex-end;
  `}
`;

export const NoResultsError = styled(_NoResultsError)`
  button {
    white-space: normal;
  }
  ${StartNewSearchBtn} {
    color: ${(p) => p.theme.custom.whiteMainColor} !important;
    padding: 15px;
    height: unset;
  }
`;

const DEFAULT_ROOMS_COUNT = 2;

const StyledProviderContainer = styled.div`
  margin-left: 5px;
  display: flex;
`;

const getProviderIcon = (provider: AffiliateApiSystem) => {
  switch (provider) {
    case AffiliateApiSystem.Google:
      return <GoogleIcon />;

    case AffiliateApiSystem.Trivago:
      return <TrivagoIcon />;

    case AffiliateApiSystem.Wego:
      return <WegoIcon />;

    default:
      return null;
  }
};

interface HotelRoomsProps {
  handleNavigateToSearchForm: () => void;
  scrollToSection: (sectionId: SectionId) => void;
  sectionRef?: (el: HTMLDivElement | null) => void;
  className?: string;
  countOfVisibleItems?: number;
  bookBtnsLoading?: boolean;
  searchId: number;
  es: string;
  scrollFn: () => void;
}

const HotelRooms: VFC<HotelRoomsProps> = memo(
  ({
    countOfVisibleItems = DEFAULT_ROOMS_COUNT,
    handleNavigateToSearchForm,
    bookBtnsLoading = false,
    scrollToSection,
    sectionRef,
    searchId,
    es,
    scrollFn,
  }) => {
    const dispatch = useDispatch();

    const {
      hotelId,
      info,
      error,
      isFinished,
      hasImages,
      totalNights,
      rooms,
      guaranteedOfferRoom,
      pricePresentation,
    } = useSelector(hotelSelector);

    const [collapsed, toggleCollapse] = useToggle(true);

    const [display, setDisplay] = useLocalStorage<RoomsDisplay>(
      'roomsFilterDisplay',
      (v) => (v ? Number(v) : RoomsDisplay.Grid),
      String(RoomsDisplay.Grid)
    );

    const toggleFn = () => {
      if (!collapsed) {
        scrollToSection('rooms');
      }

      toggleCollapse();
    };

    const { data: marketingData } = useSWR<
      NonNullable<typeof guaranteedOfferRoom>
    >(
      !error &&
        isFinished &&
        guaranteedOfferRoom?.status === CheckoutStatuses.Pending &&
        searchId !== undefined
        ? `/hotel/${hotelId}/room/guaranteed?SearchRequestId=${searchId}&PackageId=${guaranteedOfferRoom.packageId}&es=${es}`
        : null,
      (url) =>
        apiCall(
          url,
          {},
          { path: '/hotel/{hotelId}/room/guaranteed', method: 'GET' }
        ),
      {
        shouldRetryOnError: (err) => err?.status === 524,
        errorRetryCount: 2,
        errorRetryInterval: 1,
      }
    );

    const guaranteed: typeof guaranteedOfferRoom = marketingData
      ? marketingData.status !== CheckoutStatuses.SoldOutRoom
        ? marketingData
        : {
            ...(guaranteedOfferRoom as any),
            status: CheckoutStatuses.SoldOutRoom,
          }
      : guaranteedOfferRoom;

    let _rooms = rooms;

    if (guaranteed) {
      const arr: typeof _rooms = [];

      for (let i = 0; i < _rooms.length; i++) {
        const item = _rooms[i];

        const groups = item.groups.filter(
          (item) => item.id !== guaranteed.packageId
        );

        if (groups.length) {
          arr.push({ ...item, groups });
        }
      }

      _rooms = arr;
    }

    const sliceCount = display === RoomsDisplay.Grid ? 4 : countOfVisibleItems;
    const roomsToRender = collapsed ? _rooms.slice(0, sliceCount) : _rooms;

    const roomsCount = _rooms.length;

    const noRooms = roomsCount === 0;

    const moreLess = collapsed
      ? 'HOTEL_DETAILS_PAGE.ROOMS.SHOW_MORE'
      : 'HOTEL_DETAILS_PAGE.ROOMS.SHOW_LESS';

    const tradable = info ? info.tradable : true;
    const showLoader = noRooms && !isFinished && !error;
    const noFreeRooms = noRooms && isFinished && tradable;
    const showRooms = !noRooms && tradable;
    const showMoreLessButton = roomsCount > countOfVisibleItems && showRooms;

    return (
      <Wrapper ref={sectionRef} data-id="rooms">
        <RoomsContainerHeader
          showCount={roomsToRender.length}
          allCount={rooms.length}
          display={display}
          onDisplayChange={(value) => setDisplay(value)}
        />
        {!error && guaranteed && (
          <ListRoom
            packages={[
              {
                id: guaranteed.packageId,
                price: guaranteed,
                isRefundable: guaranteed.isRefundable,
                boarding: guaranteed.boarding,
                isCheapest: false,
              },
            ]}
            priceChangePercent={guaranteed.priceChangePercent}
            name={guaranteed.name}
            showImages
            images={guaranteed.images}
            facilities={guaranteed.facilities}
            marketingRoom
            bookBtnsLoading={bookBtnsLoading}
            pricePresentation={pricePresentation}
            totalNights={totalNights}
            status={guaranteed.status}
            extras={
              <Typography
                variant="body3"
                color="white"
                display="flex"
                alignItems="center"
              >
                {guaranteed.source ? (
                  <I18n
                    id="HOTEL_DETAILS_PAGE.ROOMS.OFFER_FROM"
                    values={{
                      provider: (
                        <StyledProviderContainer>
                          {getProviderIcon(guaranteed.source)}
                        </StyledProviderContainer>
                      ),
                    }}
                  />
                ) : (
                  <I18n id="HOTEL_DETAILS_PAGE.ROOMS.GUARANTEED_OFFER" />
                )}
              </Typography>
            }
          />
        )}

        {!error && noFreeRooms && !guaranteed && (
          <NoResultsError data-test-id="HOTEL_DETAILS_PAGE.ROOMS.NO_FREE_ROOMS">
            <I18n
              id="HOTEL_DETAILS_PAGE.ROOMS.NO_ROOMS"
              values={{
                button: (
                  <CustomButton
                    btnType="link"
                    onClick={handleNavigateToSearchForm}
                    data-test-id="HOTEL_DETAILS_PAGE.ROOMS.CHANGE_PARAMS"
                  >
                    &nbsp;
                    <I18n id="HOTEL_DETAILS_PAGE.ROOMS.CHANGE_PARAMS" />
                  </CustomButton>
                ),
              }}
            />
            <StartNewSearchBtn
              btnType="primary"
              onClick={() => {
                dispatch(push(`/search?q=${info?.city}, ${info?.country}`));
              }}
              data-test-id="HOTEL_DETAILS_PAGE.ROOMS.BACK_TO_SEARCH"
            >
              <I18n id="HOTEL_DETAILS_PAGE.ROOMS.BACK_TO_SEARCH" />
            </StartNewSearchBtn>
          </NoResultsError>
        )}

        {error &&
          (((error.errors as string[]) ?? []).includes('expired_dates') ? (
            <NoResultsError>
              <I18n id="HOTEL_DETAILS_PAGE.ROOMS.ERROR_MSG_PAST_DATE" />
            </NoResultsError>
          ) : (
            <NoResultsError>
              <I18n id="HOTEL_DETAILS_PAGE.ROOMS.ERROR_MSG" />
            </NoResultsError>
          ))}

        {!tradable && (
          <NoResultsError>
            <I18n
              id="HOTEL_DETAILS_PAGE.ROOMS.NOT_TRADABLE"
              values={{
                a: (msg: string) => (
                  <L10nLink to="/search" color="rgb(4, 141, 59)">
                    {msg}
                  </L10nLink>
                ),
              }}
            />
          </NoResultsError>
        )}

        <RoomContainer
          rooms={roomsToRender}
          loading={showLoader}
          showImg={hasImages}
          guaranteedOfferRoom={guaranteedOfferRoom}
          bookBtnsLoading={bookBtnsLoading}
          pricePresentation={pricePresentation}
          totalNights={totalNights}
          display={display}
          scrollFn={scrollFn}
        />

        {showMoreLessButton && (
          <ShowMoreRoomsBtn btnType="secondary" onClick={toggleFn}>
            <I18n id={moreLess} />
          </ShowMoreRoomsBtn>
        )}
      </Wrapper>
    );
  }
);

export default HotelRooms;

import buttonClasses from '@mui/material/Button/buttonClasses';
import createPalette, {
  PaletteColor,
  PaletteColorOptions,
} from '@mui/material/styles/createPalette';
import createSpacing from '@mui/system/createTheme/createSpacing';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import createTheme from '@mui/material/styles/createTheme';
import createTypography from '@mui/material/styles/createTypography';
import { CSSObject } from '@mui/system';
import { TypographyProps } from '@mui/material/Typography';
import createTransitions from '@mui/material/styles/createTransitions';
import { sliderClasses } from '@mui/material/Slider';
import generateUtilityClass from '@mui/base/generateUtilityClass';
import { collapseClasses } from '@mui/material/Collapse';
import checkboxClasses from '@mui/material/Checkbox/checkboxClasses';

import ArrowDown from 'src/icons/ArrowDown';

import { mainTheme, CustomTheme, currentTheme } from './mainTheme';

type CustomShadows = {
  widget: string;
  text: string;
};

declare module '@mui/material/styles' {
  interface Theme {
    custom: CustomTheme;
    customShadows: CustomShadows;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom: CustomTheme;
    customShadows: CustomShadows;
  }

  interface Palette {
    buttonSecondary: PaletteColor;
    buttonFilter: PaletteColor;
    focusSecondary: PaletteColor;
  }
  interface PaletteOptions {
    buttonSecondary: PaletteColorOptions;
    buttonFilter: PaletteColorOptions;
    focusSecondary: PaletteColorOptions;
  }

  interface TypographyVariants {
    buttonLarge: CSSObject;
    label1: CSSObject;
    label2: CSSObject;
    body3: CSSObject;
    body4: CSSObject;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    buttonLarge: CSSObject;
    label1: CSSObject;
    label2: CSSObject;
    body3: CSSObject;
    body4: CSSObject;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h6: false;
    subtitle1: false;
    subtitle2: false;
    caption: false;

    buttonLarge: true;
    label1: true;
    label2: true;
    body3: true;
    body4: true;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    buttonSecondary: true;
    buttonFilter: true;
    focusSecondary: true;
  }
}

declare module '@mui/material/InputBase' {
  interface InputBasePropsColorOverrides {
    buttonSecondary: true;
    buttonFilter: true;
    focusSecondary: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    inline: true;
  }
  interface ButtonPropsColorOverrides {
    buttonSecondary: true;
    buttonFilter: true;
    focusSecondary: true;
  }
}

export const WIDGET_SHADOW_BLUR_RADIUS = 57;

export const WIDGET_SHADOW_Y_OFFSET = -3;

export const POINTER_FINE = '@media (pointer: fine)';

const muiTheme = (() => {
  const colorWhite = '#FFF';
  const colorBlack = '#000000';  

  const outlineSecondaryButtonBorder = '2px solid';

  // const tabPadding = 15;

  const getButtonPadding = (
    value: number
  ): Pick<CSSObject, 'padding' | 'margin' | 'width'> => ({
    padding: value,
    margin: -value,
    width: `calc(100% + ${2 * value}px)`,
  });

  const palette = createPalette({
    mode: 'light',
    background: { default: colorWhite, paper: colorWhite },
    primary: {
      main: currentTheme.muiPrimaryColor,
      light: '#006CE4',
    },
    buttonSecondary: {
      light: 'rgba(82, 82, 82, 1)',
      main: colorBlack,
      contrastText: colorWhite,
    },
    buttonFilter: {
      main: '#F8F8F8',
      dark: '#EBEBEB',
    },
    secondary: {
      main: currentTheme.muiSecondaryActionColor,
    },
    warning: {
      main: '#FFF7D9',
    },
    focusSecondary: { main: colorBlack },
    common: {
      black: colorBlack,
      white: colorWhite,
    },
    text: {
      primary: colorBlack,
      secondary: '#767E83',
      disabled: '#C4C4C4',
    },
    divider: '#E4E4E7',
    action: {
      disabledOpacity: 1,
      disabled: '#C4C4C4',
      disabledBackground: 'rgb(224, 224, 224)',
    },
  });

  const spacing = createSpacing((v: number) => v * 4);

  const breakpoints = createBreakpoints({});

  const downMd = breakpoints.down('md');

  const typography = createTypography(palette, {
    fontFamily: currentTheme.customFontFamily,

    h6: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
    caption: undefined,

    h1: {
      fontSize: '44px',
      lineHeight: 1.4,
      fontWeight: 600,
      [downMd]: { fontSize: '34px' },
    },
    h2: {
      fontSize: '38px',
      lineHeight: 1.4,
      fontWeight: 600,
      [downMd]: { fontSize: '30px' },
    },
    h3: {
      fontSize: '24px',
      lineHeight: 1.4,
      fontWeight: 600,
      [downMd]: { fontSize: '24px' },
    },
    h4: {
      fontSize: '18px',
      lineHeight: 1.4,
      fontWeight: 600,
      [downMd]: { fontSize: '18px' },
    },
    h5: {
      fontSize: '16px',
      lineHeight: 1.4,
      fontWeight: 600,
      [downMd]: { fontSize: '16px' },
    },
    overline: {
      fontSize: '12px',
      lineHeight: 1.4,
      fontWeight: 600,
      [downMd]: { fontSize: '12px' },
    },
    button: {
      fontSize: '15px',
      lineHeight: 1.4,
      fontWeight: 600,
      textTransform: undefined,
      [downMd]: { fontSize: '15px' },
    },
    buttonLarge: {
      fontSize: '19px',
      lineHeight: 1.4,
      fontWeight: 600,
      textTransform: undefined,
      [downMd]: { fontSize: '19px' },
    },
    label1: {
      fontSize: '17px',
      lineHeight: 1.4,
      fontWeight: 600,
      [downMd]: { fontSize: '17px' },
    },
    label2: {
      fontSize: '10px',
      lineHeight: 1.4,
      fontWeight: 600,
      [downMd]: { fontSize: '10px' },
    },
    body1: {
      fontSize: '22px',
      lineHeight: 1.4,
      fontWeight: 'normal',
      [downMd]: { fontSize: '18px' },
    },
    body2: {
      fontSize: '16px',
      lineHeight: 1.4,
      fontWeight: 'normal',
      [downMd]: { fontSize: '16px' },
    },
    body3: {
      fontSize: '14px',
      lineHeight: 1.4,
      fontWeight: 500,
      [downMd]: { fontSize: '14px' },
    },
    body4: {
      fontSize: '14px',
      lineHeight: 1.4,
      fontWeight: 400,
      [downMd]: { fontSize: '14px' },
    },
  });

  //@ts-expect-error
  const { create: createTransition } = createTransitions({});

  const thumbSize = 20;

  const theme = createTheme({
    spacing,

    palette,

    breakpoints,

    typography,

    customShadows: {
      widget: `0px ${WIDGET_SHADOW_Y_OFFSET}px ${WIDGET_SHADOW_BLUR_RADIUS}px rgba(0, 0, 0, 0.1)`,
      text: '0px 4px 17px rgba(0, 0, 0, 0.27)',
    },

    shape: {
      borderRadius: 16,
    },

    components: {
      MuiStack: {
        variants: [
          {
            props: {},
            style: ({ ownerState }: any) => {
              const margin = spacing(ownerState.spacing || 0);

              return {
                [`.${collapseClasses.root}:first-child`]: {
                  [`.${collapseClasses.wrapperInner}`]: {
                    paddingBottom: margin,
                  },
                  '& + *': {
                    marginTop: 0,
                  },
                },
                [`.${collapseClasses.root}:not(:first-child)`]: {
                  marginTop: 0,
                  [`.${collapseClasses.wrapperInner}`]: {
                    paddingTop: margin,
                  },
                },
              };
            },
          },
        ],
      },
      MuiSkeleton: {
        styleOverrides: {
          text: {
            lineHeight: 'inherit',
          },
        },
        defaultProps: { animation: 'wave' },
      },
      MuiAlert: {
        styleOverrides: {
          root: ({ ownerState, theme }) => {
            const background =
              theme.palette[ownerState.severity || 'success'].main;

            return {
              color: theme.palette.getContrastText(background),
              background,
              borderRadius: '12px',
            };
          },
          icon: { opacity: 1 },
        },
      },
      MuiSlider: {
        styleOverrides: {
          thumb: {
            backgroundColor: palette.background.default,
            border: 'currentColor 2.5px solid',
            height: `${thumbSize}px`,
            width: `${thumbSize}px`,
            pointerEvents: 'auto !important' as any,
            boxShadow: 'none !important',
            cursor: 'grab',
            [`&.${generateUtilityClass('', 'active')}`]: {
              cursor: 'grabbing',
            },
          },
          root: {
            width: `calc(100% - ${thumbSize}px)`,
            margin: `0 ${thumbSize / 2}px`,
            [`&.${sliderClasses.disabled} .${sliderClasses.thumb}`]: {
              cursor: 'default',
            },
            height: '3px',
          },
          rail: {
            width: `calc(100% + ${thumbSize}px)`,
            left: `-${thumbSize / 2}px`,
            backgroundColor: palette.divider,
            opacity: 1,
          },
          track: {
            width: `calc(100% + ${thumbSize}px)`,
            marginLeft: `-${thumbSize / 2}px`,
          },
        },
      },
      MuiDivider: {
        styleOverrides: { wrapper: { paddingLeft: 10, paddingRight: 10 } },
      },
      MuiTextField: {
        defaultProps: { InputLabelProps: { shrink: true } },
        styleOverrides: { root: { input: typography.body3 } },
      },
      MuiCheckbox: {
        defaultProps: { disableRipple: true },
        styleOverrides: {
          root: { padding: 0 },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: ({ ownerState: { color, disabled }, theme }) => ({
            margin: 0,
            ...(disabled ||
            (color &&
              !(
                ['primary', 'secondary'] as (keyof typeof theme.palette)[]
              ).includes(color as any))
              ? {}
              : {
                  [POINTER_FINE]: {
                    [`&:hover .${checkboxClasses.root}`]: {
                      color: (
                        theme.palette[
                          (color || 'primary') as keyof typeof theme.palette
                        ] as PaletteColor
                      ).main,
                    },
                  },
                }),
          }),
          label: { marginLeft: spacing(3) },
        },
      },
      MuiSelect: {
        defaultProps: { IconComponent: ArrowDown },
        styleOverrides: {
          icon: { top: 'auto', right: 12 },
          select: { borderRadius: 6 },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          icon: { top: 'auto', right: 12 },
          select: { borderRadius: 6 },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: { borderRadius: 6 },
          input: {
            padding: 12,
            borderRadius: 'inherit',
          },
          notchedOutline: {
            legend: {
              width: 0,
            },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            background: 'rgba(0,0,0,0.35)',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: { ...(typography.overline as any), transform: 'none' },
          outlined: { position: 'static', marginBottom: 6 },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            'label + &': {
              marginTop: 14,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: typography.body3,
          input: { height: 'auto' },
        },
        defaultProps: { color: 'buttonSecondary' },
      },
      // MuiTabs: {
      //   defaultProps: {
      //     textColor: 'secondary',
      //     TabIndicatorProps: { hidden: true },
      //   },
      //   styleOverrides: {
      //     root: {
      //       margin: -tabPadding,
      //       width: `calc(100% + ${tabPadding}px * 2)`,
      //     },
      //   },
      // },
      // MuiTab: {
      //   styleOverrides: {
      //     root: {
      //       padding: tabPadding,
      //       borderRadius: 12,
      //       textTransform: 'none',
      //       fontWeight: 'normal',
      //     },
      //   },
      // },
      MuiModal: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiGrid: {
        defaultProps: {
          columns: 16,
        },
      },
      MuiIconButton: {
        defaultProps: { color: 'inherit' },
        styleOverrides: {
          root: {
            '&:before': {
              content: '""',
              display: 'block',
              paddingBottom: '100%',
            },
            height: '100%',
            ...getButtonPadding(10),
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            minWidth: 0,
            padding: 15,
            textTransform: 'inherit',
          },
          colorInherit: {
            background: palette.common.white,
          },
          outlined: {
            borderRadius: 16,
            [POINTER_FINE]: {
              '&:hover': {
                background: 'none',
                border: outlineSecondaryButtonBorder,
              },
            },
            border: outlineSecondaryButtonBorder,
          },
          contained: {
            borderRadius: 20,
          },
          text: {
            ...getButtonPadding(7),
            borderRadius: 10,
            fontWeight: 500,
            [`.${buttonClasses.startIcon}`]: { marginRight: 5 },
          },
          endIcon: {
            marginLeft: 6,
            '& > *:nth-of-type(1)': { fontSize: 'inherit' },
          },
          sizeSmall: typography.body3,
          sizeLarge: typography.buttonLarge,
        },
        defaultProps: {
          disableElevation: true,
        },
        variants: [
          {
            props: { variant: 'text' },
            style: {
              [`& .${buttonClasses.startIcon}`]: { marginRight: spacing(3) },
            },
          },
          {
            props: {
              variant: 'contained',
              color: 'primary',
            },
            style: {
              [POINTER_FINE]: {
                '&:hover': { background: palette.primary.light },
              },
            },
          },
          {
            props: {
              variant: 'contained',
              color: 'buttonSecondary',
            },
            style: {
              [POINTER_FINE]: {
                '&:hover': { background: palette.buttonSecondary.light },
              },
            },
          },
          {
            props: {
              variant: 'outlined',
              color: 'buttonSecondary',
            },
            style: {
              [POINTER_FINE]: {
                '&:hover': {
                  borderColor: palette.buttonSecondary.light,
                  color: palette.buttonSecondary.light,
                },
              },
              borderColor: palette.buttonSecondary.main,
              padding: '10px 15px',
            },
          },
          {
            props: { variant: 'inline' },
            style: {
              justifyContent: 'left',
              fontWeight: 'normal',
              ...getButtonPadding(12),
              borderRadius: 12,
              [POINTER_FINE]: {
                '&:hover': { background: palette.divider },
              },
              [`.${buttonClasses.startIcon}`]: {
                marginRight: 15,
                marginLeft: 0,
                padding: 5,
                background: 'rgba(117, 117, 136, .1)',
                borderRadius: 4,
              },
            },
          },
          {
            props: { selected: true, variant: 'inline' } as any,
            style: {
              background: palette.divider,
              pointerEvents: 'none',
              [`.${buttonClasses.endIcon}`]: { marginLeft: 'auto' },
            },
          },
        ],
      },
      MuiLink: {
        defaultProps: {
          underline: 'none',
          variant: 'body3',
          color: 'text.primary',
        },
        styleOverrides: {
          root: { transition: createTransition('color') },
        },
        variants: [
          {
            props: { color: 'text.primary' },
            style: {
              [POINTER_FINE]: {
                '&:hover': { color: palette.primary.light },
              },
            },
          },
          {
            props: { color: 'secondary' },
            style: {
              [POINTER_FINE]: {
                '&:hover': { color: palette.secondary.light },
              },
            },
          },
        ],
      },
    },

    custom: mainTheme,
  });

  return theme;
})();

export default muiTheme;

export type MuiTheme = typeof muiTheme;

export type TypographyVariant = Exclude<
  TypographyProps['variant'],
  'inherit' | undefined
>;

import styled, { useTheme } from "styled-components";

import useMatchMedia from "src/hooks/useMatchMedia";

export const FooterContainer = styled.div(() => {
  const theme = useTheme();
  const isTablet = useMatchMedia(theme.breakpoints.down('md'));

  return `
    background: ${theme.custom.footerBgColor};
    border-top: 1px solid ${theme.custom.footerTopBorderColor};
    padding: ${isTablet ? '48px 16px' : '64px 80px'};
    width: 100%;
    color: ${theme.custom.footerTextColor};
    font-weight: 300;
  `
})

export const FooterWrapper = styled.div`
  width: 100%;
  max-width: 1376px;
  margin: 0 auto;
`

export const FooterTopSection = styled.div(() => {
  const theme = useTheme();
  const isTablet = useMatchMedia(theme.breakpoints.down('md'));

  return `
    display: flex;
    align-items: center;
    justify-content: ${isTablet ? 'center' : 'space-between'};
    margin-bottom: ${isTablet ? '16px' : '32px'};
    text-align: ${isTablet ? 'center' : 'inherit'};
  `
})

export const FooterLinks = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px 0;
  border-top: 1px solid ${({ theme }) => theme.custom.footerBorderColor};
`

export const FooterRights = styled.div(() => {
  const theme = useTheme();
  const isTablet = useMatchMedia(theme.breakpoints.down('md'));

  return `
    font-size: 16px;
    line-height: 24px;
    padding-top: 32px;
    border-top: 1px solid rgba(246, 246, 246, 0.2);
    text-align: ${isTablet ? 'center' : 'inherit'};
  `
})

export const FooterTopSectionTitle = styled.div`
  font-size: 16px;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const FooterTopSectionPayments = styled.div(() => {
  const theme = useTheme();
  const isTablet = useMatchMedia(theme.breakpoints.down('md'));

  return `
    display: flex;
    flex-direction: ${isTablet ? 'column' : 'row'};
    align-items: center;
    gap: 16px;
  `
})

export const FooterTopSectionPaymentsTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const FooterTopSectionPaymentsList = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const FooterLinksSection = styled.div(() => {
  const theme = useTheme();
  const isTablet = useMatchMedia(theme.breakpoints.down('md'));

  return `
    display: flex;
    flex-direction: column;
    gap: ${isTablet ? '16px' : '32px'};
  `
})

export const FooterLinksSectionTitle = styled.div`
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.custom.footerTitleWeight};
`

export const FooterLinksSectionItems = styled.div(() => {
  const theme = useTheme();
  const isTablet = useMatchMedia(theme.breakpoints.down('md'));

  return `
    display: flex;
    flex-direction: column;
    gap: ${isTablet ? '4px' : '16px'};
  `
})

export const FooterLinksSectionItem = styled.a`
  font-size: 16px;
  line-height: 24px;

  &:hover {
    color: inherit;
  }

  &:visited {
    color: inherit;
  }

  &:active {
    color: inherit;
  }

  &:focus {
    color: inherit;
  }
`

export const AppButton = styled.div`
  background: black;
  border: 1px solid #A6A6A6;
  border-radius: 4px;
  display: flex;
  color: white;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`

export const AppButtonInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const AppButtonTitle = styled.span`
  font-size: 9px;
`

export const AppButtonStoreTitle = styled.span`
  font-size: 16px;
`

export const FooterAppActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const FooterLinksNetworkItems = styled.div`
  display: flex;
  gap: 16px;
`

export const FooterMobileLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  border-top: 1px solid rgba(246, 246, 246, 0.2);
`

export const FooterMobileLinksContainer = styled.div(() => {
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('sm'));

  return `
    display: flex;
    flex-direction: ${isMobile ? 'column' : 'row'};
    justify-content: space-between;
    margin: 16px 0;
    gap: ${isMobile ? '32px' : '0'};
  `
})

export const FooterAppLinksSection = styled.div`
  display: flex;
  justify-content: center;
  gap: 52px;
`

export const FooterAppMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const FooterIconWrapper = styled.div`
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.custom.footerIconBorder};
  width: 40px;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

import { FC } from 'react';

import { HotelInfoSelectorResult } from 'src/store/hotel/selectors';

import { FacilitiesList } from './FacilitiesList';
import { Wrapper } from './styles';

interface FacilitiesProps {
  data: NonNullable<HotelInfoSelectorResult>['facilityGroups'];
  scrollFn: () => void;
}

export const Facilities: FC<FacilitiesProps> = ({ data, scrollFn }) => (
  <Wrapper>
    <FacilitiesList items={data} scrollFn={scrollFn} />
  </Wrapper>
);

import ArrowDown from 'src/icons/ArrowDown';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: calc(100% - 32px);
  margin: auto;
`;

export const ArrowWrapper = styled.div<{ transparent: boolean }>`
  cursor: pointer;
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;

  ${(p) =>
    p.transparent
      ? `
      background: color-mix(in srgb, ${p.theme.custom.blackMainColor}, transparent 50%);
      color: ${p.theme.custom.whiteMainColor};
      backdrop-filter: blur(8px);
    `
      : `
      background: ${p.theme.custom.whiteMainColor};
      box-shadow: 0 2px 8px 0 ${p.theme.custom.blackMainColor};
    `}

  top: 50%;
`;

export const ArrowLeftWrapper = styled(ArrowWrapper)<{ padding: number }>`
  left: ${(p) => p.padding}px;
  transform: translate(0, -50%);
`;

export const ArrowRightWrapper = styled(ArrowWrapper)<{ padding: number }>`
  right: ${(p) => p.padding}px;
  transform: translate(0, -50%);
`;

export const ArrowRight = styled(ArrowDown)`
  height: 32px;

  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
`;

export const ArrowLeft = styled(ArrowDown)`
  height: 32px;

  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
`;

interface InfoIconProps {
  className?: string;
}
export const InfoIcon = ({ className }: InfoIconProps) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_10877_36830)">
      <path
        d="M8.00065 14.6673C11.6825 14.6673 14.6673 11.6825 14.6673 8.00065C14.6673 4.31875 11.6825 1.33398 8.00065 1.33398C4.31875 1.33398 1.33398 4.31875 1.33398 8.00065C1.33398 11.6825 4.31875 14.6673 8.00065 14.6673Z"
        stroke-width="1.13333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.00098 10.6673V8.00065M8.00098 5.33398H8.00764"
        stroke-width="1.13333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10877_36830">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

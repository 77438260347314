import { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { SWRConfig, SWRConfiguration } from 'swr';
import { QueryParamOptions, QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { parse, stringify } from 'query-string';
import isEqual from 'lodash/isEqual';

import { getUser } from 'src/store/user/actions';
import { initApp } from 'src/store/app/actions';
import config from 'src/config';
import { ConnectedIntl } from 'src/containers/ConnectedIntl';
import { GlobalStyle } from 'src/components/common/GlobalStyle';
import HeaderPanel from 'src/components/Panels/HeaderPanel';
import { Footer } from 'src/components/Panels/footer';
import { DeviceProvider } from 'src/modules/DeviceProvider';
import { ReduxState } from 'src/store/reducers';
import { Router } from 'src/router';
import { apiCall } from 'src/modules/api';
import { GOOGLE_API_KEY } from 'src/constants';

import { CookiesBanner } from '../Banners/CookiesBanner';
import { MaintenancePage } from '../pages/maintenance-page';
import { ServerErrorPage } from '../common/ServerErrorPage';
import { I18n } from '../I18n';

import { ByBitHeader } from './bybit-header/bybit-header';

const AppWrap = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 100vh;
  background: ${(p) => p.theme.custom.mainBackground};
`;

const MainWrap = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  flex-grow: 1;
  min-height: 60vh;
  background: ${(p) => p.theme.custom.mainBackground};
`;

interface AppProps {
  intl: ReduxState['intl'];
  isSettingsFetched: boolean;
  location: string;
  isAuthenticated: boolean;
  initApp: () => void;
  getUser: () => void;
}

const swrConfig: Partial<SWRConfiguration> = {
  shouldRetryOnError: false,
  revalidateOnFocus: false,
  revalidateIfStale: false,
  compare: isEqual,
};

const opt: QueryParamOptions = {
  searchStringToObject: (q) => parse(q, { arrayFormat: 'comma', sort: false }),
  objectToSearchString: (o) =>
    stringify(o, { arrayFormat: 'comma', sort: false }),
};

interface AppState {
  isMaintenanceMode?: boolean;
}

class App extends Component<AppProps, AppState, any> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      isMaintenanceMode: false,
    }
  }
  componentDidMount() {
    const { isAuthenticated } = this.props;

    const checkHealth = async () => {
      try {
        await apiCall(
          '/healthcheck',
          { method: 'GET' }
        );
      } catch (e) {
        console.error(e)
        this.setState(() => ({
          isMaintenanceMode: true,
        }));
      }
    }
  
    this.props.initApp();

    void checkHealth();

    if (isAuthenticated) {
      this.props.getUser();

    }
  }

  componentDidUpdate(prevProps: any) {
    const { location } = this.props;

    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { intl, isSettingsFetched } = this.props;
    const { currentLocale } = intl;
    const messages = intl.messages[currentLocale];
    const isAuthPages = this.props.location.includes('auth');

    if (this.state.isMaintenanceMode) {
      return <MaintenancePage />
    }

    if (!messages || !isSettingsFetched) {
      return null;
    }

    return (
      <>
        <GlobalStyle />
        <ConnectedIntl>
          <Helmet
            defer={false}
            encodeSpecialCharacters={true}
            defaultTitle={config.title}
            titleTemplate={`${config.title} | %s`}
            titleAttributes={{ itemprop: 'name', lang: currentLocale }}
          >
            <html lang={currentLocale} />
          </Helmet>
          <AppWrap>
            <SWRConfig value={swrConfig}>
              <DeviceProvider>
                {!isAuthPages ? (
                  <>
                    <ByBitHeader />
                    <HeaderPanel />
                  </>
                ) : null}
               
                {/* <UkraineBanner /> */}
                <MainWrap>
                  <QueryParamProvider
                    adapter={ReactRouter5Adapter}
                    options={opt}
                  >
                    <Router />
                  </QueryParamProvider>
                </MainWrap>
                {!isAuthPages ? (
                  <>
                    <Footer />
                    <CookiesBanner />
                  </>
                ) : null}
              </DeviceProvider>
            </SWRConfig>
          </AppWrap>
        </ConnectedIntl>
        {/* <MobileAppModal /> */}
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: ReduxState) => ({
  intl: state.intl,
  isSettingsFetched: state.settings.fetched,
  location: state.router.location.pathname,
  isAuthenticated: state.user.isAuthenticated,
});

const mapDispatchToProps = {
  initApp,
  getUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

interface StarIconProps {
  className?: string;
}

export const StarIcon = ({ className }: StarIconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="16"
    viewBox="0 1 15 16"
    fill="none"
  >
    <path
      d="M14.6046 5.91079L9.99142 5.2123L7.86223 0.728186C7.83005 0.659741 7.78003 0.602068 7.71788 0.561727C7.65573 0.521387 7.58395 0.5 7.51069 0.5C7.43743 0.5 7.36564 0.521387 7.30349 0.561727C7.24134 0.602068 7.19133 0.659741 7.15914 0.728186L5.01669 5.2123L0.396827 5.90216C0.311308 5.91495 0.230939 5.95238 0.164824 6.01023C0.0987078 6.06807 0.049487 6.14402 0.0227357 6.22946C-0.00401566 6.3149 -0.00722846 6.40643 0.0134611 6.49367C0.0341506 6.58092 0.0779159 6.66039 0.1398 6.7231L3.48945 10.119L2.69515 14.9859C2.68218 15.0669 2.69118 15.1501 2.72113 15.226C2.75108 15.302 2.8008 15.3677 2.8647 15.4159C2.92861 15.4641 3.00416 15.4928 3.08289 15.4988C3.16161 15.5048 3.24039 15.4879 3.31036 15.4499L7.50405 13.1526L11.706 15.4499C11.7757 15.4855 11.8535 15.5006 11.9309 15.4935C12.0083 15.4864 12.0823 15.4573 12.1449 15.4095C12.2075 15.3616 12.2563 15.2968 12.2859 15.2222C12.3155 15.1475 12.3249 15.0658 12.313 14.9859L11.5187 10.119L14.8617 6.73C14.9231 6.66723 14.9664 6.58788 14.9868 6.50089C15.0072 6.41389 15.0039 6.3227 14.9772 6.23758C14.9505 6.15245 14.9014 6.07676 14.8356 6.01903C14.7698 5.9613 14.6898 5.92381 14.6046 5.91079Z"
      fill="#FEAC0E"
    />
  </svg>
);

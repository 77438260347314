import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom"
import styled from "styled-components"

import { userSelector } from "src/store/user/selectors";
import LoyaltyIcon from "src/assets/loyalty/loyalty-icon";
import { ROUTES } from "src/router";

const LoyaltyProgramButton = styled.div(({ variant }: LoyaltyButtonProps) => {
  let styles = `
    border-radius: 20px;
    backdrop-filter: blur(40px);
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    transition: opacity 0.3s ease;
    color: #fff;
    font-size: 16px;
    width: 100%;

    :hover {
      opacity: 0.8;
    };
  `;

  if (variant === 'primary') {
    styles = `
      ${styles}
      border: 1px solid rgba(66, 66, 66, 0.4);
      background: rgba(0, 0, 0, 0.20);
    `
  };

  if (variant === 'secondary') {
    styles = `
      ${styles}
      border: 1px solid #E3E3E7;
      background: #FFF;
      color: #2D2D2D;
    `
  }

  return styles;
})

interface LoyaltyButtonProps {
  variant?: 'primary' | 'secondary'
}

export const LoyaltyButton = ({ variant = 'primary' }: LoyaltyButtonProps) => {
  const history = useHistory();
  const { isAuthenticated } = useSelector(userSelector);

  const handleClick = () => {
    if (!isAuthenticated) {
      history.push(ROUTES.SIGN_IN);

      return;
    }

    history.push('/account/loyalty')
  }

  return (
    <LoyaltyProgramButton onClick={handleClick} variant={variant}>
      <LoyaltyIcon /> Book 10 nights, get 1 free
    </LoyaltyProgramButton>
  )
}

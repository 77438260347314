import { useEffect, useState } from 'react';

type SetValueCallbackType<T> = (prevState: T) => T;

export const useLocalStorage = <T>(
  key: string,
  parse: (prevState: string | null) => T,
  defaultValue?: string
): [T, (value: T) => void] => {
  const [value, setValue] = useState<T>(parse(localStorage.getItem(key)));

  useEffect(() => {
    if (!defaultValue) return;

    const item = localStorage.getItem(key);
    if (!item) {
      setLocalStorageItem(parse(item));
    }
  }, []);

  const setLocalStorageItem = (v: T | SetValueCallbackType<T>) => {
    let res;
    if (v instanceof Function) {
      res = v(value);
    } else {
      res = v;
    }

    setValue(res);
    if (res === null) {
      localStorage.removeItem(key);
      return;
    }
    localStorage.setItem(key, String(res));
  };

  return [value, setLocalStorageItem];
};

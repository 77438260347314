import styled from 'styled-components/macro';

import { fontSizeMedium } from 'src/modules/fontSizes';

export const ItemWrapper = styled.div`
  margin-top: 5px;
`;

export const Icon = styled.span`
  position: absolute;
  ${fontSizeMedium};
  color: ${({ theme }) => theme.custom.roomInfoIconColor};
  padding-top: 6px;
`;

export const Item = styled.div`
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: ${({ theme }) => theme.custom.blackSecondaryColor};
`;

import { FC } from 'react';

import { I18n } from 'src/components/I18n';
import { HotelInfoSelectorResult } from 'src/store/hotel/selectors';

import { FacilitiesList } from './FacilitiesList';
import { NoteText, Wrapper } from './styles';

interface FacilitiesProps {
  data: NonNullable<HotelInfoSelectorResult>['facilityGroups'];
}

export const Facilities: FC<FacilitiesProps> = ({ data }) => (
  <>
    <Wrapper>
      <FacilitiesList items={data} />
    </Wrapper>
    <NoteText>
      <I18n
        id="HOTEL_DETAILS_PAGE.FACILITIES.CUSTOMER_NOTE"
        values={{
          contactmaillink: (...chunks: any) => (
            <a href="mailto:support@entravel.com">{chunks}</a>
          ),
        }}
        tagName="span"
      />
    </NoteText>
  </>
);

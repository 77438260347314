import { FC } from 'react';

import { I18n } from 'src/components/I18n';
import { getIconByFacilityId } from 'src/modules/facilities';
import { toI18nKey } from 'src/modules/helpers';
import { HotelInfoSelectorResult } from 'src/store/hotel/selectors';

import { FacilitiesItem } from './FacilitiesItem';
import { InfoBlockWrapper, StyledInfoBlock } from './styles';
import { Icon } from './common';

interface FacilitiesListProps {
  items: NonNullable<HotelInfoSelectorResult>['facilityGroups'];
}

export const FacilitiesList: FC<FacilitiesListProps> = ({ items }) => (
  <>
    {items.map((facilitiesItem) => {
      const iconClass = getIconByFacilityId(facilitiesItem.id);

      return (
        <InfoBlockWrapper key={facilitiesItem.name}>
          <StyledInfoBlock
            title={
              <I18n id={toI18nKey(facilitiesItem.name, 'FACILITY_GROUPS')} />
            }
            iconElement={<Icon className={iconClass} />}
          >
            <FacilitiesItem facilities={facilitiesItem.facilities} />
          </StyledInfoBlock>
        </InfoBlockWrapper>
      );
    })}
  </>
);

import { VFC } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import Skeleton from '@mui/material/Skeleton';

import { I18n } from 'src/components/I18n';
import useMatchMedia from 'src/hooks/useMatchMedia';

import { Label } from './CommonComponents';
import { RoomFacilities } from './RoomFacilities';
import { IRoomFacilitiesModal } from './RoomFacilitiesModal';
import { RoomFacilitiesModalButton } from './RoomFacilitiesModalButton';

const DEFAULT_FACILITIES_COUNT = 4;

const Wrapper = styled.div`
  height: 100%;
`;

const EmptyFacilitiesText = styled.div`
  color: ${(p) => p.theme.custom.grayTextColor};
  font-size: 11px;
  height: 100%;
  display: flex;
`;

export const FacilityInformation: VFC<IRoomFacilitiesModal> = (props) => {
  const { facilities } = props;

  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));

  return (
    <Wrapper>
      {isMobile && (
        <Label>
          <I18n id="HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.FACILITY_INFORMATION" />
        </Label>
      )}
      {facilities ? (
        <>
          {facilities.length ? (
            <RoomFacilities
              facilities={facilities.slice(0, DEFAULT_FACILITIES_COUNT)}
            />
          ) : (
            <EmptyFacilitiesText>
              <I18n id="HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.FACILITIES.CUSTOMER_EMPTY_TEXT" />
            </EmptyFacilitiesText>
          )}
          {facilities.length > DEFAULT_FACILITIES_COUNT && (
            <RoomFacilitiesModalButton {...props} />
          )}
        </>
      ) : (
        <>
          <Skeleton sx={{ bgcolor: (theme) => theme.custom.skeletonColor }} animation="wave" />
          <Skeleton sx={{ bgcolor: (theme) => theme.custom.skeletonColor }} animation="wave" />
          <Skeleton sx={{ bgcolor: (theme) => theme.custom.skeletonColor }} animation="wave" />
          <Skeleton sx={{ bgcolor: (theme) => theme.custom.skeletonColor }} animation="wave" />
          <Skeleton sx={{ bgcolor: (theme) => theme.custom.skeletonColor }} animation="wave" />
        </>
      )}
    </Wrapper>
  );
};

const FooterVisaIcon = () => (
  <svg width="41" height="31" viewBox="0 0 41 31" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.7705 19.0203L17.9524 11.937H19.8429L18.6601 19.0203H16.7705Z" fill="#1434CB"/>
  <path d="M25.49 12.09C25.1155 11.9465 24.5286 11.7925 23.7957 11.7925C21.9277 11.7925 20.6119 12.7532 20.6007 14.13C20.5901 15.1478 21.54 15.7156 22.2571 16.0544C22.993 16.4017 23.2403 16.6231 23.2368 16.9331C23.2321 17.4079 22.6492 17.6247 22.1058 17.6247C21.3492 17.6247 20.9472 17.5174 20.3263 17.253L20.0827 17.1404L19.8174 18.7261C20.2589 18.9238 21.0754 19.0951 21.9232 19.104C23.9104 19.104 25.2005 18.1543 25.2152 16.6839C25.2222 15.8781 24.7186 15.265 23.6279 14.7594C22.9671 14.4317 22.5625 14.2131 22.5668 13.8813C22.5668 13.5869 22.9093 13.272 23.6494 13.272C24.2676 13.2622 24.7154 13.3999 25.0643 13.5434L25.2337 13.6252L25.49 12.09Z" fill="#1434CB"/>
  <path d="M30.3549 11.937H28.8942C28.4416 11.937 28.103 12.0631 27.9042 12.5244L25.0967 19.0157H27.0818C27.0818 19.0157 27.4063 18.1429 27.4798 17.9513C27.6967 17.9513 29.6252 17.9543 29.9008 17.9543C29.9574 18.2023 30.1308 19.0157 30.1308 19.0157H31.885L30.3549 11.937ZM28.0372 16.5108C28.1936 16.1027 28.7905 14.5308 28.7905 14.5308C28.7793 14.5496 28.9457 14.1207 29.0411 13.8547L29.1689 14.4654C29.1689 14.4654 29.5309 16.1562 29.6065 16.5107H28.0372V16.5108Z" fill="#1434CB"/>
  <path d="M8.53977 11.937L8.51562 12.0844C9.26293 12.2691 9.93038 12.5365 10.5147 12.8691L12.1922 19.009L14.1923 19.0067L17.1685 11.937H15.1659L13.3151 16.7673L13.118 15.7856C13.1087 15.7553 13.0987 15.7249 13.0884 15.6945L12.445 12.535C12.3306 12.0865 11.9987 11.9526 11.5881 11.937H8.53977Z" fill="#1434CB"/>
  </svg>
)
          
export default FooterVisaIcon;
          
import { lazy } from 'react';

const CheckoutPage = lazy(() => import('./Checkout'));
const ConfirmationPage = lazy(() => import('./Confirmation'));
const ContactPage = lazy(() => import('./Contact'));
const HomePage = lazy(() => import('./Home'));
const NoMatchPage = lazy(() => import('./NoMatch'));
const NotAuthenticatedPage = lazy(() => import('./NotAuthenticated'));
const SearchPage = lazy(() => import('./Search'));
const HotelDetailsPage = lazy(() => import('./HotelDetails'));
const StaticPage = lazy(() => import('./Static'));
const AboutUsPage = lazy(() => import('./AboutUs'));
const ImpressumPage = lazy(() => import('./Impressum'));
const ThankYouPage = lazy(() => import('./ThankYou'));
const UnsubscribePage = lazy(() => import('./Unsubscribe'));
const FrequentlyAskedQuestionsPage = lazy(
  () => import('./FrequentlyAskedQuestions')
);
const AccountPage = lazy(() => import('./Account'));
const SubscriptionPage = lazy(() => import('./Subscription'));
const AffiliateDashboardPage = lazy(() => import('./affiliate-dashboard'));
const SignInPage = lazy(() => import('./auth-pages/sign-in-page'));
const SignUpPage = lazy(() => import('./auth-pages/sign-up-page'));
const ResetPasswordPage = lazy(() => import('./auth-pages/reset-password-page'));
const RecoverPasswordPage = lazy(() => import('./RecoverPassword'));

export {
  CheckoutPage,
  ConfirmationPage,
  ContactPage,
  HomePage,
  NoMatchPage,
  NotAuthenticatedPage,
  SearchPage,
  HotelDetailsPage,
  StaticPage,
  AboutUsPage,
  ImpressumPage,
  FrequentlyAskedQuestionsPage,
  AccountPage,
  ThankYouPage,
  UnsubscribePage,
  SubscriptionPage,
  AffiliateDashboardPage,
  SignInPage,
  SignUpPage,
  ResetPasswordPage,
  RecoverPasswordPage
};

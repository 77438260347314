interface LogoIconProps {
  isContrast?: boolean;
}

const EbpLogo = ({ isContrast }: LogoIconProps) => {
  if (!isContrast) {
    return (
      <svg width="384" height="59" viewBox="0 0 384 59" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3_106)">
        <path d="M242.213 38.9663L239.832 38.1923C238.047 37.6563 237.932 36.9123 237.932 36.5263C237.932 35.5443 238.765 34.8603 240.104 34.8603C240.376 34.831 240.652 34.859 240.913 34.9426C241.174 35.0262 241.414 35.1635 241.619 35.3457C241.823 35.5279 241.988 35.7509 242.101 36.0004C242.214 36.2499 242.274 36.5203 242.276 36.7943H247.276C247.285 35.8869 247.103 34.9878 246.74 34.1562C246.376 33.3245 245.841 32.5792 245.17 31.9693C244.498 31.3593 243.704 30.8985 242.842 30.6172C241.979 30.3359 241.066 30.2405 240.164 30.3373C239.267 30.2465 238.36 30.3412 237.501 30.6156C236.642 30.89 235.848 31.3382 235.17 31.9324C234.491 32.5266 233.942 33.254 233.557 34.0694C233.171 34.8848 232.957 35.7708 232.929 36.6723C232.929 38.5473 233.703 41.4933 238.345 42.7723L240.755 43.5723C242.838 44.2563 242.838 44.9413 242.838 45.2723C242.838 46.5813 241.588 47.1473 240.279 47.1473C239.681 47.1849 239.091 46.9985 238.624 46.6245C238.156 46.2504 237.845 45.7156 237.75 45.1243H232.602C232.602 49.0243 235.578 51.5513 240.22 51.5513C243.702 51.5513 247.867 49.8253 247.867 45.2433C247.927 43.7863 247.629 40.6323 242.213 38.9663Z" fill="white"/>
        <path d="M10.885 17.0084C11.7309 16.6191 12.4315 15.9714 12.886 15.1587C13.3404 14.3459 13.5253 13.4099 13.414 12.4854C13.4522 11.7785 13.3371 11.0718 13.0766 10.4136C12.8161 9.75543 12.4163 9.16135 11.9048 8.6721C11.3932 8.18285 10.7819 7.81 10.1127 7.5791C9.44359 7.3482 8.73243 7.26472 8.028 7.33438H0.5V28.1644H8.713C9.49582 28.2196 10.2815 28.1086 11.0184 27.8387C11.7552 27.5689 12.4267 27.1462 12.9887 26.5985C13.5507 26.0507 13.9905 25.3903 14.2792 24.6606C14.568 23.9309 14.6991 23.1484 14.664 22.3644C14.7261 21.1771 14.3872 20.0035 13.7018 19.0321C13.0164 18.0607 12.0243 17.3479 10.885 17.0084ZM2.523 9.29838H7.823C9.965 9.29838 11.423 10.3984 11.423 12.4824C11.4817 12.9345 11.4444 13.3939 11.3136 13.8307C11.1827 14.2674 10.9613 14.6716 10.6637 15.017C10.366 15.3624 9.99895 15.6411 9.58633 15.835C9.1737 16.0289 8.72483 16.1337 8.269 16.1424H2.523V9.29838ZM8.683 26.0514H2.523V17.9874H8.475C9.53951 18.0702 10.534 18.5502 11.2611 19.3321C11.9881 20.114 12.3947 21.1407 12.4 22.2084C12.412 22.7058 12.3248 23.2006 12.1436 23.664C11.9623 24.1274 11.6907 24.55 11.3444 24.9074C10.9982 25.2647 10.5843 25.5495 10.1268 25.7452C9.66938 25.941 9.17755 26.0437 8.68 26.0474L8.683 26.0514Z" fill="white"/>
        <path d="M32.548 13.6723C32.6516 12.866 32.5791 12.0468 32.3356 11.2712C32.092 10.4956 31.6833 9.782 31.1374 9.17958C30.5915 8.57716 29.9215 8.10024 29.1736 7.78168C28.4257 7.46313 27.6176 7.3105 26.805 7.33432H19.128V28.1643H21.151V19.9503H24.633L30.257 28.0143H32.697L27.043 19.9503C27.8325 19.9478 28.6125 19.7772 29.3311 19.45C30.0496 19.1228 30.6903 18.6464 31.2105 18.0525C31.7308 17.4586 32.1186 16.7607 32.3484 16.0053C32.5781 15.25 32.6445 14.4543 32.543 13.6713L32.548 13.6723ZM21.148 17.9573V9.32732H26.802C29.569 9.32732 30.551 11.4693 30.551 13.6713C30.6309 14.2196 30.5883 14.7788 30.4263 15.3086C30.2642 15.8385 29.9867 16.3258 29.6138 16.7356C29.2408 17.1454 28.7817 17.4674 28.2694 17.6785C27.7571 17.8895 27.2044 17.9844 26.651 17.9563L21.148 17.9573Z" fill="white"/>
        <path d="M42.903 7.33435L34.928 28.1644H37.07L39.183 22.6004H48.705L50.818 28.0164H52.958L45.135 7.33435H42.903ZM39.927 20.7243L43.944 10.1014L48.11 20.7243H39.927Z" fill="white"/>
        <path d="M69.089 24.5334L58.644 7.33435H56.502V28.0154H58.496V10.6974L69.03 28.0154H71.082V7.33435H69.089V24.5334Z" fill="white"/>
        <path d="M84.834 7.33435H77.064V28.1644H84.834C90.785 28.1644 93.999 23.7304 93.999 17.8644C93.999 11.9984 90.693 7.33435 84.834 7.33435ZM84.834 26.0514H79.058V9.32435H84.834C89.625 9.32435 92.005 12.9544 92.005 17.7154C92.005 22.4764 89.77 26.0514 84.834 26.0514Z" fill="white"/>
        <path d="M104.142 26.3493C102.947 26.4059 101.778 25.9868 100.892 25.1838C100.005 24.3807 99.4726 23.259 99.411 22.0643H97.447C97.509 23.7807 98.2492 25.4024 99.5052 26.5739C100.761 27.7453 102.43 28.3708 104.147 28.3133C105.719 28.4396 107.285 28.0075 108.569 27.0929C109.854 26.1783 110.775 24.84 111.17 23.3133C111.77 19.3553 109.028 17.3623 105.725 16.6483L102.749 16.0233C99.773 15.3393 99.449 13.8233 99.449 12.7503C99.5115 12.2016 99.6826 11.6708 99.9521 11.1888C100.222 10.7068 100.584 10.2832 101.019 9.94262C101.454 9.60205 101.952 9.35131 102.484 9.20499C103.017 9.05868 103.573 9.0197 104.121 9.09033C104.679 9.02833 105.243 9.08302 105.779 9.25095C106.314 9.41887 106.809 9.69635 107.232 10.0657C107.654 10.4351 107.995 10.8884 108.233 11.3967C108.471 11.905 108.601 12.4572 108.614 13.0183H110.608C110.611 12.1924 110.442 11.3748 110.112 10.6177C109.782 9.86065 109.297 9.18058 108.69 8.62082C108.083 8.06106 107.365 7.63385 106.584 7.3664C105.803 7.09895 104.974 6.9971 104.151 7.06733C102.544 6.96893 100.962 7.50682 99.7487 8.56478C98.5348 9.62273 97.786 11.1158 97.664 12.7213C97.664 14.4473 98.229 16.9463 102.514 17.8993L105.314 18.5243C107.665 19.0893 109.629 20.3693 109.242 22.9243C108.944 24.0093 108.267 24.9514 107.333 25.5793C106.399 26.2073 105.271 26.4794 104.154 26.3463L104.142 26.3493Z" fill="white"/>
        <path d="M167.554 30.4254C165.111 30.4255 162.744 31.2721 160.855 32.8212C158.966 34.3702 157.673 36.526 157.195 38.9215C156.717 41.317 157.083 43.8042 158.233 45.9597C159.382 48.1152 161.243 49.8058 163.498 50.7437C165.754 51.6816 168.265 51.8089 170.603 51.1038C172.942 50.3988 174.964 48.905 176.326 46.8768C177.687 44.8485 178.303 42.4112 178.07 39.9796C177.836 37.548 176.767 35.2725 175.045 33.5404C173.058 31.5519 170.365 30.4318 167.554 30.4254ZM167.554 46.6134C166.444 46.6251 165.355 46.3062 164.427 45.6974C163.499 45.0885 162.773 44.2172 162.341 43.1944C161.91 42.1715 161.792 41.0434 162.004 39.9536C162.215 38.8638 162.746 37.8616 163.529 37.0745C164.312 36.2875 165.312 35.7512 166.4 35.5339C167.489 35.3166 168.618 35.4282 169.643 35.8544C170.668 36.2807 171.543 37.0022 172.157 37.9274C172.77 38.8525 173.095 39.9393 173.089 41.0494C173.097 41.7785 172.959 42.5019 172.684 43.177C172.409 43.8522 172.001 44.4656 171.486 44.9812C170.97 45.4967 170.357 45.9041 169.682 46.1795C169.006 46.4548 168.283 46.5925 167.554 46.5844V46.6134Z" fill="#E75653"/>
        <path d="M157.02 17.4223C157.013 14.9789 156.159 12.6135 154.604 10.7289C153.049 8.84429 150.889 7.55705 148.491 7.0864C146.093 6.61574 143.607 6.99079 141.454 8.14765C139.302 9.30452 137.618 11.1717 136.687 13.4311C135.757 15.6906 135.639 18.2026 136.353 20.5394C137.067 22.8761 138.569 24.8932 140.603 26.2469C142.637 27.6007 145.078 28.2075 147.509 27.964C149.94 27.7204 152.212 26.6417 153.937 24.9113C154.918 23.9272 155.696 22.7591 156.225 21.474C156.754 20.1889 157.024 18.8121 157.02 17.4223ZM140.981 17.4223C141.002 16.1578 141.46 14.9393 142.277 13.9741C143.094 13.0089 144.22 12.3564 145.464 12.1275C146.708 11.8986 147.993 12.1074 149.1 12.7184C150.208 13.3294 151.069 14.305 151.539 15.4794C152.009 16.6537 152.057 17.9544 151.676 19.1605C151.295 20.3666 150.509 21.4036 149.45 22.0954C148.391 22.7872 147.126 23.0911 145.868 22.9555C144.611 22.8198 143.439 22.253 142.552 21.3513C142.044 20.8326 141.643 20.2183 141.374 19.544C141.104 18.8696 140.97 18.1485 140.981 17.4223Z" fill="#E75653"/>
        <path d="M82.033 43.3404H89.414V39.0254H82.034V35.0674H90.454V30.6934H77.034V51.3744H90.722V47.0004H82.033V43.3404Z" fill="white"/>
        <path d="M111.582 30.6934L105.868 43.2804L100.036 30.6934H95.87L93.311 51.3744H98.489L99.769 40.2454L103.969 48.9644H107.748L111.948 40.2454L113.198 51.3744H118.346L115.837 30.6934H111.582Z" fill="white"/>
        <path d="M130.001 30.6933H121.431V51.5233H126.46V44.4713H130.001C130.925 44.5387 131.854 44.4107 132.726 44.0957C133.597 43.7807 134.393 43.2858 135.061 42.6431C135.729 42.0005 136.254 41.2244 136.603 40.3654C136.951 39.5064 137.115 38.5837 137.083 37.6573C137.132 36.7199 136.981 35.7827 136.64 34.9079C136.3 34.033 135.778 33.2403 135.109 32.5821C134.439 31.9239 133.638 31.4152 132.758 31.0897C131.877 30.7641 130.938 30.629 130.001 30.6933ZM129.763 40.1563H126.463V35.0683H129.763C130.083 35.0661 130.399 35.1324 130.69 35.263C130.982 35.3935 131.242 35.5851 131.454 35.8248C131.665 36.0646 131.822 36.3469 131.915 36.6527C132.008 36.9585 132.034 37.2806 131.991 37.5973C132.033 37.9158 132.007 38.2396 131.914 38.5472C131.822 38.8548 131.664 39.1391 131.453 39.3812C131.242 39.6233 130.982 39.8177 130.69 39.9513C130.398 40.085 130.08 40.1549 129.759 40.1563H129.763Z" fill="white"/>
        <path d="M144.94 47.0004V30.6934H139.88V51.5234H148.093C148.569 50.0654 149.045 48.5474 149.551 47.1494L144.94 47.0004Z" fill="white"/>
        <path d="M204.125 43.3404H211.505V39.0254H204.125V35.0674H212.546V30.6934H199.126V51.3744H212.814V47.0004H204.125V43.3404Z" fill="white"/>
        <path d="M221.562 43.3404H228.942V39.0254H221.562V35.0674H229.953V30.6934H216.563V51.3744H230.221V47.0004H221.562V43.3404Z" fill="white"/>
        <path d="M126.519 20.0104H133.036V15.6964H126.519V11.7384H134.732V7.33435H121.431V28.0154H126.519V20.0104Z" fill="white"/>
        <path d="M160.918 28.0143H165.947V21.1143H166.393L170.827 28.0143H176.778L171.72 20.8743C173.19 20.5167 174.489 19.659 175.396 18.4483C176.302 17.2376 176.76 15.7492 176.689 14.2383C176.729 13.3058 176.572 12.3754 176.227 11.5079C175.883 10.6403 175.36 9.85516 174.692 9.2036C174.023 8.55205 173.225 8.04878 172.349 7.72668C171.473 7.40458 170.539 7.27088 169.608 7.33432H165.114C163.765 11.441 162.365 15.5177 160.914 19.5643L160.918 28.0143ZM165.947 11.7083H169.247C169.567 11.7055 169.884 11.7714 170.176 11.9017C170.468 12.0319 170.729 12.2235 170.94 12.4633C171.152 12.7031 171.31 12.9856 171.403 13.2917C171.496 13.5978 171.522 13.9203 171.479 14.2373C171.521 14.5558 171.495 14.8797 171.403 15.1874C171.31 15.495 171.153 15.7794 170.942 16.0215C170.73 16.2637 170.47 16.458 170.178 16.5916C169.886 16.7252 169.568 16.795 169.247 16.7963H165.947V11.7083Z" fill="white"/>
        <path d="M157.258 29.7704L160.918 19.5634C162.366 15.596 163.766 11.5194 165.118 7.33336C165.679 5.54836 166.274 3.76336 166.81 1.94836C162.823 10.8754 159.371 20.1004 155.86 29.2354C153.718 35.1864 151.575 41.1354 149.552 47.0894C149.046 48.5474 148.57 50.0654 148.094 51.4634C147.618 52.8614 146.844 55.2424 146.279 57.1474C150.295 48.1014 153.747 38.9064 157.258 29.7704Z" fill="#E75653"/>
        <path d="M186.688 38.1034L182.82 30.6034H177.106L184.069 43.4884V51.3744H189.306V43.3994L196.329 30.5994H190.556L186.688 38.1034Z" fill="white"/>
        </g>
        <path d="M264.373 29.5041L264.312 18.6623C264.297 14.4392 264.253 10.1308 264.182 5.7371C264.147 3.869 264.122 1.98889 264.075 0.101562C263.883 9.86142 263.892 19.7111 263.873 29.4974C263.906 35.8214 263.938 42.1439 264.008 48.4287C264.022 49.9714 264.052 51.5597 264.069 53.0357C264.085 54.5117 264.124 57.0126 264.172 58.9944C264.36 49.113 264.354 39.2914 264.373 29.5041Z" fill="#E75653"/>
        <path d="M380.982 24.5053C380.871 23.3891 380.379 22.5013 379.504 21.842C378.629 21.1827 377.529 20.853 376.204 20.853C375.271 20.853 374.448 21.0129 373.737 21.3328C373.032 21.6527 372.477 22.0965 372.072 22.6645C371.674 23.2259 371.475 23.8656 371.475 24.5836C371.475 25.1124 371.589 25.5759 371.817 25.9741C372.046 26.3722 372.353 26.715 372.738 27.0022C373.129 27.2829 373.56 27.5244 374.03 27.7268C374.507 27.9291 374.987 28.0988 375.47 28.2359L377.585 28.843C378.224 29.0193 378.864 29.2445 379.504 29.5186C380.144 29.7928 380.728 30.1388 381.257 30.5566C381.792 30.9678 382.219 31.4737 382.539 32.0743C382.866 32.6683 383.029 33.3831 383.029 34.2186C383.029 35.2892 382.751 36.252 382.197 37.1072C381.642 37.9623 380.845 38.6412 379.807 39.1438C378.769 39.64 377.526 39.888 376.077 39.888C374.712 39.888 373.528 39.6628 372.522 39.2124C371.517 38.7554 370.727 38.1255 370.153 37.3226C369.578 36.5197 369.255 35.5895 369.183 34.532H371.063C371.129 35.3218 371.39 35.9909 371.847 36.5393C372.304 37.0876 372.898 37.5054 373.629 37.7926C374.36 38.0733 375.176 38.2136 376.077 38.2136C377.076 38.2136 377.963 38.0472 378.74 37.7143C379.523 37.3748 380.137 36.9048 380.581 36.3043C381.031 35.6972 381.257 34.9922 381.257 34.1893C381.257 33.5104 381.08 32.9425 380.728 32.4855C380.375 32.022 379.882 31.6336 379.249 31.3203C378.623 31.007 377.895 30.7295 377.066 30.488L374.667 29.783C373.094 29.313 371.873 28.6635 371.005 27.8345C370.136 27.0054 369.702 25.9479 369.702 24.662C369.702 23.5849 369.99 22.6384 370.564 21.8224C371.145 20.9999 371.928 20.3602 372.914 19.9032C373.906 19.4397 375.019 19.208 376.253 19.208C377.5 19.208 378.603 19.4365 379.563 19.8934C380.522 20.3504 381.283 20.9803 381.844 21.7832C382.412 22.5796 382.716 23.487 382.755 24.5053H380.982Z" fill="white"/>
        <path d="M354.584 39.5453V19.4919H356.415V37.9003H365.972V39.5453H354.584Z" fill="white"/>
        <path d="M338.212 39.5453V19.4919H349.845V21.1369H340.043V28.6863H349.228V30.3313H340.043V37.9003H350.041V39.5453H338.212Z" fill="white"/>
        <path d="M319.549 21.1369V19.4919H334.09V21.1369H327.735V39.5453H325.904V21.1369H319.549Z" fill="white"/>
        <path d="M317.17 29.5186C317.17 31.6075 316.798 33.4255 316.054 34.9726C315.31 36.5131 314.285 37.7077 312.979 38.5563C311.68 39.3984 310.185 39.8194 308.495 39.8194C306.804 39.8194 305.306 39.3984 304 38.5563C302.701 37.7077 301.68 36.5131 300.935 34.9726C300.198 33.4255 299.829 31.6075 299.829 29.5186C299.829 27.4297 300.198 25.615 300.935 24.0744C301.68 22.5274 302.704 21.3328 304.01 20.4907C305.316 19.6421 306.81 19.2178 308.495 19.2178C310.185 19.2178 311.68 19.6421 312.979 20.4907C314.285 21.3328 315.31 22.5274 316.054 24.0744C316.798 25.615 317.17 27.4297 317.17 29.5186ZM315.388 29.5186C315.388 27.7235 315.088 26.1862 314.487 24.9067C313.887 23.6208 313.067 22.6383 312.029 21.9594C310.992 21.2806 309.813 20.9411 308.495 20.9411C307.183 20.9411 306.008 21.2806 304.97 21.9594C303.932 22.6383 303.109 23.6175 302.502 24.8969C301.902 26.1764 301.601 27.7169 301.601 29.5186C301.601 31.3137 301.902 32.851 302.502 34.1305C303.103 35.4099 303.922 36.3924 304.96 37.0778C305.998 37.7567 307.176 38.0961 308.495 38.0961C309.813 38.0961 310.992 37.7567 312.029 37.0778C313.074 36.3989 313.896 35.4197 314.497 34.1403C315.097 32.8543 315.394 31.3137 315.388 29.5186Z" fill="white"/>
        <path d="M280.373 39.5453V19.4919H282.204V28.6863H293.582V19.4919H295.413V39.5453H293.582V30.3313H282.204V39.5453H280.373Z" fill="white"/>
        <defs>
        <clipPath id="clip0_3_106">
        <rect width="247.373" height="55.199" fill="white" transform="translate(0.5 1.94836)"/>
        </clipPath>
        </defs>
      </svg>
    )
  }

  return (
    <svg width="384" height="59" viewBox="0 0 384 59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3_79)">
    <path d="M242.213 38.8647L239.832 38.0907C238.047 37.5547 237.932 36.8107 237.932 36.4247C237.932 35.4427 238.765 34.7587 240.104 34.7587C240.376 34.7294 240.652 34.7574 240.913 34.8411C241.174 34.9247 241.414 35.062 241.619 35.2442C241.823 35.4264 241.988 35.6494 242.101 35.8989C242.214 36.1484 242.274 36.4188 242.276 36.6927H247.276C247.285 35.7854 247.103 34.8863 246.74 34.0546C246.376 33.223 245.841 32.4777 245.17 31.8677C244.498 31.2577 243.704 30.7969 242.842 30.5157C241.979 30.2344 241.066 30.139 240.164 30.2357C239.267 30.1449 238.36 30.2396 237.501 30.5141C236.642 30.7885 235.848 31.2367 235.17 31.8308C234.491 32.425 233.942 33.1524 233.557 33.9678C233.171 34.7833 232.957 35.6693 232.929 36.5707C232.929 38.4457 233.703 41.3917 238.345 42.6707L240.755 43.4707C242.838 44.1547 242.838 44.8397 242.838 45.1707C242.838 46.4797 241.588 47.0457 240.279 47.0457C239.681 47.0833 239.091 46.8969 238.624 46.5229C238.156 46.1489 237.845 45.614 237.75 45.0227H232.602C232.602 48.9227 235.578 51.4497 240.22 51.4497C243.702 51.4497 247.867 49.7237 247.867 45.1417C247.927 43.6847 247.629 40.5307 242.213 38.8647Z" fill="black"/>
    <path d="M10.885 16.9068C11.7309 16.5175 12.4315 15.8699 12.886 15.0571C13.3404 14.2443 13.5253 13.3083 13.414 12.3838C13.4522 11.677 13.3371 10.9702 13.0766 10.3121C12.8161 9.65387 12.4163 9.05979 11.9048 8.57054C11.3932 8.08129 10.7819 7.70844 10.1127 7.47754C9.44359 7.24664 8.73243 7.16316 8.028 7.23281H0.5V28.0628H8.713C9.49582 28.118 10.2815 28.0071 11.0184 27.7372C11.7552 27.4673 12.4267 27.0446 12.9887 26.4969C13.5507 25.9492 13.9905 25.2888 14.2792 24.559C14.568 23.8293 14.6991 23.0468 14.664 22.2628C14.7261 21.0756 14.3872 19.9019 13.7018 18.9305C13.0164 17.9591 12.0243 17.2464 10.885 16.9068ZM2.523 9.19681H7.823C9.965 9.19681 11.423 10.2968 11.423 12.3808C11.4817 12.8329 11.4444 13.2924 11.3136 13.7291C11.1827 14.1658 10.9613 14.5701 10.6637 14.9154C10.366 15.2608 9.99895 15.5395 9.58633 15.7335C9.1737 15.9274 8.72483 16.0321 8.269 16.0408H2.523V9.19681ZM8.683 25.9498H2.523V17.8858H8.475C9.53951 17.9686 10.534 18.4486 11.2611 19.2305C11.9881 20.0124 12.3947 21.0391 12.4 22.1068C12.412 22.6042 12.3248 23.0991 12.1436 23.5624C11.9623 24.0258 11.6907 24.4485 11.3444 24.8058C10.9982 25.1631 10.5843 25.448 10.1268 25.6437C9.66938 25.8394 9.17755 25.9421 8.68 25.9458L8.683 25.9498Z" fill="black"/>
    <path d="M32.548 13.5708C32.6516 12.7644 32.5791 11.9453 32.3356 11.1696C32.092 10.394 31.6833 9.68043 31.1374 9.07801C30.5915 8.4756 29.9215 7.99868 29.1736 7.68012C28.4257 7.36156 27.6176 7.20894 26.805 7.23276H19.128V28.0628H21.151V19.8488H24.633L30.257 27.9128H32.697L27.043 19.8488C27.8325 19.8462 28.6125 19.6757 29.3311 19.3485C30.0496 19.0212 30.6903 18.5448 31.2105 17.9509C31.7308 17.357 32.1186 16.6592 32.3484 15.9038C32.5781 15.1484 32.6445 14.3528 32.543 13.5698L32.548 13.5708ZM21.148 17.8558V9.22576H26.802C29.569 9.22576 30.551 11.3678 30.551 13.5698C30.6309 14.118 30.5883 14.6772 30.4263 15.2071C30.2642 15.7369 29.9867 16.2243 29.6138 16.6341C29.2408 17.0438 28.7817 17.3658 28.2694 17.5769C27.7571 17.788 27.2044 17.8829 26.651 17.8548L21.148 17.8558Z" fill="black"/>
    <path d="M42.903 7.23279L34.928 28.0628H37.07L39.183 22.4988H48.705L50.818 27.9148H52.958L45.135 7.23279H42.903ZM39.927 20.6228L43.944 9.99979L48.11 20.6228H39.927Z" fill="black"/>
    <path d="M69.089 24.4318L58.644 7.23279H56.502V27.9138H58.496V10.5958L69.03 27.9138H71.082V7.23279H69.089V24.4318Z" fill="black"/>
    <path d="M84.834 7.23279H77.064V28.0628H84.834C90.785 28.0628 93.999 23.6288 93.999 17.7628C93.999 11.8968 90.693 7.23279 84.834 7.23279ZM84.834 25.9498H79.058V9.22279H84.834C89.625 9.22279 92.005 12.8528 92.005 17.6138C92.005 22.3748 89.77 25.9498 84.834 25.9498Z" fill="black"/>
    <path d="M104.142 26.2478C102.947 26.3044 101.778 25.8853 100.892 25.0822C100.005 24.2791 99.4726 23.1575 99.411 21.9628H97.447C97.509 23.6791 98.2492 25.3009 99.5052 26.4723C100.761 27.6437 102.43 28.2693 104.147 28.2118C105.719 28.3381 107.285 27.9059 108.569 26.9913C109.854 26.0768 110.775 24.7384 111.17 23.2118C111.77 19.2538 109.028 17.2608 105.725 16.5468L102.749 15.9218C99.773 15.2378 99.449 13.7218 99.449 12.6488C99.5115 12.1001 99.6826 11.5693 99.9521 11.0873C100.222 10.6052 100.584 10.1816 101.019 9.84106C101.454 9.50048 101.952 9.24975 102.484 9.10343C103.017 8.95712 103.573 8.91814 104.121 8.98877C104.679 8.92676 105.243 8.98146 105.779 9.14938C106.314 9.31731 106.809 9.59478 107.232 9.96418C107.654 10.3336 107.995 10.7868 108.233 11.2951C108.471 11.8034 108.601 12.3557 108.614 12.9168H110.608C110.611 12.0908 110.442 11.2733 110.112 10.5162C109.782 9.75909 109.297 9.07902 108.69 8.51926C108.083 7.95949 107.365 7.53229 106.584 7.26484C105.803 6.99738 104.974 6.89553 104.151 6.96577C102.544 6.86737 100.962 7.40526 99.7487 8.46321C98.5348 9.52117 97.786 11.0142 97.664 12.6198C97.664 14.3458 98.229 16.8448 102.514 17.7978L105.314 18.4228C107.665 18.9878 109.629 20.2678 109.242 22.8228C108.944 23.9078 108.267 24.8498 107.333 25.4777C106.399 26.1057 105.271 26.3778 104.154 26.2448L104.142 26.2478Z" fill="black"/>
    <path d="M167.554 30.3239C165.111 30.3239 162.744 31.1705 160.855 32.7196C158.966 34.2687 157.673 36.4244 157.195 38.8199C156.717 41.2154 157.083 43.7026 158.233 45.8581C159.382 48.0136 161.243 49.7042 163.498 50.6421C165.754 51.58 168.265 51.7073 170.603 51.0023C172.942 50.2972 174.964 48.8034 176.326 46.7752C177.687 44.747 178.303 42.3096 178.07 39.878C177.836 37.4465 176.767 35.171 175.045 33.4389C173.058 31.4504 170.365 30.3303 167.554 30.3239ZM167.554 46.5119C166.444 46.5235 165.355 46.2047 164.427 45.5958C163.499 44.987 162.773 44.1157 162.341 43.0928C161.91 42.07 161.792 40.9419 162.004 39.852C162.215 38.7622 162.746 37.76 163.529 36.973C164.312 36.1859 165.312 35.6496 166.4 35.4323C167.489 35.2151 168.618 35.3266 169.643 35.7529C170.668 36.1791 171.543 36.9007 172.157 37.8258C172.77 38.7509 173.095 39.8377 173.089 40.9479C173.097 41.677 172.959 42.4003 172.684 43.0755C172.409 43.7506 172.001 44.364 171.486 44.8796C170.97 45.3952 170.357 45.8026 169.682 46.0779C169.006 46.3532 168.283 46.4909 167.554 46.4829V46.5119Z" fill="#E75653"/>
    <path d="M157.02 17.3208C157.013 14.8774 156.159 12.512 154.604 10.6273C153.049 8.74273 150.889 7.45549 148.491 6.98483C146.093 6.51418 143.607 6.88922 141.454 8.04609C139.302 9.20296 137.618 11.0701 136.687 13.3296C135.757 15.589 135.639 18.101 136.353 20.4378C137.067 22.7746 138.569 24.7916 140.603 26.1454C142.637 27.4991 145.078 28.1059 147.509 27.8624C149.94 27.6189 152.212 26.5401 153.937 24.8098C154.918 23.8256 155.696 22.6576 156.225 21.3725C156.754 20.0874 157.024 18.7105 157.02 17.3208ZM140.981 17.3208C141.002 16.0562 141.46 14.8378 142.277 13.8726C143.094 12.9073 144.22 12.2548 145.464 12.0259C146.708 11.797 147.993 12.0058 149.1 12.6168C150.208 13.2279 151.069 14.2034 151.539 15.3778C152.009 16.5522 152.057 17.8529 151.676 19.0589C151.295 20.265 150.509 21.3021 149.45 21.9939C148.391 22.6857 147.126 22.9896 145.868 22.8539C144.611 22.7183 143.439 22.1515 142.552 21.2498C142.044 20.731 141.643 20.1168 141.374 19.4424C141.104 18.7681 140.97 18.047 140.981 17.3208Z" fill="#E75653"/>
    <path d="M82.033 43.2388H89.414V38.9238H82.034V34.9658H90.454V30.5918H77.034V51.2728H90.722V46.8988H82.033V43.2388Z" fill="black"/>
    <path d="M111.582 30.5918L105.868 43.1788L100.036 30.5918H95.87L93.311 51.2728H98.489L99.769 40.1438L103.969 48.8628H107.748L111.948 40.1438L113.198 51.2728H118.346L115.837 30.5918H111.582Z" fill="black"/>
    <path d="M130.001 30.5918H121.431V51.4218H126.46V44.3698H130.001C130.925 44.4371 131.854 44.3091 132.726 43.9942C133.597 43.6792 134.393 43.1843 135.061 42.5416C135.729 41.8989 136.254 41.1228 136.603 40.2639C136.951 39.4049 137.115 38.4822 137.083 37.5558C137.132 36.6183 136.981 35.6812 136.64 34.8063C136.3 33.9314 135.778 33.1387 135.109 32.4806C134.439 31.8224 133.638 31.3137 132.758 30.9881C131.877 30.6625 130.938 30.5274 130.001 30.5918ZM129.763 40.0548H126.463V34.9668H129.763C130.083 34.9645 130.399 35.0309 130.69 35.1614C130.982 35.2919 131.242 35.4835 131.454 35.7233C131.665 35.963 131.822 36.2454 131.915 36.5512C132.008 36.857 132.034 37.1791 131.991 37.4958C132.033 37.8143 132.007 38.1381 131.914 38.4457C131.822 38.7532 131.664 39.0375 131.453 39.2797C131.242 39.5218 130.982 39.7161 130.69 39.8498C130.398 39.9834 130.08 40.0533 129.759 40.0548H129.763Z" fill="black"/>
    <path d="M144.94 46.8988V30.5918H139.88V51.4218H148.093C148.569 49.9638 149.045 48.4458 149.551 47.0478L144.94 46.8988Z" fill="black"/>
    <path d="M204.125 43.2388H211.505V38.9238H204.125V34.9658H212.546V30.5918H199.126V51.2728H212.814V46.8988H204.125V43.2388Z" fill="black"/>
    <path d="M221.562 43.2388H228.942V38.9238H221.562V34.9658H229.953V30.5918H216.563V51.2728H230.221V46.8988H221.562V43.2388Z" fill="black"/>
    <path d="M126.519 19.9088H133.036V15.5948H126.519V11.6368H134.732V7.23279H121.431V27.9138H126.519V19.9088Z" fill="black"/>
    <path d="M160.918 27.9128H165.947V21.0128H166.393L170.827 27.9128H176.778L171.72 20.7728C173.19 20.4151 174.489 19.5574 175.396 18.3467C176.302 17.136 176.76 15.6476 176.689 14.1368C176.729 13.2043 176.572 12.2739 176.227 11.4063C175.883 10.5388 175.36 9.75359 174.692 9.10204C174.023 8.45048 173.225 7.94722 172.349 7.62512C171.473 7.30301 170.539 7.16932 169.608 7.23275H165.114C163.765 11.3394 162.365 15.4161 160.914 19.4628L160.918 27.9128ZM165.947 11.6068H169.247C169.567 11.6039 169.884 11.6698 170.176 11.8001C170.468 11.9304 170.729 12.1219 170.94 12.3617C171.152 12.6016 171.31 12.8841 171.403 13.1902C171.496 13.4962 171.522 13.8187 171.479 14.1358C171.521 14.4543 171.495 14.7781 171.403 15.0858C171.31 15.3934 171.153 15.6778 170.942 15.92C170.73 16.1621 170.47 16.3565 170.178 16.4901C169.886 16.6237 169.568 16.6934 169.247 16.6948H165.947V11.6068Z" fill="black"/>
    <path d="M157.258 29.6688L160.918 19.4618C162.366 15.4945 163.766 11.4178 165.118 7.2318C165.679 5.4468 166.274 3.6618 166.81 1.8468C162.823 10.7738 159.371 19.9988 155.86 29.1338C153.718 35.0848 151.575 41.0338 149.552 46.9878C149.046 48.4458 148.57 49.9638 148.094 51.3618C147.618 52.7598 146.844 55.1408 146.279 57.0458C150.295 47.9998 153.747 38.8048 157.258 29.6688Z" fill="#E75653"/>
    <path d="M186.688 38.0018L182.82 30.5018H177.106L184.069 43.3868V51.2728H189.306V43.2978L196.329 30.4978H190.556L186.688 38.0018Z" fill="black"/>
    </g>
    <path d="M264.373 29.4026L264.312 18.5607C264.297 14.3377 264.253 10.0293 264.182 5.63553C264.147 3.76744 264.122 1.88733 264.075 0C263.883 9.75985 263.892 19.6095 263.873 29.3958C263.906 35.7198 263.938 42.0423 264.008 48.3271C264.022 49.8698 264.052 51.4581 264.069 52.9341C264.085 54.4102 264.124 56.9111 264.172 58.8929C264.36 49.0114 264.354 39.1898 264.373 29.4026Z" fill="#E75653"/>
    <path d="M380.982 24.4037C380.871 23.2875 380.379 22.3997 379.504 21.7404C378.629 21.0811 377.529 20.7514 376.204 20.7514C375.271 20.7514 374.448 20.9114 373.737 21.2312C373.032 21.5511 372.477 21.995 372.072 22.5629C371.674 23.1243 371.475 23.764 371.475 24.4821C371.475 25.0108 371.589 25.4743 371.817 25.8725C372.046 26.2707 372.353 26.6134 372.738 26.9006C373.129 27.1813 373.56 27.4228 374.03 27.6252C374.507 27.8276 374.987 27.9973 375.47 28.1344L377.585 28.7414C378.224 28.9177 378.864 29.1429 379.504 29.4171C380.144 29.6912 380.728 30.0372 381.257 30.455C381.792 30.8662 382.219 31.3721 382.539 31.9727C382.866 32.5667 383.029 33.2815 383.029 34.1171C383.029 35.1876 382.751 36.1505 382.197 37.0056C381.642 37.8608 380.845 38.5396 379.807 39.0423C378.769 39.5384 377.526 39.7864 376.077 39.7864C374.712 39.7864 373.528 39.5612 372.522 39.1108C371.517 38.6539 370.727 38.0239 370.153 37.221C369.578 36.4181 369.255 35.4879 369.183 34.4304H371.063C371.129 35.2203 371.39 35.8894 371.847 36.4377C372.304 36.986 372.898 37.4038 373.629 37.691C374.36 37.9717 375.176 38.1121 376.077 38.1121C377.076 38.1121 377.963 37.9456 378.74 37.6127C379.523 37.2733 380.137 36.8033 380.581 36.2027C381.031 35.5956 381.257 34.8906 381.257 34.0877C381.257 33.4088 381.08 32.8409 380.728 32.3839C380.375 31.9205 379.882 31.5321 379.249 31.2187C378.623 30.9054 377.895 30.628 377.066 30.3864L374.667 29.6814C373.094 29.2114 371.873 28.5619 371.005 27.7329C370.136 26.9039 369.702 25.8464 369.702 24.5604C369.702 23.4833 369.99 22.5368 370.564 21.7208C371.145 20.8983 371.928 20.2586 372.914 19.8017C373.906 19.3382 375.019 19.1064 376.253 19.1064C377.5 19.1064 378.603 19.3349 379.563 19.7919C380.522 20.2488 381.283 20.8787 381.844 21.6817C382.412 22.478 382.716 23.3854 382.755 24.4037H380.982Z" fill="black"/>
    <path d="M354.584 39.4437V19.3904H356.415V37.7987H365.972V39.4437H354.584Z" fill="black"/>
    <path d="M338.212 39.4437V19.3904H349.845V21.0354H340.043V28.5848H349.228V30.2298H340.043V37.7987H350.041V39.4437H338.212Z" fill="black"/>
    <path d="M319.549 21.0354V19.3904H334.09V21.0354H327.735V39.4437H325.904V21.0354H319.549Z" fill="black"/>
    <path d="M317.17 29.417C317.17 31.5059 316.798 33.3239 316.054 34.871C315.31 36.4116 314.285 37.6061 312.979 38.4548C311.68 39.2968 310.185 39.7179 308.495 39.7179C306.804 39.7179 305.306 39.2968 304 38.4548C302.701 37.6061 301.68 36.4116 300.935 34.871C300.198 33.3239 299.829 31.5059 299.829 29.417C299.829 27.3282 300.198 25.5134 300.935 23.9729C301.68 22.4258 302.704 21.2312 304.01 20.3891C305.316 19.5405 306.81 19.1162 308.495 19.1162C310.185 19.1162 311.68 19.5405 312.979 20.3891C314.285 21.2312 315.31 22.4258 316.054 23.9729C316.798 25.5134 317.17 27.3282 317.17 29.417ZM315.388 29.417C315.388 27.6219 315.088 26.0846 314.487 24.8052C313.887 23.5192 313.067 22.5368 312.029 21.8579C310.992 21.179 309.813 20.8395 308.495 20.8395C307.183 20.8395 306.008 21.179 304.97 21.8579C303.932 22.5368 303.109 23.5159 302.502 24.7954C301.902 26.0748 301.601 27.6154 301.601 29.417C301.601 31.2122 301.902 32.7495 302.502 34.0289C303.103 35.3084 303.922 36.2908 304.96 36.9762C305.998 37.6551 307.176 37.9945 308.495 37.9945C309.813 37.9945 310.992 37.6551 312.029 36.9762C313.074 36.2973 313.896 35.3182 314.497 34.0387C315.097 32.7527 315.394 31.2122 315.388 29.417Z" fill="black"/>
    <path d="M280.373 39.4437V19.3904H282.204V28.5848H293.582V19.3904H295.413V39.4437H293.582V30.2298H282.204V39.4437H280.373Z" fill="black"/>
    <defs>
    <clipPath id="clip0_3_79">
    <rect width="247.373" height="55.199" fill="white" transform="translate(0.5 1.8468)"/>
    </clipPath>
    </defs>
    </svg>
  )
};

export default EbpLogo;

import Stack from '@mui/material/Stack';
import { push } from 'connected-react-router';
import { pick, stringifyUrl } from 'query-string';
import { useContext, useEffect, VFC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { I18n } from 'src/components/I18n';
import { DefaultAppMessagesTypeKey } from 'src/containers/ConnectedIntl/messages/defaultMessages.d';
import useConfig, { EFilterAction } from 'src/hooks/swr/useConfig';
import { useExistedPrevious } from 'src/hooks/usePrevious';
import {
  parseClientOptions,
  stringifyClientOptions,
} from 'src/modules/queryStringOptions';

import {
  ESearchType,
  FiltersContext,
  SearchTypeContext,
} from '../SearchPageProvider';
import { SearchResponseData } from '../SearchResultsList';

import DistanceSection from './DistanceSection';
import FacilitiesSection from './FacilitiesSection';
import Filter from './Filter';
import FilterAdd from './Filter/FilterAdd';
import FilterSet from './Filter/FilterSet';
import SkeletonFilters from './Filter/SceletonFilters';
import FilterGroup from './FilterGroup';
import NameSection from './NameSection';
import PriceSection from './PriceSection';
import PropertyTypesSection from './PropertyTypesSection';
import RatingSection from './RatingSection';
import StarSection from './StarSection';

import './filters.css';

export type FiltersProps = { filtersMeta?: SearchResponseData['filtersMeta'] };

export type StarsRating = 1 | 2 | 3 | 4 | 5;

export type FiltersForm = {
  name: string;
  distanceToPoint: number | null;
  priceFrom: number | null;
  priceTo: number | null;
  stars: Set<StarsRating>;
  suitableTypes: Set<number>;
  facilities: Set<number>;
  rating: Set<number>;
  hideSoldOut: boolean | null;
  propertyTypes: Set<number>;
};

type SetToArr<T extends Record<string, any>> = {
  [key in keyof T]: T[key] extends Set<infer K> ? K[] | undefined : T[key];
};

export const getUrlWithoutFilters = () =>
  `/search${pick(
    window.location.search,
    ['q', 'placeId', 'dates', 'businessTrip', 'rooms', 'sorting'],
    parseClientOptions
  )}`;

export type FiltersQueryParams = SetToArr<FiltersForm>;

const Filters: VFC<FiltersProps> = ({ filtersMeta }) => {
  const { data: config } = useConfig();

  const prevFiltersMeta = useExistedPrevious(filtersMeta);

  const isConfigLoading = !config;

  filtersMeta ??= prevFiltersMeta;

  const isLoading = isConfigLoading || !filtersMeta;

  const searchType = useContext(SearchTypeContext);

  const availableFilters = useContext(FiltersContext);
  const propertyTypesSet = new Set(
    availableFilters?.availableFilters.propertyTypes
  );
  const starsSet = availableFilters?.availableFilters.stars?.reduce<
    Record<number, boolean>
  >((acc, item) => ({ ...acc, [item]: true }), {});

  return (
    <Stack spacing={{ xs: 7, md: 10 }}>
      <FilterGroup
        title={<I18n id="SEARCH_PAGE.FILTERS_PANEL.NAME_CONTAINS" />}
      >
        <NameSection isLoading={isConfigLoading} />
      </FilterGroup>
      <FilterGroup title={<I18n id="SEARCH_PAGE.FILTERS_PANEL.STARS_TITLE" />}>
        <StarSection disabled={isConfigLoading} starsMeta={starsSet} />
      </FilterGroup>
      <FilterGroup
        title={<I18n id="SEARCH_PAGE.FILTERS_PANEL.PROPERTY_TYPE_TITLE" />}
      >
        <Stack spacing={4}>
          {config ? (
            <PropertyTypesSection
              propertyTypes={config.propertyTypes}
              propertyTypesSet={propertyTypesSet}
              disabled={false}
            />
          ) : (
            <SkeletonFilters count={10} />
          )}
        </Stack>
      </FilterGroup>
      <FilterGroup
        title={<I18n id="SEARCH_PAGE.FILTERS_PANEL.PRICE_TITLE" />}
        dropdown
      >
        <div>
          <PriceSection
            min={filtersMeta?.priceMin}
            max={filtersMeta?.priceMax}
            disabled={isLoading}
          />
        </div>
      </FilterGroup>
      <FilterGroup
        title={<I18n id="SEARCH_PAGE.FILTERS_PANEL.HOT_FILTERS_TITLE" />}
      >
        <Stack spacing={4}>
          {isConfigLoading ? (
            <SkeletonFilters count={5} />
          ) : (
            <>
              {config.hotFilters.map(({ name, action, value, id }) => {
                let Component;
                let i18nId;

                switch (action) {
                  case EFilterAction.ADD:
                    Component = FilterAdd;

                    i18nId =
                      name === 'facilities'
                        ? `FACILITIES.${value}`
                        : `HOT_FILTERS.${id}`;
                    break;

                  case EFilterAction.SET:
                    Component = FilterSet;

                    i18nId = `HOT_FILTERS.${id}`;
                    break;
                }

                return (
                  <>
                    {filtersMeta?.hotFiltersSet.has(id) && (
                      <Component
                        key={id}
                        disabled={!filtersMeta?.hotFiltersSet.has(id)}
                        label={
                          <I18n id={i18nId as DefaultAppMessagesTypeKey} />
                        }
                        value={value}
                        name={name}
                      />
                    )}
                  </>
                );
              })}
              <Controller<FiltersForm, 'hideSoldOut'>
                name="hideSoldOut"
                render={({ field }) => (
                  <Filter
                    checked={field.value ?? searchType !== ESearchType.POI}
                    label={<I18n id="HOT_FILTERS.HIDE_SOLD_OUT" />}
                    onChange={(_, v) => field.onChange(v)}
                  />
                )}
              />
            </>
          )}
        </Stack>
      </FilterGroup>
      {searchType !== ESearchType.COUNTRY && (
        <FilterGroup
          title={
            <I18n
              id={
                searchType === ESearchType.POI
                  ? 'SEARCH_PAGE.FILTERS_PANEL.DISTANCE_TO_POINT_TITLE'
                  : 'SEARCH_PAGE.FILTERS_PANEL.DISTANCE_TITLE'
              }
            />
          }
        >
          <DistanceSection
            disabled={isConfigLoading}
            max={filtersMeta?.distanceMax}
            min={filtersMeta?.distanceMin}
          />
        </FilterGroup>
      )}
      <FilterGroup
        title={<I18n id="SEARCH_PAGE.FILTERS_PANEL.HOTEL_RATING_TITLE" />}
      >
        <Stack spacing={4}>
          {config ? (
            <RatingSection
              ratingMeta={filtersMeta?.rating}
              hotelScoreCategoryOrder={config.hotelScoreCategoryOrder}
            />
          ) : (
            <SkeletonFilters count={5} />
          )}
        </Stack>
      </FilterGroup>
      <FilterGroup
        title={<I18n id="SEARCH_PAGE.FILTERS_PANEL.FACILITIES_TITLE" />}
      >
        <Stack spacing={4}>
          {config ? (
            <FacilitiesSection
              filterableFacilities={config.filterableFacilities}
              facilitiesSet={filtersMeta?.facilitiesSet}
            />
          ) : (
            <SkeletonFilters count={10} />
          )}
        </Stack>
      </FilterGroup>
    </Stack>
  );
};

export default Filters;

import { VFC } from 'react';

import useAddToSet from './useAddToSet';

import Filter, { FilterProps } from '.';

const FilterAdd: VFC<FilterProps> = ({ label, disabled, name, value }) => {
  const { set, handleChange } = useAddToSet(name);

  const selected = set.has(value);

  return (
    <Filter
      label={label}
      disabled={!selected && disabled}
      checked={selected}
      onChange={(_, checked) => handleChange(checked, value)}
    />
  );
};

export default FilterAdd;

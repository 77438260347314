import styled, { DefaultTheme } from 'styled-components';

export const HorizontalSelectContainer = styled('div')`
  padding: 1px 2px;
  border-radius: 15px / 50%;
  border: ${({ theme }) => theme.custom.roomLayoutSelectBorder};
  background: color-mix(
    in srgb,
    ${(props) => props.theme.custom.blackSecondaryColor},
    transparent 90%
  );
`;

export const HorizontalSelectInner = styled('div')`
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const HorizontalSelectToggler = styled('div')<{
  selectedRight: boolean;
}>`
  position: absolute;
  border-radius: 15px / 50%;
  width: 50%;
  height: 100%;
  z-index: 1;
  background: ${(props) => props.theme.custom.whiteMainColor};

  transition: 0.1s;
  left: ${(props) => (props.selectedRight ? '50%' : '0')};
`;

export const HorizontalSelectElementWrapper = styled('div')(({ selected, theme }: { selected: boolean, theme: DefaultTheme }) => `
  padding: 5px 20px;
  ${theme.breakpoints.down('sm')} {
    padding: 5px 15px;
  }
  color: ${selected ? theme.custom.roomLayoutSelectButtonActiveColor : theme.custom.roomLayoutSelectButtonColor};
  z-index: 2;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  user-select: none;
`)
